import React from 'react';
import {
  Paper,
  Table,
  Typography,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Box
} from '@mui/material';
import { translate } from '../../app';
import EmptyPage from '../../../EmptyPage';

function createData(name, type) {
  return { name, type };
}

const language = localStorage.getItem('currentLanguage');

const rows = [
  createData(translate(language, 'Display Name'), 'String'),
  createData(translate(language, 'Email'), 'String'),
  createData(translate(language, 'Mobile Number'), 'String'),
  createData(translate(language, 'Gender'), 'String'),
  createData(translate(language, 'Date of Birth'), 'Date'),
  createData(translate(language, 'Join Time'), 'Date')
];
export default function ProfileAttributes() {
  return (
    <div>
      <Typography variant="h5" align="left">
        {translate(language, 'Defined Profile Attributes')}
      </Typography>
      <Typography variant="subtitle2" align="left">
        {translate(language, 'Numbers of Attributes (6)')}
      </Typography>
      <TableContainer component={Paper}>
        <Table aria-label="caption table">
          <caption>{translate(language, 'Here we can write caption for the Attributes')}</caption>
          <TableHead>
            <TableRow>
              <TableCell align="left">{translate(language, 'Attributes Name')}</TableCell>
              <TableCell align="right">{translate(language, 'Attributes Type')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.name}>
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell align="right">{row.type}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Typography variant="h5" paddingTop={2} align="left">
        {translate(language, 'Custom Profile Attributes')}
      </Typography>
      <Typography variant="subtitle2" align="left">
        {translate(language, 'Numbers of Attributes (0)')}
      </Typography>
      <Box
        mt={0.5}
        mb={0.5}
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          '& > :not(style)': {
            mt: 6,
            mb: 4,
            width: 400
          }
        }}
      >
        <EmptyPage language={language} name={translate(language, 'Custom Profile Attributes')} />
      </Box>
    </div>
  );
}
