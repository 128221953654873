import {
  Card,
  Typography,
  Toolbar,
  Table,
  TableHead,
  TableContainer,
  TableCell,
  TableRow,
  TablePagination,
  TableBody
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import PropTypes from 'prop-types';
import moment from 'moment';
import Label from '../../Label';
import PlayerListToolbar from './PlayerListToolbar';
import Scrollbar from '../../Scrollbar';
import CenterSpinner from '../../CenterSpinner';
import { translate } from '../app';

const RootStyle = styled(Toolbar)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3)
}));

export default function PointsListTable({ language }) {
  const [sortType, setSortType] = useState('Creation date by newest first');
  const [transData, setTransData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const fetchTransData = async () => {
    setLoading(true);
    try {
      const res = await axios.get('transaction');
      setTransData(res.data.data);
    } catch (error) {
      toast(error.response.data.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchTransData();
  }, []);

  if (loading) {
    return <CenterSpinner />;
  }

  return (
    <Card>
      <PlayerListToolbar language={language} sortType={sortType} setSortType={setSortType} />
      <ToastContainer
        position="bottom-right"
        autoClose={2500}
        newestOnTop
        closeOnClick
        pauseOnFocusLoss
        pauseOnHover
        limit={2}
      />
      <RootStyle>
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
          <Typography mr={2} variant="h6">
            {translate(language, 'Transaction')} ({transData.length})
          </Typography>
          <Typography mr={2} variant="h6">
            {translate(language, 'Debit')} (
            {transData
              .filter((item) => item.transaction_type === 'dr')
              .reduce((prev, curr) => prev + curr.points, 0)}
            )
          </Typography>
          <Typography variant="h6">
            {translate(language, 'Credit')} (
            {transData
              .filter((item) => item.transaction_type === 'cr')
              .reduce((prev, curr) => prev + curr.points, 0)}
            )
          </Typography>
        </div>
      </RootStyle>

      <Scrollbar>
        <TableContainer style={{ marginTop: '20px' }} sx={{ minWidth: 800 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="left">{translate(language, 'Creation Time')}</TableCell>
                <TableCell align="left">{translate(language, 'Player ID')}</TableCell>
                <TableCell align="left">{translate(language, 'Player Name')}</TableCell>
                {/* <TableCell align="left">{translate(language,"Group Name")}</TableCell> */}
                <TableCell align="left">{translate(language, 'Transaction Type')}</TableCell>
                <TableCell align="left">{translate(language, 'Points')}</TableCell>
                {/* <TableCell align="left">{translate(language,"Status")}</TableCell>
                <TableCell align="left">{translate(language,"Paid Amount")}</TableCell>
                <TableCell align="left">{translate(language,"Redeemed Amount")}</TableCell> */}
              </TableRow>
            </TableHead>

            <TableBody>
              {transData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(
                  ({
                    created_on: createdOn,
                    trans_id: transId,
                    players_id: playerId,
                    transaction_type: transType,
                    points,
                    name
                  }) => (
                    <TableRow hover key={transId}>
                      <TableCell align="left">
                        {moment(createdOn).format('DD-MM-YYYY hh:mm a')}
                      </TableCell>
                      <TableCell align="left">{playerId}</TableCell>
                      <TableCell align="left">{name}</TableCell>
                      {/* <TableCell align="left">{groupName}</TableCell> */}
                      <TableCell align="left">
                        <Label variant="ghost" color={(transType === 'dr' && 'error') || 'success'}>
                          {transType === 'dr' ? 'Debit' : 'Credit'}
                        </Label>
                      </TableCell>
                      <TableCell align="left">{points}</TableCell>
                      {/* <TableCell align="left">status</TableCell>
                    <TableCell align="left">paid amt</TableCell>
                    <TableCell align="left">rdmd amot</TableCell> */}
                    </TableRow>
                  )
                )}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={transData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Card>
  );
}

PointsListTable.propTypes = {
  language: PropTypes.element
};
