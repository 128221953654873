import PropTypes from 'prop-types';

const Coins = ({ width, height }) => (
  <svg width={width || '41'} height={height || '41'} viewBox="0 0 41 41" fill="none">
    <path
      d="M32.8591 22.3858L32.8532 25.7436C32.8532 24.2559 31.877 22.7622 29.9129 21.6332C28.9426 21.0687 27.8135 20.6512 26.6139 20.3689C23.0149 19.5279 18.7338 19.9454 15.8288 21.6332C13.9117 22.7504 12.9473 24.2149 12.9414 25.685L12.9532 22.3272C12.9591 20.8628 13.9176 19.3987 15.8406 18.2814C18.7456 16.5936 23.0267 16.17 26.6256 17.0111C27.8253 17.2933 28.9485 17.7166 29.9247 18.2754C31.8829 19.4104 32.865 20.8981 32.8591 22.3858Z"
      fill="#F2BC36"
    />
    <path
      d="M32.8591 22.3853L32.8495 25.7409C32.8454 27.2087 31.8825 28.6742 29.959 29.792L29.9685 26.4365C31.892 25.3186 32.8549 23.8531 32.8591 22.3853Z"
      fill="#F9C73A"
    />
    <path
      d="M12.9434 25.6838L12.9529 22.3283C12.9487 23.8145 13.9272 25.3031 15.8865 26.4343C19.7838 28.6844 26.1008 28.6844 29.969 26.4365L29.9595 29.7921C26.0913 32.04 19.7743 32.04 15.877 29.7899C13.9177 28.6587 12.9392 27.1701 12.9434 25.6838Z"
      fill="#F9C73A"
    />
    <path
      d="M29.9233 19.1699C33.8206 21.42 33.8413 25.079 29.9693 27.3292C26.1011 29.5771 19.7841 29.5771 15.8868 27.327C11.9933 25.0791 11.9726 21.4201 15.8408 19.1721C19.7128 16.9219 26.0299 16.922 29.9233 19.1699Z"
      fill="#F9C73A"
    />
    <path
      d="M35.8047 22.3936L35.7952 25.7491C35.7898 27.6499 34.5412 29.5477 32.0468 30.9973C27.0261 33.915 18.8565 33.9128 13.8029 30.9951C11.2598 29.5268 9.98968 27.5997 9.99513 25.6755L10.0046 22.3199C9.99919 24.2441 11.2693 26.1713 13.8124 27.6395C18.8661 30.5573 27.0356 30.5594 32.0564 27.6417C34.5507 26.1922 35.7993 24.2943 35.8047 22.3936Z"
      fill="#F2BC36"
    />
    <path
      d="M31.9973 17.0718C37.0509 19.9895 37.0775 24.7239 32.0566 27.6417C27.0359 30.5594 18.8663 30.5573 13.8127 27.6395C8.75901 24.7218 8.7323 19.9919 13.753 17.0741C18.7739 14.1563 26.9436 14.1541 31.9973 17.0718ZM15.8866 26.4343C19.7839 28.6844 26.1009 28.6844 29.9691 26.4365C33.8411 24.1863 33.8204 20.5272 29.9231 18.2772C26.0296 16.0292 19.7126 16.0292 15.8406 18.2794C11.9724 20.5273 11.9931 24.1864 15.8866 26.4343Z"
      fill="#FED44C"
    />
    <path
      d="M27.7595 20.0424L27.7576 20.7341L27.2139 21.05L27.2158 20.3584L27.7595 20.0424Z"
      fill="#F2BC36"
    />
    <path
      d="M23.1348 21.6702L23.1367 20.9786C23.1372 20.8177 23.2552 20.6703 23.488 20.535C23.7274 20.3959 24.0186 20.3333 24.3588 20.3459C24.7018 20.3568 25.0478 20.4475 25.3972 20.6179L25.3952 21.3096C25.0459 21.1392 24.6998 21.0485 24.3569 21.0375C24.0167 21.025 23.7255 21.0875 23.486 21.2267C23.2533 21.3619 23.1352 21.5094 23.1348 21.6702Z"
      fill="#F2BC36"
    />
    <path
      d="M26.053 21.0339L26.051 21.7255L24.0488 22.8891L24.0508 22.1974L26.053 21.0339Z"
      fill="#F2BC36"
    />
    <path
      d="M26.9705 22.2771L26.9686 22.9688C26.8309 22.5028 26.5235 22.0884 26.0518 21.7255L26.0537 21.0339C26.5254 21.3967 26.8328 21.8112 26.9705 22.2771Z"
      fill="#F2BC36"
    />
    <path
      d="M28.648 22.1114L28.6461 22.803L26.9678 22.9688L26.9697 22.2771L28.648 22.1114Z"
      fill="#F2BC36"
    />
    <path
      d="M21.2148 21.5642L21.2168 20.8725C21.2167 20.9105 21.2197 20.9487 21.2259 20.987C21.2876 21.3842 21.6278 21.8737 22.2438 22.4508L22.2418 23.1424C21.6258 22.5654 21.2856 22.0759 21.224 21.6787C21.2178 21.6403 21.2147 21.6021 21.2148 21.5642Z"
      fill="#F2BC36"
    />
    <path
      d="M23.221 23.7246L23.2191 24.4163C23.2191 24.4082 23.2188 24.4001 23.2183 24.3919C23.2066 24.2167 23.0486 23.9665 22.7471 23.646L22.749 22.9543C23.0506 23.2749 23.2085 23.5251 23.2203 23.7003C23.2208 23.7084 23.2211 23.7165 23.221 23.7246Z"
      fill="#F2BC36"
    />
    <path
      d="M22.7487 22.9543L22.7468 23.646L20.8281 24.761L20.8301 24.0693L22.7487 22.9543Z"
      fill="#F2BC36"
    />
    <path
      d="M19.0119 24.3289L19.0099 25.0205C18.129 24.3966 17.6389 23.6647 17.5342 22.8219L17.5361 22.1302C17.6408 22.9731 18.131 23.7049 19.0119 24.3289Z"
      fill="#F2BC36"
    />
    <path
      d="M18.9224 25.178L18.9204 25.8697L18.2324 25.4725L18.2344 24.7808L18.9224 25.178Z"
      fill="#F2BC36"
    />
    <path
      d="M19.6888 24.7324L19.6868 25.424L18.9199 25.8697L18.9219 25.1781L19.6888 24.7324Z"
      fill="#F2BC36"
    />
    <path
      d="M25.124 23.8288L25.1221 24.5204C25.121 24.891 24.8372 25.2397 24.2726 25.5678C23.6483 25.9306 22.9201 26.0965 22.0886 26.0683C21.2541 26.0386 20.4538 25.8228 19.6875 25.424L19.6895 24.7324C20.4557 25.1311 21.2561 25.3469 22.0905 25.3766C22.9221 25.4048 23.6502 25.239 24.2745 24.8762C24.8391 24.5481 25.123 24.1993 25.124 23.8288Z"
      fill="#F2BC36"
    />
    <path
      d="M25.1153 23.7159C25.177 24.1287 24.8958 24.5149 24.2741 24.8762C23.6498 25.239 22.9217 25.4048 22.0901 25.3766C21.2557 25.3469 20.4553 25.1311 19.6891 24.7324L18.9221 25.178L18.2342 24.7808L19.0119 24.3289C18.1309 23.7049 17.6408 22.9731 17.5361 22.1302L19.2473 22.0426C19.3293 22.6916 19.6373 23.228 20.1743 23.6534L22.2436 22.4508C21.6276 21.8737 21.2874 21.3842 21.2258 20.987C21.1614 20.5883 21.4373 20.2114 22.0535 19.8533C22.6672 19.4967 23.3844 19.3278 24.2079 19.3513C25.0341 19.3732 25.8101 19.5749 26.5385 19.9549L27.0714 19.6452L27.7594 20.0424L27.2157 20.3584C27.9447 20.8447 28.4231 21.4296 28.6484 22.1114L26.9701 22.2771C26.8324 21.8111 26.525 21.3967 26.0533 21.0339L24.0511 22.1974L24.0836 22.2162C24.7106 22.8026 25.059 23.303 25.1153 23.7159ZM21.944 24.3664C22.3085 24.3868 22.6133 24.3258 22.8635 24.1804C23.1111 24.0365 23.2317 23.8754 23.2199 23.7003C23.2082 23.5251 23.0502 23.2749 22.7487 22.9543L20.83 24.0693C21.209 24.2476 21.5795 24.3461 21.944 24.3664ZM23.5542 21.6892L25.3976 20.6179C25.0482 20.4475 24.7022 20.3568 24.3592 20.3459C24.019 20.3333 23.7278 20.3959 23.4884 20.535C23.2516 20.6726 23.1335 20.8228 23.1372 20.987C23.1435 21.1496 23.2798 21.3842 23.5542 21.6892Z"
      fill="#FED44C"
    />
    <path
      d="M34.0085 17.6985L34.0026 21.0563C34.0026 19.5687 33.0265 18.075 31.0623 16.9459C30.092 16.3814 28.9629 15.9639 27.7633 15.6816C24.1643 14.8406 19.8833 15.2581 16.9782 16.9459C15.0611 18.0632 14.0967 19.5276 14.0908 20.9978L14.1026 17.6399C14.1085 16.1755 15.067 14.7114 16.99 13.5941C19.895 11.9063 24.1761 11.4828 27.7751 12.3238C28.9747 12.6061 30.0979 13.0293 31.0741 13.5881C33.0323 14.7232 34.0144 16.2108 34.0085 17.6985Z"
      fill="#F2BC36"
    />
    <path
      d="M34.0085 17.6981L33.9989 21.0537C33.9948 22.5214 33.0319 23.987 31.1084 25.1048L31.1179 21.7492C33.0415 20.6314 34.0043 19.1659 34.0085 17.6981Z"
      fill="#F9C73A"
    />
    <path
      d="M14.0928 20.9966L14.1023 17.641C14.0981 19.1273 15.0767 20.6159 17.036 21.7471C20.9332 23.9972 27.2503 23.9972 31.1184 21.7492L31.1089 25.1048C27.2407 27.3528 20.9237 27.3527 17.0265 25.1026C15.0672 23.9714 14.0886 22.4828 14.0928 20.9966Z"
      fill="#F9C73A"
    />
    <path
      d="M31.0727 14.4826C34.97 16.7327 34.9907 20.3918 31.1187 22.6419C27.2505 24.8899 20.9335 24.8898 17.0362 22.6398C13.1427 20.3919 13.122 16.7328 16.9902 14.4849C20.8623 12.2347 27.1793 12.2347 31.0727 14.4826Z"
      fill="#F9C73A"
    />
    <path
      d="M36.9541 17.7063L36.9446 21.0619C36.9392 22.9626 35.6906 24.8605 33.1963 26.3101C28.1755 29.2278 20.006 29.2256 14.9523 26.3079C12.4092 24.8396 11.1391 22.9125 11.1445 20.9883L11.1541 17.6327C11.1486 19.5569 12.4187 21.4841 14.9618 22.9523C20.0155 25.87 28.185 25.8722 33.2058 22.9545C35.7001 21.5049 36.9487 19.6071 36.9541 17.7063Z"
      fill="#F2BC36"
    />
    <path
      d="M33.1467 12.3845C38.2003 15.3023 38.2269 20.0366 33.206 22.9545C28.1853 25.8722 20.0157 25.87 14.9621 22.9523C9.90842 20.0346 9.88172 15.3046 14.9024 12.3869C19.9234 9.46905 28.093 9.46683 33.1467 12.3845ZM17.036 21.7471C20.9333 23.9971 27.2503 23.9972 31.1185 21.7492C34.9905 19.499 34.9698 15.84 31.0725 13.5899C27.1791 11.342 20.862 11.342 16.99 13.5922C13.1218 15.8401 13.1425 19.4991 17.036 21.7471Z"
      fill="#FED44C"
    />
    <path
      d="M28.9089 15.3552L28.907 16.0468L28.3633 16.3628L28.3652 15.6711L28.9089 15.3552Z"
      fill="#F2BC36"
    />
    <path
      d="M24.2842 16.983L24.2861 16.2914C24.2866 16.1305 24.4046 15.983 24.6374 15.8478C24.8768 15.7086 25.168 15.6461 25.5082 15.6586C25.8512 15.6696 26.1972 15.7603 26.5466 15.9307L26.5446 16.6223C26.1953 16.4519 25.8492 16.3612 25.5063 16.3503C25.1661 16.3377 24.8749 16.4003 24.6354 16.5394C24.4027 16.6747 24.2846 16.8221 24.2842 16.983Z"
      fill="#F2BC36"
    />
    <path
      d="M27.2024 16.3466L27.2004 17.0383L25.1982 18.2018L25.2002 17.5102L27.2024 16.3466Z"
      fill="#F2BC36"
    />
    <path
      d="M28.1199 17.5899L28.118 18.2815C27.9803 17.8155 27.6729 17.4011 27.2012 17.0383L27.2031 16.3466C27.6749 16.7095 27.9822 17.1239 28.1199 17.5899Z"
      fill="#F2BC36"
    />
    <path
      d="M29.7974 17.4241L29.7955 18.1158L28.1172 18.2815L28.1191 17.5899L29.7974 17.4241Z"
      fill="#F2BC36"
    />
    <path
      d="M22.3643 16.8769L22.3662 16.1853C22.3661 16.2232 22.3692 16.2614 22.3753 16.2998C22.437 16.697 22.7772 17.1865 23.3932 17.7635L23.3913 18.4552C22.7752 17.8781 22.435 17.3886 22.3734 16.9914C22.3672 16.953 22.3642 16.9149 22.3643 16.8769Z"
      fill="#F2BC36"
    />
    <path
      d="M24.3705 19.0374L24.3685 19.729C24.3685 19.7209 24.3682 19.7128 24.3677 19.7047C24.356 19.5295 24.198 19.2793 23.8965 18.9587L23.8984 18.2671C24.2 18.5876 24.358 18.8378 24.3697 19.013C24.3702 19.0212 24.3705 19.0293 24.3705 19.0374Z"
      fill="#F2BC36"
    />
    <path
      d="M23.8982 18.2671L23.8962 18.9587L21.9775 20.0737L21.9795 19.3821L23.8982 18.2671Z"
      fill="#F2BC36"
    />
    <path
      d="M20.1613 19.6416L20.1593 20.3333C19.2784 19.7093 18.7883 18.9775 18.6836 18.1346L18.6855 17.443C18.7902 18.2858 19.2804 19.0177 20.1613 19.6416Z"
      fill="#F2BC36"
    />
    <path
      d="M20.0718 20.4908L20.0698 21.1824L19.3818 20.7852L19.3838 20.0936L20.0718 20.4908Z"
      fill="#F2BC36"
    />
    <path
      d="M20.8382 20.0451L20.8362 20.7368L20.0693 21.1824L20.0713 20.4908L20.8382 20.0451Z"
      fill="#F2BC36"
    />
    <path
      d="M26.2734 19.1415L26.2715 19.8332C26.2704 20.2037 25.9866 20.5525 25.422 20.8806C24.7977 21.2434 24.0696 21.4092 23.238 21.381C22.4035 21.3513 21.6032 21.1355 20.8369 20.7367L20.8389 20.0451C21.6051 20.4439 22.4055 20.6597 23.2399 20.6894C24.0715 20.7176 24.7997 20.5517 25.4239 20.1889C25.9885 19.8608 26.2724 19.5121 26.2734 19.1415Z"
      fill="#F2BC36"
    />
    <path
      d="M26.2647 19.0286C26.3265 19.4414 26.0452 19.8277 25.4235 20.189C24.7993 20.5517 24.0711 20.7176 23.2395 20.6894C22.4051 20.6597 21.6047 20.4439 20.8385 20.0451L20.0716 20.4908L19.3836 20.0936L20.1613 19.6416C19.2804 19.0177 18.7902 18.2858 18.6855 17.443L20.3967 17.3554C20.4787 18.0043 20.7868 18.5407 21.3237 18.9661L23.393 17.7635C22.777 17.1865 22.4368 16.6969 22.3752 16.2997C22.3108 15.901 22.5867 15.5241 23.203 15.166C23.8166 14.8094 24.5338 14.6405 25.3573 14.664C26.1835 14.6859 26.9595 14.8876 27.6879 15.2676L28.2208 14.9579L28.9088 15.3551L28.3651 15.6711C29.0941 16.1574 29.5725 16.7423 29.7978 17.4241L28.1195 17.5899C27.9818 17.1239 27.6744 16.7095 27.2027 16.3466L25.2005 17.5102L25.233 17.5289C25.86 18.1154 26.2084 18.6157 26.2647 19.0286ZM23.0934 19.6792C23.4579 19.6995 23.7627 19.6385 24.0129 19.4931C24.2605 19.3492 24.3811 19.1881 24.3693 19.013C24.3576 18.8378 24.1996 18.5876 23.8981 18.2671L21.9794 19.3821C22.3584 19.5603 22.7289 19.6588 23.0934 19.6792ZM24.7036 17.0019L26.547 15.9307C26.1976 15.7603 25.8516 15.6696 25.5086 15.6586C25.1685 15.6461 24.8772 15.7086 24.6378 15.8478C24.401 15.9854 24.2829 16.1356 24.2866 16.2997C24.2929 16.4624 24.4292 16.697 24.7036 17.0019Z"
      fill="#FED44C"
    />
    <path
      d="M37.2351 12.0607L37.2292 15.4186C37.2292 13.9309 36.253 12.4372 34.2889 11.3082C33.3186 10.7436 32.1895 10.3261 30.9898 10.0439C27.3909 9.20284 23.1098 9.62034 20.2048 11.3082C18.2877 12.4254 17.3233 13.8898 17.3174 15.36L17.3292 12.0022C17.335 10.5378 18.2936 9.07363 20.2165 7.95636C23.1216 6.26855 27.4027 5.84502 31.0016 6.68605C32.2013 6.96831 33.3245 7.39155 34.3006 7.95032C36.2589 9.08539 37.241 10.5731 37.2351 12.0607Z"
      fill="#F2BC36"
    />
    <path
      d="M37.235 12.0603L37.2255 15.4159C37.2214 16.8836 36.2585 18.3492 34.335 19.467L34.3445 16.1115C36.268 14.9936 37.2309 13.5281 37.235 12.0603Z"
      fill="#F9C73A"
    />
    <path
      d="M17.3193 15.3588L17.3289 12.0032C17.3246 13.4895 18.3032 14.9781 20.2625 16.1093C24.1598 18.3593 30.4768 18.3594 34.345 16.1114L34.3355 19.467C30.4673 21.715 24.1503 21.7149 20.253 19.4648C18.2937 18.3336 17.3151 16.8451 17.3193 15.3588Z"
      fill="#F9C73A"
    />
    <path
      d="M34.2993 8.84482C38.1966 11.0949 38.2173 14.754 34.3453 17.0042C30.4771 19.2521 24.1601 19.2521 20.2628 17.002C16.3693 14.7541 16.3486 11.095 20.2168 8.84707C24.0888 6.59688 30.4058 6.59692 34.2993 8.84482Z"
      fill="#F9C73A"
    />
    <path
      d="M40.1807 12.0685L40.1712 15.4241C40.1658 17.3248 38.9172 19.2227 36.4228 20.6723C31.4021 23.59 23.2325 23.5878 18.1789 20.6701C15.6358 19.2018 14.3657 17.2747 14.3711 15.3505L14.3806 11.9949C14.3752 13.9191 15.6453 15.8462 18.1884 17.3145C23.242 20.2322 31.4116 20.2344 36.4323 17.3167C38.9267 15.8671 40.1753 13.9693 40.1807 12.0685Z"
      fill="#F2BC36"
    />
    <path
      d="M36.3732 6.74676C41.4269 9.66449 41.4535 14.3988 36.4326 17.3167C31.4119 20.2344 23.2423 20.2322 18.1886 17.3145C13.135 14.3968 13.1083 9.66683 18.129 6.74911C23.1499 3.83127 31.3196 3.82905 36.3732 6.74676ZM20.2626 16.1093C24.1598 18.3593 30.4769 18.3594 34.3451 16.1114C38.2171 13.8613 38.1964 10.2022 34.2991 7.95212C30.4056 5.70421 24.0886 5.70417 20.2166 7.95436C16.3484 10.2023 16.3691 13.8614 20.2626 16.1093Z"
      fill="#FED44C"
    />
    <path
      d="M32.1355 9.71739L32.1335 10.409L31.5898 10.725L31.5918 10.0333L32.1355 9.71739Z"
      fill="#F2BC36"
    />
    <path
      d="M27.5107 11.3452L27.5127 10.6536C27.5132 10.4927 27.6312 10.3453 27.8639 10.21C28.1034 10.0709 28.3946 10.0083 28.7348 10.0209C29.0777 10.0318 29.4238 10.1225 29.7731 10.2929L29.7712 10.9845C29.4218 10.8141 29.0758 10.7234 28.7328 10.7125C28.3927 10.6999 28.1014 10.7625 27.862 10.9016C27.6292 11.0369 27.5112 11.1843 27.5107 11.3452Z"
      fill="#F2BC36"
    />
    <path
      d="M30.4289 10.7089L30.427 11.4005L28.4248 12.564L28.4268 11.8724L30.4289 10.7089Z"
      fill="#F2BC36"
    />
    <path
      d="M31.3465 11.9521L31.3445 12.6437C31.2068 12.1777 30.8995 11.7633 30.4277 11.4005L30.4297 10.7088C30.9014 11.0717 31.2088 11.4861 31.3465 11.9521Z"
      fill="#F2BC36"
    />
    <path
      d="M33.024 11.7863L33.022 12.478L31.3438 12.6437L31.3457 11.9521L33.024 11.7863Z"
      fill="#F2BC36"
    />
    <path
      d="M25.5908 11.2391L25.5928 10.5475C25.5927 10.5855 25.5957 10.6236 25.6019 10.662C25.6636 11.0592 26.0037 11.5487 26.6198 12.1258L26.6178 12.8174C26.0018 12.2403 25.6616 11.7508 25.6 11.3536C25.5938 11.3153 25.5907 11.2771 25.5908 11.2391Z"
      fill="#F2BC36"
    />
    <path
      d="M27.597 13.3996L27.595 14.0912C27.5951 14.0832 27.5948 14.075 27.5943 14.0669C27.5826 13.8917 27.4246 13.6415 27.123 13.3209L27.125 12.6293C27.4265 12.9498 27.5845 13.2 27.5962 13.3752C27.5968 13.3834 27.597 13.3915 27.597 13.3996Z"
      fill="#F2BC36"
    />
    <path
      d="M27.1247 12.6293L27.1228 13.3209L25.2041 14.4359L25.2061 13.7443L27.1247 12.6293Z"
      fill="#F2BC36"
    />
    <path
      d="M23.3879 14.0038L23.3859 14.6955C22.505 14.0715 22.0148 13.3397 21.9102 12.4968L21.9121 11.8052C22.0168 12.648 22.5069 13.3799 23.3879 14.0038Z"
      fill="#F2BC36"
    />
    <path
      d="M23.2983 14.853L23.2964 15.5447L22.6084 15.1474L22.6104 14.4558L23.2983 14.853Z"
      fill="#F2BC36"
    />
    <path
      d="M24.0648 14.4073L24.0628 15.099L23.2959 15.5447L23.2979 14.853L24.0648 14.4073Z"
      fill="#F2BC36"
    />
    <path
      d="M29.5 13.5038L29.498 14.1954C29.497 14.5659 29.2132 14.9147 28.6485 15.2428C28.0243 15.6056 27.2961 15.7714 26.4645 15.7432C25.6301 15.7136 24.8297 15.4977 24.0635 15.099L24.0654 14.4073C24.8317 14.8061 25.632 15.0219 26.4665 15.0516C27.2981 15.0798 28.0262 14.914 28.6505 14.5512C29.2151 14.223 29.499 13.8743 29.5 13.5038Z"
      fill="#F2BC36"
    />
    <path
      d="M29.4913 13.3908C29.553 13.8036 29.2717 14.1899 28.6501 14.5512C28.0258 14.914 27.2977 15.0798 26.4661 15.0516C25.6316 15.0219 24.8313 14.8061 24.065 14.4073L23.2981 14.853L22.6101 14.4558L23.3879 14.0038C22.5069 13.3799 22.0168 12.648 21.9121 11.8052L23.6233 11.7176C23.7053 12.3665 24.0133 12.903 24.5503 13.3283L26.6196 12.1257C26.0035 11.5487 25.6634 11.0592 25.6017 10.662C25.5374 10.2632 25.8133 9.88634 26.4295 9.52822C27.0431 9.17163 27.7603 9.00272 28.5838 9.02622C29.4101 9.04811 30.186 9.24986 30.9145 9.62983L31.4474 9.32016L32.1353 9.71737L31.5917 10.0333C32.3206 10.5197 32.7991 11.1045 33.0243 11.7863L31.3461 11.9521C31.2084 11.4861 30.901 11.0717 30.4293 10.7088L28.4271 11.8724L28.4596 11.8911C29.0865 12.4776 29.435 12.978 29.4913 13.3908ZM26.32 14.0414C26.6845 14.0618 26.9892 14.0007 27.2395 13.8553C27.4871 13.7114 27.6077 13.5504 27.5959 13.3752C27.5842 13.2 27.4262 12.9498 27.1247 12.6293L25.206 13.7443C25.585 13.9225 25.9554 14.021 26.32 14.0414ZM27.9302 11.3641L29.7735 10.2929C29.4242 10.1225 29.0781 10.0318 28.7352 10.0208C28.395 10.0083 28.1038 10.0709 27.8643 10.21C27.6275 10.3476 27.5095 10.4978 27.5132 10.662C27.5195 10.8246 27.6557 11.0592 27.9302 11.3641Z"
      fill="#FED44C"
    />
    <path
      d="M20.3939 30.8676L17.0663 32.8049C16.3239 33.237 15.4396 33.4192 14.4542 33.3316C13.8265 33.2775 13.1582 33.1088 12.4562 32.8184C12.0782 32.6632 11.6936 32.4743 11.302 32.2446C6.82697 29.6663 3.20235 23.3823 3.21586 18.2524C3.21586 18.2524 3.21586 18.2524 3.21586 18.2458V18.0636C3.24284 16.8553 3.47231 15.8293 3.86381 14.9925C4.0393 14.628 4.24179 14.2971 4.47806 14.0071C4.79528 13.6156 5.16651 13.2913 5.58499 13.0484L8.91261 11.118C8.49413 11.3609 8.1229 11.6783 7.80569 12.0764C7.56942 12.3599 7.36694 12.6908 7.19819 13.0553C6.79998 13.8921 6.57047 14.925 6.5502 16.1263C6.5502 16.1869 6.54348 16.2479 6.54348 16.3155C6.52997 21.4519 10.1546 27.7291 14.6297 30.3143C15.0212 30.5371 15.4126 30.7259 15.7906 30.8815C16.4859 31.1715 17.1541 31.3403 17.7818 31.4009C18.7673 31.4889 19.6515 31.2997 20.3939 30.8676Z"
      fill="#FED44C"
    />
    <path
      d="M20.3955 30.8709L17.0654 32.8061C18.5215 31.96 19.4256 30.1631 19.4329 27.6121L22.7629 25.6769C22.7557 28.2279 21.8515 30.0248 20.3955 30.8709Z"
      fill="#F9C73A"
    />
    <path
      d="M5.58398 13.0512L8.91402 11.116C10.3895 10.2586 12.4318 10.3776 14.6829 11.6773L11.3528 13.6125C9.1018 12.3128 7.05948 12.1938 5.58398 13.0512Z"
      fill="#F9C73A"
    />
    <path
      d="M11.3535 13.6125L14.6836 11.6773C19.1525 14.2574 22.7779 20.5366 22.7633 25.6769L19.4332 27.6121C19.4478 22.4718 15.8224 16.1926 11.3535 13.6125Z"
      fill="#F9C73A"
    />
    <path
      d="M12.1389 13.0115C16.6078 15.5917 20.2332 21.8709 20.2186 27.0111C20.204 32.1463 16.555 34.2256 12.086 31.6455C7.61279 29.0628 3.98743 22.7836 4.002 17.6485C4.01656 12.5082 7.66562 10.4289 12.1389 13.0115Z"
      fill="#F2BC36"
    />
    <path
      d="M11.3611 10.859C17.1617 14.208 21.848 22.33 21.8291 28.9955C21.8102 35.6606 17.0932 38.3486 11.2927 34.9996C5.49211 31.6507 0.801468 23.5312 0.820369 16.8661C0.839262 10.2006 5.56055 7.51005 11.3611 10.859ZM11.3005 32.2464C15.7694 34.8266 19.4185 32.7472 19.433 27.6121C19.4476 22.4718 15.8222 16.1926 11.3533 13.6125C6.88006 11.0299 3.23101 13.1092 3.21643 18.2494C3.20187 23.3846 6.82723 29.6638 11.3005 32.2464Z"
      fill="#F9C73A"
    />
    <path
      d="M11.7762 27.6705L12.3545 27.3344L12.3517 28.3127L11.7734 28.6488L11.7762 27.6705Z"
      fill="#E2AB30"
    />
    <path
      d="M8.98145 23.3765L9.55974 23.0405C10.2206 24.0823 10.9031 24.8712 11.6021 25.4015L11.0238 25.7376C10.3248 25.2073 9.64228 24.4184 8.98145 23.3765Z"
      fill="#FED44C"
    />
    <path
      d="M11.7803 26.2168L12.3586 25.8808C12.7385 26.0548 13.0375 26.1008 13.2607 26.0187L12.6824 26.3548C12.4592 26.4369 12.1602 26.3909 11.7803 26.2168Z"
      fill="#FED44C"
    />
    <path
      d="M13.3419 25.981L12.7636 26.317C12.7385 26.3316 12.7115 26.3442 12.6826 26.3548L13.2609 26.0187C13.2898 26.0081 13.3168 25.9956 13.3419 25.981Z"
      fill="black"
    />
    <path
      d="M11.7872 23.7697L12.3655 23.4336L12.3586 25.8808L11.7803 26.2168L11.7872 23.7697Z"
      fill="#E2AB30"
    />
    <path
      d="M11.792 22.1112L12.3703 21.7751L12.407 21.7963L11.8287 22.1324L11.792 22.1112Z"
      fill="#FED44C"
    />
    <path
      d="M10.1615 18.9842L10.7398 18.6481C10.5711 18.7461 10.4844 18.9323 10.4836 19.2115C10.4827 19.5124 10.5606 19.7987 10.7173 20.0669C10.8766 20.3368 11.1747 20.6777 11.6143 21.0943L11.036 21.4304C10.5964 21.0138 10.2983 20.6729 10.139 20.403C9.98234 20.1347 9.90443 19.8485 9.90528 19.5475C9.90607 19.2684 9.99283 19.0822 10.1615 18.9842Z"
      fill="#FED44C"
    />
    <path
      d="M11.8291 22.1324L12.4074 21.7963C13.313 22.5665 13.9774 23.2816 14.4032 23.9435L13.8249 24.2796C13.3991 23.6177 12.7347 22.9026 11.8291 22.1324Z"
      fill="#FED44C"
    />
    <path
      d="M14.4067 27.5835L13.8284 27.9196C14.2448 27.6776 14.4561 27.1939 14.4582 26.4635C14.4604 25.6689 14.2474 24.9399 13.8242 24.2796L14.4025 23.9435C14.8257 24.6038 15.0388 25.3328 15.0365 26.1274C15.0344 26.8578 14.8231 27.3415 14.4067 27.5835Z"
      fill="#FED44C"
    />
    <path
      d="M11.7992 19.5589L12.3775 19.2228L12.3703 21.7751L11.792 22.1112L11.7992 19.5589Z"
      fill="#E2AB30"
    />
    <path
      d="M9.09277 17.4128L9.67107 17.0768C9.69973 17.0601 9.72936 17.0446 9.75997 17.0302C10.2381 16.8089 10.8597 16.8935 11.6247 17.2898L11.0463 17.6259C10.2814 17.2295 9.6598 17.145 9.18166 17.3663C9.15105 17.3807 9.12142 17.3962 9.09277 17.4128Z"
      fill="#FED44C"
    />
    <path
      d="M11.0488 16.9458L11.6271 16.6097L12.3841 17.0468L11.8058 17.3828L11.0488 16.9458Z"
      fill="#FED44C"
    />
    <path
      d="M11.8057 17.3828L12.384 17.0468L12.382 17.7419L11.8037 18.078L11.8057 17.3828Z"
      fill="#E2AB30"
    />
    <path
      d="M11.8037 18.078L12.382 17.7419C13.2488 18.3148 14.0677 19.1283 14.8387 20.1793L14.2604 20.5154C13.4894 19.4644 12.6705 18.6509 11.8037 18.078Z"
      fill="#FED44C"
    />
    <path
      d="M14.2603 20.5154L14.8386 20.1793L14.0676 20.9883L13.4893 21.3244L14.2603 20.5154Z"
      fill="#E2AB30"
    />
    <path
      d="M13.8247 24.2796C14.2479 24.9399 14.4609 25.6689 14.4587 26.4635C14.4564 27.2551 14.2084 27.757 13.7197 27.9754C13.2285 28.1923 12.5808 28.0897 11.7765 27.6705L11.7737 28.6488L11.0167 28.2117L11.0195 27.2184C9.94069 26.4689 8.97291 25.4157 8.11621 24.059L8.98134 23.3765C9.64217 24.4184 10.3247 25.2073 11.0237 25.7376L11.0312 23.1009C10.1413 22.3429 9.48997 21.6443 9.07725 21.0081C8.66451 20.3718 8.45927 19.6594 8.46148 18.8768C8.4637 18.0942 8.70389 17.5908 9.18204 17.3663C9.66017 17.145 10.2817 17.2295 11.0467 17.6259L11.0487 16.9458L11.8057 17.3828L11.8037 18.078C12.6705 18.6509 13.4894 19.4644 14.2604 20.5154L13.4894 21.3244C12.9693 20.5961 12.4069 20.009 11.7995 19.5589L11.7923 22.1112L11.8289 22.1324C12.7345 22.9026 13.3989 23.6177 13.8247 24.2796ZM12.6827 26.3548C12.9034 26.2741 13.0141 26.0757 13.015 25.7597C13.0159 25.4406 12.9302 25.1409 12.7579 24.8546C12.5829 24.5666 12.2612 24.206 11.7875 23.7697L11.7806 26.2168C12.1605 26.3909 12.4595 26.4369 12.6827 26.3548ZM11.0359 21.4304L11.0426 19.0797C10.6889 18.9116 10.4109 18.8717 10.2086 18.96C10.009 19.0497 9.90607 19.2435 9.90521 19.5476C9.90436 19.8485 9.98226 20.1347 10.1389 20.403C10.2982 20.6729 10.5963 21.0138 11.0359 21.4304Z"
      fill="#F9C73A"
    />
    <path
      d="M25.1619 27.0604C25.1484 30.3747 23.9807 32.7031 22.0975 33.7967L18.7632 35.7339C20.6531 34.6334 21.8208 32.305 21.8275 28.9977C21.8478 22.329 17.1635 14.2089 11.3587 10.861C8.4428 9.17358 5.79687 9.01835 3.88672 10.1254L7.21434 8.19474C9.12449 7.0811 11.7704 7.23632 14.693 8.92377C20.4911 12.2716 25.1754 20.3917 25.1619 27.0604Z"
      fill="#FED44C"
    />
    <path
      d="M17.8432 31.8465L18.0334 32.4883L17.6397 31.947L16.9354 33.2448L17.1658 31.7862L16.524 31.9765L17.0654 31.5828L15.7676 30.8785L17.2261 31.1088L17.0358 30.4671L17.4295 31.0084L18.1338 29.7107L17.9035 31.1692L18.5453 30.979L18.0039 31.3726L19.3017 32.0769L17.8432 31.8465Z"
      fill="white"
    />
    <path
      d="M34.2018 10.7029L34.5544 11.8925L33.8247 10.889L32.5192 13.2946L32.9462 10.5911L31.7566 10.9438L32.7601 10.214L30.3545 8.90859L33.058 9.33552L32.7053 8.14602L33.435 9.14942L34.7405 6.74391L34.3136 9.44734L35.5031 9.09475L34.4997 9.8244L36.9052 11.13L34.2018 10.7029Z"
      fill="white"
    />
  </svg>
);

export default Coins;

Coins.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string
};
