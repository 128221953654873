import { useEffect, useState } from 'react';
import { Container, Stack, Avatar, Typography, Grid } from '@mui/material';
import axios from 'axios';
import Page from '../Page';
import ChallengesCard from './ChallengesCard';
import Fb from './Fb';
import FifthOrder from './FifthOrder';
import FirstOrder from './FirstOrder';
import Insta from './Insta';
import Mail from './Mail';
import { translate } from '../_dashboard/app';
import CenterSpinner from '../CenterSpinner';

const Challenges = () => {
  const [challengesData, setChallengesData] = useState({});
  const [loading, setLoading] = useState(false);
  const language = localStorage.getItem('currentLanguage');

  const fetchChallengesData = async () => {
    try {
      setLoading(true);
      const {
        data: { data }
      } = await axios.get('challenges');
      setChallengesData(data);
    } catch (error) {
      console.error(error);
      // toast.error('Something went wrong!');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchChallengesData();
  }, []);

  if (loading) <CenterSpinner />;

  return (
    <Page
      title={`${translate(language, 'Dashboard')} : ${translate(language, 'Program')} - ${translate(
        language,
        'Challenges'
      )}`}
    >
      <Container>
        <Stack direction="row" justifyContent="space-between" mb={5}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: 'auto',
              alignItems: 'center'
            }}
          >
            <Avatar
              sx={{ width: 56, height: 56 }}
              alt=" "
              src="/static/mock-images/avatars/Challange-Mission.png"
            />
            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '10px' }}>
              <Typography style={{ marginBottom: '-5px' }} variant="h4" gutterBottom>
                {translate(language, 'Challenges')}
              </Typography>
            </div>
          </div>
        </Stack>
        <Grid container spacing={5} mt={2}>
          <Grid item xs={12} md={6}>
            <ChallengesCard
              language={language}
              title={translate(language, 'Sign Up')}
              desc={translate(language, 'Give points when player signs up')}
              jsx={<Mail />}
              challengeName="sign-up"
              data={challengesData['sign-up']}
              fetchData={fetchChallengesData}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ChallengesCard
              language={language}
              title={translate(language, 'First Order')}
              desc={translate(language, 'Give points when player fulfills 1st order')}
              jsx={<FirstOrder />}
              challengeName="first-order"
              data={challengesData['first-order']}
              fetchData={fetchChallengesData}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ChallengesCard
              language={language}
              title={translate(language, 'Fifth Order')}
              desc={translate(language, 'Give points when player fulfills 5th order')}
              jsx={<FifthOrder />}
              challengeName="fifth-order"
              data={challengesData['fifth-order']}
              fetchData={fetchChallengesData}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ChallengesCard
              language={language}
              title={translate(language, 'Follow on Instagram')}
              desc={translate(language, 'Give points when player visits Instagram page')}
              jsx={<Insta />}
              challengeName="insta"
              data={challengesData.insta}
              fetchData={fetchChallengesData}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ChallengesCard
              language={language}
              title={translate(language, 'Follow on Facebook')}
              desc={translate(language, 'Give points when player visits Facebook page')}
              jsx={<Fb />}
              challengeName="fb"
              data={challengesData.fb}
              fetchData={fetchChallengesData}
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default Challenges;
