import React from 'react';
import { Button, Typography, TextField, MenuItem } from '@mui/material';

export default function DefineTrigger() {
  return (
    <div style={{ display: 'flex', justifyContent: 'flex-left' }}>
      <div style={{ display: 'flex', flexDirection: 'column', width: '600px' }}>
        <Typography
          variant="h5"
          gutterBottom
          style={{
            width: '450px'
          }}
        >
          Message Trigger
        </Typography>
        <Typography align="left">Select your message triggers</Typography>
        <TextField select size="medium" value="Language 0" align="left" fullWidth>
          <MenuItem fullWidth required value="Language 0">
            First Page
          </MenuItem>
        </TextField>
        <Typography align="right" pt={5}>
          <Button style={{ height: '50px', width: '100px' }} variant="contained">
            Next
          </Button>
        </Typography>
      </div>
    </div>
  );
}
