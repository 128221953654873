import React, { useRef, useState } from 'react';
import {
  Typography,
  useMediaQuery,
  Grid,
  Switch,
  Card,
  FormControl,
  OutlinedInput,
  CardContent,
  FormGroup,
  FormControlLabel,
  Input,
  IconButton,
  Container
} from '@mui/material';
import { Icon } from '@iconify/react';
import Tooltip from '@mui/material/Tooltip';
import copyIcon from '@iconify/icons-akar-icons/copy';
import checkIcon from '@iconify/icons-jam/check';
import PropTypes from 'prop-types';
import LanguageMenu from './LanguageMenu';
import { translate } from '../app';

export default function WidgetSettingsTab({ language, getFieldProps, setFieldValue }) {
  const [initialIcon, setIcon] = useState('Copy');
  const timer = useRef(null);
  const matchDownSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  return (
    <Container>
      <Typography gutterBottom paddingTop={2} variant="h5" align="left" />
      <Grid container spacing={2}>
        <Grid item xs={matchDownSM ? '12' : '6'}>
          <Typography gutterBottom paddingTop={2} variant="h5" align="left">
            {translate(language, 'Status')}
          </Typography>
          <Card>
            <CardContent>
              <div
                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
              >
                <Typography variant="body">{translate(language, 'Active')}</Typography>
                <Switch
                  checked={Boolean(getFieldProps('isActive').value)}
                  onChange={(e) => {
                    setFieldValue('isActive', e.target.checked);
                  }}
                />
              </div>
            </CardContent>
          </Card>
          <Typography gutterBottom paddingTop={2} variant="h5" align="left">
            {translate(language, 'Appearance')}
          </Typography>
          <Card>
            <CardContent>
              <div
                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
              >
                <Typography variant="body">
                  {translate(language, 'Color')} ({getFieldProps('color').value})
                </Typography>
                <Input
                  onBlur={(event) => setFieldValue('color', event.target.value)}
                  defaultValue={getFieldProps('color').value}
                  type="color"
                  style={{
                    width: '40px',
                    height: '40px',
                    borderRadius: '50%',
                    backgroundColor: '',
                    border: 'solid '
                  }}
                />
              </div>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={matchDownSM ? '12' : '6'}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography gutterBottom paddingTop={2} variant="h5">
              {translate(language, 'Code')}
            </Typography>
            <Tooltip
              title={
                initialIcon === 'check'
                  ? translate(language, 'Copied')
                  : translate(language, 'Copy Code')
              }
              arrow
              placement="top"
            >
              <IconButton
                style={{ paddingBottom: '0px' }}
                onClick={() => {
                  if (initialIcon !== 'check') {
                    navigator.clipboard.writeText(getFieldProps('code').value);
                    timer.current = setTimeout(() => {
                      setIcon('Copy');
                      clearTimeout(timer.current);
                    }, 1000);
                    setIcon('check');
                  }
                }}
              >
                <Icon fontSize={25} icon={initialIcon === 'check' ? checkIcon : copyIcon} />
              </IconButton>
            </Tooltip>
          </div>

          <FormControl variant="outlined" fullWidth>
            <OutlinedInput
              {...getFieldProps('code')}
              style={{ OverflowY: 'scroll' }}
              type="string"
              multiline
              rows={8}
            />
          </FormControl>
        </Grid>
        <Grid item xs={matchDownSM ? '12' : '6'}>
          <Typography gutterBottom paddingTop={2} variant="h5" align="left">
            {translate(language, 'Content')}
          </Typography>
          <Card>
            <CardContent>
              <div
                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
              >
                <Typography variant="body">{translate(language, 'Language')}</Typography>
                <LanguageMenu getFieldProps={getFieldProps} />
                {/* <Field name="language" component={LanguageMenu} /> */}
              </div>
            </CardContent>
          </Card>
          <Typography gutterBottom paddingTop={2} variant="h5" align="left">
            {translate(language, 'Visibility Settings')}
          </Typography>
          <Card>
            <CardContent>
              <FormGroup>
                <FormControlLabel
                  labelPlacement="start"
                  control={
                    <Switch
                      checked={Boolean(getFieldProps('hide_on_load_desktop').value)}
                      onChange={(e) => {
                        setFieldValue('hide_on_load_desktop', e.target.checked);
                      }}
                    />
                  }
                  label={translate(language, 'Hide widget on load in Desktop')}
                  style={{ justifyContent: 'space-between', marginLeft: 0 }}
                />
                <FormControlLabel
                  labelPlacement="start"
                  control={
                    <Switch
                      checked={Boolean(getFieldProps('hide_on_load_mobile').value)}
                      onChange={(e) => {
                        setFieldValue('hide_on_load_mobile', e.target.checked);
                      }}
                    />
                  }
                  label={translate(language, 'Hide widget on load in Mobile')}
                  style={{ justifyContent: 'space-between', marginLeft: 0 }}
                />
              </FormGroup>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}

WidgetSettingsTab.propTypes = {
  getFieldProps: PropTypes.func,
  setFieldValue: PropTypes.func,
  language: PropTypes.element
};
