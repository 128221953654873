import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// material
import {
  Link,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel
} from '@mui/material';
import PropTypes from 'prop-types';
import { LoadingButton } from '@mui/lab';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import { translate } from '../../_dashboard/app';
import { setToken } from '../../../utils/axiosDefault';

// ----------------------------------------------------------------------

export default function LoginForm({ language }) {
  const [showPassword, setShowPassword] = useState(false);
  const urlParams = new URLSearchParams(window.location.search);
  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email(translate(language, 'Email must be a valid email address'))
      .required(translate(language, 'Email is required')),
    password: Yup.string().required('Password is required')
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      remember: true
    },
    validationSchema: LoginSchema,
    onSubmit: async (values) => {
      try {
        const res = await axios.post('user/signin', { ...values });
        localStorage.setItem('loginUser', JSON.stringify(res.data));
        setToken(res.data.token);
        window.location.reload();
      } catch (error) {
        console.log(error.response.data.message);
        toast.error(error.response.data.message);
      }
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  useEffect(() => {
    const token = urlParams.get('token');

    if (token) {
      autoLogin(token);
    }
    // eslint-disable-next-line
  }, []);

  const autoLogin = async (token) => {
    try {
      const res = await axios.post('user/autoLogin', { data: token });
      localStorage.setItem('loginUser', JSON.stringify(res.data));
      setToken(res.data.token);
      window.location.reload();
    } catch (error) {
      console.log(error.response.data.message);
      toast.error(error.response.data.message);
    }
  };

  return (
    <FormikProvider value={formik}>
      <ToastContainer
        position="bottom-right"
        autoClose={2500}
        newestOnTop
        closeOnClick
        pauseOnFocusLoss
        pauseOnHover
        limit={2}
      />
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label={translate(language, 'Email address')}
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label={translate(language, 'Password')}
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <FormControlLabel
            control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
            label={translate(language, 'Remember me')}
          />

          <Link component={RouterLink} variant="subtitle2" to="#">
            {translate(language, 'Forgot password?')}
          </Link>
        </Stack>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          {translate(language, 'Login')}
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}

LoginForm.propTypes = {
  language: PropTypes.element
};
