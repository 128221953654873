import { useState, useEffect } from 'react';
import axios from 'axios';
import { Stack, Avatar, Typography, Button, Container, Box, Grid } from '@mui/material';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { ToastContainer } from 'react-toastify';

import CenterSpinner from '../CenterSpinner';
import { translate } from '../_dashboard/app';
import fetchCouponsTableData from '../../utils/getCouponsTableData';
import Page from '../Page';
import AddCoupon from './AddCoupon';
import CouponsTable from './CouponsTable';

export default function Redeem() {
  const [loading, setLoading] = useState(false);
  const [couponsTableData, setCouponsTableData] = useState([]);
  const [openCouponModal, setOpenCouponModal] = useState(false);
  const language = localStorage.getItem('currentLanguage');
  const [currency, setCurrency] = useState('');

  const fetchOrgCurrency = async () => {
    setLoading(true);
    try {
      const res = await axios.get('org');
      setCurrency(res.data.data.currency);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const getData = async () => {
    await fetchCouponsTableData(setCouponsTableData);
  };

  useEffect(() => {
    getData();
    fetchOrgCurrency();
  }, []);

  if (loading) {
    return <CenterSpinner />;
  }

  return (
    <Page
      title={`${translate(language, 'Dashboard')} : ${translate(language, 'Program')} - ${translate(
        language,
        'Redeem'
      )}`}
    >
      <ToastContainer
        position="bottom-right"
        autoClose={2500}
        newestOnTop
        closeOnClick
        pauseOnFocusLoss
        pauseOnHover
        limit={2}
      />
      <Container>
        {openCouponModal && (
          <AddCoupon
            currency={currency}
            open={openCouponModal}
            setOpen={setOpenCouponModal}
            getData={getData}
          />
        )}
        <Stack direction="row" mb={5}>
          <Avatar
            sx={{ width: 56, height: 56 }}
            alt=" "
            src="/static/mock-images/avatars/referrals.png"
          />
          <Typography style={{ marginTop: '10px' }} variant="h4" gutterBottom>
            {translate(language, 'Redeem')}
          </Typography>
        </Stack>
        <Box sx={{ width: '100%' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginBottom: '5px'
            }}
          >
            <Button
              onClick={() => setOpenCouponModal(true)}
              variant="contained"
              startIcon={<Icon icon={plusFill} />}
            >
              {translate(language, 'Add New Coupon')}
            </Button>
          </div>
          <Grid>
            <CouponsTable language={language} tableData={couponsTableData} getData={getData} />
          </Grid>
        </Box>
      </Container>
    </Page>
  );
}
