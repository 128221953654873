import { useState } from 'react';
import {
  Grid,
  IconButton,
  Typography,
  Card,
  Container,
  CardActionArea,
  CardContent,
  Button,
  Stack,
  CardHeader,
  TextField,
  Switch
} from '@mui/material';
import * as Yup from 'yup';
import { bool, func, object, element } from 'prop-types';
import { Icon } from '@iconify/react';
import Modal from '@mui/material/Modal';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useFormik, Form, FormikProvider } from 'formik';
import CenterSpinner from '../../CenterSpinner';
import { translate } from '../app';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 30,
  minWidth: '300px',
  height: '85%',
  maxHeight: '600px',
  overflowY: 'auto',
  p: 4,
  borderRadius: 1
};

export default function ProductModal({
  initialOpen,
  language,
  setInitialOpen,
  data,
  fetchProductData
}) {
  const [open, setOpen] = useState(initialOpen);
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(data.template_active);

  const validationSchema = Yup.object().shape({
    title: Yup.string().required(),
    pageUrl: Yup.string().required(),
    desc: Yup.string().required()
  });

  const handleActiveChange = async ({ target: { checked } }) => {
    try {
      // eslint-disable-next-line
      const res = await axios.patch(`notification/${data.id}`, {
        template_active: checked
      });
      fetchProductData();
      setChecked(checked);
    } catch (error) {
      console.error(error);
      toast.error(translate(language, 'Something went wrong!'));
    }
  };

  const formik = useFormik({
    initialValues: {
      title: data.title || '',
      pageUrl: data.page_url || '',
      desc: data.desc || ''
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values, { resetForm }) => {
      try {
        setLoading(true);
        // eslint-disable-next-line
        const res = await axios.patch(`notification/${data.id}`, {
          title: values.title,
          page_url: values.pageUrl,
          desc: values.desc
        });

        resetForm();
        fetchProductData();
        setOpen(false);
      } catch (error) {
        console.log(error);
        toast.error(translate(language, 'Something went wrong!'));
      } finally {
        setLoading(false);
      }
      setInitialOpen(false);
    }
  });

  const { dirty, handleSubmit, handleChange, values, touched, errors } = formik;

  if (loading) {
    return <CenterSpinner />;
  }

  return (
    <>
      <Card align="left" variant="outlined" sx={{ maxWidth: 500, m: 3 }}>
        <CardHeader
          title={data.title}
          action={
            <Switch aria-label="5" onChange={handleActiveChange} checked={Boolean(checked)} />
          }
        />
        <CardActionArea>
          <CardContent onClick={() => setOpen(true)}>
            <Typography variant="body2" color="text.secondary" style={{ whiteSpace: 'pre-wrap' }}>
              <p>{translate(language, 'All about product page message')}</p>
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
      <Modal open={open} onClose={() => setOpen(false)}>
        <Grid sx={style}>
          <Grid container direction="row" pb={2}>
            <Grid item xs>
              <Typography variant="h3">{translate(language, 'Product')}</Typography>
            </Grid>
            <Grid item>
              <Typography align="right">
                <IconButton width={22} height={22} onClick={() => setOpen(false)}>
                  <Icon icon="bi:x-lg" />
                </IconButton>
              </Typography>
            </Grid>
          </Grid>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Container>
                <Grid container spacing={2}>
                  <Grid item xs={16}>
                    <TextField
                      id="title"
                      name="title"
                      variant="outlined"
                      placeholder={translate(language, 'Enter Product Title')}
                      fullWidth
                      value={values.title}
                      onChange={handleChange}
                      error={Boolean(touched.title && errors.title)}
                      helperText={touched.title && errors.title}
                    />
                  </Grid>
                  <Grid item xs={16}>
                    <TextField
                      id="pageUrl"
                      name="pageUrl"
                      variant="outlined"
                      fullWidth
                      placeholder={translate(language, 'Enter page url')}
                      value={values.pageUrl}
                      onChange={handleChange}
                      error={Boolean(touched.pageUrl && errors.pageUrl)}
                      helperText={touched.pageUrl && errors.pageUrl}
                    />
                  </Grid>
                  <Grid item xs={16}>
                    <TextField
                      multiline
                      rows={10}
                      variant="outlined"
                      fullWidth
                      id="desc"
                      name="desc"
                      placeholder={translate(language, 'Enter Product Description')}
                      required
                      value={values.desc}
                      onChange={handleChange}
                      error={Boolean(touched.desc && errors.desc)}
                      helperText={touched.desc && errors.desc}
                    />
                  </Grid>
                </Grid>
                <Stack spacing={2} direction="row-reverse" paddingTop={3}>
                  <Button disabled={!dirty} variant="contained" type="submit">
                    {translate(language, 'Save')}
                  </Button>
                </Stack>
              </Container>
            </Form>
          </FormikProvider>
        </Grid>
      </Modal>
    </>
  );
}
ProductModal.propTypes = {
  initialOpen: bool,
  setInitialOpen: func,
  fetchProductData: func,
  data: object,
  language: element
};
