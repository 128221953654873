import React from 'react';
import { Grid, Typography } from '@mui/material';
import { PropTypes } from 'prop-types';
import Accounts from './Accounts/Accounts';
import { translate } from '../app';

export default function AccountsTab({ language }) {
  return (
    <div>
      <Typography variant="h4" align="left" paddingTop={1}>
        {translate(language, 'Accounts')}
      </Typography>
      <Grid paddingY={3} paddingX={2}>
        <Accounts language={language} />
      </Grid>
    </div>
  );
}

AccountsTab.propTypes = {
  language: PropTypes.element
};
