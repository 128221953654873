const Redeem = () => (
  <div className="flex flex-col items-center justify-center">
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="#121212"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="#121212"
        d="M22.5 5.05364H19.775C19.9125 4.66209 20 4.23263 20 3.79055C20.0014 2.97564 19.7423 2.18205 19.2615 1.52784C18.7806 0.873637 18.1036 0.393759 17.3311 0.159595C16.5587 -0.0745696 15.732 -0.0505096 14.9742 0.228196C14.2164 0.506901 13.5678 1.02536 13.125 1.70645L12.5 2.55272L11.875 1.69382C11.2 0.68335 10.0625 0.00128089 8.75 0.00128089C6.675 0.00128089 5 1.69382 5 3.79055C5 4.23263 5.0875 4.66209 5.225 5.05364H2.5C1.1125 5.05364 0.0125 6.17779 0.0125 7.57982L0 21.4738C0 22.8759 1.1125 24 2.5 24H22.5C23.8875 24 25 22.8759 25 21.4738V7.57982C25 6.17779 23.8875 5.05364 22.5 5.05364ZM16.25 2.52746C16.9375 2.52746 17.5 3.09585 17.5 3.79055C17.5 4.48525 16.9375 5.05364 16.25 5.05364C15.5625 5.05364 15 4.48525 15 3.79055C15 3.09585 15.5625 2.52746 16.25 2.52746ZM8.75 2.52746C9.4375 2.52746 10 3.09585 10 3.79055C10 4.48525 9.4375 5.05364 8.75 5.05364C8.0625 5.05364 7.5 4.48525 7.5 3.79055C7.5 3.09585 8.0625 2.52746 8.75 2.52746ZM22.5 21.4738H2.5V18.9476H22.5V21.4738ZM22.5 15.1584H2.5V7.57982H8.85L6.25 11.1544L8.275 12.6322L11.25 8.53977L12.5 6.82197L13.75 8.53977L16.725 12.6322L18.75 11.1544L16.15 7.57982H22.5V15.1584Z"
      />
    </svg>
  </div>
);
export default Redeem;
