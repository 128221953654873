import { useState } from 'react';
import PropTypes from 'prop-types';
import Template from './Template';
import TemplateSetting from './TemplateSetting';

const PopUp = ({ promotionType, language }) => {
  const [pageNum, setPageNum] = useState(0);
  const [templateType, setTemplateType] = useState({});

  const Pages = [
    {
      key: 0,
      jsx: (
        <Template
          language={language}
          templateType={templateType}
          setTemplateType={setTemplateType}
          setPageNum={setPageNum}
        />
      )
    },
    {
      key: 1,
      jsx: (
        <TemplateSetting
          language={language}
          templateType={templateType}
          promotionType={promotionType}
        />
      )
    }
  ];

  return <>{Pages[pageNum].jsx}</>;
};

export default PopUp;

PopUp.propTypes = {
  promotionType: PropTypes.string,
  language: PropTypes.element
};
