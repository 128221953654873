import React from 'react';
import { Typography, Card, CardContent, Box, Stepper, Step, StepLabel } from '@mui/material';
import { PropTypes } from 'prop-types';
import { translate } from '../_dashboard/app';

export function StepperCard({ language }) {
  const steps = [
    translate(language, 'Player refers a friends'),
    translate(language, 'Referred friend creates an accounts'),
    translate(language, 'Referred friend')
  ];
  return (
    <>
      <Typography variant="h6" marginY={2}>
        {translate(language, 'Actions By your Players')}
      </Typography>
      <Card>
        <CardContent>
          <Box
            sx={{
              width: '100%'
            }}
          >
            <Stepper orientation="vertical">
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
        </CardContent>
      </Card>
    </>
  );
}
StepperCard.propTypes = {
  language: PropTypes.element
};
