import { string } from 'prop-types';

function Levels({ color, size }) {
  return (
    <svg
      width={size || '25'}
      height={size || '25'}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.875 15.8125H11.375V0.375H15.875V15.8125ZM12.5 14.625H14.75V1.5625H12.5V14.625ZM10.25 15.8125H5.75V5.125H10.25V15.8125ZM6.875 14.625H9.125V6.3125H6.875V14.625ZM4.625 15.8125H0.125V8.6875H4.625V15.8125ZM1.25 14.625H3.5V9.875H1.25V14.625Z"
        fill={color || '#00AB55'}
      />
    </svg>
  );
}

export default Levels;

Levels.propTypes = {
  color: string,
  size: string
};
