import React from 'react';
import { Icon } from '@iconify/react';
import { Typography, Card, CardContent } from '@mui/material';
import PropTypes from 'prop-types';

export function ReferralCards({ CardTitle, CardIcon, CardValue }) {
  return (
    <div>
      <Card
        style={{
          height: '100px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <CardContent style={{ width: '100%' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div style={{ display: 'flex', justifyContent: 'stretch', alignItems: 'center' }}>
              <Icon color="#e1b836" icon={CardIcon} width={40} height={40} />
              <Typography variant="body" paddingLeft={2}>
                {CardTitle}
              </Typography>
            </div>

            <Typography align="right" variant="h4" style={{ marginRight: '15px' }}>
              {CardValue}
            </Typography>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}

ReferralCards.propTypes = {
  CardTitle: PropTypes.string.isRequired,
  CardIcon: PropTypes.string.isRequired,
  CardValue: PropTypes.number.isRequired
};
