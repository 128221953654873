import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import { Backdrop, Box, Fade, Modal, Stack, MenuItem, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { LoadingButton } from '@mui/lab';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import { translate } from '../_dashboard/app';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4
};

export default function ModifyGroupModal({
  open,
  setOpen,
  modType,
  setModType,
  playerId,
  language,
  currentGroupId,
  allGroupsData,
  getPlayersData
}) {
  const handleClose = () => {
    setModType('');
    setOpen(false);
  };

  const GroupSchema = Yup.object().shape({
    id: Yup.string().required(translate(language, 'Group is Required'))
  });

  const formik = useFormik({
    initialValues: {
      id: ''
    },
    validationSchema: GroupSchema,
    onSubmit: async (values, { resetForm }) => {
      if (values.id !== currentGroupId) {
        try {
          // eslint-disable-next-line
          const res = await axios.patch('group', {
            player_id: playerId,
            currentGroupId,
            modType,
            destGroupId: values.id
          });

          resetForm();
          getPlayersData();
          setOpen(false);
          toast.success(
            translate(language, 'Player %s Successfully!', [
              modType === 'add' ? translate(language, 'Added') : translate(language, 'Moved')
            ])
          );
        } catch (error) {
          toast.error(error.response.data.message);
        }
      } else {
        toast.error(translate(language, "Can't %s player to same group!", [modType]));
      }
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <div>
      <ToastContainer
        position="bottom-right"
        autoClose={2500}
        newestOnTop
        closeOnClick
        pauseOnFocusLoss
        pauseOnHover
        limit={2}
      />
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack spacing={3}>
                  <TextField
                    fullWidth
                    select
                    label={translate(language, 'Group Name')}
                    {...getFieldProps('id')}
                    error={Boolean(touched.id && errors.id)}
                    helperText={touched.id && errors.id}
                  >
                    {allGroupsData.map((item, index) => (
                      <MenuItem key={index} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </TextField>
                  <LoadingButton
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                  >
                    {`${modType.toUpperCase()}`}
                  </LoadingButton>
                </Stack>
              </Form>
            </FormikProvider>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

ModifyGroupModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  modType: PropTypes.string,
  setModType: PropTypes.func,
  playerId: PropTypes.number,
  currentGroupId: PropTypes.number,
  allGroupsData: PropTypes.array,
  getPlayersData: PropTypes.func,
  language: PropTypes.element
};
