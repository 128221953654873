import React from 'react';
import {
  Input,
  Button,
  TableContainer,
  Table,
  OutlinedInput,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  MenuItem,
  Grid,
  Card,
  TableHead,
  TableRow,
  TableCell
} from '@mui/material';
import { Icon } from '@iconify/react';
import boldOutlined from '@iconify/icons-ant-design/bold-outlined';
import underLineOutlined from '@iconify/icons-ant-design/underline-outlined';
import italicOutlined from '@iconify/icons-ant-design/italic-outlined';
import attachmentLine from '@iconify/icons-clarity/attachment-line';

export default function ComposeMessage() {
  return (
    <div style={{ display: 'flex', justifyContent: 'flex-left' }}>
      <div style={{ display: 'flex', flexDirection: 'column', marginRight: '20px' }}>
        <Typography
          variant="h5"
          gutterBottom
          style={{
            width: '450px'
          }}
        >
          Message Format
        </Typography>
        <Typography align="left">Select the look and feel of your message.</Typography>
        <Grid>
          <TextField select size="medium" value="Language 0" align="left" fullWidth>
            <MenuItem fullWidth required value="Language 0">
              Small Toast
            </MenuItem>
          </TextField>
          <Typography style={{ height: '20px' }} align="center">
            {}
          </Typography>
          <Typography
            variant="h5"
            gutterBottom
            style={{
              width: '450px'
            }}
          >
            Message Icon
          </Typography>
          <Typography align="left">Select an Icon for your Message.</Typography>
          <FormControl>
            <RadioGroup row name="position" defaultValue="end">
              <Typography marginRight={2}>
                <FormControlLabel value="date" control={<Radio />} label="Default Image" />
              </Typography>
              <Typography marginRight={2}>
                <FormControlLabel value="name" control={<Radio />} label="From Library" />
              </Typography>
              <Typography marginRight={5}>
                <FormControlLabel value="manual" control={<Radio />} label="Upload Image" />
              </Typography>
            </RadioGroup>
          </FormControl>
          <Typography style={{ height: '20px' }} align="center">
            {}
          </Typography>
          <Typography
            variant="h5"
            gutterBottom
            style={{
              width: '450px'
            }}
          >
            Compose Message
          </Typography>
          <Typography style={{ height: '20px' }} align="center">
            {}
          </Typography>
          <Card>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow style={{ width: '490px', height: '5px', background: '#007B55' }}>
                    <TableCell align="left">
                      <Icon
                        icon={boldOutlined}
                        width={30}
                        height={19}
                        align="right"
                        color="white"
                      />
                      <Icon
                        icon={underLineOutlined}
                        width={35}
                        height={19}
                        align="right"
                        color="white"
                      />
                      <Icon
                        icon={italicOutlined}
                        width={35}
                        height={19}
                        align="right"
                        color="white"
                      />
                      <Icon
                        icon={attachmentLine}
                        width={35}
                        height={19}
                        align="right"
                        color="white"
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow style={{ height: '20px' }}>
                    <TableCell align="left">
                      <Input
                        style={{
                          width: '490px',
                          height: '20px',
                          textDecoration: 'none',
                          outline: 'none'
                        }}
                        placeholder="Discover our rewards experience!"
                      />
                    </TableCell>
                  </TableRow>
                </TableHead>
              </Table>
            </TableContainer>
          </Card>
          <Typography style={{ height: '20px' }} align="center">
            {}
          </Typography>
          <Card>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow style={{ width: '490px', height: '5px', background: '#007B55' }}>
                    <TableCell align="left">
                      <Icon
                        icon={boldOutlined}
                        width={30}
                        height={19}
                        align="right"
                        color="white"
                      />
                      <Icon
                        icon={underLineOutlined}
                        width={35}
                        height={19}
                        align="right"
                        color="white"
                      />
                      <Icon
                        icon={italicOutlined}
                        width={35}
                        height={19}
                        align="right"
                        color="white"
                      />
                      <Icon
                        icon={attachmentLine}
                        width={35}
                        height={19}
                        align="right"
                        color="white"
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow style={{ height: '20px' }}>
                    <TableCell align="left">
                      <Input
                        style={{
                          width: '490px',
                          height: '20px',
                          textDecoration: 'none',
                          outline: 'none'
                        }}
                        placeholder="Collect badges, level up and win points to receive free vouchers!"
                      />
                    </TableCell>
                  </TableRow>
                </TableHead>
              </Table>
            </TableContainer>
          </Card>
          <Typography style={{ height: '20px' }} align="center">
            {}
          </Typography>
          <Typography
            variant="h5"
            gutterBottom
            style={{
              width: '450px'
            }}
          >
            Message Redirection
          </Typography>
          <Typography align="left">
            Choose "Use redirection" to enable this message to open another page.
          </Typography>
          <TextField select size="medium" value="Language 0" align="left" fullWidth>
            <MenuItem fullWidth required value="Language 0">
              Use Redirection
            </MenuItem>
          </TextField>
          <div>
            <Typography style={{ height: '20px' }}>{}</Typography>
          </div>
          <Typography align="left">Insert your message redirection link.</Typography>
          <FormControl variant="outlined" fullWidth>
            <OutlinedInput placeholder="http://www.gamify.com" />
          </FormControl>
          <Typography style={{ height: '20px' }}>{}</Typography>
          <Typography align="left">
            Choose an option for your players to click a button or anywhere on the message.
          </Typography>
          <FormControl variant="outlined" fullWidth>
            <OutlinedInput placeholder="Join now" />
          </FormControl>
        </Grid>
        <Typography align="right" pt={5}>
          <Button style={{ height: '50px', width: '100px' }} variant="contained">
            Next
          </Button>
        </Typography>
      </div>
    </div>
  );
}
