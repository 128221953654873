import React from 'react';
import { TextField, MenuItem } from '@mui/material';
import { PropTypes } from 'prop-types';
import { translate } from '../../app';

export default function EmailFrequencyMenu({ language }) {
  return (
    <TextField
      select
      size="medium"
      value={translate(language, 'Email Frequency')}
      align="left"
      fullWidth
    >
      <MenuItem fullWidth required value={translate(language, 'Email Frequency')}>
        {translate(language, 'Email Frequency')}
      </MenuItem>
      <MenuItem fullWidth required value="Email Frequency One">
        {translate(language, 'Email Frequency')} 1
      </MenuItem>
      <MenuItem fullWidth required value="Email Frequency Two">
        {translate(language, 'Email Frequency')} 2
      </MenuItem>
      <MenuItem fullWidth required value="Email Frequency Three">
        {translate(language, 'Email Frequency')} 3
      </MenuItem>
      <MenuItem fullWidth required value="Email Frequency Four">
        {translate(language, 'Email Frequency')} 4
      </MenuItem>
      <MenuItem fullWidth required value="Email Frequency Five">
        {translate(language, 'Email Frequency')} 5
      </MenuItem>
    </TextField>
  );
}

EmailFrequencyMenu.propTypes = {
  language: PropTypes.element
};
