/* eslint-disable */
import * as Yup from 'yup';
import { Backdrop, Box, Fade, Modal, Stack, TextField } from '@mui/material';
import { useFormik, Form, FormikProvider } from 'formik';
import { LoadingButton } from '@mui/lab';
import PropTypes from 'prop-types';
import axios from 'axios';
import { translate } from '../_dashboard/app';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4
};

export default function EditHeaders({ open, setOpen, data, getData, language }) {
  const handleClose = () => setOpen(false);

  const HeaderSchema = Yup.object().shape({
    header: Yup.string().required(translate(language, 'Header is Required')),
    subHeader: Yup.string().required(translate(language, 'Sub-Header is Required'))
  });

  const formik = useFormik({
    initialValues: {
      header: data.spin_wheel_title,
      subHeader: data.spin_wheel_subtitle
    },
    enableReinitialize: true,
    validationSchema: HeaderSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        const res = await axios.patch('widget/settings', {
          spin_wheel_title: values.header,
          spin_wheel_subtitle: values.subHeader
        });
        resetForm();
        getData();
        setOpen(false);
      } catch (error) {
        console.log(error);
      }
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack spacing={3}>
                  <TextField
                    fullWidth
                    label={translate(language, "Header")}
                    value={data.spin_wheel_title}
                    {...getFieldProps('header')}
                    error={Boolean(touched.header && errors.header)}
                    helperText={touched.header && errors.header}
                  />
                  <TextField
                    fullWidth
                    label={translate(language, "Sub-Header")}
                    value={data.spin_wheel_subtitle}
                    {...getFieldProps('subHeader')}
                    error={Boolean(touched.subHeader && errors.subHeader)}
                    helperText={touched.subHeader && errors.subHeader}
                  />

                  <LoadingButton
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                  >
                    {translate(language, 'Save')}
                  </LoadingButton>
                </Stack>
              </Form>
            </FormikProvider>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

EditHeaders.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  data: PropTypes.object,
  getData: PropTypes.func,
  language: PropTypes.element
};
