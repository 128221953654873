import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import PropTypes from 'prop-types';
import { translate } from '../_dashboard/app';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  p: 4,
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column'
};
const APP_EXTENSION_UUID = '63b4bd80-e560-43b7-821b-27ae9798a31d';
function EnableAppModal({ open, setOpen, shopDomain, language }) {
  const handleClose = () => {
    const user = JSON.parse(localStorage.getItem('loginUser'));
    user.openEnableApp = false;
    localStorage.setItem('loginUser', JSON.stringify(user));
    setOpen(false);
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <Typography textAlign="center" variant="h6" component="h6">
          {translate(language, 'Enable App embed in store')}
        </Typography>
        <Typography maxWidth={300} textAlign="center" variant="subtitle1" component="p">
          {translate(
            language,
            "To Enable Shopify Widget of Loyalty Program, Please click the 'Enable Button' and save your shopify theme. The widget will be live on your website. You can disable it anytime you want."
          )}
        </Typography>
        <Button variant="contained" sx={{ mt: 2 }}>
          <a
            style={{ textDecoration: 'none', color: '#fff' }}
            href={`https://${
              shopDomain.split(',')[0]
            }/admin/themes/current/editor?context=apps&activateAppId=${APP_EXTENSION_UUID}/app-embed`}
            target="_blank"
            rel="noreferrer"
          >
            {translate(language, 'Enable')}
          </a>
        </Button>
      </Box>
    </Modal>
  );
}

EnableAppModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  shopDomain: PropTypes.string.isRequired,
  language: PropTypes.element
};

export default EnableAppModal;
