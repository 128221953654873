import { Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import DatePickerWithTitle from '../DatePickerWithTitle';
import { AppWebsiteVisits, translate, AppWeeklySales } from '../_dashboard/app';

export default function Challenges({ language }) {
  return (
    <>
      <DatePickerWithTitle language={language} title={translate(language, 'Challenges')} />
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4}>
          <AppWeeklySales
            language={language}
            points="0"
            title={translate(language, 'Total Accumulated Points')}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <AppWeeklySales
            language={language}
            points="0"
            title={translate(language, 'Number of `Active` Challenges')}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <AppWeeklySales
            language={language}
            points="0"
            title={translate(language, 'Number of `Inactive` Challenges')}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <AppWeeklySales
            language={language}
            points="0"
            title={translate(language, 'Number of `Visible` Challenges')}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <AppWeeklySales
            language={language}
            points="0"
            title={translate(language, 'Number of `Invisible` Challenges')}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <AppWeeklySales
            language={language}
            points="0"
            title={translate(language, 'Number of `Visible if Earned` Challenges')}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <AppWeeklySales
            language={language}
            points="0"
            title={translate(language, 'Number of Signup Challenges')}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <AppWeeklySales
            language={language}
            points="0"
            title={translate(language, 'Number of Birthday Challenges')}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <AppWeeklySales
            language={language}
            points="0"
            title={translate(language, 'Number of Anniversary Challenges')}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <AppWeeklySales
            language={language}
            points="0"
            title={translate(language, 'Number of Event Based Challenges')}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <AppWeeklySales
            language={language}
            points="0"
            title={translate(language, 'Number of Scheduled Challenges')}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <AppWeeklySales
            language={language}
            points="0"
            title={translate(language, 'Number of Social Challenges')}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <AppWeeklySales
            language={language}
            points="0"
            title={translate(language, 'Number of Daily Activity Streak Challenges')}
          />
        </Grid>
      </Grid>
      <Typography mt={5} mb={5} variant="h6">
        {translate(language, 'Detailed Analytics')}
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12}>
          <AppWebsiteVisits
            sub={translate(
              language,
              'Number of shared badges per social media platform by your players'
            )}
            title={translate(language, 'Shared Badges Insights')}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <AppWebsiteVisits
            sub={translate(language, 'Top achieved challenges by your players')}
            title={translate(language, 'Top Challenges Insights')}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <AppWebsiteVisits
            sub={translate(language, 'Number of achievement vs challenges type')}
            title={translate(language, 'Challenges Types Performance')}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <AppWebsiteVisits
            sub={translate(language, 'Points accumulated per challenge type')}
            title={translate(language, 'Number of accumulated points per type')}
          />
        </Grid>
      </Grid>
    </>
  );
}

Challenges.propTypes = {
  language: PropTypes.element
};
