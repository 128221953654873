import { Button } from '@mui/material';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

const OauthPopup = (props) => {
  const { height = 500, width = 500, url = '', title = '', disableBtn } = props;

  let externalWindow;

  useEffect(() => {
    console.log('.');
    return () => {
      if (externalWindow) {
        externalWindow.close();
      }
    };
    // eslint-disable-next-line
  }, []);

  const createPopup = () => {
    const left = window.screenX + (window.outerWidth - width) / 2;
    const top = window.screenY + (window.outerHeight - height) / 2.5;

    const windowFeatures = `toolbar=0,scrollbars=1,status=1,resizable=0,location=1,menuBar=0,width=${width},height=${height},top=${top},left=${left}`;

    externalWindow = window.open(url, title, windowFeatures);
  };

  return (
    <div aria-hidden="true" onClick={createPopup}>
      {disableBtn ? (
        <Button disabled variant="contained">
          {title}
        </Button>
      ) : (
        <Button variant="contained">{title}</Button>
      )}
    </div>
  );
};

export default OauthPopup;

OauthPopup.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  url: PropTypes.string,
  disableBtn: PropTypes.bool,
  title: PropTypes.string
};
