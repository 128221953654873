import { Select, MenuItem, Grid, CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';

export default function LanguageMenu({ getFieldProps }) {
  const { value } = getFieldProps('language');

  if (!value) {
    return <CircularProgress />;
  }

  return (
    <Grid>
      <Select {...getFieldProps('language')} size="small" fullWidth>
        <MenuItem fullWidth required value="en">
          EN: English
        </MenuItem>
        <MenuItem fullWidth required value="bul">
          BUL: Bulgarian
        </MenuItem>
        <MenuItem fullWidth required value="cr">
          CR: Croatian
        </MenuItem>
        <MenuItem fullWidth required value="cz">
          CZ: Czech
        </MenuItem>
        <MenuItem fullWidth required value="da">
          DA: Danish
        </MenuItem>
        <MenuItem fullWidth required value="du">
          DUT: Dutch
        </MenuItem>
        <MenuItem fullWidth required value="est">
          ET: Estonian
        </MenuItem>
        <MenuItem fullWidth required value="fi">
          FI: Finnish
        </MenuItem>
        <MenuItem fullWidth required value="fr">
          FR: French
        </MenuItem>
        <MenuItem fullWidth required value="de">
          DE: German
        </MenuItem>
        <MenuItem fullWidth required value="gr">
          GR: Greek
        </MenuItem>
        <MenuItem fullWidth required value="gu">
          GU: Gujarati
        </MenuItem>
        <MenuItem fullWidth required value="hi">
          HI: Hindi
        </MenuItem>
        <MenuItem fullWidth required value="hu">
          HU: Hungarian
        </MenuItem>
        <MenuItem fullWidth required value="ind">
          IN: Indonesian
        </MenuItem>
        <MenuItem fullWidth required value="ir">
          IR: Irish
        </MenuItem>
        <MenuItem fullWidth required value="it">
          IT: Italian
        </MenuItem>
        <MenuItem fullWidth required value="kn">
          KN: Kannada
        </MenuItem>
        <MenuItem fullWidth required value="la">
          LA: Latvian
        </MenuItem>
        <MenuItem fullWidth required value="li">
          LI: Lithuanian
        </MenuItem>
        <MenuItem fullWidth required value="ma">
          MA: Marathi
        </MenuItem>
        <MenuItem fullWidth required value="ml">
          ML: Malayalam
        </MenuItem>
        <MenuItem fullWidth required value="mals">
          MAY: Malaysian(Malay)
        </MenuItem>
        <MenuItem fullWidth required value="mal">
          MT: Maltese
        </MenuItem>
        <MenuItem fullWidth required value="pol">
          POL: Polish
        </MenuItem>
        <MenuItem fullWidth required value="por">
          POR: Portuguese
        </MenuItem>
        <MenuItem fullWidth required value="rom">
          RO: Romanian
        </MenuItem>
        <MenuItem fullWidth required value="ru">
          RU: Russian
        </MenuItem>
        <MenuItem fullWidth required value="slk">
          SLK: Slovak
        </MenuItem>
        <MenuItem fullWidth required value="slv">
          SLV: Slovenian
        </MenuItem>
        <MenuItem fullWidth required value="es">
          ES: Spanish
        </MenuItem>
        <MenuItem fullWidth required value="sw">
          SW: Swedish
        </MenuItem>
        <MenuItem fullWidth required value="ta">
          TA: Tamil
        </MenuItem>
      </Select>
    </Grid>
  );
}
LanguageMenu.propTypes = {
  getFieldProps: PropTypes.func
};
