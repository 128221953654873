function Coins() {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.0356 13.6502L20.032 15.6977C20.032 14.7906 19.4367 13.8798 18.2391 13.1913C17.6475 12.8471 16.959 12.5926 16.2275 12.4204C14.033 11.9076 11.4226 12.1622 9.65124 13.1913C8.48228 13.8726 7.89422 14.7655 7.89062 15.662L7.8978 13.6145C7.90139 12.7216 8.48588 11.8288 9.6584 11.1476C11.4298 10.1184 14.0402 9.86017 16.2347 10.373C16.9662 10.5451 17.651 10.8032 18.2463 11.1439C19.4403 11.836 20.0391 12.7431 20.0356 13.6502Z"
        fill="#F2BC36"
      />
      <path
        d="M20.0359 13.6499L20.0301 15.696C20.0276 16.591 19.4405 17.4846 18.2676 18.1662L18.2734 16.1201C19.4463 15.4385 20.0334 14.5449 20.0359 13.6499Z"
        fill="#F9C73A"
      />
      <path
        d="M7.89161 15.6608L7.8974 13.6147C7.89484 14.521 8.49153 15.4287 9.68623 16.1184C12.0626 17.4904 15.9145 17.4905 18.2731 16.1198L18.2673 18.1658C15.9087 19.5365 12.0568 19.5365 9.68043 18.1645C8.48573 17.4747 7.88904 16.5671 7.89161 15.6608Z"
        fill="#F9C73A"
      />
      <path
        d="M18.2452 11.689C20.6215 13.061 20.6342 15.2921 18.2732 16.6642C15.9145 18.0349 12.0627 18.0349 9.68629 16.6628C7.31222 15.2922 7.29959 13.061 9.65824 11.6903C12.0192 10.3183 15.8711 10.3183 18.2452 11.689Z"
        fill="#F9C73A"
      />
      <path
        d="M21.8318 13.6548L21.826 15.7008C21.8227 16.8598 21.0614 18.0171 19.5404 18.9009C16.479 20.68 11.4975 20.6787 8.41605 18.8996C6.86539 18.0043 6.09092 16.8292 6.09425 15.6559L6.10005 13.6099C6.09672 14.7832 6.87119 15.9583 8.42185 16.8535C11.5033 18.6326 16.4848 18.634 19.5462 16.8549C21.0672 15.971 21.8285 14.8137 21.8318 13.6548Z"
        fill="#F2BC36"
      />
      <path
        d="M19.5101 10.4098C22.5916 12.1889 22.6078 15.0757 19.5463 16.8549C16.4849 18.634 11.5034 18.6327 8.42191 16.8536C5.34042 15.0745 5.32413 12.1903 8.38555 10.4112C11.4471 8.63207 16.4286 8.63071 19.5101 10.4098ZM9.68651 16.1186C12.0629 17.4907 15.9147 17.4907 18.2734 16.12C20.6344 14.7479 20.6218 12.5168 18.2454 11.1448C15.8713 9.77411 12.0195 9.77408 9.65845 11.1461C7.2998 12.5169 7.31243 14.748 9.68651 16.1186Z"
        fill="#FED44C"
      />
      <path
        d="M16.926 12.2212L16.9248 12.6429L16.5933 12.8356L16.5945 12.4138L16.926 12.2212Z"
        fill="#F2BC36"
      />
      <path
        d="M14.106 13.2138L14.1071 12.792C14.1074 12.6939 14.1794 12.604 14.3213 12.5216C14.4673 12.4367 14.6449 12.3986 14.8523 12.4062C15.0614 12.4129 15.2725 12.4682 15.4855 12.5721L15.4843 12.9938C15.2713 12.8899 15.0602 12.8346 14.8511 12.828C14.6437 12.8203 14.4661 12.8585 14.3201 12.9433C14.1782 13.0258 14.1062 13.1157 14.106 13.2138Z"
        fill="#F2BC36"
      />
      <path
        d="M15.8856 12.8257L15.8844 13.2474L14.6636 13.9569L14.6648 13.5352L15.8856 12.8257Z"
        fill="#F2BC36"
      />
      <path
        d="M16.4445 13.5838L16.4433 14.0055C16.3593 13.7214 16.1719 13.4687 15.8843 13.2474L15.8855 12.8257C16.1731 13.0469 16.3605 13.2996 16.4445 13.5838Z"
        fill="#F2BC36"
      />
      <path
        d="M17.4679 13.4824L17.4667 13.9042L16.4434 14.0052L16.4446 13.5835L17.4679 13.4824Z"
        fill="#F2BC36"
      />
      <path
        d="M12.9351 13.1488L12.9363 12.7271C12.9362 12.7502 12.938 12.7735 12.9418 12.7969C12.9794 13.039 13.1868 13.3375 13.5625 13.6894L13.5613 14.1111C13.1856 13.7593 12.9782 13.4608 12.9406 13.2186C12.9369 13.1952 12.935 13.1719 12.9351 13.1488Z"
        fill="#F2BC36"
      />
      <path
        d="M14.1581 14.4663L14.1569 14.888C14.157 14.8831 14.1568 14.8781 14.1565 14.8732C14.1493 14.7663 14.053 14.6138 13.8691 14.4183L13.8703 13.9966C14.0542 14.192 14.1505 14.3446 14.1577 14.4514C14.158 14.4564 14.1582 14.4614 14.1581 14.4663Z"
        fill="#F2BC36"
      />
      <path
        d="M13.8703 13.9966L13.8691 14.4183L12.6992 15.0982L12.7004 14.6765L13.8703 13.9966Z"
        fill="#F2BC36"
      />
      <path
        d="M11.592 14.8348L11.5908 15.2565C11.0536 14.8761 10.7548 14.4298 10.6909 13.9159L10.6921 13.4941C10.756 14.0081 11.0548 14.4543 11.592 14.8348Z"
        fill="#F2BC36"
      />
      <path
        d="M11.5374 15.3526L11.5362 15.7743L11.1167 15.5321L11.1179 15.1104L11.5374 15.3526Z"
        fill="#F2BC36"
      />
      <path
        d="M12.005 15.0811L12.0038 15.5028L11.5361 15.7745L11.5373 15.3528L12.005 15.0811Z"
        fill="#F2BC36"
      />
      <path
        d="M15.3189 14.5298L15.3177 14.9515C15.317 15.1775 15.144 15.3901 14.7997 15.5902C14.419 15.8114 13.975 15.9125 13.468 15.8953C12.9592 15.8772 12.4711 15.7456 12.0039 15.5025L12.0051 15.0807C12.4723 15.3239 12.9603 15.4555 13.4692 15.4736C13.9762 15.4908 14.4202 15.3897 14.8009 15.1684C15.1451 14.9684 15.3182 14.7557 15.3189 14.5298Z"
        fill="#F2BC36"
      />
      <path
        d="M15.3138 14.461C15.3515 14.7127 15.18 14.9482 14.8009 15.1685C14.4203 15.3897 13.9763 15.4908 13.4692 15.4737C12.9604 15.4556 12.4724 15.324 12.0051 15.0808L11.5375 15.3526L11.118 15.1104L11.5922 14.8348C11.0551 14.4543 10.7562 14.0081 10.6924 13.4941L11.7358 13.4407C11.7858 13.8364 11.9736 14.1635 12.301 14.4229L13.5628 13.6896C13.1872 13.3377 12.9797 13.0393 12.9422 12.7971C12.9029 12.5539 13.0711 12.3241 13.4469 12.1058C13.8211 11.8883 14.2584 11.7853 14.7605 11.7997C15.2643 11.813 15.7375 11.936 16.1816 12.1677L16.5066 11.9789L16.9261 12.2211L16.5946 12.4137C17.0391 12.7103 17.3308 13.0669 17.4681 13.4826L16.4448 13.5837C16.3608 13.2996 16.1734 13.0469 15.8858 12.8256L14.6649 13.5351L14.6847 13.5466C15.067 13.9041 15.2795 14.2092 15.3138 14.461ZM13.3801 14.8577C13.6024 14.8701 13.7882 14.8329 13.9408 14.7442C14.0918 14.6565 14.1653 14.5583 14.1581 14.4515C14.1509 14.3447 14.0546 14.1921 13.8708 13.9966L12.7008 14.6765C12.9319 14.7852 13.1578 14.8453 13.3801 14.8577ZM14.3619 13.2252L15.4859 12.572C15.2729 12.4681 15.0619 12.4128 14.8528 12.4061C14.6454 12.3985 14.4678 12.4366 14.3218 12.5215C14.1774 12.6054 14.1054 12.697 14.1077 12.7971C14.1115 12.8962 14.1946 13.0393 14.3619 13.2252Z"
        fill="#FED44C"
      />
      <path
        d="M20.7362 10.7919L20.7326 12.8393C20.7326 11.9322 20.1374 11.0214 18.9398 10.333C18.3481 9.98874 17.6597 9.73416 16.9282 9.56205C14.7337 9.04923 12.1233 9.3038 10.3519 10.333C9.18296 11.0142 8.5949 11.9071 8.59131 12.8036L8.59849 10.7561C8.60208 9.86319 9.18656 8.97044 10.3591 8.28918C12.1304 7.26003 14.7409 7.00177 16.9354 7.5146C17.6668 7.68671 18.3517 7.94478 18.947 8.2855C20.141 8.97762 20.7398 9.88474 20.7362 10.7919Z"
        fill="#F2BC36"
      />
      <path
        d="M20.7366 10.7915L20.7308 12.8376C20.7283 13.7326 20.1412 14.6262 18.9683 15.3078L18.9741 13.2617C20.147 12.5801 20.7341 11.6865 20.7366 10.7915Z"
        fill="#F9C73A"
      />
      <path
        d="M8.59229 12.8024L8.59809 10.7563C8.59552 11.6626 9.19222 12.5703 10.3869 13.26C12.7633 14.632 16.6151 14.6321 18.9738 13.2614L18.968 15.3074C16.6093 16.6781 12.7575 16.6781 10.3811 15.3061C9.18642 14.6163 8.58972 13.7087 8.59229 12.8024Z"
        fill="#F9C73A"
      />
      <path
        d="M18.9458 8.83058C21.3222 10.2026 21.3349 12.4337 18.9739 13.8058C16.6152 15.1765 12.7634 15.1765 10.387 13.8044C8.0129 12.4338 8.00027 10.2026 10.3589 8.83194C12.7199 7.45988 16.5718 7.45991 18.9458 8.83058Z"
        fill="#F9C73A"
      />
      <path
        d="M22.5325 10.7964L22.5267 12.8424C22.5234 14.0014 21.762 15.1587 20.2411 16.0425C17.1797 17.8216 12.1982 17.8203 9.11673 16.0412C7.56607 15.1459 6.79161 13.9708 6.79493 12.7975L6.80073 10.7515C6.79741 11.9248 7.57187 13.0999 9.12253 13.9951C12.204 15.7742 17.1855 15.7756 20.2469 13.9965C21.7678 13.1126 22.5292 11.9553 22.5325 10.7964Z"
        fill="#F2BC36"
      />
      <path
        d="M20.2108 7.55141C23.2923 9.33051 23.3085 12.2173 20.247 13.9965C17.1855 15.7756 12.2041 15.7743 9.12259 13.9952C6.0411 12.2161 6.02482 9.33194 9.08624 7.55284C12.1478 5.77367 17.1293 5.77231 20.2108 7.55141ZM10.3872 13.2603C12.7636 14.6323 16.6154 14.6323 18.9741 13.2616C21.3351 11.8895 21.3224 9.65839 18.9461 8.28639C16.572 6.91571 12.7201 6.91569 10.3591 8.28775C8.00048 9.65845 8.01312 11.8896 10.3872 13.2603Z"
        fill="#FED44C"
      />
      <path
        d="M17.6267 9.36279L17.6255 9.78452L17.2939 9.97718L17.2951 9.55545L17.6267 9.36279Z"
        fill="#F2BC36"
      />
      <path
        d="M14.8066 10.3554L14.8078 9.93364C14.8081 9.83553 14.8801 9.74564 15.022 9.66317C15.168 9.57832 15.3456 9.54018 15.553 9.54783C15.7621 9.55451 15.9731 9.60981 16.1862 9.71372L16.185 10.1354C15.9719 10.0315 15.7609 9.97623 15.5518 9.96956C15.3444 9.96191 15.1668 10.0001 15.0208 10.0849C14.8789 10.1674 14.8069 10.2573 14.8066 10.3554Z"
        fill="#F2BC36"
      />
      <path
        d="M16.5863 9.96729L16.5851 10.389L15.3643 11.0985L15.3655 10.6768L16.5863 9.96729Z"
        fill="#F2BC36"
      />
      <path
        d="M17.1452 10.7254L17.144 11.1471C17.06 10.863 16.8726 10.6103 16.585 10.389L16.5862 9.96729C16.8738 10.1885 17.0612 10.4412 17.1452 10.7254Z"
        fill="#F2BC36"
      />
      <path
        d="M18.1686 10.624L18.1674 11.0458L17.144 11.1468L17.1452 10.7251L18.1686 10.624Z"
        fill="#F2BC36"
      />
      <path
        d="M13.6357 10.2904L13.6369 9.86865C13.6369 9.8918 13.6387 9.91506 13.6425 9.93845C13.6801 10.1806 13.8875 10.4791 14.2632 10.831L14.262 11.2527C13.8863 10.9009 13.6789 10.6024 13.6413 10.3602C13.6375 10.3368 13.6357 10.3135 13.6357 10.2904Z"
        fill="#F2BC36"
      />
      <path
        d="M14.8588 11.6079L14.8576 12.0296C14.8576 12.0247 14.8575 12.0197 14.8571 12.0148C14.85 11.9079 14.7537 11.7554 14.5698 11.5599L14.571 11.1382C14.7549 11.3336 14.8512 11.4862 14.8583 11.593C14.8587 11.598 14.8588 11.603 14.8588 11.6079Z"
        fill="#F2BC36"
      />
      <path
        d="M14.571 11.1382L14.5698 11.5599L13.3999 12.2398L13.4011 11.8181L14.571 11.1382Z"
        fill="#F2BC36"
      />
      <path
        d="M12.2926 11.9764L12.2914 12.3981C11.7543 12.0177 11.4554 11.5714 11.3916 11.0575L11.3928 10.6357C11.4566 11.1497 11.7555 11.5959 12.2926 11.9764Z"
        fill="#F2BC36"
      />
      <path
        d="M12.2381 12.4942L12.2369 12.9159L11.8174 12.6737L11.8186 12.252L12.2381 12.4942Z"
        fill="#F2BC36"
      />
      <path
        d="M12.7056 12.2227L12.7044 12.6444L12.2368 12.9161L12.238 12.4944L12.7056 12.2227Z"
        fill="#F2BC36"
      />
      <path
        d="M16.0195 11.6714L16.0183 12.0931C16.0177 12.3191 15.8446 12.5317 15.5004 12.7318C15.1197 12.953 14.6757 13.0541 14.1686 13.0369C13.6598 13.0188 13.1718 12.8872 12.7046 12.6441L12.7058 12.2223C13.173 12.4655 13.661 12.5971 14.1698 12.6152C14.6769 12.6324 15.1209 12.5313 15.5015 12.31C15.8458 12.11 16.0189 11.8973 16.0195 11.6714Z"
        fill="#F2BC36"
      />
      <path
        d="M16.0145 11.6026C16.0522 11.8543 15.8806 12.0898 15.5016 12.3101C15.1209 12.5313 14.677 12.6324 14.1699 12.6153C13.6611 12.5972 13.1731 12.4656 12.7058 12.2224L12.2382 12.4942L11.8187 12.252L12.2929 11.9764C11.7558 11.5959 11.4569 11.1497 11.3931 10.6357L12.4365 10.5823C12.4865 10.978 12.6743 11.3051 13.0017 11.5645L14.2635 10.8312C13.8878 10.4793 13.6804 10.1809 13.6428 9.93866C13.6036 9.69552 13.7718 9.46572 14.1476 9.24736C14.5217 9.02992 14.9591 8.92692 15.4612 8.94126C15.965 8.95461 16.4381 9.07762 16.8823 9.30931L17.2072 9.12049L17.6267 9.36269L17.2952 9.55534C17.7397 9.85188 18.0315 10.2085 18.1688 10.6242L17.1455 10.7253C17.0615 10.4412 16.8741 10.1885 16.5864 9.96724L15.3656 10.6767L15.3854 10.6882C15.7677 11.0457 15.9802 11.3508 16.0145 11.6026ZM14.0808 11.9993C14.3031 12.0117 14.4889 11.9745 14.6415 11.8858C14.7924 11.7981 14.866 11.6999 14.8588 11.5931C14.8516 11.4863 14.7553 11.3337 14.5714 11.1382L13.4015 11.8181C13.6326 11.9268 13.8585 11.9869 14.0808 11.9993ZM15.0626 10.3668L16.1866 9.71362C15.9736 9.60972 15.7626 9.55441 15.5535 9.54774C15.3461 9.54009 15.1685 9.57823 15.0225 9.66307C14.8781 9.74698 14.8061 9.83856 14.8083 9.93866C14.8122 10.0378 14.8953 10.1809 15.0626 10.3668Z"
        fill="#FED44C"
      />
      <path
        d="M22.7035 7.35435L22.6999 9.4018C22.6999 8.49468 22.1047 7.58389 20.9071 6.89545C20.3154 6.55123 19.6269 6.29666 18.8955 6.12455C16.701 5.61173 14.0906 5.8663 12.3192 6.89545C11.1502 7.57671 10.5622 8.46965 10.5586 9.36608L10.5658 7.31863C10.5694 6.4257 11.1538 5.53294 12.3264 4.85168C14.0977 3.82253 16.7082 3.56427 18.9026 4.0771C19.6341 4.24921 20.319 4.50728 20.9142 4.848C22.1083 5.54011 22.7071 6.44723 22.7035 7.35435Z"
        fill="#F2BC36"
      />
      <path
        d="M22.7039 7.354L22.6981 9.40008C22.6955 10.2951 22.1084 11.1887 20.9355 11.8703L20.9413 9.82421C22.1142 9.14259 22.7013 8.24898 22.7039 7.354Z"
        fill="#F9C73A"
      />
      <path
        d="M10.5596 9.36492L10.5654 7.31885C10.5628 8.2251 11.1595 9.13276 12.3542 9.82252C14.7306 11.1945 18.5824 11.1946 20.9411 9.82385L20.9353 11.8699C18.5766 13.2406 14.7248 13.2406 12.3484 11.8686C11.1537 11.1788 10.557 10.2712 10.5596 9.36492Z"
        fill="#F9C73A"
      />
      <path
        d="M20.9131 5.39308C23.2895 6.76508 23.3021 8.99621 20.9411 10.3683C18.5825 11.739 14.7306 11.7389 12.3543 10.3669C9.98019 8.99627 9.96755 6.76515 12.3262 5.39445C14.6872 4.02238 18.539 4.02241 20.9131 5.39308Z"
        fill="#F9C73A"
      />
      <path
        d="M24.4998 7.35886L24.494 9.40494C24.4907 10.5639 23.7293 11.7212 22.2084 12.605C19.147 14.3841 14.1655 14.3828 11.084 12.6037C9.53336 11.7084 8.75889 10.5333 8.76222 9.36004L8.76802 7.31396C8.76469 8.48726 9.53916 9.66236 11.0898 10.5576C14.1713 12.3367 19.1528 12.3381 22.2142 10.559C23.7351 9.67509 24.4965 8.51785 24.4998 7.35886Z"
        fill="#F2BC36"
      />
      <path
        d="M22.1781 4.11391C25.2596 5.89301 25.2758 8.77981 22.2142 10.559C19.1528 12.3381 14.1714 12.3368 11.0899 10.5577C8.00839 8.77855 7.9921 5.89444 11.0535 4.11534C14.1151 2.33617 19.0966 2.33481 22.1781 4.11391ZM12.3545 9.82275C14.7309 11.1948 18.5827 11.1948 20.9414 9.82408C23.3024 8.45201 23.2897 6.22089 20.9133 4.84888C18.5393 3.47821 14.6874 3.47818 12.3264 4.85025C9.96777 6.22095 9.9804 8.45208 12.3545 9.82275Z"
        fill="#FED44C"
      />
      <path
        d="M19.5939 5.92529L19.5927 6.34702L19.2612 6.53967L19.2624 6.11794L19.5939 5.92529Z"
        fill="#F2BC36"
      />
      <path
        d="M16.7739 6.91787L16.7751 6.49614C16.7754 6.39803 16.8474 6.30814 16.9893 6.22567C17.1353 6.14082 17.3129 6.10268 17.5203 6.11033C17.7294 6.11701 17.9404 6.17231 18.1534 6.27622L18.1522 6.69795C17.9392 6.59404 17.7282 6.53873 17.5191 6.53206C17.3117 6.52441 17.1341 6.56256 16.9881 6.6474C16.8462 6.72987 16.7742 6.81976 16.7739 6.91787Z"
        fill="#F2BC36"
      />
      <path
        d="M18.5536 6.52979L18.5524 6.95152L17.3315 7.66099L17.3327 7.23926L18.5536 6.52979Z"
        fill="#F2BC36"
      />
      <path
        d="M19.1125 7.28786L19.1113 7.70959C19.0273 7.42546 18.8399 7.17276 18.5522 6.95152L18.5534 6.52979C18.8411 6.75104 19.0285 7.00373 19.1125 7.28786Z"
        fill="#F2BC36"
      />
      <path
        d="M20.1359 7.18652L20.1347 7.60825L19.1113 7.70932L19.1125 7.28759L20.1359 7.18652Z"
        fill="#F2BC36"
      />
      <path
        d="M15.603 6.85288L15.6042 6.43115C15.6042 6.45429 15.606 6.47756 15.6098 6.50095C15.6474 6.74315 15.8548 7.04163 16.2304 7.3935L16.2292 7.81524C15.8536 7.46336 15.6462 7.16488 15.6086 6.92269C15.6048 6.89929 15.603 6.87603 15.603 6.85288Z"
        fill="#F2BC36"
      />
      <path
        d="M16.8261 8.17039L16.8249 8.59212C16.8249 8.58719 16.8248 8.58224 16.8244 8.57727C16.8173 8.47043 16.721 8.31787 16.5371 8.12241L16.5383 7.70068C16.7222 7.89614 16.8185 8.04871 16.8256 8.15554C16.826 8.16051 16.8261 8.16546 16.8261 8.17039Z"
        fill="#F2BC36"
      />
      <path
        d="M16.5383 7.70068L16.5371 8.12241L15.3672 8.80229L15.3684 8.38056L16.5383 7.70068Z"
        fill="#F2BC36"
      />
      <path
        d="M14.2599 8.53888L14.2587 8.96062C13.7216 8.58015 13.4227 8.1339 13.3589 7.61997L13.3601 7.19824C13.4239 7.71217 13.7228 8.15842 14.2599 8.53888Z"
        fill="#F2BC36"
      />
      <path
        d="M14.2054 9.05665L14.2042 9.47838L13.7847 9.23618L13.7859 8.81445L14.2054 9.05665Z"
        fill="#F2BC36"
      />
      <path
        d="M14.6729 8.78516L14.6717 9.20689L14.2041 9.47865L14.2053 9.05692L14.6729 8.78516Z"
        fill="#F2BC36"
      />
      <path
        d="M17.9868 8.23389L17.9856 8.65562C17.985 8.88156 17.8119 9.09421 17.4676 9.29428C17.087 9.5155 16.643 9.61661 16.1359 9.59942C15.6271 9.58132 15.1391 9.44973 14.6719 9.20658L14.6731 8.78485C15.1403 9.028 15.6283 9.15959 16.1371 9.17769C16.6442 9.19488 17.0882 9.09377 17.4688 8.87255C17.8131 8.67247 17.9862 8.45982 17.9868 8.23389Z"
        fill="#F2BC36"
      />
      <path
        d="M17.9818 8.1651C18.0194 8.41682 17.8479 8.65235 17.4689 8.87263C17.0882 9.09384 16.6442 9.19496 16.1372 9.17777C15.6284 9.15967 15.1403 9.02807 14.6731 8.78492L14.2055 9.05668L13.786 8.81447L14.2602 8.53889C13.723 8.15843 13.4242 7.71217 13.3604 7.19824L14.4037 7.14483C14.4537 7.54054 14.6416 7.86762 14.969 8.12698L16.2308 7.39371C15.8551 7.04183 15.6477 6.74335 15.6101 6.50116C15.5709 6.25801 15.7391 6.02822 16.1149 5.80985C16.489 5.59242 16.9264 5.48942 17.4285 5.50375C17.9323 5.5171 18.4054 5.64012 18.8496 5.87181L19.1745 5.68299L19.594 5.92519L19.2625 6.11784C19.707 6.41439 19.9987 6.77102 20.1361 7.18674L19.1128 7.28781C19.0288 7.00368 18.8414 6.75099 18.5537 6.52974L17.3329 7.23922L17.3527 7.25066C17.735 7.60824 17.9475 7.91335 17.9818 8.1651ZM16.0481 8.56179C16.2704 8.5742 16.4562 8.537 16.6088 8.44833C16.7597 8.36059 16.8333 8.26237 16.8261 8.1556C16.8189 8.04876 16.7226 7.8962 16.5387 7.70074L15.3688 8.38063C15.5999 8.48931 15.8258 8.54938 16.0481 8.56179ZM17.0299 6.92931L18.1539 6.27612C17.9409 6.17221 17.7299 6.1169 17.5208 6.11023C17.3133 6.10258 17.1358 6.14073 16.9898 6.22557C16.8454 6.30948 16.7734 6.40106 16.7756 6.50116C16.7795 6.60033 16.8626 6.74338 17.0299 6.92931Z"
        fill="#FED44C"
      />
      <path
        d="M12.4349 18.8217L10.4059 20.003C9.95316 20.2665 9.414 20.3776 8.8131 20.3241C8.43035 20.2912 8.02289 20.1883 7.59485 20.0112C7.36437 19.9166 7.1298 19.8014 6.89108 19.6614C4.16237 18.0892 1.95224 14.2575 1.96047 11.1295C1.96047 11.1295 1.96047 11.1295 1.96047 11.1255V11.0144C1.97693 10.2776 2.11685 9.65205 2.35556 9.14181C2.46257 8.91954 2.58604 8.71778 2.73011 8.54093C2.92353 8.30219 3.14989 8.10444 3.40506 7.95633L5.4341 6.7793C5.17893 6.92741 4.95257 7.12093 4.75915 7.3637C4.61508 7.53652 4.49162 7.73829 4.38872 7.96055C4.14591 8.47079 4.00597 9.10061 3.99361 9.83312C3.99361 9.87009 3.98951 9.90727 3.98951 9.94847C3.98127 13.0805 6.1914 16.908 8.92011 18.4843C9.15883 18.6202 9.39752 18.7353 9.628 18.8302C10.0519 19.007 10.4594 19.1099 10.8421 19.1469C11.443 19.2006 11.9822 19.0852 12.4349 18.8217Z"
        fill="#FED44C"
      />
      <path
        d="M12.4363 18.8238L10.4058 20.0038C11.2936 19.4879 11.8449 18.3922 11.8493 16.8367L13.8798 15.6567C13.8754 17.2122 13.3241 18.3079 12.4363 18.8238Z"
        fill="#F9C73A"
      />
      <path
        d="M3.40479 7.9583L5.4353 6.7783C6.33499 6.25546 7.58031 6.32806 8.95289 7.12052L6.92238 8.30053C5.5498 7.50807 4.30448 7.43546 3.40479 7.9583Z"
        fill="#F9C73A"
      />
      <path
        d="M6.92236 8.30012L8.95287 7.12012C11.6778 8.69338 13.8884 12.5222 13.8795 15.6565L11.849 16.8365C11.8579 13.7022 9.64732 9.87338 6.92236 8.30012Z"
        fill="#F9C73A"
      />
      <path
        d="M7.40147 7.934C10.1264 9.50726 12.337 13.336 12.3281 16.4703C12.3193 19.6015 10.0942 20.8694 7.36926 19.2962C4.64167 17.7214 2.43109 13.8926 2.43997 10.7614C2.44885 7.62711 4.67388 6.35923 7.40147 7.934Z"
        fill="#F2BC36"
      />
      <path
        d="M6.92731 6.62135C10.4642 8.6634 13.3218 13.6159 13.3102 17.6802C13.2987 21.7443 10.4225 23.3833 6.88557 21.3412C3.34866 19.2992 0.48851 14.3483 0.500035 10.2842C0.511555 6.21991 3.39039 4.57931 6.92731 6.62135ZM6.89034 19.6625C9.61529 21.2357 11.8403 19.9678 11.8492 16.8366C11.8581 13.7023 9.64751 9.87356 6.92255 8.3003C4.19497 6.72553 1.96993 7.99341 1.96105 11.1277C1.95217 14.2589 4.16275 18.0877 6.89034 19.6625Z"
        fill="#F9C73A"
      />
      <path
        d="M7.18041 16.8724L7.53303 16.6675L7.53134 17.264L7.17871 17.4689L7.18041 16.8724Z"
        fill="#E2AB30"
      />
      <path
        d="M5.47607 14.2542L5.82869 14.0493C6.23164 14.6846 6.64782 15.1656 7.07405 15.489L6.72143 15.6939C6.2952 15.3705 5.87902 14.8895 5.47607 14.2542Z"
        fill="#FED44C"
      />
      <path
        d="M7.18311 15.9862L7.53573 15.7812C7.7674 15.8874 7.94969 15.9154 8.08581 15.8654L7.73319 16.0703C7.59707 16.1204 7.41478 16.0923 7.18311 15.9862Z"
        fill="#FED44C"
      />
      <path
        d="M8.13541 15.8423L7.78278 16.0472C7.76747 16.0561 7.75101 16.0638 7.7334 16.0702L8.08602 15.8653C8.10363 15.8589 8.12009 15.8512 8.13541 15.8423Z"
        fill="black"
      />
      <path
        d="M7.18734 14.494L7.53996 14.2891L7.53573 15.7812L7.18311 15.9862L7.18734 14.494Z"
        fill="#E2AB30"
      />
      <path
        d="M7.19043 13.4828L7.54305 13.2778L7.5654 13.2907L7.21278 13.4957L7.19043 13.4828Z"
        fill="#FED44C"
      />
      <path
        d="M6.1958 11.576L6.54843 11.3711C6.44557 11.4309 6.39266 11.5444 6.39218 11.7146C6.39166 11.8981 6.43916 12.0727 6.53469 12.2362C6.63181 12.4008 6.8136 12.6087 7.08165 12.8627L6.72903 13.0676C6.46098 12.8136 6.27919 12.6057 6.18206 12.4411C6.08654 12.2776 6.03904 12.103 6.03956 11.9195C6.04004 11.7493 6.09294 11.6358 6.1958 11.576Z"
        fill="#FED44C"
      />
      <path
        d="M7.2124 13.4955L7.56503 13.2905C8.11723 13.7601 8.52234 14.1962 8.78196 14.5998L8.42934 14.8047C8.16972 14.4011 7.7646 13.9651 7.2124 13.4955Z"
        fill="#FED44C"
      />
      <path
        d="M8.78485 16.8191L8.43222 17.0241C8.68615 16.8765 8.815 16.5815 8.81626 16.1362C8.81763 15.6517 8.68771 15.2071 8.42969 14.8045L8.78231 14.5996C9.04034 15.0022 9.17026 15.4467 9.16888 15.9312C9.16762 16.3766 9.03877 16.6716 8.78485 16.8191Z"
        fill="#FED44C"
      />
      <path
        d="M7.19484 11.9261L7.54746 11.7212L7.54305 13.2775L7.19043 13.4824L7.19484 11.9261Z"
        fill="#E2AB30"
      />
      <path
        d="M5.54443 10.6179L5.89705 10.413C5.91453 10.4028 5.9326 10.3934 5.95126 10.3846C6.2428 10.2496 6.62181 10.3012 7.08826 10.5429L6.73564 10.7478C6.26918 10.5061 5.89018 10.4546 5.59863 10.5895C5.57997 10.5983 5.5619 10.6077 5.54443 10.6179Z"
        fill="#FED44C"
      />
      <path
        d="M6.73682 10.3328L7.08944 10.1279L7.55103 10.3944L7.1984 10.5994L6.73682 10.3328Z"
        fill="#FED44C"
      />
      <path
        d="M7.19847 10.5995L7.55109 10.3945L7.54989 10.8184L7.19727 11.0233L7.19847 10.5995Z"
        fill="#E2AB30"
      />
      <path
        d="M7.19727 11.0233L7.54989 10.8184C8.07842 11.1677 8.57776 11.6637 9.04788 12.3046L8.69526 12.5095C8.22513 11.8687 7.7258 11.3726 7.19727 11.0233Z"
        fill="#FED44C"
      />
      <path
        d="M8.69524 12.5096L9.04786 12.3047L8.57772 12.798L8.2251 13.0029L8.69524 12.5096Z"
        fill="#E2AB30"
      />
      <path
        d="M8.42951 14.8049C8.68754 15.2075 8.81746 15.652 8.81608 16.1365C8.81471 16.6192 8.66347 16.9252 8.36552 17.0584C8.06599 17.1907 7.67102 17.1281 7.18061 16.8725L7.17892 17.469L6.71733 17.2025L6.71905 16.5968C6.06122 16.1398 5.47111 15.4976 4.94873 14.6703L5.47625 14.2542C5.87919 14.8895 6.29537 15.3705 6.72161 15.6939L6.72617 14.0861C6.18355 13.6239 5.78639 13.198 5.53473 12.81C5.28306 12.4221 5.15791 11.9877 5.15926 11.5104C5.16062 11.0333 5.30707 10.7263 5.59863 10.5894C5.89017 10.4545 6.26918 10.506 6.73563 10.7477L6.73681 10.333L7.1984 10.5995L7.1972 11.0234C7.72573 11.3727 8.22506 11.8687 8.69519 12.5096L8.22505 13.0029C7.90794 12.5588 7.565 12.2009 7.19464 11.9264L7.19022 13.4827L7.21258 13.4956C7.76477 13.9652 8.16989 14.4012 8.42951 14.8049ZM7.73321 16.0702C7.86773 16.021 7.93527 15.9 7.93581 15.7074C7.93636 15.5128 7.88409 15.33 7.779 15.1555C7.67231 14.9799 7.47615 14.76 7.18735 14.4939L7.18312 15.9861C7.41479 16.0922 7.59709 16.1203 7.73321 16.0702ZM6.72905 13.0675L6.73312 11.6342C6.51743 11.5317 6.34792 11.5073 6.22459 11.5612C6.10284 11.6159 6.04011 11.7341 6.03959 11.9195C6.03907 12.103 6.08657 12.2775 6.18209 12.4411C6.27921 12.6056 6.461 12.8135 6.72905 13.0675Z"
        fill="#F9C73A"
      />
      <path
        d="M15.3428 16.5002C15.3346 18.5211 14.6225 19.9409 13.4743 20.6077L11.4411 21.7889C12.5935 21.1179 13.3055 19.6981 13.3096 17.6815C13.322 13.6152 10.4657 8.66392 6.9262 6.62254C5.14822 5.59362 3.53484 5.49896 2.37012 6.174L4.39916 4.99676C5.56388 4.31771 7.17726 4.41236 8.95934 5.44129C12.4947 7.48266 15.351 12.434 15.3428 16.5002Z"
        fill="#FED44C"
      />
      <path
        d="M10.8794 19.4186L10.9954 19.8099L10.7553 19.4798L10.3259 20.2712L10.4663 19.3818L10.075 19.4978L10.4051 19.2578L9.61377 18.8283L10.5031 18.9688L10.3871 18.5775L10.6272 18.9075L11.0566 18.1162L10.9162 19.0055L11.3075 18.8896L10.9774 19.1296L11.7687 19.5591L10.8794 19.4186Z"
        fill="white"
      />
      <path
        d="M20.8537 6.52633L21.0687 7.2517L20.6238 6.6398L19.8277 8.10664L20.0881 6.45815L19.3627 6.6732L19.9746 6.22823L18.5078 5.43223L20.1563 5.69255L19.9412 4.96725L20.3862 5.57908L21.1822 4.1123L20.9219 5.76073L21.6472 5.54574L21.0354 5.99065L22.5021 6.78672L20.8537 6.52633Z"
        fill="white"
      />
    </svg>
  );
}

export default Coins;
