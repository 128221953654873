import { Avatar, Button, Grid, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { translate } from '../../_dashboard/app';

const Template = ({ templateType, setTemplateType, setPageNum, language }) => {
  const Templates = [
    {
      key: 0,
      name: 'Template 1',
      src: 'https://gsaas-getmok.s3.ap-south-1.amazonaws.com/default/file-1660985861731.png',
      innerImg: 'https://gsaas-getmok.s3.ap-south-1.amazonaws.com/default/file-1661247770479.png'
    },
    {
      key: 1,
      name: 'Template 2',
      src: 'https://gsaas-getmok.s3.ap-south-1.amazonaws.com/default/file-1660986480475.png',
      innerImg: 'https://gsaas-getmok.s3.ap-south-1.amazonaws.com/default/file-1661250333657.png'
    }
  ];

  const handleTemplateSelect = (value) => {
    setTemplateType(value);
  };

  return (
    <>
      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
        {Templates.map((item, index) => (
          <Grid item key={index} xs={4} sm={4} md={4}>
            <div
              role="button"
              aria-hidden="true"
              onClick={() => handleTemplateSelect(item)}
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer'
              }}
            >
              <Avatar
                variant="square"
                alt=" "
                src={item.src}
                sx={{
                  width: 150,
                  height: 100,
                  marginBottom: 2,
                  border: `${item.src === templateType.src ? '2px solid #00ab55' : ''}`
                }}
              />
              <Typography color={item.src === templateType.src ? 'primary' : '#000'}>
                {item.name}
              </Typography>
            </div>
          </Grid>
        ))}
      </Grid>
      <Stack sx={{ width: '100%', alignItems: 'self-end' }}>
        <Button
          onClick={() => setPageNum((prev) => prev + 1)}
          sx={{
            marginTop: '40px',
            marginRight: '40px',
            padding: '5px 25px'
          }}
          variant="contained"
          size="medium"
          disabled={!templateType.src}
        >
          {translate(language, 'Next Step')}
        </Button>
      </Stack>
    </>
  );
};

export default Template;

Template.propTypes = {
  templateType: PropTypes.object,
  setTemplateType: PropTypes.func,
  setPageNum: PropTypes.func,
  language: PropTypes.element
};
