import * as React from 'react';
import { useState } from 'react';
import {
  Card,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  Table,
  TableHead,
  TableBody,
  TableContainer,
  TableCell,
  TableRow,
  TablePagination,
  Typography,
  Button,
  Tooltip
} from '@mui/material';
import { Icon } from '@iconify/react';
import trashFill from '@iconify/icons-eva/trash-2-fill';
import infoCircleFill from '@iconify/icons-bi/info-circle-fill';
import { toast, ToastContainer } from 'react-toastify';
import axios from 'axios';
import lodash from 'lodash';
import PropTypes from 'prop-types';
import Scrollbar from '../Scrollbar';
import { translate } from '../_dashboard/app';

/* eslint-disable camelcase */
export default function WheelOptionsTable({
  wheelData,
  language,
  getWheelData,
  handleOptionModalOpen
}) {
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [openDeleteProp, setOpenDeleteProp] = useState(false);
  const [optionToDelete, setOptionToDelete] = useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDeleteOption = async () => {
    try {
      // eslint-disable-next-line
      const res = axios.delete(`spinwheel/${optionToDelete}`);

      setOpenDeleteProp(false);
      await getWheelData();
      toast.success(translate(language, 'Option Deleted Successfully!'));
    } catch (error) {
      console.log(error);
      toast.error(translate(language, 'Something went wrong!'));
    }
  };

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <Typography variant="h6">
          {translate(language, 'Wheel Tab Options ')}
          <Tooltip
            describeChild
            followCursor
            title={translate(
              language,
              'Must Add four or more wheel options to activate Spin To Win.'
            )}
          >
            <Icon icon={infoCircleFill} style={{ paddingTop: '3px', color: '#229A16' }} />
          </Tooltip>
        </Typography>
        {wheelData.length < 20 && (
          <Button variant="contained" onClick={handleOptionModalOpen}>
            {translate(language, 'Add Option')}
          </Button>
        )}
      </div>
      <Card style={{ marginTop: '15px' }}>
        <Scrollbar>
          <Dialog open={openDeleteProp} onClose={() => setOpenDeleteProp(false)}>
            <DialogContent>
              {translate(language, 'Are you sure you want to delete this Option?')}
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenDeleteProp(false)}>{translate(language, 'No')}</Button>
              <Button onClick={handleDeleteOption}>{translate(language, 'Yes')}</Button>
            </DialogActions>
          </Dialog>
          <ToastContainer
            position="bottom-right"
            autoClose={1500}
            newestOnTop
            closeOnClick
            limit={2}
          />
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{translate(language, 'ID')}</TableCell>
                  <TableCell align="center">{translate(language, 'Name')}</TableCell>
                  <TableCell align="center">{translate(language, 'Chance')}</TableCell>
                  <TableCell align="center">{translate(language, 'Type')}</TableCell>
                  <TableCell align="center">{translate(language, 'Discount')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {wheelData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell align="center">{row.option_name}</TableCell>
                      <TableCell align="center">{row.option_chance}</TableCell>
                      <TableCell align="center">{lodash.startCase(row.coupon_type)}</TableCell>
                      <TableCell align="center">{row.coupon_discount}</TableCell>
                      <TableCell align="center">
                        <IconButton
                          onClick={() => {
                            setOpenDeleteProp(true);
                            setOptionToDelete(row.spin_wheel_id);
                          }}
                          edge="end"
                        >
                          <Icon icon={trashFill} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>
        <TablePagination
          rowsPerPageOptions={[5, 10, 20]}
          component="div"
          count={wheelData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>
    </>
  );
}

WheelOptionsTable.propTypes = {
  wheelData: PropTypes.array,
  getWheelData: PropTypes.func,
  handleOptionModalOpen: PropTypes.func,
  language: PropTypes.element
};
