import { useState, useEffect, useRef } from 'react';
import {
  Avatar,
  Select,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  Table,
  TableHead,
  TableBody,
  TableContainer,
  TableCell,
  TableRow,
  TablePagination
} from '@mui/material';
import axios from 'axios';
import PropTypes from 'prop-types';
import { toast, ToastContainer } from 'react-toastify';
import CenterSpinner from '../CenterSpinner';
import ModifyGroupModal from './ModifyGroupModal';
import { translate } from '../_dashboard/app';

const PlayerTable = ({ refresh, groupData, language, allGroupsData }) => {
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [playersData, setPlayersData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modType, setModType] = useState('');
  const [playerIdToChange, setPlayerIdToChange] = useState(0);
  const [openModifyModal, setOpenModifyModal] = useState(false);
  const [openDeleteProp, setOpenDeleteProp] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getPlayersData = async () => {
    setLoading(true);
    const res = await axios.get('group/players', {
      params: {
        group_id: groupData.id
      }
    });
    setPlayersData(res.data.data);
    setLoading(false);
  };

  const handleChangeOp = (event, playerID) => {
    setModType(event.target.value);
    setPlayerIdToChange(playerID);
  };

  const deletePlayer = async () => {
    try {
      setLoading(true);
      setOpenDeleteProp(true);
      // eslint-disable-next-line
      const res = await axios.delete(`group/${groupData.id}/${playerIdToChange}`);

      getPlayersData();
      toast.success(translate(language, 'Player Deleted Successfully!'));
      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error(translate(language, 'Something went wrong!'));
    }
    setOpenDeleteProp(false);
  };

  useEffect(() => {
    getPlayersData();
    // eslint-disable-next-line
  }, [refresh]);

  const firstUpdate = useRef(true);
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    setOpenModifyModal(true);
    // eslint-disable-next-line
  }, [modType]);

  if (loading) <CenterSpinner />;

  return (
    <>
      {['move', 'add'].includes(modType) && (
        <ModifyGroupModal
          language={language}
          open={openModifyModal}
          setOpen={setOpenModifyModal}
          modType={modType}
          setModType={setModType}
          playerId={playerIdToChange}
          currentGroupId={groupData.id}
          allGroupsData={allGroupsData}
          getPlayersData={getPlayersData}
        />
      )}
      <Dialog open={openDeleteProp} onClose={() => setOpenDeleteProp(false)}>
        <DialogContent>
          {translate(language, 'Are you sure you want to delete this Player')}?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteProp(false)}>{translate(language, 'No')}</Button>
          <Button onClick={deletePlayer}>{translate(language, 'Yes')}</Button>
        </DialogActions>
      </Dialog>
      <ToastContainer
        position="bottom-right"
        autoClose={1000}
        newestOnTop
        closeOnClick
        pauseOnHover
        limit={2}
      />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{translate(language, 'ID')}</TableCell>
              <TableCell align="center">{translate(language, 'Player Name')}</TableCell>
              <TableCell align="center">{translate(language, 'Level')}</TableCell>
              <TableCell align="center">{translate(language, 'Points')}</TableCell>
              <TableCell align="center">{translate(language, 'Coupons')}</TableCell>
              <TableCell align="center" />
            </TableRow>
          </TableHead>
          <TableBody>
            {playersData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell align="center">{row.name}</TableCell>
                  <TableCell align="center">
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      {!!row.level?.image_url && (
                        <Avatar
                          alt=""
                          variant="square"
                          sx={{ height: '30px', width: '30px', marginRight: '5px' }}
                          src={row.level.image_url}
                        />
                      )}
                      <p>{row.level?.name ?? translate(language, 'No Level')}</p>
                    </div>
                  </TableCell>
                  <TableCell align="center">{row.total_points}</TableCell>
                  <TableCell align="center">{row.couponsCount}</TableCell>
                  <TableCell align="center">
                    <Select
                      displayEmpty
                      renderValue={() => (
                        <Avatar
                          variant="square"
                          alt=" "
                          src="/static/mock-images/Dots.png"
                          sx={{ left: '18px', top: '2px', width: '5px', height: '20px' }}
                        />
                      )}
                      IconComponent={() => null}
                      value=""
                      onChange={(e) => handleChangeOp(e, row.players_id)}
                      variant="standard"
                      disableUnderline
                      sx={{ width: '0' }}
                    >
                      <MenuItem value="move">
                        {translate(language, 'Move to another group')}
                      </MenuItem>
                      <MenuItem value="add">{translate(language, 'Add to another group')}</MenuItem>
                      <MenuItem value="del" onClick={() => setOpenDeleteProp(true)}>
                        {translate(language, 'Delete from this group')}
                      </MenuItem>
                    </Select>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 20]}
        component="div"
        count={playersData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

export default PlayerTable;

PlayerTable.propTypes = {
  refresh: PropTypes.bool,
  groupData: PropTypes.object,
  allGroupsData: PropTypes.array,
  language: PropTypes.element
};
