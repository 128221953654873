import { Typography, Card, CardContent, CardHeader, Chip } from '@mui/material';
import { object } from 'prop-types';
import { Icon } from '@iconify/react';
import coinsIcon from '@iconify/icons-fa-solid/coins';
import Coins from './icons/Coins';
import Levels from './icons/Levels';
import Check from './icons/Check';
import Challenges from './icons/Challenges';
import Medal from './icons/Medal';
import UserTimeline from './UserTimeline';

const UserJourney = ({ data, playerInfo }) => (
  <>
    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '55px' }}>
      <Card sx={{ width: '200px' }}>
        <CardContent>
          <div style={{ display: 'flex', justifyContent: 'start', marginBottom: '25px' }}>
            <Icon color="#00AB55" icon={coinsIcon} width={25} height={25} />
            <Typography variant="body" paddingLeft={2}>
              Total Points
            </Typography>
          </div>
          <div style={{ display: 'flex', justifyContent: 'start' }}>
            <Coins />
            <Typography variant="body" paddingLeft={2}>
              {playerInfo.total_points}
            </Typography>
          </div>
        </CardContent>
      </Card>
      <Card sx={{ width: '250px' }}>
        <CardContent>
          <div style={{ display: 'flex', justifyContent: 'start', marginBottom: '25px' }}>
            <Levels />
            <Typography variant="body" paddingLeft={2}>
              Player Level
            </Typography>
          </div>
          <div style={{ display: 'flex', justifyContent: 'start' }}>
            <Medal />
            <Typography variant="body" paddingLeft={2}>
              {data.levelName ?? 'No Level'}
            </Typography>
          </div>
        </CardContent>
      </Card>
      <Card sx={{ width: '300px' }}>
        <CardContent>
          <div style={{ display: 'flex', justifyContent: 'start', marginBottom: '25px' }}>
            <Challenges />
            <Typography variant="body" paddingLeft={2}>
              Challenges Completed
            </Typography>
          </div>
          <div style={{ display: 'flex', justifyContent: 'start' }}>
            <Check />
            <Typography variant="body" paddingLeft={2}>
              {data.challenges}
            </Typography>
          </div>
        </CardContent>
      </Card>
    </div>
    <div style={{ marginTop: '50px', display: 'flex', justifyContent: 'space-between' }}>
      {data.activityData?.length > 0 && (
        <Card sx={{ width: '500px', height: '500px', overflowY: 'auto' }}>
          <CardContent>
            <UserTimeline data={data.activityData} />
          </CardContent>
        </Card>
      )}
      <Card sx={{ width: '300px' }}>
        <CardHeader title="Player Details" />
        <CardContent>
          <Typography variant="h6">Email</Typography>
          <Typography sx={{ wordBreak: 'break-word' }}>
            {playerInfo.email ?? 'Not Provided'}
          </Typography>
          <Typography variant="h6" marginTop={2}>
            Phone Number
          </Typography>
          <Typography>{playerInfo.mobile ?? 'Not Provided'}</Typography>
          <Typography variant="h6" marginTop={2}>
            Address
          </Typography>
          <Typography>
            {playerInfo.state && playerInfo.country
              ? `${playerInfo.state}, ${playerInfo.country}`
              : 'Not Provided'}
          </Typography>
          <Typography variant="h6" marginTop={2}>
            Orders Fullfilled
          </Typography>
          <Typography>{data.numOrders}</Typography>
          <Typography variant="h6" marginTop={2}>
            Groups
          </Typography>
          <Typography>
            {data.groups?.map((item, index) => (
              <Chip label={item} key={index} sx={{ margin: '2px' }} />
            ))}
          </Typography>
        </CardContent>
      </Card>
    </div>
  </>
);

export default UserJourney;

UserJourney.propTypes = {
  data: object,
  playerInfo: object
};
