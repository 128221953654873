import React from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Icon } from '@iconify/react';
import upOutlined from '@iconify/icons-ant-design/up-outlined';
import uploadIcon from '@iconify/icons-fa-solid/upload';
import downOutlined from '@iconify/icons-ant-design/down-outlined';
import deleteFill from '@iconify/icons-ant-design/delete-filled';
import editFill from '@iconify/icons-eva/edit-fill';

import {
  Grid,
  styled,
  Paper,
  IconButton,
  Collapse,
  Box,
  Toolbar,
  Table,
  Button,
  TableBody,
  TableCell,
  Typography,
  TableRow,
  TableHead,
  TableContainer
} from '@mui/material';
import PropTypes from 'prop-types';
import { translate } from '../../app';

const language = localStorage.getItem('currentLanguage');

const handleEdit = (values) => {
  console.log(' Working ', values);
};
const handleDelete = (values) => {
  console.log(' Working ', values);
};

function createData(name, displayName, rule, linkStatus) {
  return {
    name,
    displayName,
    rule,
    linkStatus,
    history: [
      {
        date: '2020-01-05',
        LinkId: '11091700',
        totalLinks: 3,
        linkStatus: 'Linked'
      },
      {
        date: '2020-01-02',
        LinkId: '11091723',
        totalLinks: 2,
        linkStatus: 'Not-Linked'
      }
    ]
  };
}

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <Icon icon={upOutlined} /> : <Icon icon={downOutlined} />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.name}
        </TableCell>
        <TableCell>{row.displayName}</TableCell>
        <TableCell align="right">{row.rule}</TableCell>
        <TableCell align="right">{row.linkStatus}</TableCell>
        <TableCell align="right">
          <IconButton aria-label="expand row" size="small" onClick={() => handleEdit(row)}>
            <Icon icon={editFill} />
          </IconButton>
          <IconButton aria-label="expand row" size="small" onClick={() => handleDelete(row)}>
            <Icon icon={deleteFill} />
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                {translate(language, 'Link History')}
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>{translate(language, 'Date')}</TableCell>
                    <TableCell>{translate(language, 'Link Id')}</TableCell>
                    <TableCell align="right">{translate(language, 'Total Links')}</TableCell>
                    <TableCell align="right">{translate(language, 'Link Status')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.history.map((historyRow) => (
                    <TableRow key={historyRow.date}>
                      <TableCell component="th" scope="row">
                        {historyRow.date}
                      </TableCell>
                      <TableCell>{historyRow.LinkId}</TableCell>
                      <TableCell align="right">{historyRow.totalLinks}</TableCell>
                      <TableCell align="right">{historyRow.linkStatus}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    name: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    rule: PropTypes.number.isRequired,
    history: PropTypes.object.isRequired,
    linkStatus: PropTypes.objectOf(
      PropTypes.shape({
        totalLinks: PropTypes.number.isRequired,
        LinkId: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired
      })
    ).isRequired
  })
};

const rows = [createData('abcdefg', 'dfuyjduy', 159, 'Not-Linked')];

const RootStyle = styled(Toolbar)(() => ({
  display: 'flex',
  justifyContent: 'space-between'
}));
export default function Events() {
  return (
    <>
      <RootStyle>
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
          <Typography variant="h5" align="left" color="#0D1C21">
            {translate(language, 'Player Tag')}
            <Typography variant="subtitle2" align="left" paddingTop={1} color="#0D1C21">
              {translate(language, 'Numbers of Tag (0)')}
            </Typography>
          </Typography>
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Grid flex-direction="column" paddingX={2}>
            <IconButton size="small">
              <Icon icon={uploadIcon} />
            </IconButton>
            <h6>{translate(language, 'Events')}</h6>
          </Grid>
          <Button variant="contained" startIcon={<Icon icon={plusFill} />}>
            {translate(language, 'Create New Tag')}
          </Button>
        </div>
      </RootStyle>
      <TableContainer component={Paper}>
        <Table aria-label="simple table" paddingTop={10}>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>{translate(language, 'Event Name')}</TableCell>
              <TableCell>{translate(language, 'Display Name')}&nbsp;</TableCell>
              <TableCell align="right">{translate(language, 'Rule')}&nbsp;</TableCell>
              <TableCell align="right">{translate(language, 'Link Status')}&nbsp;</TableCell>
              <TableCell align="right">{translate(language, 'Actions')}&nbsp;</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <Row key={row.name} row={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
