export default function Mail() {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M39.5821 13.5815H0.326477V39.6736H39.5821V13.5815Z" fill="#FFB636" />
      <path
        d="M39.5821 39.9997H0.326477C0.23989 39.9997 0.156844 39.9653 0.0956178 39.904C0.0343914 39.8428 0 39.7598 0 39.6732V13.555C0 13.4684 0.0343914 13.3854 0.0956178 13.3241C0.156844 13.2629 0.23989 13.2285 0.326477 13.2285H39.5821C39.6687 13.2285 39.7517 13.2629 39.813 13.3241C39.8742 13.3854 39.9086 13.4684 39.9086 13.555V39.6732C39.9086 39.7598 39.8742 39.8428 39.813 39.904C39.7517 39.9653 39.6687 39.9997 39.5821 39.9997ZM0.652955 39.3467H39.2556V13.8815H0.652955V39.3467Z"
        fill="#263238"
      />
      <path d="M39.5821 13.5815L19.9543 26.6276L0.326477 13.5815H39.5821Z" fill="#263238" />
      <path
        d="M19.9543 26.9541C19.8899 26.9528 19.8269 26.9348 19.7715 26.9018L0.143654 13.8427C0.0863813 13.8031 0.042977 13.7465 0.0195511 13.6809C-0.00387483 13.6153 -0.00614119 13.544 0.013065 13.4771C0.0340074 13.4105 0.0757374 13.3523 0.132146 13.3112C0.188555 13.2701 0.256669 13.2481 0.32648 13.2485H39.5821C39.6512 13.2494 39.7183 13.2722 39.7737 13.3136C39.829 13.355 39.8698 13.4129 39.8902 13.479C39.9105 13.5451 39.9093 13.6159 39.8868 13.6812C39.8643 13.7466 39.8216 13.8032 39.7649 13.8427L20.1371 26.9018C20.0817 26.9348 20.0187 26.9528 19.9543 26.9541ZM1.40385 13.895L19.9543 26.2358L38.4982 13.908L1.40385 13.895Z"
        fill="#263238"
      />
      <path
        d="M5.53052 17.186V0.32666H34.3389V17.0488L19.9543 26.6277L5.53052 17.186Z"
        fill="white"
      />
      <path
        d="M19.9543 26.954C19.8898 26.9527 19.8268 26.9347 19.7715 26.9017L5.35422 17.46C5.30904 17.4297 5.27182 17.3889 5.24569 17.3412C5.21955 17.2935 5.20527 17.2402 5.20404 17.1858V0.326477C5.20404 0.23989 5.23843 0.156854 5.29966 0.0956277C5.36088 0.0344014 5.44393 0 5.53052 0H34.3193C34.4059 0 34.4889 0.0344014 34.5501 0.0956277C34.6114 0.156854 34.6458 0.23989 34.6458 0.326477V17.0486C34.6464 17.1027 34.6336 17.156 34.6085 17.2039C34.5834 17.2517 34.5469 17.2926 34.5021 17.3229L20.1371 26.9017C20.0817 26.9347 20.0187 26.9527 19.9543 26.954ZM5.857 17.0095L19.9543 26.2357L34.0124 16.8789V0.633367H5.857V17.0095Z"
        fill="#263238"
      />
      <path
        d="M28.3644 6.50989H10.9762C10.8896 6.50989 10.8065 6.47549 10.7453 6.41426C10.6841 6.35303 10.6497 6.27 10.6497 6.18341C10.6497 6.09682 10.6841 6.01379 10.7453 5.95256C10.8065 5.89133 10.8896 5.85693 10.9762 5.85693H28.3644C28.4509 5.85693 28.534 5.89133 28.5952 5.95256C28.6564 6.01379 28.6908 6.09682 28.6908 6.18341C28.6908 6.27 28.6564 6.35303 28.5952 6.41426C28.534 6.47549 28.4509 6.50989 28.3644 6.50989Z"
        fill="#263238"
      />
      <path
        d="M28.3644 10.8717H10.9762C10.8896 10.8717 10.8065 10.8373 10.7453 10.7761C10.6841 10.7149 10.6497 10.6318 10.6497 10.5452C10.6497 10.4586 10.6841 10.3756 10.7453 10.3144C10.8065 10.2532 10.8896 10.2188 10.9762 10.2188H28.3644C28.4509 10.2188 28.534 10.2532 28.5952 10.3144C28.6564 10.3756 28.6908 10.4586 28.6908 10.5452C28.6908 10.6318 28.6564 10.7149 28.5952 10.7761C28.534 10.8373 28.4509 10.8717 28.3644 10.8717Z"
        fill="#263238"
      />
      <path
        d="M28.3644 15.2531H10.9762C10.8896 15.2531 10.8065 15.2187 10.7453 15.1574C10.6841 15.0962 10.6497 15.0132 10.6497 14.9266C10.6497 14.84 10.6841 14.757 10.7453 14.6957C10.8065 14.6345 10.8896 14.6001 10.9762 14.6001H28.3644C28.4509 14.6001 28.534 14.6345 28.5952 14.6957C28.6564 14.757 28.6908 14.84 28.6908 14.9266C28.6908 15.0132 28.6564 15.0962 28.5952 15.1574C28.534 15.2187 28.4509 15.2531 28.3644 15.2531Z"
        fill="#263238"
      />
      <path d="M0.326477 39.6736L20 23.291L39.5821 39.6736H0.326477Z" fill="#FFB636" />
      <path
        d="M39.5821 39.9998H0.326492C0.260029 39.9992 0.195347 39.9783 0.141095 39.9399C0.0868433 39.9015 0.045605 39.8474 0.0228905 39.7849C0.000176067 39.7225 -0.00293096 39.6546 0.0139833 39.5903C0.0308977 39.526 0.0670241 39.4684 0.117542 39.4252L19.7911 23.0361C19.8497 22.9872 19.9237 22.9604 20 22.9604C20.0764 22.9604 20.1503 22.9872 20.209 23.0361L39.7976 39.4187C39.8481 39.4619 39.8842 39.5195 39.9012 39.5838C39.9181 39.648 39.915 39.716 39.8923 39.7784C39.8695 39.8409 39.8283 39.8949 39.774 39.9333C39.7198 39.9717 39.6551 39.9926 39.5887 39.9933L39.5821 39.9998ZM1.22757 39.3469H38.681L20 23.7151L1.22757 39.3469Z"
        fill="#263238"
      />
    </svg>
  );
}
