function Medal() {
  return (
    <svg width="25" height="25" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.4062 0.625L10.875 5.90625H12.625L16.5625 0.625H14.4062Z" fill="#428BC1" />
      <path d="M12.625 0.625L9.125 5.90625H10.875L14.4062 0.625H12.625Z" fill="#E8E8E8" />
      <path d="M10.875 0.625L7.375 5.90625H9.125L12.625 0.625H10.875Z" fill="#ED4C5C" />
      <path
        d="M10 19.375C13.5553 19.375 16.4375 16.4928 16.4375 12.9375C16.4375 9.38217 13.5553 6.5 10 6.5C6.44467 6.5 3.5625 9.38217 3.5625 12.9375C3.5625 16.4928 6.44467 19.375 10 19.375Z"
        fill="#FFCE31"
      />
      <path
        d="M6.78125 4.71875C6.625 4.71875 6.5 4.84375 6.5 5V7.625C6.5 7.78125 6.625 7.90625 6.78125 7.90625H13.2188C13.375 7.90625 13.5 7.78125 13.5 7.625V5C13.5 4.84375 13.375 4.71875 13.2188 4.71875H6.78125ZM12.9375 6.78125C12.9375 6.9375 12.8125 7.0625 12.6562 7.0625H7.375C7.21875 7.0625 7.09375 6.9375 7.09375 6.78125V5.59375C7.09375 5.4375 7.21875 5.3125 7.375 5.3125H12.6562C12.8125 5.3125 12.9375 5.4375 12.9375 5.59375V6.78125Z"
        fill="#FFCE31"
      />
      <path
        opacity="0.5"
        d="M4.46875 13.6875C4.46875 10.1875 7.15625 7.34375 10.5938 7.09375C10.4375 7.09375 10.25 7.0625 10.0938 7.0625C6.8125 7.0625 4.15625 9.71875 4.15625 13.0312C4.15625 14 4.40625 14.9375 4.8125 15.75C4.59375 15.0938 4.46875 14.4062 4.46875 13.6875Z"
        fill="#89664C"
      />
      <path
        opacity="0.33"
        d="M12.4688 17.9062C15.375 15.9687 16.25 12.1875 14.5625 9.25L14.8438 9.625C16.6563 12.3125 15.9063 15.9688 13.1875 17.8125C12.375 18.375 11.4688 18.6875 10.5625 18.7812C11.25 18.5937 11.875 18.3125 12.4688 17.9062Z"
        fill="white"
      />
      <path d="M7.375 5.90625H9.125L5.59375 0.625H3.4375L7.375 5.90625Z" fill="#ED4C5C" />
      <path d="M9.125 5.90625H10.875L7.375 0.625H5.59375L9.125 5.90625Z" fill="#E8E8E8" />
      <path
        opacity="0.5"
        d="M10.2813 1.5L9.40625 2.8125L11.4688 5.90625H12.625L12.9375 5.46875L10.2813 1.5Z"
        fill="#3E4347"
      />
      <path d="M10.875 5.90625H12.625L9.125 0.625H7.375L10.875 5.90625Z" fill="#428BC1" />
    </svg>
  );
}

export default Medal;
