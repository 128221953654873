import React from 'react';
import { Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import ProfileAttributes from './ProfileAttributes/ProfileAttributes';
import PlayerTag from './PlayerTag/PlayerTag';
import { translate } from '../app';

export default function PlayersTab({ language }) {
  return (
    <div style={{ overflowX: 'hidden' }}>
      <Typography variant="h4" align="left" paddingTop={1}>
        {translate(language, 'Profile Attributes')}
      </Typography>
      <Grid paddingY={3} paddingX={2}>
        <ProfileAttributes />
      </Grid>
      <Typography variant="h4" align="left" paddingTop={2}>
        {translate(language, 'PlayerTag')}
      </Typography>
      <Grid>
        <PlayerTag language={language} />
      </Grid>
    </div>
  );
}
PlayersTab.propTypes = {
  language: PropTypes.element
};
