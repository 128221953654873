const Fb = () => (
  <svg width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 21.62C0 32.3091 7.76329 41.1976 17.9167 43V27.4716H12.5417V21.5H17.9167V16.7216C17.9167 11.3466 21.38 8.36171 26.2784 8.36171C27.83 8.36171 29.5034 8.6 31.055 8.83829V14.3333H28.3083C25.68 14.3333 25.0833 15.6466 25.0833 17.32V21.5H30.8167L29.8617 27.4716H25.0833V43C35.2367 41.1976 43 32.3109 43 21.62C43 9.72875 33.325 0 21.5 0C9.675 0 0 9.72875 0 21.62Z"
      fill="#2E76AB"
    />
  </svg>
);

export default Fb;
