import { Icon } from '@iconify/react';
import homeFill from '@iconify/icons-eva/home-fill';
import pieChartFill from '@iconify/icons-eva/pie-chart-2-fill';
import shakeFill from '@iconify/icons-eva/shake-fill';
import usersThreeFill from '@iconify/icons-ant-design/user-outlined';
// import messageFilled from '@iconify/icons-ant-design/message-filled';
// import appStore from '@iconify/icons-ant-design/appstore-add-outlined';
import peopleOutline from '@iconify/icons-eva/people-outline';
import settingFilled from '@iconify/icons-ant-design/setting-filled';
import baseLine from '@iconify/icons-ic/baseline-local-activity';
import roundNotification from '@iconify/icons-ic/round-notifications-active';
import { translate } from '../../components/_dashboard/app';
// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;
const language = localStorage.getItem('currentLanguage');

const sidebarConfig = [
  {
    title: translate(language, 'Home'),
    path: '/dashboard/home',
    icon: getIcon(homeFill)
  },
  {
    title: translate(language, 'Players'),
    path: '/dashboard/players',
    icon: getIcon(usersThreeFill)
  },
  {
    title: translate(language, 'Player Groups'),
    path: '/dashboard/player-groups',
    icon: getIcon(peopleOutline)
  },
  // {
  //   title: translate(language,'Messages'),
  //   path: '/dashboard/messages',
  //   icon: getIcon(messageFilled)
  // },
  {
    title: translate(language, 'Program'),
    path: '/dashboard/programs',
    icon: getIcon(baseLine)
  },
  {
    title: translate(language, 'Notifications'),
    path: '/dashboard/notifications',
    icon: getIcon(roundNotification)
  },
  // {
  //   title: translate(language,'Applications'),
  //   path: '/dashboard/apps',
  //   icon: getIcon(appStore)
  // },
  {
    title: translate(language, 'Analytics'),
    path: '/dashboard/analytics',
    icon: getIcon(pieChartFill)
  },
  {
    title: translate(language, 'Organization Settings'),
    path: '/dashboard/organization-settings',
    icon: getIcon(settingFilled)
  },
  {
    title: translate(language, 'Widget Settings'),
    path: '/dashboard/widget-settings',
    icon: getIcon(shakeFill)
  }
];
export default sidebarConfig;
