import { Button, Stack, Typography } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import { translate } from '../app';

const HelpCenter = ({ language }) => {
  const handleClick = () => {
    window.fcWidget.open();
    window.fcWidget.show();
  };

  return (
    <>
      <Stack direction="row" alignItems="center">
        <Typography variant="h5">{translate(language, 'Hello, how can we help?')}</Typography>
        <Button onClick={handleClick} variant="contained" style={{ marginLeft: '95px' }}>
          {translate(language, 'Contact Us')}
        </Button>
      </Stack>
    </>
  );
};

HelpCenter.propTypes = {
  language: PropTypes.element
};
export default HelpCenter;
