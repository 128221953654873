import { useEffect, useState } from 'react';
import {
  Container,
  Typography,
  Stack,
  Box,
  Tabs,
  Tab,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent
} from '@mui/material';
import axios from 'axios';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { toast, ToastContainer } from 'react-toastify';
import TabPanel from '../components/TabPanel';
import Page from '../components/Page';
import AddGroupModal from '../components/playerGroups/AddGroupModal';
import CenterSpinner from '../components/CenterSpinner';
import PlayerTable from '../components/playerGroups/PlayerTable';
import EmptyPage from '../components/EmptyPage';
import { translate } from '../components/_dashboard/app';

const PlayerGroups = () => {
  const [value, setValue] = useState(0);
  const [openGroupModal, setOpenGroupModal] = useState(false);
  const [groups, setGroups] = useState([]);
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState('add');
  const [groupId, setGroupId] = useState(0);
  const [openDeleteProp, setOpenDeleteProp] = useState(false);
  const language = localStorage.getItem('currentLanguage');

  const handleTabChange = (event, newValue) => setValue(newValue);

  const getGroups = async () => {
    setLoading(true);
    const res = await axios.get('group');
    setGroups(res.data.data);
    setLoading(false);
  };

  const handleDeleteGroup = async () => {
    try {
      // eslint-disable-next-line
      const res = await axios.delete(`group/${groupId}`);
      setValue(0);
      await getGroups();
      toast.success(translate(language, 'Player Deleted Successfully!'));
    } catch (error) {
      console.log(error);
      toast.error(translate(language, 'Something went wrong!'));
    }
    setOpenDeleteProp(false);
  };

  useEffect(() => {
    getGroups();
  }, []);

  if (loading) <CenterSpinner />;

  return (
    <Page
      title={`${translate(language, 'Dashboard')}: ${translate(
        language,
        'Player Groups'
      )} | ${translate(language, 'Gamify')}`}
    >
      <Container>
        <AddGroupModal
          language={language}
          open={openGroupModal}
          setOpen={setOpenGroupModal}
          type={type}
          setType={setType}
          data={groups.filter((item) => item.id === groupId)[0]}
          getGroups={getGroups}
        />
        <Dialog open={openDeleteProp} onClose={() => setOpenDeleteProp(false)}>
          <DialogContent>
            {translate(language, 'Are you sure you want to delete this Group ?')}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDeleteProp(false)}>{translate(language, 'No')}</Button>
            <Button onClick={handleDeleteGroup}>{translate(language, 'Yes')}</Button>
          </DialogActions>
        </Dialog>
        <ToastContainer
          position="bottom-right"
          autoClose={1500}
          newestOnTop
          closeOnClick
          pauseOnFocusLoss={false}
          pauseOnHover={false}
          limit={2}
        />
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <div>
            <Typography variant="h4" gutterBottom>
              {translate(language, 'Player Groups')}
            </Typography>
            <p>
              {translate(
                language,
                "At Evolve we call our customers 'player'. Here you can track your players, and see how active and engaged they are. You can also view their daily activities, transaction history and achievements. Explore the impact of Gamify experience."
              )}
            </p>
          </div>
        </Stack>
        <Box sx={{ width: '100%' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginBottom: '5px'
            }}
          >
            <Button
              onClick={() => {
                setType('add');
                setOpenGroupModal(true);
              }}
              variant="contained"
              startIcon={<Icon icon={plusFill} />}
            >
              {translate(language, 'Add new group')}
            </Button>
          </div>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              variant="scrollable"
              scrollButtons="auto"
              value={value}
              onChange={handleTabChange}
            >
              {groups.map((item, index) => (
                <Tab key={index} label={item.name} />
              ))}
            </Tabs>
          </Box>
          {!!(groups.length === 0) && (
            <EmptyPage language={language} name={translate(language, 'Groups')} />
          )}
          {groups.map((item, index) => (
            <TabPanel key={index} value={value} index={index}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: '25px'
                }}
              >
                <Card
                  sx={{
                    width: '200px',
                    height: '50px',
                    display: 'flex',
                    justifyContent: 'space-around',
                    alignItems: 'center'
                  }}
                >
                  <Typography>{translate(language, 'Group Bonus')}</Typography>
                  <Typography variant="h6">{item.bonus}%</Typography>
                </Card>
                <div>
                  <Button
                    onClick={() => {
                      setType('edit');
                      setGroupId(item.id);
                      setOpenGroupModal(true);
                    }}
                    variant="outlined"
                    sx={{ marginRight: '5px' }}
                  >
                    {translate(language, 'Modify Group')}
                  </Button>
                  <Button
                    onClick={() => {
                      setGroupId(item.id);
                      setOpenDeleteProp(true);
                    }}
                    variant="outlined"
                    color="error"
                  >
                    {translate(language, 'Delete Group')}
                  </Button>
                </div>
              </div>
              <PlayerTable
                language={language}
                refresh={openGroupModal}
                groupData={item}
                allGroupsData={groups}
              />
            </TabPanel>
          ))}
        </Box>
      </Container>
    </Page>
  );
};

export default PlayerGroups;
