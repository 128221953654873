import React, { useState, useEffect } from 'react';
import {
  Stack,
  Grid,
  FormControl,
  InputAdornment,
  OutlinedInput,
  Typography,
  IconButton,
  Select,
  MenuItem
} from '@mui/material';
import PropTypes from 'prop-types';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { Icon } from '@iconify/react';
import editFill from '@iconify/icons-eva/edit-fill';
import CenterSpinner from '../../../CenterSpinner';
import EmailFrequencyMenu from './EmailFrequencyMenu';
import { currencies } from './currencies';
import { translate } from '../../app';

export default function GeneralBox({ language }) {
  const keys = Object.keys(currencies);
  const [currencyVal, setCurrencyVal] = useState('INR');
  const [loading, setLoading] = useState(false);

  const fetchCurrencyData = async () => {
    setLoading(true);
    try {
      const obj = await axios.get('org');
      setCurrencyVal(obj.data.data.currency);
    } catch (err) {
      toast.error(translate(language, 'Something went wrong!'));
      console.log(err);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchCurrencyData();
    // eslint-disable-next-line
  }, []);

  const handleCurrencyChange = async (e) => {
    setLoading(true);
    try {
      setCurrencyVal(e.target.value);
      // eslint-disable-next-line
      const res = await axios.patch('org', {
        currency: e.target.value
      });
      toast.success(translate(language, 'Currency successfully updated'));
    } catch (err) {
      toast.error(translate(language, 'Something went wrong!'));
      console.log(err);
    }
    setLoading(false);
  };

  if (loading) {
    return <CenterSpinner />;
  }

  return (
    <Grid>
      <Grid sx={12}>
        <ToastContainer
          position="bottom-right"
          autoClose={2500}
          newestOnTop
          closeOnClick
          pauseOnFocusLoss
          pauseOnHover
          limit={2}
        />
        <Stack spacing={-1.7} paddingTop={0} paddingBottom={2}>
          <Typography gutterBottom variant="h5" align="left">
            {translate(language, 'Time Zone')}
          </Typography>
          <Typography gutterBottom variant="subtitle2" align="left" paddingTop={1}>
            {translate(language, 'Time Zone Description')}
          </Typography>
        </Stack>
        <FormControl variant="outlined" fullWidth>
          <OutlinedInput
            value="02:40 AM (IST)"
            endAdornment={
              <InputAdornment fullWidth>
                <IconButton edge="end">
                  <Icon fontSize={25} icon={editFill} />
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
      </Grid>
      <Grid>
        <Stack spacing={-1.7} paddingTop={2} paddingBottom={2}>
          <Typography gutterBottom variant="h5" align="left">
            {translate(language, 'Currency')}
          </Typography>
          <Typography gutterBottom variant="subtitle2" align="left" paddingTop={1}>
            {translate(language, 'Currency Description')}
          </Typography>
        </Stack>
        <FormControl fullWidth variant="outlined">
          <Select value={currencyVal} onChange={handleCurrencyChange}>
            {keys.map((item, index) => (
              <MenuItem key={index} value={item}>{`${currencies[item]} (${item})`}</MenuItem>
            ))}
            {/* <MenuItem value={20}>Twenty</MenuItem>
            <MenuItem value={30}>Thirty</MenuItem> */}
          </Select>
        </FormControl>
      </Grid>
      <Grid>
        <Stack spacing={-1.7} paddingTop={2} paddingBottom={2}>
          <Typography gutterBottom variant="h5" align="left">
            {translate(language, 'Subscription Status')}
          </Typography>
          <Typography gutterBottom variant="subtitle2" align="left" paddingTop={1}>
            {translate(language, 'Subscription Status Description')}
          </Typography>
        </Stack>
        <FormControl variant="outlined" fullWidth>
          <EmailFrequencyMenu language={language} />
        </FormControl>
      </Grid>
      <Grid>
        <Stack spacing={-1.7} paddingTop={2} paddingBottom={2}>
          <Typography gutterBottom variant="h5" align="left">
            {translate(language, 'Enable Purchase Channels Merging')}
          </Typography>
          <Typography gutterBottom variant="subtitle2" align="left" paddingTop={1}>
            {translate(
              language,
              'Use this toggle to enable having one merged profile for any player with purchases through different Channels/POSs'
            )}
          </Typography>
        </Stack>
        <FormControl fullWidth variant="outlined">
          <OutlinedInput
            value={translate(language, 'Phone Number')}
            endAdornment={
              <InputAdornment>
                <IconButton edge="end">
                  <Icon fontSize={25} icon={editFill} />
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
      </Grid>
    </Grid>
  );
}

GeneralBox.propTypes = {
  language: PropTypes.element
};
