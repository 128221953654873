import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import PropTypes from 'prop-types';
import { toast, ToastContainer } from 'react-toastify';

import { Icon } from '@iconify/react';
import { Avatar, Button, Grid, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import DatePickerWithTitle from './DatePickerWithTitle';
import Design1 from './Design1';
import { translate } from '../../_dashboard/app';
// import Upload from '../../levels/icons/Upload';
import Design2 from './Design2';

const TemplateSetting = ({ templateType, promotionType, language }) => {
  const [dateRange, setDateRange] = useState([null, null]);
  const [dateError, setDateError] = useState([false, false]);
  const [image, setImage] = useState(templateType.innerImg);

  const navigate = useNavigate();

  const handleImageUpload = async (event) => {
    const formData = new FormData();
    formData.append('file', event.target.files[0]);

    try {
      const res = await axios.post('upload', formData);
      setImage(res.data.fileUrl);
    } catch (error) {
      console.log(error);
    }
  };

  const PopupSchema = Yup.object().shape({
    discount: Yup.number()
      .positive(translate(language, 'Discount Should be greater than 0'))
      .max(100, translate(language, 'Discount must be less than or equal to 100'))
      .required(translate(language, 'Discount is required'))
  });

  const formik = useFormik({
    initialValues: {
      discount: 0
    },
    validationSchema: PopupSchema,
    onSubmit: async (values, { resetForm }) => {
      if (!promotionType) {
        toast.error(translate(language, 'Please Select a Promotion Type'));
        return;
      }
      try {
        // eslint-disable-next-line
        const res = await axios.post('intentPromo', {
          promotion_type: promotionType,
          template_name: templateType.name.replace(/ /g, '-'),
          template_img_url: image,
          discount: values.discount,
          dateRange
        });

        toast.success(translate(language, 'Promotion Pop-up Created Successfully!'));
        setTimeout(() => {
          resetForm();
          navigate('/dashboard/programs');
        }, 2500);
      } catch (error) {
        console.log(error);
        toast.error(translate(language, 'Something went wrong!'));
      }
    }
  });

  const { dirty, errors, touched, handleSubmit, isSubmitting, getFieldProps, values } = formik;

  return (
    <>
      <Grid container columns={12} direction="row">
        <Grid item xs={12} md={6}>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <ToastContainer
                position="bottom-right"
                autoClose={1200}
                newestOnTop
                closeOnClick
                pauseOnFocusLoss={false}
                pauseOnHover={false}
                limit={2}
              />
              <Stack direction="column">
                <Typography mt={3} mb={2}>
                  {translate(language, 'Set Discount Percentage')}
                </Typography>
                <TextField
                  type="number"
                  placeholder={translate(language, 'Discount')}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>
                  }}
                  sx={{ width: '80%' }}
                  {...getFieldProps('discount')}
                  error={Boolean(touched.discount && errors.discount)}
                  helperText={touched.discount && errors.discount}
                />
                <Typography mt={3} mb={2}>
                  {translate(language, 'Set Campaign Date')}
                </Typography>
                <DatePickerWithTitle
                  language={language}
                  dateRange={dateRange}
                  setDateRange={setDateRange}
                  dateError={dateError}
                  setDateError={setDateError}
                />
                <Stack
                  mt={3}
                  mb={2}
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ width: '80%' }}
                >
                  <Typography>{translate(language, 'Set Image')}</Typography>
                  <Stack direction="row">
                    {image !== templateType.innerImg && (
                      <Avatar variant="square" alt="" src={image} />
                    )}
                    <Button variant="outlined" sx={{ marginLeft: '15px' }} component="label">
                      {translate(language, 'Upload')}
                      <input type="file" accept=".jpeg, .png" hidden onChange={handleImageUpload} />
                    </Button>
                  </Stack>
                </Stack>
                <LoadingButton
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                  sx={{ width: '50%', marginY: '35px' }}
                  disabled={!dirty || dateError.includes(true) || dateRange.includes(null)}
                >
                  {translate(language, 'Create Promotion')}
                </LoadingButton>
              </Stack>
            </Form>
          </FormikProvider>
        </Grid>
        <Grid item xs={12} md={6}>
          <Stack direction="column">
            <Stack direction="row" justifyContent="space-between">
              <Stack direction="row">
                <Icon icon="akar-icons:plus" width="22" />
                <Icon icon="ant-design:minus-outlined" width="22" style={{ marginLeft: '5px' }} />
              </Stack>
              <Stack direction="row">
                <Icon icon="icon-park-outline:preview-open" width="22" />
                <Typography sx={{ marginLeft: '5px' }}>{translate(language, 'Preview')}</Typography>
              </Stack>
              <Stack direction="row">
                <Icon icon="akar-icons:mobile-device" width="22" />
                <Icon
                  icon="tabler:device-mobile-rotated"
                  width="22"
                  style={{ marginLeft: '5px' }}
                />
              </Stack>
            </Stack>
            {templateType.name === 'Template 1' && (
              <Design1
                language={language}
                discount={values.discount}
                dateRange={dateRange}
                image={image}
              />
            )}
            {templateType.name === 'Template 2' && (
              <Design2
                language={language}
                discount={values.discount}
                dateRange={dateRange}
                image={image}
              />
            )}
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};

export default TemplateSetting;

TemplateSetting.propTypes = {
  templateType: PropTypes.object,
  promotionType: PropTypes.string,
  language: PropTypes.element
};
