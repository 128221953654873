import React from 'react';
import { Grid } from '@mui/material';

export default function PlayerWidgetLanguage() {
  return (
    <div>
      <Grid>
        {/* <Stack spacing={-1.7} paddingTop={1} paddingBottom={2}>
          <Typography gutterBottom variant="h5" align="left">
            Subscription Status
          </Typography>
          <Typography gutterBottom variant="subtitle2" align="left" paddingTop={1}>
            Subscription Status Description
          </Typography>
         
        </Stack> */}
        {/* <FormControl variant="outlined" fullWidth required>
        </FormControl> */}
        {/* <Stack spacing={-1.7} paddingTop={2} paddingBottom={2}>
        </Stack> */}
      </Grid>
    </div>
  );
}
