import React, { useEffect, useState, useRef } from 'react';
import {
  Grid,
  Typography,
  Stack,
  FormControl,
  OutlinedInput,
  Select,
  MenuItem,
  Button
} from '@mui/material';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { translate } from '../../app';
import OauthPopup from '../../../OauthPopup';

function ShopDetails({ data, language }) {
  const [shopDomain, setShopDomain] = useState(data.shopDomain);
  const [showErrorShopDomain, setShowErrorShopDomain] = useState(false);
  const [shopType, setShopType] = useState(data.shopType);
  const [shopKey, setShopKey] = useState(data.shopKey);
  const [shopSecret, setShopSecret] = useState(data.shopSecret);
  const ref = useRef(false);

  useEffect(() => {
    let timeoutId = '';

    if (ref.current) {
      timeoutId = setTimeout(() => saveShopDetails({ shopDomain, shopKey, shopSecret }), 1500);
    } else {
      ref.current = true;
    }
    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line
  }, [shopDomain, shopKey, shopSecret]);

  const saveShopType = async (e) => {
    try {
      setShopType(e.target.value);
      // eslint-disable-next-line
      const res = await axios.patch('org', {
        shopType: e.target.value
      });
      toast.success(translate(language, 'Shop Type updated successfully!'));
    } catch (error) {
      console.log(error);
    }
  };

  const saveShopDetails = async (data) => {
    try {
      if (!showErrorShopDomain) {
        // eslint-disable-next-line
        const res = await axios.patch('org', data);
        toast.success(translate(language, 'Shop Domain updated successfully!'));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleShopDomainChange = (event) => {
    const input = event.target.value;

    const prefix1 = new RegExp('^(https://)').test(input);
    // eslint-disable-next-line
    const prefix2 = new RegExp('^(www)[.]').test(input);
    // eslint-disable-next-line
    const suffix = new RegExp('.+[.]com$').test(input);

    if (!prefix1 && !prefix2) {
      setShowErrorShopDomain(false);
      setShopDomain(input);
    } else {
      setShowErrorShopDomain(true);
    }
  };

  return (
    <>
      <Typography style={{ marginTop: '10px' }} variant="h4">
        {translate(language, 'Shop Details')}
      </Typography>
      <Grid>
        <ToastContainer
          position="bottom-right"
          autoClose={2500}
          newestOnTop
          closeOnClick
          pauseOnFocusLoss
          pauseOnHover
          limit={2}
        />
        <Stack spacing={-1.7} paddingTop={2} paddingBottom={2}>
          <Typography gutterBottom variant="h5" align="left">
            {translate(language, 'Shop Type')}
          </Typography>
          <Typography gutterBottom variant="subtitle2" align="left" paddingTop={1}>
            {translate(language, 'Platform of your shop')}
          </Typography>
        </Stack>
        <FormControl fullWidth variant="outlined">
          <Select
            value={shopType}
            onChange={saveShopType}
            inputProps={{ 'aria-label': 'Without label' }}
          >
            <MenuItem value="">
              <em>{translate(language, 'None')}</em>
            </MenuItem>
            <MenuItem value="shopify">Shopify</MenuItem>
            <MenuItem value="woocommerce">WooCommerce</MenuItem>
            <MenuItem value="other">{translate(language, 'Other')}</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid>
        <Stack spacing={-1.7} paddingTop={2} paddingBottom={2}>
          <Typography gutterBottom variant="h5" align="left">
            {translate(language, 'Shop Domain')}
          </Typography>
          <Typography gutterBottom variant="subtitle2" align="left" paddingTop={1}>
            {translate(language, 'Shop domain name here without the `https://www.`')}
          </Typography>
        </Stack>
        <FormControl fullWidth variant="outlined">
          <OutlinedInput
            defaultValue={shopDomain}
            error={showErrorShopDomain}
            onChange={handleShopDomainChange}
            disabled
          />
          {showErrorShopDomain && (
            <Typography variant="subtitle2" align="left" style={{ color: 'red' }}>
              <em>
                {translate(
                  language,
                  "Do not add 'https://' OR 'www.' in the input and make sure to add '.com' in the end"
                )}
              </em>
            </Typography>
          )}
        </FormControl>

        <Typography gutterBottom align="right" paddingTop={5}>
          <Button style={{ padding: '0px' }} disabled={!!data.shopify_access_token}>
            {shopType === 'shopify' && (
              <OauthPopup
                title={
                  data.shopify_access_token
                    ? translate(language, 'Connected')
                    : translate(language, 'Connect Shopify')
                }
                disableBtn={data.shopify_access_token}
                url={`${window.location.origin}/evolve/shopify?shop=${shopDomain}`}
              />
            )}
          </Button>
        </Typography>

        {shopType === 'woocommerce' && (
          <>
            <Stack spacing={-1.7} paddingTop={2} paddingBottom={2}>
              <Typography gutterBottom variant="h5" align="left">
                {translate(language, 'Shop Key')}
              </Typography>
              <Typography gutterBottom variant="subtitle2" align="left" paddingTop={1}>
                {translate(language, 'Shop Key Description')}
              </Typography>
            </Stack>
            <FormControl fullWidth variant="outlined">
              <OutlinedInput value={shopKey} onChange={(e) => setShopKey(e.target.value)} />
            </FormControl>
            <Stack spacing={-1.7} paddingTop={2} paddingBottom={2}>
              <Typography gutterBottom variant="h5" align="left">
                {translate(language, 'Shop Secret')}
              </Typography>
              <Typography gutterBottom variant="subtitle2" align="left" paddingTop={1}>
                {translate(language, 'Shop Secret Description')}
              </Typography>
            </Stack>
            <FormControl fullWidth variant="outlined">
              <OutlinedInput value={shopSecret} onChange={(e) => setShopSecret(e.target.value)} />
            </FormControl>
          </>
        )}
      </Grid>
    </>
  );
}

export default ShopDetails;

ShopDetails.propTypes = {
  data: PropTypes.object,
  language: PropTypes.element
};
