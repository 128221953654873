import * as React from 'react';
import { useState } from 'react';
import {
  Switch,
  Card,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  Table,
  TableHead,
  TableBody,
  TableContainer,
  TableCell,
  TableRow,
  TablePagination
} from '@mui/material';
import { Icon } from '@iconify/react';
import trashFill from '@iconify/icons-eva/trash-2-fill';
import { toast, ToastContainer } from 'react-toastify';
import axios from 'axios';
import lodash from 'lodash';
import PropTypes from 'prop-types';
import { translate } from '../_dashboard/app';
import Scrollbar from '../Scrollbar';

/* eslint-disable camelcase */
export default function CouponsTable({ tableData, getData, language }) {
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [openDeleteProp, setOpenDeleteProp] = useState(false);
  const [couponToDelete, setCouponToDelete] = useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleStatus = async (e, redeem_coupon_id) => {
    try {
      // eslint-disable-next-line
      const res = await axios.patch('redeem', { redeem_coupon_id, isActive: e.target.checked });
      getData();
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteCoupon = async () => {
    try {
      // eslint-disable-next-line
      const res = axios.delete(`redeem/${couponToDelete}`);

      await getData();
      toast.success(translate(language, 'Coupon Deleted Successfully!'));
    } catch (error) {
      console.log(error);
      toast.error(translate(language, 'Something went wrong!'));
    }
    setOpenDeleteProp(false);
  };

  return (
    <>
      <Card style={{ marginTop: '15px' }}>
        <Scrollbar>
          <Dialog open={openDeleteProp} onClose={() => setOpenDeleteProp(false)}>
            <DialogContent>
              {translate(language, 'Are you sure you want to delete this Coupon ?')}
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenDeleteProp(false)}>{translate(language, 'No')}</Button>
              <Button onClick={handleDeleteCoupon}>{translate(language, 'Yes')}</Button>
            </DialogActions>
          </Dialog>
          <ToastContainer
            position="bottom-right"
            autoClose={1500}
            newestOnTop
            closeOnClick
            limit={2}
          />
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{translate(language, 'ID')}</TableCell>
                  <TableCell align="center">{translate(language, 'Code')}</TableCell>
                  <TableCell align="center">{translate(language, 'Type')}</TableCell>
                  <TableCell align="center">{translate(language, 'Price(in points)')}</TableCell>
                  <TableCell align="center">{translate(language, 'Discount')}</TableCell>
                  <TableCell align="center">{translate(language, 'Status')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell align="center">{row.coupon_code}</TableCell>
                      <TableCell align="center">{lodash.startCase(row.coupon_type)}</TableCell>
                      <TableCell align="center">{row.price}</TableCell>
                      <TableCell align="center">{row.discount}</TableCell>
                      <TableCell align="center">
                        <Switch
                          checked={Boolean(row.isActive)}
                          onChange={(e) => handleStatus(e, row.redeem_coupon_id)}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <IconButton
                          onClick={() => {
                            setOpenDeleteProp(true);
                            setCouponToDelete(row.redeem_coupon_id);
                          }}
                          edge="end"
                        >
                          <Icon icon={trashFill} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>
        <TablePagination
          rowsPerPageOptions={[5, 10, 20]}
          component="div"
          count={tableData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>
    </>
  );
}

CouponsTable.propTypes = {
  tableData: PropTypes.array,
  getData: PropTypes.func,
  language: PropTypes.element
};
