import { useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  Container,
  Grid,
  Stack,
  Switch,
  Typography,
  Card,
  CardHeader,
  CardContent,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent
} from '@mui/material';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import trashFill from '@iconify/icons-eva/trash-2-fill';
import editFill from '@iconify/icons-eva/edit-fill';
import coinsIcon from '@iconify/icons-fa-solid/coins';
import { toast, ToastContainer } from 'react-toastify';
import axios from 'axios';
import Page from '../Page';
import EventModal from './EventModal';
import CenterSpinner from '../CenterSpinner';
import EmptyPage from '../EmptyPage';
import { translate } from '../_dashboard/app';
import fetchEventsData from '../../utils/getEventsData';

const Events = () => {
  const [loading, setLoading] = useState(false);
  const [openEventModal, setOpenEventModal] = useState(false);
  const [openDeleteProp, setOpenDeleteProp] = useState(false);
  const [type, setType] = useState('add');
  const [eventsData, setEventsData] = useState([]);
  const [targetEventId, setTargetEventId] = useState(0);
  const language = localStorage.getItem('currentLanguage');

  const getData = async () => {
    await fetchEventsData(setEventsData);
  };

  const handleActiveChange = async (event, eventId) => {
    setLoading(true);
    try {
      // eslint-disable-next-line
      const res = await axios.patch(`events/${eventId}`, {
        is_active: event.target.checked
      });

      getData();
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleDeleteEvent = async () => {
    try {
      // eslint-disable-next-line
      const res = await axios.delete(`events/${targetEventId}`);

      await getData();
      toast.success(translate(language, 'Event Deleted Successfully!'));
    } catch (error) {
      console.log(error);
      toast.error(translate(language, 'Something went wrong!'));
    }
    setOpenDeleteProp(false);
  };

  useEffect(() => {
    getData();
  }, []);

  if (loading) <CenterSpinner />;

  return (
    <Page
      title={`${translate(language, 'Dashboard')} : ${translate(language, 'Program')} - ${translate(
        language,
        'Events'
      )}`}
    >
      <Container>
        <EventModal
          language={language}
          open={openEventModal}
          setOpen={setOpenEventModal}
          type={type}
          data={eventsData.filter((item) => item.event_id === targetEventId)[0]}
          getData={getData}
        />
        <Dialog open={openDeleteProp} onClose={() => setOpenDeleteProp(false)}>
          <DialogContent>
            {translate(language, 'Are you sure you want to delete this Event ?')}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDeleteProp(false)}>{translate(language, 'No')}</Button>
            <Button onClick={handleDeleteEvent}>{translate(language, 'Yes')}</Button>
          </DialogActions>
        </Dialog>
        <ToastContainer
          position="bottom-right"
          autoClose={1500}
          newestOnTop
          pauseOnFocusLoss="false"
          pauseOnHover="false"
          closeOnClick
          limit={2}
        />
        <Stack direction="row" mb={5}>
          <Avatar
            sx={{ width: 56, height: 56 }}
            alt=" "
            src="/static/mock-images/avatars/referrals.png"
          />
          <Typography style={{ marginTop: '10px', marginLeft: '5px' }} variant="h4" gutterBottom>
            {translate(language, 'Events')}
          </Typography>
        </Stack>
        <Box sx={{ width: '100%' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginBottom: '5px'
            }}
          >
            <Button
              onClick={() => {
                setOpenEventModal(true);
                setType('add');
              }}
              variant="contained"
              startIcon={<Icon icon={plusFill} />}
            >
              {translate(language, 'Add New Event')}
            </Button>
          </div>
        </Box>
        {eventsData.length === 0 && (
          <EmptyPage language={language} name={translate(language, 'Events')} />
        )}
        <Grid container spacing={5} mt={2}>
          {eventsData?.map((item, index) => (
            <Grid key={index} item xs={12} md={6}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row'
                }}
              >
                <Card style={{ minWidth: '300px' }}>
                  <CardHeader
                    title={item.event_name}
                    action={
                      <Switch
                        onChange={(e) => handleActiveChange(e, item.event_id)}
                        checked={!!item.is_active}
                      />
                    }
                  />
                  <CardContent
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      marginTop: '15px'
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center'
                      }}
                    >
                      <Icon color="#e1b836" icon={coinsIcon} width={25} height={25} />
                      <Typography variant="body2" ml={2}>
                        {translate(language, 'Total Points')}
                      </Typography>
                    </div>
                    <Typography variant="h6">{item.points}</Typography>
                  </CardContent>
                </Card>
                <div>
                  <IconButton
                    onClick={() => {
                      setOpenEventModal(true);
                      setType('edit');
                      setTargetEventId(item.event_id);
                    }}
                  >
                    <Icon icon={editFill} width="20" />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      setOpenDeleteProp(true);
                      setTargetEventId(item.event_id);
                    }}
                  >
                    <Icon icon={trashFill} width="20" />
                  </IconButton>
                </div>
              </div>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Page>
  );
};

export default Events;
