import React from 'react';
import {
  Stack,
  Grid,
  FormControl,
  InputAdornment,
  OutlinedInput,
  Typography,
  IconButton,
  Button
} from '@mui/material';
import { Icon } from '@iconify/react';
import editFill from '@iconify/icons-eva/edit-fill';
import PropTypes from 'prop-types';
import { translate } from '../../app';

export default function WordPressConfiguration({ language }) {
  return (
    <div>
      <Grid>
        <Stack spacing={-1.7}>
          <Typography gutterBottom variant="subtitle2" paddingTop={1} align="left">
            {translate(
              language,
              'Add your WooCommerce API keys to let your customers be able to redeem their points into coupons on your store. Without adding these keys, the point redemption feature will be disabled.'
            )}
            <Typography paddingTop={1} paddingBottom={1} align="left">
              {translate(
                language,
                'Once you finish all step and add all required please click on test connection below.'
              )}
            </Typography>
          </Typography>
        </Stack>
        <FormControl fullWidth variant="outlined" margin="normal">
          <OutlinedInput
            value={translate(language, 'Website URL')}
            endAdornment={
              <InputAdornment position="end">
                <IconButton edge="end">
                  <Icon fontSize={25} icon={editFill} />
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        <FormControl fullWidth variant="outlined" margin="normal">
          <OutlinedInput
            value={translate(language, 'Consumer Key')}
            endAdornment={
              <InputAdornment position="end">
                <IconButton edge="end">
                  <Icon fontSize={25} icon={editFill} />
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        <FormControl fullWidth margin="normal" variant="outlined">
          <OutlinedInput
            value={translate(language, 'Consumer Secret Key')}
            endAdornment={
              <InputAdornment position="end">
                <IconButton edge="end">
                  <Icon fontSize={25} icon={editFill} />
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        <FormControl fullWidth margin="normal" variant="outlined">
          <OutlinedInput
            value={translate(language, 'API Version')}
            endAdornment={
              <InputAdornment position="end">
                <IconButton edge="end">
                  <Icon fontSize={25} icon={editFill} />
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        <Typography gutterBottom align="right" paddingBottom={5} paddingTop={5}>
          <Button variant="contained">
            <h3>{translate(language, 'Test Connection')}</h3>
          </Button>
        </Typography>
      </Grid>
    </div>
  );
}

WordPressConfiguration.propTypes = {
  language: PropTypes.element
};
