import React from 'react';
import { Button } from '@mui/material';
import PropTypes from 'prop-types';
import { translate } from '../app';

export default function ApplyChangesButton({ language }) {
  return (
    <div
      style={{
        zIndex: '999',
        position: 'fixed',
        right: '0vw',
        bottom: '0vh',
        boxShadow: '0 8px 16px 16px rgb(145 158 171 / 24%)',
        backgroundColor: 'rgba(255, 255, 255, 0.72)',
        width: '100vw',
        display: 'flex',
        justifyContent: 'flex-end',
        height: '92px'
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          marginRight: '35px'
        }}
      >
        <Button type="submit" variant="contained">
          {translate(language, 'Apply Changes')}
        </Button>
      </div>
    </div>
  );
}

ApplyChangesButton.propTypes = {
  language: PropTypes.element
};
