import Tracker from '@openreplay/tracker';
import trackerAxios from '@openreplay/tracker-axios';
import trackerAssist from '@openreplay/tracker-assist';
// routes
import { useEffect } from 'react';
import { LoggedInRouter, LoggedOutRouter } from './routes';
// theme
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
// components
import ScrollToTop from './components/ScrollToTop';
import { BaseOptionChartStyle } from './components/charts/BaseOptionChart';
import { setAxiosDefault, setToken } from './utils/axiosDefault';
import 'react-toastify/dist/ReactToastify.css';

// ----------------------------------------------------------------------

/* eslint-disable */
const tracker = new Tracker({
  projectKey: "p29nPSjejDnL3YY8VuEA",
  ingestPoint: "https://telemetry.getmok.com/ingest",
});

export default function App() {
  tracker.use(trackerAxios({}));
  tracker.use(trackerAssist({}));
  const user = JSON.parse(localStorage.getItem('loginUser'));
  setAxiosDefault();
  if (user && user.token) {
    setToken(user.token);
    tracker.setMetadata('user_id', user.user.users_id);
    tracker.setUserID(user.user.email);
  }

  useEffect(() => {
    tracker.start();
  }, []);

  return (
    <ThemeConfig>
      <ScrollToTop />
      <GlobalStyles />
      <BaseOptionChartStyle />
      {user ? <LoggedInRouter /> : <LoggedOutRouter />}
    </ThemeConfig>
  );
}
