import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Login from './pages/Login';
import Register from './pages/Register';
import DashboardApp from './pages/DashboardApp';
import User from './pages/User';
import Programs from './pages/Programs';
import NotFound from './pages/Page404';
import { Verify } from './components/authentication/verify';
import AccountSetting from './pages/AccountSetting';
import Apps from './pages/Apps';
import Notifications from './pages/Notifications';
import Analytics from './pages/Analytics';
import Players from './pages/Players';
import Messages from './pages/Messages';
import InAppNotification from './components/_dashboard/messages/InAppNotification';
import OrganizationSettings from './pages/OrganizationSettings';
import WidgetSettings from './pages/WidgetSettings';
import { CashBack } from './components/cashback';
import Referral from './components/referrals/Referral';
import SpinToWin from './components/spinWheel/SpinToWin';
import Redeem from './components/redeem/Redeem';
import Levels from './components/levels/Levels';
import Events from './components/events/Events';
import PlayerGroups from './pages/PlayerGroups';
import Challenges from './components/challenges/Challenges';
import OnBoarding from './components/newUserModal/OnBoarding';
import IntentBasePromotion from './components/IntentBasePromotion/IntentBasePromotion';

// ----------------------------------------------------------------------

// Returns Routes when user is logged in
export const LoggedInRouter = () =>
  useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/home" replace /> },
        { path: 'home', element: <DashboardApp /> },
        { path: 'programs', element: <Programs /> },
        { path: 'apps', element: <Apps /> },
        { path: 'notifications', element: <Notifications /> },
        { path: 'user', element: <User /> },
        { path: 'account-setting', element: <AccountSetting /> },
        { path: 'analytics', element: <Analytics /> },
        { path: 'players', element: <Players /> },
        { path: 'player-groups', element: <PlayerGroups /> },
        { path: 'messages', element: <Messages /> },
        { path: 'messages/InAppNotification', element: <InAppNotification /> },
        { path: 'organization-settings', element: <OrganizationSettings /> },
        { path: 'widget-settings', element: <WidgetSettings /> },
        { path: 'programs/cashback', element: <CashBack /> },
        { path: 'programs/referrals', element: <Referral /> },
        { path: 'programs/spinToWin', element: <SpinToWin /> },
        { path: 'programs/redeem', element: <Redeem /> },
        { path: 'programs/levels', element: <Levels /> },
        { path: 'programs/events', element: <Events /> },
        { path: 'programs/challenges', element: <Challenges /> },
        { path: 'programs/intentbasepromotion', element: <IntentBasePromotion /> }
      ]
    },
    { path: '/onboarding', element: <OnBoarding /> },
    { path: '*', element: <Navigate to="/dashboard" replace /> }
  ]);

// Returns Routes when user is logged out
export const LoggedOutRouter = () =>
  useRoutes([
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'login', element: <Login /> },
        { path: 'register', element: <Register /> },
        { path: 'verify/:verifyCode', element: <Verify /> },
        { path: '404', element: <NotFound /> },
        { path: '/', element: <Navigate to="/login" /> },
        { path: '*', element: <Navigate to="/login" /> }
      ]
    },
    { path: '*', element: <Navigate to="/login" replace /> }
  ]);
