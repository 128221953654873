import axios from 'axios';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { toast, ToastContainer } from 'react-toastify';

export default function VerifyEmail() {
  const navigate = useNavigate();

  useEffect(() => {
    verifyEmailApi();
    // eslint-disable-next-line
  }, []);

  const verifyEmailApi = async () => {
    try {
      const code = window.location.pathname.split('/')[2];
      // eslint-disable-next-line
      const res = await axios.get(`user/confirm/${code}`);
      toast.success('Email verified successfully!');
      navigate('/login', { replace: true });
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
  };

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={2500}
        newestOnTop
        closeOnClick
        pauseOnFocusLoss
        pauseOnHover
        limit={2}
      />
      <h1>Verify email</h1>
    </>
  );
}
