import { Icon } from '@iconify/react';
import { useState } from 'react';
import searchFill from '@iconify/icons-eva/search-fill';
// material

import { styled, alpha } from '@mui/material/styles';
import {
  Slide,
  Autocomplete,
  InputBase,
  Button,
  ClickAwayListener,
  IconButton
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

// ----------------------------------------------------------------------

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const SearchbarStyle = styled('div')(({ theme }) => ({
  top: 0,
  left: 0,
  zIndex: 99,
  width: '100%',
  display: 'flex',
  position: 'absolute',
  alignItems: 'center',
  height: APPBAR_MOBILE,
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  padding: theme.spacing(0, 3),
  boxShadow: theme.customShadows.z8,
  backgroundColor: `${alpha(theme.palette.background.default, 0.72)}`,
  [theme.breakpoints.up('md')]: {
    height: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5)
  }
}));

// ----------------------------------------------------------------------

export default function Searchbar() {
  const navigate = useNavigate();
  const [isOpen, setOpen] = useState(false);
  const searchjson = [
    { name: 'home', add: { path: 'home', id: 0 } },
    { name: 'dashboard', add: { path: 'home', id: 0 } },
    { name: 'players', add: { path: 'players', id: 0 } },
    { name: 'point transaction', add: { path: 'players', id: 1 } },
    { name: 'messages', add: { path: 'messages', id: 0 } },
    { name: 'guest messages', add: { path: 'messages', id: 0 } },
    { name: 'player messages', add: { path: '/dashboard/messages', id: 1 } },
    { name: 'programs', add: { path: 'programs', id: 0 } },
    { name: 'cashback', add: { path: 'programs/cashback', id: 0 } },
    { name: 'referrals', add: { path: 'programs/referrals', id: 0 } },
    { name: 'redeem', add: { path: 'programs/redeem', id: 0 } },
    { name: 'spintowin', add: { path: 'programs/spinToWin', id: 0 } },
    { name: 'notifications', add: { path: 'notifications', id: 0 } },
    { name: 'inapplicaions', add: { path: 'notifications', id: 0 } },
    { name: 'inapp', add: { path: 'notifications', id: 0 } },
    { name: 'emails', add: { path: 'notifications', id: 1 } },
    { name: 'browser', add: { path: 'notifications', id: 2 } },
    { name: 'analytics', add: { path: 'analytics', id: 0 } },
    { name: 'analytics cashback', add: { path: 'analytics', id: 1 } },
    { name: 'analytics referrals', add: { path: 'analytics' }, id: 2 },
    { name: 'challenges', add: { path: 'analytics', id: 3 } },
    { name: 'levels', add: { path: 'analytics', id: 4 } },
    { name: 'redemption', add: { path: 'analytics', id: 5 } },
    { name: 'organisation settings', add: { path: 'organization-settings', id: 0 } },
    { name: 'organization-settings/players', add: { path: 'organization-settings', id: 1 } },
    { name: 'integrations', add: { path: 'organization-settings', id: 2 } },
    { name: 'merchants', add: { path: 'organization-settings', id: 3 } },
    { name: 'team', add: { path: 'organization-settings', id: 4 } },
    { name: 'widget settings', add: { path: 'widget-settings', id: 0 } }
  ];
  const [inputValue, setInputValue] = useState('');
  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const handlesubmit = (value) => {
    value = value || inputValue;
    handleClose();
    const filtereditem = searchjson.filter((val) => {
      if (value === '') {
        return null;
      }
      if (val.name.toLowerCase().includes(value.toLowerCase())) {
        return val;
      }

      return null;
    });
    if (filtereditem.length > 0)
      navigate(filtereditem[0].add.path, { state: filtereditem[0].add.id });
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div>
        {!isOpen && (
          <IconButton onClick={handleOpen}>
            <Icon icon={searchFill} width={20} height={20} />
          </IconButton>
        )}

        <Slide direction="down" in={isOpen} mountOnEnter unmountOnExit>
          <SearchbarStyle>
            <IconButton>
              <Icon icon={searchFill} width={20} height={20} />
            </IconButton>
            <Autocomplete
              freeSolo
              value={inputValue}
              clearIcon={false}
              onChange={(event, newValue) => {
                // setValue(newValue);
                if (newValue != null) handlesubmit(newValue);
              }}
              inputValue={inputValue}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
              }}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handlesubmit();
                }
              }}
              fullWidth
              options={searchjson.map((option) => option.name)}
              sx={{ mr: 3 }}
              renderInput={(params) => (
                <InputBase
                  fullWidth
                  ref={params.InputProps.ref}
                  inputProps={params.inputProps}
                  placeholder="Search..."
                  sx={{ fontWeight: 'fontWeightBold' }}
                />
              )}
            />
            <Button
              size="medium"
              variant="contained"
              onClick={() => {
                handlesubmit();
              }}
            >
              Search
            </Button>
          </SearchbarStyle>
        </Slide>
      </div>
    </ClickAwayListener>
  );
}
