import { Button, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import ScratchCard from './ScratchCard';

const Home = ({ setPage }) => (
  <>
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: '80vh',
        width: '900px'
      }}
    >
      <Typography color="primary" sx={{ fontSize: '44px', marginTop: '40px' }}>
        Welcome to Evolve !
      </Typography>
      <ScratchCard />
      <Button
        onClick={() => setPage('qna')}
        variant="contained"
        sx={{ paddingX: '55px', paddingY: '8px', fontSize: '18px' }}
      >
        Next
      </Button>
    </div>
  </>
);

export default Home;

Home.propTypes = {
  setPage: PropTypes.func
};
