import PropTypes from 'prop-types';

export default function Back({ color }) {
  return (
    <svg width="27" height="49" viewBox="0 0 27 49" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M25.5 48L2 23.6296L25.5 0.999999" stroke={color} strokeWidth="2" />
    </svg>
  );
}

Back.propTypes = {
  color: PropTypes.string
};
