import PropTypes from 'prop-types';

export default function Fwd({ color }) {
  return (
    <svg width="27" height="49" viewBox="0 0 27 49" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.5 48L25 23.6296L1.5 0.999999" stroke={color} strokeWidth="2" />
    </svg>
  );
}

Fwd.propTypes = {
  color: PropTypes.string
};
