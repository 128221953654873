import axios from 'axios';

const fetchCouponsTableData = async (setCouponsTableData) => {
  try {
    const res = await axios.get('redeem');
    setCouponsTableData(res.data.data);
  } catch (error) {
    console.log(error);
  }
};

export default fetchCouponsTableData;
