import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Avatar, Button, Card, CardContent, Collapse, Stack, Typography } from '@mui/material';
import FirstOrder from './icons/FirstOrder';
import Insta from './icons/Insta';
import Levels from './icons/Levels';
import Points from './icons/Points';
import Vouchers from './icons/Vouchers';
import PointsRatio from './icons/PointsRatio';
import Discount from './icons/Discount';

const SeeSetup = () => {
  const [challengesOpen, setChallengesOpen] = useState(false);
  const [levelsOpen, setLevelsOpen] = useState(false);
  const [referralOpen, setReferralOpen] = useState(false);

  const navigate = useNavigate();

  const handleClick = () => {
    const user = JSON.parse(localStorage.getItem('loginUser'));
    user.newUser = false;
    user.openEnableApp = true;
    localStorage.setItem('loginUser', JSON.stringify(user));
    navigate('/dashboard');
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          height: '70vh',
          width: '900px'
        }}
      >
        <Typography style={{ fontSize: '44px', fontWeight: '400' }}>
          See your <span style={{ color: '#00AB55' }}>Setup</span>
        </Typography>
        <Stack
          style={{ width: '70vw', margin: '75px 0' }}
          direction="row"
          justifyContent="space-between"
        >
          <div style={{ minHeight: 'min-content', cursor: 'pointer' }}>
            <Card onClick={() => setChallengesOpen((prev) => !prev)}>
              <CardContent
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  width: '250px'
                }}
              >
                <Stack direction="row" alignItems="center">
                  <Avatar alt=" " src="/static/mock-images/avatars/Challange-Mission.png" />
                  <div style={{ paddingLeft: '15px' }}>
                    <Typography variant="h4">Challenges</Typography>
                    <Typography style={{ fontWeight: '700', color: '#00AB55' }}>ACTIVE</Typography>
                  </div>
                </Stack>
                <Collapse in={challengesOpen}>
                  <Stack direction="column" mt={3}>
                    <Typography variant="body2" mb={2}>
                      Create interesting challenges and missions to enable your players to win
                      badges.
                    </Typography>
                    <div style={{ marginBottom: '16px' }}>
                      <FirstOrder />
                    </div>
                    <Insta />
                  </Stack>
                </Collapse>
              </CardContent>
            </Card>
          </div>
          <div style={{ minHeight: 'min-content', cursor: 'pointer' }}>
            <Card onClick={() => setLevelsOpen((prev) => !prev)}>
              <CardContent
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  width: '250px'
                }}
              >
                <Stack direction="row" alignItems="center">
                  <Avatar alt=" " src="/static/mock-images/avatars/Levels.png" />
                  <div style={{ paddingLeft: '55px' }}>
                    <Typography variant="h4">Levels</Typography>
                    <Typography style={{ fontWeight: '700', color: '#00AB55' }}>ACTIVE</Typography>
                  </div>
                </Stack>
                <Collapse in={levelsOpen}>
                  <Stack direction="column" mt={3}>
                    <Typography variant="body2" mb={2}>
                      Customize and create levels for your players to keep them challenged and
                      engaged.
                    </Typography>
                    <div style={{ marginBottom: '16px' }}>
                      <Levels />
                    </div>
                    <div style={{ marginBottom: '16px' }}>
                      <Points />
                    </div>
                    <div style={{ marginBottom: '16px' }}>
                      <Discount />
                    </div>
                  </Stack>
                </Collapse>
              </CardContent>
            </Card>
          </div>
          <div style={{ minHeight: 'min-content', cursor: 'pointer' }}>
            <Card onClick={() => setReferralOpen((prev) => !prev)}>
              <CardContent
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  width: '250px'
                }}
              >
                <Stack direction="row" alignItems="center">
                  <Avatar alt=" " src="/static/mock-images/avatars/referrals.png" />
                  <div style={{ paddingLeft: '35px' }}>
                    <Typography variant="h4">Referrals</Typography>
                    <Typography style={{ fontWeight: '700', color: '#00AB55' }}>ACTIVE</Typography>
                  </div>
                </Stack>
                <Collapse in={referralOpen}>
                  <Stack direction="column" mt={3}>
                    <Typography variant="body2" mb={2}>
                      Refer us to your friends and earn reward.
                    </Typography>
                    <div style={{ marginBottom: '16px' }}>
                      <Vouchers />
                    </div>
                    <div style={{ marginBottom: '16px' }}>
                      <PointsRatio />
                    </div>
                  </Stack>
                </Collapse>
              </CardContent>
            </Card>
          </div>
        </Stack>
        <Button
          onClick={handleClick}
          variant="contained"
          sx={{ marginTop: '100px', paddingX: '55px', paddingY: '8px', fontSize: '18px' }}
        >
          Next Step
        </Button>
      </div>
    </>
  );
};

export default SeeSetup;
