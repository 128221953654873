import {
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography
} from '@mui/material';
import PropTypes from 'prop-types';

export default function QnA2({ setQuestionNum, answers, setAnswers }) {
  return (
    <Card>
      <CardContent style={{ margin: '15px' }}>
        <FormControl style={{ width: '40vw' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '25px' }}>
            <Typography>What kind of products do you sell?</Typography>
            <Typography>(2/3)</Typography>
          </div>
          <RadioGroup
            defaultValue={answers[2]}
            onChange={(event) => {
              setAnswers((prev) => (prev = { ...prev, 2: event.target.value }));
              setQuestionNum((prev) => prev + 1);
            }}
          >
            <FormControlLabel
              value="fashion-beauty"
              control={<Radio />}
              label="Fashion and Beauty"
            />
            <FormControlLabel
              value="food-lifestyle"
              control={<Radio />}
              label="Food and Lifestyle"
            />
            <FormControlLabel
              value="industrial-products"
              control={<Radio />}
              label="Industrial Products"
            />
          </RadioGroup>
        </FormControl>
      </CardContent>
    </Card>
  );
}

QnA2.propTypes = {
  answers: PropTypes.object,
  setAnswers: PropTypes.func,
  setQuestionNum: PropTypes.func
};
