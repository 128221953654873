import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Box, Grid, Container, Typography, Skeleton } from '@mui/material';

import axios from 'axios';
// component
import EnableAppModal from '../components/newUserModal/EnableAppModal';
import Page from '../components/Page';
import {
  // AppTasks,
  // AppNewUsers,
  // AppBugReports,
  // AppItemOrders,
  // AppNewsUpdate,
  translate,
  AppWeeklySales
  // AppOrderTimeline,
  // AppCurrentVisits,
  // AppWebsiteVisits,
  // AppTrafficBySite,
  // AppCurrentSubject,
  // AppConversionRates
} from '../components/_dashboard/app';

// ----------------------------------------------------------------------

const initialValues = {
  totalAccumulatedPoints: '0',
  totalRedeemedPoints: '0',
  totalValuesOfRedeemedPoints: '0',
  currentOutstandingPoints: '0',
  currentValueOutstandingPoints: '0',
  currency: 'INR',
  totalPlayers: '0',
  totalPlayersWithNoPoint: '0',
  newlyEnrolledPlayers: '0'
};

export default function DashboardApp() {
  const [dashboard, setDashboard] = useState(initialValues);
  const [duration, setDuration] = useState(0);
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [shopDomain, setShopDomain] = useState('');
  const [language] = useState(localStorage.getItem('currentLanguage'));
  const navigate = useNavigate();

  useEffect(() => {
    const { newUser } = JSON.parse(localStorage.getItem('loginUser'));
    if (newUser === true) {
      navigate('/onboarding');
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const { openEnableApp } = JSON.parse(localStorage.getItem('loginUser'));

    fetchOrgData();
    if (openEnableApp === true && !loading) {
      setOpenModal(true);
    }
    // eslint-disable-next-line
  }, []);

  const fetchOrgData = async () => {
    setLoading(true);
    try {
      const res = await axios.get('org');
      setShopDomain(res.data.data.shopDomain);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const fetchDefaultDashboardData = async () => {
    setLoading(true);
    try {
      const res = await axios.get('dashboard');
      setDashboard({ ...res.data.data });
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    const FirstRun = sessionStorage.getItem('firstRun');
    if (FirstRun) {
      // No animation
      setDuration(0);
    } else {
      // Animation
      setDuration(3);
      sessionStorage.setItem('firstRun', true);
    }
  }, []);

  useEffect(() => {
    fetchDefaultDashboardData();
  }, []);

  return (
    <Page title={`${translate(language, 'Dashboard')} | Evolve-UI`}>
      {openModal && (
        <EnableAppModal
          language={language}
          shopDomain={shopDomain}
          open={openModal}
          setOpen={setOpenModal}
        />
      )}
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">{translate(language, 'Hi, Welcome back')}</Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4}>
            {loading ? (
              <StyledSkeleton variant="rectangular" height={180} />
            ) : (
              <AppWeeklySales
                points={dashboard.totalAccumulatedPoints}
                duration={duration}
                language={language}
                title={translate(language, 'Total Accumulated Points')}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            {loading ? (
              <StyledSkeleton variant="rectangular" height={180} />
            ) : (
              <AppWeeklySales
                points={dashboard.totalRedeemedPoints}
                duration={duration}
                language={language}
                title={translate(language, 'Total Redeemed Points')}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            {loading ? (
              <StyledSkeleton variant="rectangular" height={180} />
            ) : (
              <AppWeeklySales
                duration={duration}
                points={dashboard.totalValuesOfRedeemedPoints}
                language={language}
                currency={dashboard.currency}
                title={translate(language, 'Total Value of Redeemed Points')}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            {loading ? (
              <StyledSkeleton variant="rectangular" height={180} />
            ) : (
              <AppWeeklySales
                language={language}
                duration={duration}
                points={dashboard.currentOutstandingPoints}
                title={translate(language, 'Current Outstanding Points')}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            {loading ? (
              <StyledSkeleton variant="rectangular" height={180} />
            ) : (
              <AppWeeklySales
                duration={duration}
                points={dashboard.currentValueOutstandingPoints}
                language={language}
                title={translate(language, 'Current Value of Outstanding Points')}
                currency={dashboard.currency}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            {loading ? (
              <StyledSkeleton variant="rectangular" height={180} />
            ) : (
              <AppWeeklySales
                duration={duration}
                points={dashboard.totalPlayers}
                language={language}
                title={translate(language, 'Total Number of Players')}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            {loading ? (
              <StyledSkeleton variant="rectangular" height={180} />
            ) : (
              <AppWeeklySales
                duration={duration}
                points={dashboard.totalPlayersWithNoPoint}
                language={language}
                title={translate(language, 'Total Number of Players With Points')}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            {loading ? (
              <StyledSkeleton variant="rectangular" height={180} />
            ) : (
              <AppWeeklySales
                duration={duration}
                points={dashboard.newlyEnrolledPlayers}
                language={language}
                title={translate(language, 'Newly Enrolled Players')}
              />
            )}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
const StyledSkeleton = styled(Skeleton)({
  borderRadius: '16px'
});
