import React from 'react';
import { Button } from '@mui/material';
import PropTypes from 'prop-types';
import { translate } from '../app';

export default function WriteYourReview({ language }) {
  return (
    <div>
      <a style={{ textDecoration: 'none' }} href="https://apps.shopify.com/evolve/reviews">
        <Button variant="contained" size="medium">
          {translate(language, 'Review the app')}
        </Button>
      </a>
    </div>
  );
}

WriteYourReview.propTypes = {
  language: PropTypes.element
};
