import { useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import {
  Typography,
  Card,
  CardActionArea,
  CardContent,
  Switch,
  CardHeader,
  Backdrop,
  Box,
  Fade,
  Modal,
  Stack,
  TextField
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import PropTypes from 'prop-types';
import { translate } from '../_dashboard/app';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4
};

const ChallengesCard = ({ title, desc, jsx, challengeName, language, data, fetchData }) => {
  const [open, setOpen] = useState(false);

  const ChallengeSchema = Yup.object().shape({
    points: Yup.number()
      .positive(translate(language, 'Must be a Positive Number'))
      .required(translate(language, 'Points is a required field'))
  });

  const handleModalOpen = () => {
    if (!data?.is_active) toast.info(translate(language, 'Activate the %s Challenge', [title]));
    else setOpen(true);
  };

  const handleActiveChange = async ({ target: { checked } }) => {
    try {
      // eslint-disable-next-line
      const res = await axios.post('challenges', {
        challenge_id: data?.challenge_id,
        is_active: checked,
        challenge_name: challengeName,
        points: data?.points ?? 0,
        url: data?.url ?? ''
      });
      fetchData();
    } catch (error) {
      console.error(error);
      toast.error(translate(language, 'Something went wrong!'));
    }
  };

  const formik = useFormik({
    initialValues: {
      url: data?.url,
      points: data?.points
    },
    validationSchema: ChallengeSchema,
    enableReinitialize: true,
    onSubmit: async (values, { resetForm, setFieldError }) => {
      try {
        if (['fb', 'insta'].includes(challengeName) && !values.url) {
          setFieldError('url', translate(language, 'URL is a required field!'));
          return;
        }
        // eslint-disable-next-line
        const res = await axios.post('challenges', {
          challenge_id: data?.challenge_id,
          is_active: data?.is_active,
          challenge_name: challengeName,
          points: values.points,
          url: values.url
        });
        resetForm();
        await fetchData();
        toast.success(translate(language, 'Challenge Modified Successfully'));
        setOpen(false);
      } catch (error) {
        toast.error(error.response.data.message);
      }
    }
  });

  const { dirty, errors, touched, handleSubmit, isSubmitting, getFieldProps, values } = formik;

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={1000}
        pauseOnFocusLoss={false}
        pauseOnHover={false}
        limit={2}
      />
      <Card align="left" variant="outlined" sx={{ maxWidth: 500 }}>
        <CardHeader
          sx={{ paddingTop: 1 }}
          action={<Switch onChange={handleActiveChange} checked={!!data?.is_active} />}
        />
        <CardActionArea>
          <CardContent sx={{ paddingTop: 0 }} onClick={handleModalOpen}>
            <div style={{ marginLeft: '30px', display: 'flex', alignItems: 'center' }}>
              {jsx}
              <div
                style={{
                  marginLeft: '35px',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'start',
                  maxWidth: '180px'
                }}
              >
                <Typography variant="h6">{title}</Typography>
                <Typography variant="body2" color="text.secondary">
                  {desc}
                </Typography>
              </div>
            </div>
          </CardContent>
        </CardActionArea>
      </Card>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack spacing={3}>
                  <TextField
                    fullWidth
                    label={translate(language, 'Points')}
                    type="number"
                    value={values.points}
                    {...getFieldProps('points')}
                    error={Boolean(touched.points && errors.points)}
                    helperText={touched.points && errors.points}
                  />
                  {['insta', 'fb'].includes(challengeName) && (
                    <TextField
                      fullWidth
                      label={translate(language, 'URL')}
                      value={values.url}
                      {...getFieldProps('url')}
                      error={Boolean(touched.url && errors.url)}
                      helperText={touched.url && errors.url}
                    />
                  )}
                  <LoadingButton
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    disabled={!dirty}
                    loading={isSubmitting}
                  >
                    {translate(language, 'Save')}
                  </LoadingButton>
                </Stack>
              </Form>
            </FormikProvider>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default ChallengesCard;

ChallengesCard.propTypes = {
  title: PropTypes.string,
  desc: PropTypes.string,
  jsx: PropTypes.object,
  challengeName: PropTypes.string,
  data: PropTypes.object,
  fetchData: PropTypes.func,
  language: PropTypes.element
};
