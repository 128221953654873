import moment from 'moment';
import PropTypes from 'prop-types';
import { Avatar, Stack, Typography } from '@mui/material';
import { translate } from '../../_dashboard/app';

const Design1 = ({ discount, dateRange, image, language }) => {
  const startDate = moment(dateRange[0]);
  // const currDate = moment(new Date());
  const endDate = moment(dateRange[1]);
  const duration = moment.duration(endDate.diff(startDate));

  return (
    <>
      <Stack
        sx={{
          display: 'flex',
          position: 'relative',
          marginTop: '10px',
          color: 'white',
          fontSize: { xs: '10px', sm: '18px' }
        }}
      >
        <Avatar variant="square" alt=" " src={image} sx={{ width: '100%', height: '100%' }} />
        <Stack
          sx={{
            display: 'flex',
            flexDirection: 'column',
            position: 'absolute',
            top: 0,
            left: 0,
            height: '100%',
            justifyContent: 'space-evenly',
            marginLeft: '20px',
            minWidth: '50%'
          }}
        >
          <Stack sx={{ backgroundColor: 'rgba(85, 162, 133,0.7)', padding: '10px 20px' }}>
            <Typography sx={{ fontSize: { xs: '10px', sm: '18px' } }}>
              {translate(language, 'Discount')}
            </Typography>
            <Stack direction="row">
              <Typography sx={{ fontSize: { xs: '20px', sm: '30px' } }}>
                {discount || 0} %
              </Typography>
              <Typography
                sx={{
                  alignSelf: 'end',
                  marginBottom: '5px',
                  marginLeft: '3px',
                  fontSize: { xs: '10px', sm: '18px' }
                }}
              >
                {translate(language, 'OFF')}
              </Typography>
            </Stack>
          </Stack>
          {/* <Stack
            sx={{
              backgroundColor: '#455A64',
              padding: '2px 4px',
              width: 'fit-content',
              marginLeft: 'auto',
              marginRight: 'auto'
            }}
          >
            <Typography sx={{ fontSize: { xs: '10px', sm: '18px' } }}>copy code</Typography>
          </Stack> */}
          <Stack direction="column">
            <Stack sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
              <Typography
                sx={{
                  backgroundColor: 'rgba(85, 162, 133,0.7)',
                  fontSize: { xs: '10px', sm: '18px' },
                  padding: '10px 20px'
                }}
              >
                {Math.ceil(duration?.asDays()) || 0} {translate(language, 'Days')}
              </Typography>
              <Typography
                sx={{
                  backgroundColor: 'rgba(85, 162, 133,0.7)',
                  fontSize: { xs: '10px', sm: '18px' },
                  padding: '10px 20px',
                  marginLeft: '10px'
                }}
              >
                {duration?._data?.hours ?? 0} h
              </Typography>
              <Typography
                sx={{
                  backgroundColor: 'rgba(85, 162, 133,0.7)',
                  fontSize: { xs: '10px', sm: '18px' },
                  padding: '10px 20px',
                  marginLeft: '10px'
                }}
              >
                {duration?._data?.minutes ?? 0} min
              </Typography>
            </Stack>
            {endDate.format('MMM Do YYYY') !== 'Invalid date' && (
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{
                  textAlign: 'center',
                  marginTop: '10px',
                  fontSize: { xs: '10px', sm: '18px' }
                }}
              >
                {translate(language, 'valid till')} {endDate.format('MMM Do YYYY')}
              </Typography>
            )}
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default Design1;

Design1.propTypes = {
  discount: PropTypes.number,
  dateRange: PropTypes.array,
  image: PropTypes.string,
  language: PropTypes.element
};
