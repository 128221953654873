import {
  Button,
  Card,
  Typography,
  Toolbar,
  TextField,
  MenuItem,
  Table,
  TableHead,
  TableBody,
  TableContainer,
  TableCell,
  TableRow,
  TablePagination
} from '@mui/material';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import PropTypes from 'prop-types';
import PlayerListToolbar from './PlayerListToolbar';
import Scrollbar from '../../Scrollbar';
import CenterSpinner from '../../CenterSpinner';
import UserJourney from './UserJourney';
import { translate } from '../app';

const RootStyle = styled(Toolbar)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3)
}));

export default function PlayersListTable({ language }) {
  const [playerData, setPlayerData] = useState([]);
  const [sortType, setSortType] = useState('Creation date by newest first');

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);

  const [open, setOpen] = useState(false);
  const [targetPlayer, setTargetPlayer] = useState(0);
  const [targetPlayerData, setTargetPlayerData] = useState({});
  const [targetPlayerInfo, setTargetPlayerInfo] = useState({});
  const bottomRef = useRef();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const fetchPlayerData = async () => {
    setLoading(true);
    try {
      const res = await axios.get('players', {
        params: {
          sortType
        }
      });
      setPlayerData(res.data.data);
    } catch (error) {
      toast(error.response.data.message);
    }
    setLoading(false);
  };

  const getTargetPlayerData = async () => {
    setLoading(true);
    const res = await axios.get(`userjourney/${targetPlayer}`);
    setTargetPlayerData(res.data.data);
    setLoading(false);
  };

  const firstUpdate = useRef(true);
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    getTargetPlayerData();
    bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
    // eslint-disable-next-line
  }, [targetPlayer]);

  useEffect(() => {
    fetchPlayerData();
    // eslint-disable-next-line
  }, [sortType]);

  if (loading) {
    return <CenterSpinner />;
  }

  return (
    <>
      <Card>
        <PlayerListToolbar language={language} sortType={sortType} setSortType={setSortType} />
        <ToastContainer
          position="bottom-right"
          autoClose={1500}
          newestOnTop
          closeOnClick
          pauseOnFocusLoss={false}
          pauseOnHover={false}
          limit={2}
        />
        <RootStyle>
          <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
            <Typography mr={2} variant="h6">
              {translate(language, 'Players')} ({playerData.length})
            </Typography>
            <Typography mr={2} variant="h6">
              {translate(language, 'Active')} ({playerData.filter((item) => item.isActive).length})
            </Typography>
            <Typography variant="h6">
              {translate(language, 'Inactive')} (
              {playerData.filter((item) => !item.isActive).length})
            </Typography>
          </div>
          <div style={{ display: 'flex', justifyContent: 'flex-end', width: '60%' }}>
            <Button
              style={{ marginRight: '5px' }}
              variant="contained"
              onClick={() => toast.info(translate(language, 'Comes with Enterprise Plan'))}
            >
              {translate(language, 'Reward a Challenge')}
            </Button>
            <Button
              style={{ marginRight: '5px' }}
              variant="contained"
              onClick={() => toast.info(translate(language, 'Comes with Enterprise Plan'))}
            >
              {translate(language, 'Add/Deduct Points')}
            </Button>
            <TextField select size="small" value="More">
              <MenuItem value="More">{translate(language, 'More')}</MenuItem>
            </TextField>
          </div>
        </RootStyle>

        <Scrollbar>
          <TableContainer style={{ marginTop: '20px' }} sx={{ minWidth: 800 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="left">{translate(language, 'Creation Time')}</TableCell>
                  <TableCell align="left">{translate(language, 'Player Name')}</TableCell>
                  <TableCell align="left">{translate(language, 'Points')}</TableCell>
                  <TableCell align="left">{translate(language, 'Amount Spent')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {playerData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item, index) => (
                    <TableRow
                      hover
                      key={index}
                      onClick={() => {
                        setOpen(true);
                        setTargetPlayer(item.players_id);
                        setTargetPlayerInfo(item);
                      }}
                      sx={{ cursor: 'pointer' }}
                    >
                      <TableCell align="left">
                        {moment(item.created_on).format('DD-MM-YYYY hh:mm a')}
                      </TableCell>
                      <TableCell align="left">
                        <Typography variant="subtitle2" noWrap>
                          {item.name}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">{item.total_points}</TableCell>
                      <TableCell align="center">{item.total_amount_spent}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>
        <TablePagination
          ref={bottomRef}
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={playerData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>
      {open && <UserJourney data={targetPlayerData} playerInfo={targetPlayerInfo} />}
    </>
  );
}

PlayersListTable.propTypes = {
  language: PropTypes.element
};
