import { Backdrop, Tabs, Tab, Box, Fade, Modal, Typography, List } from '@mui/material';
import PropTypes from 'prop-types';
import ListHeader from '@mui/material/ListSubheader';
import { useState } from 'react';
import { Icon } from '@iconify/react';
import ScheduleMessage from './ScheduleMessage';
import DefineTrigger from './DefineTrigger';
import ReviewMessage from './ReviewMessage';
import ComposeMessage from './ComposeMessage';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4
};

export default function Edit({ open, setOpen, getGroups }) {
  const handleClose = () => setOpen();
  const [openGroupModal, setOpenGroupModal] = useState(false);
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={open}>
          <Box
            sx={style}
            style={{
              height: '500px',
              width: '1000px',
              overflowY: 'scroll',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <div style={{ marginRight: '20px' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <List
                  variant="outlined"
                  sx={{
                    width: '100%',
                    maxWidth: 300,
                    mt: 4
                  }}
                  component="nav"
                  aria-labelledby="nested-list-subheader"
                  subheader={
                    <ListHeader style={{ fontSize: 20 }} className="testClass">
                      <Typography variant="h3" color="black" align="left">
                        <b>Update Message</b>
                      </Typography>
                    </ListHeader>
                  }
                >
                  <Typography style={{ height: '22px' }} align="center">
                    {}
                  </Typography>
                </List>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography style={{ height: '44px' }} align="center">
                    {}
                  </Typography>
                  <Icon
                    icon="raphael:cross"
                    onClick={handleClose}
                    width={105}
                    height={22}
                    align="right"
                  />
                </div>
              </div>

              <Box
                sx={{ borderBottom: 1, borderColor: 'divider' }}
                style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
              >
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                  <Tab label="Compose your Message" {...a11yProps(0)} />
                  <Tab label="Define Trigger" {...a11yProps(1)} />
                  <Tab label="Schedule your message" {...a11yProps(2)} />
                  <Tab label="Review your message" {...a11yProps(3)} />
                </Tabs>
              </Box>

              <TabPanel value={value} index={0}>
                <ComposeMessage />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <DefineTrigger />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <ScheduleMessage />
              </TabPanel>
              <TabPanel value={value} index={3}>
                <ReviewMessage />
              </TabPanel>

              <Typography style={{ height: '90px' }} align="center">
                {}
              </Typography>
            </div>

            <Edit getGroups={getGroups} open={openGroupModal} setOpen={setOpenGroupModal} />
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

Edit.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  getGroups: PropTypes.func
};
