import { Container, Box, Tabs, Tab, Typography, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Cashback,
  Challenges,
  Levels,
  Programs,
  Redemption,
  Referrals
} from '../components/analytics';
import Page from '../components/Page';
import { translate } from '../components/_dashboard/app';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

export default function Analytics() {
  const [value, setValue] = useState(0);
  const language = localStorage.getItem('currentLanguage');

  const { state } = useLocation();
  useEffect(() => {
    if (state !== null) setValue(state);
  }, [state]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Page title={`${translate(language, 'Dashboard')}: ${translate(language, 'Analytics')}`}>
      <Container>
        <Stack direction="column" justifyContent="space-between" mb={5}>
          <Stack direction="col" alignItems="center">
            <Typography variant="h4" gutterBottom>
              {translate(language, 'Analytics')}
            </Typography>
            <Typography
              style={{
                backgroundColor: '#A7F3D0',
                color: '#059669',
                marginLeft: 10,
                borderRadius: 5,
                padding: 3,
                fontSize: '12px'
              }}
            >
              {translate(language, 'Enterprise Plan')}
            </Typography>
          </Stack>
          <p>
            {translate(
              language,
              'Gain insights on how your business is performing and stay up to date with your Analytics. Understand how your players engage with Evolve widget and measure the effectiveness of your loyalty configurations.'
            )}
          </p>
        </Stack>

        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            allowScrollButtonsMobile
            aria-label="scrollable force tabs example"
          >
            <Tab label={translate(language, 'General')} {...a11yProps(0)} />
            <Tab label={translate(language, 'Cashback')} {...a11yProps(1)} />
            <Tab label={translate(language, 'Referrals')} {...a11yProps(2)} />
            <Tab label={translate(language, 'Challenges')} {...a11yProps(3)} />
            <Tab label={translate(language, 'Levels')} {...a11yProps(4)} />
            <Tab label={translate(language, 'Redemption')} {...a11yProps(5)} />
          </Tabs>
        </Box>

        <TabPanel value={value} index={0}>
          <Programs language={language} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Cashback language={language} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Referrals language={language} />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <Challenges language={language} />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <Levels language={language} />
        </TabPanel>
        <TabPanel value={value} index={5}>
          <Redemption language={language} />
        </TabPanel>
      </Container>
    </Page>
  );
}
