import axios from 'axios';

const fetchOrgInfo = async (setOrgInfo) => {
  try {
    const res = await axios.get('org');
    setOrgInfo(res.data.data);
  } catch (error) {
    console.log(error);
  }
};

export default fetchOrgInfo;
