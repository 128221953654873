import * as React from 'react';
import { Typography, Button, Grid } from '@mui/material';
import { translate } from '../../app';

const language = localStorage.getItem('currentLanguage');

const message1 = translate(
  language,
  "By resetting your players' data you are deleting ALL of your players' data (achievements, points, score.. etc). However, all other give Evolve account personalization, settings, and configurations will remain. If you would like to backup your players' data before deletion, considered exporting their data through the 'Import Data' tab."
);

const message2 = translate(
  language,
  'Reset all your Evolve account data and widget by clicking below. all your data and configurations will be deleted. You will be able to start with the configuration wizard from the beginning with the fresh new version of Evolve'
);

const boldmessage1 = translate(
  language,
  'The below players data will be removed without retrieval.'
);

const boldmessage2 = translate(
  language,
  'Remember that all your data and configurations will be removed without retrieval.'
);

const list1 = translate(
  language,
  'all existing player data on both live and test virgin achievement transactions score points activities and all the corresponding information.'
);
const list2 = translate(language, 'All program achievements');
const list3 = translate(language, 'All point redemption of all transactions');
const list4 = translate(language, 'All message sending and clicking activities');

export default function resetData() {
  return (
    <>
      <Grid>
        <Grid container>
          <Grid item>
            <Typography align="left">{message1}</Typography>
            <Typography paddingTop={1} paddingBottom={1} align="left" fontWeight="bold">
              {boldmessage1}
            </Typography>
            <Typography align="left" paddingLeft={3}>
              <ul>
                <li>{list1}</li>
                <li>{list2}</li>
                <li>{list3}</li>
                <li>{list4}</li>
              </ul>
            </Typography>
            <Typography align="right" paddingY={5}>
              <Button variant="contained">{translate(language, 'Reset All Players')}</Button>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid>
        <Grid container>
          <Grid item>
            <Typography align="left">{message2}</Typography>
            <Typography paddingTop={3} paddingBottom={3} align="left" fontWeight="bold">
              {boldmessage2}
            </Typography>
            <Typography align="right" paddingY={3}>
              <Button variant="contained">{translate(language, 'Reset Everything')}</Button>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
