import PropTypes from 'prop-types';

const Discount = ({ width, height }) => (
  <svg width={width || '31'} height={height || '23'} viewBox="0 0 31 23" fill="none">
    <path
      d="M29.3747 0.708313H1.62467C1.2158 0.708313 0.82367 0.870738 0.534551 1.15986C0.245433 1.44898 0.0830078 1.8411 0.0830078 2.24998V8.41665H1.45972C2.99522 8.41665 4.41972 9.46652 4.66638 10.982C4.74153 11.4245 4.7192 11.8781 4.60096 12.3111C4.48272 12.7441 4.27142 13.1461 3.9818 13.489C3.69217 13.8319 3.33121 14.1074 2.9241 14.2965C2.51698 14.4855 2.07353 14.5834 1.62467 14.5833H0.0830078V20.75C0.0830078 21.1589 0.245433 21.551 0.534551 21.8401C0.82367 22.1292 1.2158 22.2916 1.62467 22.2916H29.3747C29.7836 22.2916 30.1757 22.1292 30.4648 21.8401C30.7539 21.551 30.9163 21.1589 30.9163 20.75V14.5833H29.3747C28.9258 14.5834 28.4824 14.4855 28.0753 14.2965C27.6681 14.1074 27.3072 13.8319 27.0176 13.489C26.7279 13.1461 26.5166 12.7441 26.3984 12.3111C26.2801 11.8781 26.2578 11.4245 26.333 10.982C26.5796 9.46652 28.0041 8.41665 29.5396 8.41665H30.9163V2.24998C30.9163 1.8411 30.7539 1.44898 30.4648 1.15986C30.1757 0.870738 29.7836 0.708313 29.3747 0.708313ZM10.8747 6.87498C11.2836 6.87498 11.6757 7.03741 11.9648 7.32652C12.2539 7.61564 12.4163 8.00777 12.4163 8.41665C12.4163 8.82552 12.2539 9.21765 11.9648 9.50677C11.6757 9.79589 11.2836 9.95831 10.8747 9.95831C10.4658 9.95831 10.0737 9.79589 9.78455 9.50677C9.49543 9.21765 9.33301 8.82552 9.33301 8.41665C9.33301 8.00777 9.49543 7.61564 9.78455 7.32652C10.0737 7.03741 10.4658 6.87498 10.8747 6.87498ZM9.64134 16.7416L18.8913 4.40831L21.358 6.25831L12.108 18.5916L9.64134 16.7416ZM20.1247 16.125C19.7158 16.125 19.3237 15.9626 19.0346 15.6734C18.7454 15.3843 18.583 14.9922 18.583 14.5833C18.583 14.1744 18.7454 13.7823 19.0346 13.4932C19.3237 13.2041 19.7158 13.0416 20.1247 13.0416C20.5336 13.0416 20.9257 13.2041 21.2148 13.4932C21.5039 13.7823 21.6663 14.1744 21.6663 14.5833C21.6663 14.9922 21.5039 15.3843 21.2148 15.6734C20.9257 15.9626 20.5336 16.125 20.1247 16.125Z"
      fill="#00AB55"
    />
  </svg>
);

export default Discount;

Discount.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string
};
