import { Avatar, Button, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import { translate } from '../../_dashboard/app';

function ShopifyRedirect({ language }) {
  return (
    <>
      <Stack direction="column" alignItems="center" spacing={9}>
        <Avatar
          variant="square"
          sx={{ width: 260, height: 110 }}
          alt=" "
          src="/static/Shopify.png"
        />
        <Button variant="contained" sx={{ width: '250px', fontSize: '16px' }}>
          <a
            style={{ textDecoration: 'none', color: '#fff' }}
            href="https://apps.shopify.com/evolve"
            target="_blank"
            rel="noreferrer"
          >
            {translate(language, 'Connect To Shopify')}
          </a>
        </Button>
      </Stack>
    </>
  );
}

ShopifyRedirect.propTypes = {
  language: PropTypes.element
};

export default ShopifyRedirect;
