import React from 'react';
import { Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import GeneralBox from './General/GeneralBox';
import ResetData from './ResetData/ResetDataBox';
import { translate } from '../app';

export default function BasicTab({ language }) {
  return (
    <div>
      <Typography variant="h4" align="left" paddingTop={1}>
        {translate(language, 'General')}
      </Typography>
      <Grid paddingY={3} paddingX={2}>
        <GeneralBox language={language} />
      </Grid>
      <Typography variant="h4" align="left" paddingTop={2}>
        {translate(language, 'Reset Data')}
      </Typography>
      <Grid paddingY={3} paddingX={2}>
        <ResetData />
      </Grid>
    </div>
  );
}

BasicTab.propTypes = {
  language: PropTypes.element
};
