const Upload = () => (
  <svg width="33" height="33" viewBox="0 0 33 33" fill="none">
    <path d="M16.5 8.25L22 15.125H17.875V30.25H15.125V15.125H11L16.5 8.25Z" fill="black" />
    <path
      d="M26.125 20.625H20.625V17.875H26.125V5.5H6.875V17.875H12.375V20.625H6.875C5.35837 20.625 4.125 19.3916 4.125 17.875V5.5C4.125 3.98337 5.35837 2.75 6.875 2.75H26.125C27.6416 2.75 28.875 3.98337 28.875 5.5V17.875C28.875 19.3916 27.6416 20.625 26.125 20.625Z"
      fill="black"
    />
  </svg>
);

export default Upload;
