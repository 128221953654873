import { Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import DatePickerWithTitle from '../DatePickerWithTitle';
import { AppWebsiteVisits, AppWeeklySales, translate } from '../_dashboard/app';

export default function Cashback({ language }) {
  return (
    <>
      <DatePickerWithTitle language={language} title={translate(language, 'Cashback')} />
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4}>
          <AppWeeklySales
            language={language}
            points="0"
            title={translate(language, 'Total number of sales transactions')}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <AppWeeklySales
            language={language}
            points="0"
            title={translate(language, 'Total Cashback Points')}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <AppWeeklySales
            language={language}
            points="0 INR"
            title={translate(language, 'Total Cashback Sales')}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <AppWeeklySales
            language={language}
            points="0"
            title={translate(language, 'Total pending cashback points')}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <AppWeeklySales
            language={language}
            points="0 INR"
            title={translate(language, 'Total pending value of cashback points')}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <AppWeeklySales
            language={language}
            points="0"
            title={translate(language, 'Total pending cashback points')}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <AppWeeklySales
            language={language}
            points="0 INR"
            title={translate(language, 'Total pending cashback sales')}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <AppWeeklySales
            language={language}
            points="0 INR"
            title={translate(language, 'Total value of cashback points')}
          />
        </Grid>
      </Grid>
      <Typography mt={5} mb={5} variant="h6">
        {translate(language, 'Detailed Analytics')}
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12}>
          <AppWebsiteVisits
            title={translate(language, 'Cashback Reward Behavior Trend')}
            sub={translate(language, 'The trend of the cashback reward behavior in points')}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <AppWebsiteVisits
            title={translate(language, 'Cashback Reward Daily Trend')}
            sub={translate(language, 'The daily cashback reward trends in points')}
          />
        </Grid>
      </Grid>
    </>
  );
}

Cashback.propTypes = {
  language: PropTypes.element
};
