export default function Eye() {
  return (
    <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 6.99967C0.933333 3.13301 4.13333 0.333008 8 0.333008C11.8667 0.333008 15.0667 3.13301 16 6.99967C15.0667 10.8663 11.8667 13.6663 8 13.6663C4.13333 13.6663 0.933333 10.8663 0 6.99967ZM4.66667 6.99967C4.66667 8.86634 6.13333 10.333 8 10.333C9.86667 10.333 11.3333 8.86634 11.3333 6.99967C11.3333 5.13301 9.86667 3.66634 8 3.66634C6.13333 3.66634 4.66667 5.13301 4.66667 6.99967ZM9.33333 6.99967C9.33333 7.73605 8.73638 8.33301 8 8.33301C7.26362 8.33301 6.66667 7.73605 6.66667 6.99967C6.66667 6.26329 7.26362 5.66634 8 5.66634C8.73638 5.66634 9.33333 6.26329 9.33333 6.99967Z"
        fill="black"
      />
    </svg>
  );
}
