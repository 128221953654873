import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink } from 'react-router-dom';
// material
import { Container, Typography, Stack, Button, Box } from '@mui/material';
// components
import Page from '../components/Page';

export default function Apps() {
  return (
    <Page title="Dashboard: Applications">
      <Container>
        <Typography variant="h4" gutterBottom>
          Applications
        </Typography>
        <p>
          Integrate other apps with Gamify! Add apps to your account here. This will allow you to
          push Gamify data to other apps or reward your players based on actions from other apps
        </p>
      </Container>

      <Stack mb={5} mt={5} direction="row" alignItems="center" justifyContent="space-around">
        <Typography variant="h6" color="#00AB55">
          Available apps (0)
        </Typography>
        <Button
          variant="contained"
          component={RouterLink}
          to="#"
          startIcon={<Icon icon={plusFill} />}
        >
          Add new application
        </Button>
      </Stack>

      <Box
        mt={0.5}
        mb={0.5}
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          '& > :not(style)': {
            m: 6,
            width: 400
          }
        }}
      >
        <img alt="no-apps" src="/static/mock-images/no-apps.png" />
        <Typography align="center" variant="h3" style={{ marginTop: '-15px' }}>
          No Integrated Apps
        </Typography>
      </Box>
    </Page>
  );
}
