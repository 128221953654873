import { Typography } from '@mui/material';
import moment from 'moment';
import { array } from 'prop-types';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
// import Levels from './icons/Levels';
// import PageOpen from './icons/PageOpen';
// import PageView from './icons/PageView';
// import Order from './icons/Order';
// import Coupons from './icons/Coupons';

export default function UserTimeline({ data }) {
  return (
    <>
      <Timeline position="right">
        <TimelineItem>
          <TimelineOppositeContent />
          <TimelineSeparator>
            <TimelineDot
              color="primary"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '60px',
                height: '60px'
              }}
            >
              START
            </TimelineDot>
            <TimelineConnector sx={{ height: '30px' }} />
          </TimelineSeparator>
          <TimelineContent />
        </TimelineItem>
        {data?.map((item, index) => (
          <TimelineItem key={index}>
            <TimelineOppositeContent color="text.secondary" sx={{ margin: '25px' }}>
              {moment(item.created_on).format('DD-MM-YYYY hh:mm a')}
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot color="primary">
                {/* {['orders-complete', 'orders-create'].includes(item.type) && <Order />}
                {item.type === 'page-open' && <PageOpen />}
                {item.type === 'page-view' && <PageView />}
                {item.type === 'level-change' && <Levels color="#fff" size="18" />}
                {['coupon-purchase', 'refer-friend', 'notification'].includes(item.type) && (
                  <Coupons />
                )} */}
              </TimelineDot>
              <TimelineConnector sx={{ height: '30px' }} />
            </TimelineSeparator>
            <TimelineContent sx={{ margin: '25px' }}>
              {item.type === 'activity' && (
                <>
                  <Typography variant="h6">Notification</Typography>
                  <Typography variant="body2">{item.description}</Typography>
                </>
              )}
              {item.type === 'trans' && (
                <Typography
                  variant="body1"
                  color={item.transaction_type === 'cr' ? 'primary' : 'error'}
                >
                  {item.transaction_type === 'cr' ? '+' : '-'}
                  {item.points ?? 0} Points
                </Typography>
              )}
            </TimelineContent>
          </TimelineItem>
        ))}
        <TimelineItem>
          <TimelineOppositeContent />
          <TimelineSeparator>
            <TimelineDot
              color="primary"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '50px',
                height: '50px'
              }}
            >
              END
            </TimelineDot>
          </TimelineSeparator>
          <TimelineContent />
        </TimelineItem>
      </Timeline>
    </>
  );
}

UserTimeline.propTypes = {
  data: array
};
