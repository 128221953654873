import {
  Container,
  Typography,
  Stack,
  Card,
  CardContent,
  Box,
  Table,
  TableContainer,
  TableRow,
  TableCell,
  TableHead
} from '@mui/material';
import PropTypes from 'prop-types';
import * as React from 'react';
import { Icon } from '@iconify/react';
import crossIcon from '@iconify/icons-akar-icons/cross';
import messageAltWritingF from '@iconify/icons-jam/message-alt-writing-f';
import circleFill from '@iconify/icons-bi/circle-fill';
import baseLineDelete from '@iconify/icons-ic/baseline-delete';
import editFill from '@iconify/icons-eva/edit-fill';
import Switch from '@mui/material/Switch';
import { useEffect, useState } from 'react';
import axios from 'axios';
import Page from '../../Page';
import Edit from './Edit';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

export default function InAppNotification() {
  const [openGroupModal, setOpenGroupModal] = useState(false);
  const [setGroups] = useState([]);
  const user = JSON.parse(localStorage.getItem('loginUser'));
  const getGroups = async () => {
    const orgId = user.user.orgs[0].org_id;
    const res = await axios.get(`group/${orgId}`);

    if (res.data.data.length) {
      setGroups(res.data.data);
    }
  };

  useEffect(() => {
    getGroups();
    // eslint-disable-next-line
    }, []);

  return (
    <Page title="Dashboard: Players | Gamify">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row'
            }}
          >
            <Icon
              icon={messageAltWritingF}
              color="green"
              style={{ height: '54px', width: '45px' }}
            />
            <div>
              <Typography variant="h4" gutterBottom>
                In-App Notification
              </Typography>
              <Typography variant="h7" gutterBottom>
                Web
              </Typography>
            </div>
          </div>
          <div>
            <Typography
              variant="h7"
              gutterBottom
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-end',
                height: '40px',
                width: '270px'
              }}
            >
              <div>
                <Switch {...label} defaultChecked size="small" gutterBottom />
                Active
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', cursor: 'pointer' }}>
                <Icon onClick={() => setOpenGroupModal(true)} icon={editFill} fontSize={22} />
                <Typography onClick={() => setOpenGroupModal(true)}>Edit</Typography>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', cursor: 'pointer' }}>
                <Icon icon={baseLineDelete} fontSize={22} align="right" />
                Delete
              </div>
            </Typography>
          </div>
        </Stack>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Card style={{ width: '450px' }} variant="outlined">
            <TableContainer
              style={{ marginTop: '2px' }}
              sx={{ maxWidth: 400 }}
              border="3px solid rgb(0, 0, 0)"
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="left">
                      <b>Sent</b>
                    </TableCell>
                    <TableCell align="left">
                      <b>Clicked</b>
                    </TableCell>
                    <TableCell align="left">
                      <b>Conversion</b>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left">0</TableCell>
                    <TableCell align="left">0</TableCell>
                    <TableCell align="left">0</TableCell>
                  </TableRow>
                </TableHead>
              </Table>
            </TableContainer>
          </Card>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography
              variant="h4"
              gutterBottom
              style={{
                width: '450px'
              }}
            >
              Message Live Preview
            </Typography>
          </div>
        </div>
        <div>
          <Typography variant="h4" gutterBottom align="center">
            {}
          </Typography>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Card style={{ width: '450px' }} variant="outlined" border="1px solid pink">
            <CardContent>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'flex-end',
                    height: '40px'
                  }}
                >
                  <Typography variant="h8" gutterBottom>
                    Composed Message
                  </Typography>
                  <Icon icon={editFill} height={22} />
                </div>
                <Typography variant="h6" gutterBottom>
                  Message Title
                </Typography>
                <Typography variant="h9" gutterBottom>
                  Discover our rewards experience!
                </Typography>
                <Typography variant="h6" gutterBottom>
                  Message Body
                </Typography>
                <Typography variant="h9" gutterBottom>
                  Collect Badges, level up and win points to receive free vouchers!
                </Typography>
                <Typography variant="h6" gutterBottom>
                  Message Redirection
                </Typography>
                <Typography variant="h9" gutterBottom>
                  http://www.gamify.com
                </Typography>
              </div>
            </CardContent>
          </Card>
          <Card
            style={{
              display: 'flex',
              width: '450px',
              flexDirection: 'column',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
            variant="outlined"
          >
            <Typography style={{ height: '350px' }}>
              <Icon
                icon={circleFill}
                style={{
                  paddingLeft: '5px',
                  paddingTop: '4px',
                  color: 'red',
                  width: '25px',
                  height: '20px'
                }}
              />
              <Icon
                icon={circleFill}
                style={{ paddingTop: '4px', color: 'green', width: '25px', height: '20px' }}
              />
              <hr style={{ width: '450px' }} />
              <div>
                <Typography style={{ height: '190px' }}>{}</Typography>
              </div>
              <div>
                <Typography variant="h4" gutterBottom align="center">
                  {}
                </Typography>
              </div>
              <Card
                style={{
                  width: '330px',
                  display: 'flex',
                  height: '100px',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginLeft: '20px',
                  boxShadow: 'none'
                }}
                variant="outlined"
              >
                <CardContent>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography variant="h8" gutterBottom>
                      Discover our rewards experience!
                    </Typography>
                    <Icon icon={crossIcon} width={20} height={18} />
                  </div>
                  <Typography variant="h9" gutterBottom>
                    Collect badges level-up and win points to receive free vouchers!
                  </Typography>
                </CardContent>
              </Card>
            </Typography>
          </Card>
        </div>
        <div>
          <Typography variant="h4" gutterBottom align="center">
            {}
          </Typography>
        </div>
        <Card style={{ width: '450px' }} variant="outlined">
          <CardContent>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant="h8" gutterBottom>
                Message Trigger
              </Typography>
              <Typography variant="h8" gutterBottom>
                {}
              </Typography>
              <Typography variant="h6" gutterBottom>
                Page Trigger
              </Typography>
              <Typography variant="h9" gutterBottom>
                First Landing Page
              </Typography>
            </div>
          </CardContent>
        </Card>
        <div>
          <Typography variant="h4" gutterBottom align="center">
            {}
          </Typography>
        </div>
        <Card style={{ width: '450px' }} variant="outlined">
          <CardContent>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant="h8" gutterBottom>
                Message Schedule
              </Typography>
              <Typography variant="h8" gutterBottom>
                {}
              </Typography>
              <Typography variant="h6" gutterBottom>
                Message Time
              </Typography>
              <Typography variant="h9" gutterBottom>
                Immediately
              </Typography>
              <Typography variant="h6" gutterBottom>
                Frequency Settings
              </Typography>
              <Typography variant="h9" gutterBottom>
                One Time
              </Typography>
              <Typography variant="h6" gutterBottom>
                Message Status
              </Typography>
              <Typography variant="h9" gutterBottom>
                Active
              </Typography>
            </div>
          </CardContent>
        </Card>
        <Edit getGroups={getGroups} open={openGroupModal} setOpen={setOpenGroupModal} />
      </Container>
    </Page>
  );
}

const label = { inputProps: { 'aria-label': 'Switch demo' } };
export function SwitchesSize() {
  return (
    <div>
      <Switch {...label} defaultChecked size="small" />
      <Switch {...label} defaultChecked />
    </div>
  );
}
