import React from 'react';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import PropTypes from 'prop-types';
import { Box, Stack, Button, Typography } from '@mui/material';
import { translate } from '../../app';
import EmptyPage from '../../../EmptyPage';

export default function PlayerTag({ language }) {
  return (
    <div>
      <Stack mb={5} mt={5} direction="row" alignItems="center" justifyContent="space-around">
        <Typography variant="h6" color="#00AB55">
          {translate(language, 'Available apps (0)')}
        </Typography>
        <Button variant="contained" to="#" startIcon={<Icon icon={plusFill} />}>
          {translate(language, 'Add New Application')}
        </Button>
      </Stack>

      <Box
        mt={0.5}
        mb={0.5}
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          '& > :not(style)': {
            mt: 6,
            mb: 4,
            width: 400
          }
        }}
      >
        <EmptyPage language={language} name={translate(language, 'PlayerTag')} />
      </Box>
    </div>
  );
}

PlayerTag.propTypes = {
  language: PropTypes.element
};
