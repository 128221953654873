import axios from 'axios';

const fetchWheelData = async (setWheelData) => {
  try {
    const res = await axios.get('spinwheel');
    setWheelData(res.data.data);
  } catch (error) {
    console.log(error);
  }
};

export default fetchWheelData;
