/* eslint-disable */
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import {
  Backdrop,
  Box,
  Fade,
  Modal,
  Stack,
  TextField,
  Autocomplete,
  Typography,
  Checkbox,
  InputAdornment
} from '@mui/material';
import PropTypes from 'prop-types';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
import axios from 'axios';
import { translate } from '../_dashboard/app';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  maxHeight: '500px',
  overflowY: 'auto',
  p: 4
};

export default function AddGroupModal({ open,language, setOpen, type, setType, data, getGroups }) {
  const [players, setPlayers] = useState([]);
  const [showError, setShowError] = useState(false);
  const [selectedPlayers, setSelectedPlayers] = useState([]);

  const handleClose = () => {
    setType(translate(language, 'add'));
    setShowError(false);
    setOpen(false);
  };

  const GroupSchema = Yup.object().shape({
    name: Yup.string().min(2, translate(language, 'Too Short!')).max(50, translate(language, 'Too Long!')).required(translate(language, 'Group name required')),
    bonus: Yup.number().positive(translate(language, 'Bonus Should be positive')).required(translate(language, 'Bonus Required'))
  });

  const getPlayers = async () => {
    const res = await axios.get('players');
    setPlayers(
      res.data.data.map((item, index) => ({
        ...item,
        key: `${index + 1}.${item.name ? item.name : translate(language, 'No Name')} - ${item.total_points}`,
        label: `${item.name ? item.name : translate(language, 'No Name')} - ${item.total_points}`
      }))
    );

    if (type === 'edit') {
      const res2 = await axios.get('group/players', {
        params: {
          group_id: data.id
        }
      });

      res2.data.data.forEach((item) => {
        delete item.level;
        delete item.couponsCount;
      });

      setPlayers((prev) =>
        prev.filter((item) => !res2.data.data.some((pl) => pl.players_id === item.players_id))
      );
    }
  };

  useEffect(() => {
    getPlayers();
    // eslint-disable-next-line
  }, [data, type]);

  const formik = useFormik({
    initialValues: {
      name: type === 'edit' ? data.name : '',
      bonus: type === 'edit' ? data.bonus : ''
    },
    enableReinitialize: true,
    validationSchema: GroupSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        if (type !== 'edit' && selectedPlayers.length < 1) {
          setShowError(true);
          return;
        }
        // eslint-disable-next-line
        const res =
          type === 'edit'
            ? await axios.patch(`/group/${data.id}`, {
                group_name: values.name,
                bonus: values.bonus,
                selectedPlayers: selectedPlayers
              })
            : await axios.post('group', {
                group_name: values.name,
                bonus: values.bonus,
                selectedPlayers: selectedPlayers
              });

        resetForm();
        getGroups();
        setSelectedPlayers([]);
        setOpen(false);
      } catch (error) {
        console.log(error.response.data.message);
        toast.error(error.response.data.message);
      }
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, values } = formik;

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack spacing={3}>
                  <TextField
                    fullWidth
                    type="text"
                    label={translate(language, "Group name")}
                    value={values.name}
                    {...getFieldProps('name')}
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                  />
                  <TextField
                    fullWidth
                    type="number"
                    label={translate(language, "Bonus")}
                    value={values.bonus}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">%</InputAdornment>
                    }}
                    {...getFieldProps('bonus')}
                    error={Boolean(touched.bonus && errors.bonus)}
                    helperText={touched.bonus && errors.bonus}
                  />
                  <Autocomplete
                    multiple
                    options={players}
                    disableCloseOnSelect
                    limitTags={2}
                    onChange={(event, value) => setSelectedPlayers(value)}
                    getOptionLabel={(option) => option.key}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox style={{ marginRight: 8 }} checked={selected} />
                        {option.label}
                      </li>
                    )}
                    renderInput={(params) => <TextField {...params} label={translate(language, "Players")} />}
                  />
                  {showError && (
                    <Typography color="error" sx={{ fontSize: '12px' }}>
                    {translate(language, 'Player Field is Empty!!')}
                    </Typography>
                  )}
                  <LoadingButton
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                  >
                    {translate(language, 'Add')}
                  </LoadingButton>
                </Stack>
              </Form>
            </FormikProvider>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

AddGroupModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  getGroups: PropTypes.func,
  language: PropTypes.element
};
