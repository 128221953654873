import * as React from 'react';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Tabs, Tab, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ToastContainer } from 'react-toastify';

import EmailsSection from './EmailsSection';
import BrowserSection from './BrowserSection';
import GuestMessagePage from './GuestMessagePage';
import WidgetSection from './WidgetSection';
import { translate } from '../app';

const StyledComingSoonTitle = styled('p')({
  backgroundColor: '#A7F3D0',
  color: '#059669',
  marginLeft: 10,
  borderRadius: 5,
  padding: 3,
  fontFamily: 'Public Sans',
  fontSize: '12px'
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1, pl: 0 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

export default function BasicTabs({ id, language }) {
  const [value, setValue] = React.useState(0);
  useEffect(() => {
    if (id !== null) setValue(id);
  }, [id]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <ToastContainer
        position="bottom-right"
        autoClose={2500}
        newestOnTop
        closeOnClick
        pauseOnFocusLoss
        pauseOnHover
        limit={2}
      />
      <Box>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          allowScrollButtonsMobile
          aria-label="scrollable force tabs example"
        >
          <Tab
            label={
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <p>{translate(language, 'Widget')}</p>
              </div>
            }
            {...a11yProps(0)}
          />
          <Tab
            label={
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <p>{translate(language, 'Guest')}</p>
                <StyledComingSoonTitle>
                  {translate(language, 'Coming soon')} !
                </StyledComingSoonTitle>
              </div>
            }
            {...a11yProps(1)}
          />
          <Tab
            label={
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <p>{translate(language, 'Email')}</p>
                <StyledComingSoonTitle>
                  {translate(language, 'Coming soon')} !
                </StyledComingSoonTitle>
              </div>
            }
            {...a11yProps(2)}
          />
          <Tab
            label={
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <p>{translate(language, 'Browser')}</p>
                <StyledComingSoonTitle>
                  {translate(language, 'Coming soon')} !
                </StyledComingSoonTitle>
              </div>
            }
            {...a11yProps(3)}
          />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <WidgetSection language={language} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <GuestMessagePage language={language} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <EmailsSection language={language} />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <BrowserSection language={language} />
      </TabPanel>
    </Box>
  );
}
BasicTabs.propTypes = {
  id: PropTypes.string,
  language: PropTypes.element
};
