import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select
} from '@mui/material';
import axios from 'axios';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { translate } from '../_dashboard/app';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

function ProductsSelect({ value, handleChange, language }) {
  const [options, setOptions] = useState([]);

  const fetchProducts = async () => {
    try {
      const res = await axios.get('master_products');
      setOptions(res.data.data);
    } catch (error) {
      console.error(error);
      toast.error(translate(language, 'Something went wrong!'));
    }
  };

  useEffect(() => {
    fetchProducts();
    // eslint-disable-next-line
  }, []);

  return (
    <FormControl>
      <InputLabel id="master-products">{translate(language, 'Products')}</InputLabel>
      <Select
        value={value}
        onChange={handleChange}
        id="master-products"
        labelId="master-products"
        multiple
        input={<OutlinedInput label={translate(language, 'Products')} />}
        MenuProps={MenuProps}
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map((value) => (
              <Chip key={value} label={value} />
            ))}
          </Box>
        )}
      >
        {options.map(({ external_product_id: prId, title }) => (
          <MenuItem key={prId} value={JSON.stringify({ prId, title })}>
            <Checkbox checked={value.indexOf(title) > -1} />
            <ListItemText primary={title} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

ProductsSelect.propTypes = {
  value: PropTypes.array.isRequired,
  handleChange: PropTypes.func.isRequired,
  language: PropTypes.element
};

export default ProductsSelect;
