/* eslint-disable camelcase */
import { useState, useEffect } from 'react';
import {
  Avatar,
  Container,
  FormControlLabel,
  Grid,
  Stack,
  Switch,
  Tab,
  Tabs,
  Typography
} from '@mui/material';
import axios from 'axios';
// import { Icon } from '@iconify/react';
import Page from '../Page';
import TabPanel from '../TabPanel';
import PopUp from './PopUp/PopUp';
import { translate } from '../_dashboard/app';
import fetchOrgInfo from '../../utils/getOrgInfo';
import CenterSpinner from '../CenterSpinner';
// import Header from './Header';
// import CampaignSchedule from './CampaignSchedule';
// import DiscountRangeAndGoal from './DiscountRangeAndGoal';
// import CouponSettings from './CouponSettings';
// import Transferrable from './Transferrable';
// import CampaignAudience from './CampaignAudience';
// import SpecificProduct from './SpecificProduct';
// import PromotionStudio from './PromotionStudio';

export default function IntentBasePromotion() {
  const [value, setValue] = useState(0);
  // const [promotionType, setPromotionType] = useState('discount');
  const promotionType = 'promo-discount';
  const [orgInfo, setOrgInfo] = useState([]);
  const [loading, setLoading] = useState(false);
  const language = localStorage.getItem('currentLanguage');

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleActiveChange = async (event) => {
    try {
      // eslint-disable-next-line
      const req = await axios.patch('org', {
        intent_based_promo: event.target.checked
      });
      getOrgInfo();
    } catch (error) {
      console.log(error);
    }
  };

  const getOrgInfo = async () => {
    setLoading(true);
    await fetchOrgInfo(setOrgInfo);
    setLoading(false);
  };

  const { intent_based_promo } = orgInfo;

  useEffect(() => {
    getOrgInfo();
  }, []);

  if (loading) return <CenterSpinner />;

  // const [openCampaign, setOpenCampaign] = useState(false);
  // const [openDiscountRange, setDiscountRange] = useState(false);
  // const [openCouponSettings, setCouponSettings] = useState(false);
  // const [openCampaignAudience, setOpenCampaignAudience] = useState(false);
  // const [openTranferrable, setOpenTransferrable] = useState(false);
  // const [openSpecificProduct, setOpenSpecificProduct] = useState(false);

  // const handleClickCampaign = () => {
  //   setOpenCampaign(!openCampaign);
  //   setDiscountRange(false);
  //   setCouponSettings(false);
  //   setOpenCampaignAudience(false);
  //   setOpenTransferrable(false);
  // };

  // const handleClickDiscountRange = () => {
  //   setOpenCampaign(false);
  //   setDiscountRange(!openDiscountRange);
  //   setCouponSettings(false);
  //   setOpenCampaignAudience(false);
  //   setOpenTransferrable(false);
  // };

  // const handleClickCouponSettings = () => {
  //   setOpenCampaign(false);
  //   setDiscountRange(false);
  //   setCouponSettings(!openCouponSettings);
  //   setOpenCampaignAudience(false);
  //   setOpenTransferrable(false);
  // };

  // const handleClickCampaignAudience = () => {
  //   setOpenCampaign(false);
  //   setDiscountRange(false);
  //   setCouponSettings(false);
  //   setOpenCampaignAudience(!openCampaignAudience);
  //   setOpenTransferrable(false);
  // };

  // const handleClickTransferrable = () => {
  //   setOpenCampaign(false);
  //   setDiscountRange(false);
  //   setCouponSettings(false);
  //   setOpenCampaignAudience(false);
  //   setOpenTransferrable(!openTranferrable);
  // };

  return (
    <Page
      title={`${translate(language, 'Dashboard')} : ${translate(
        language,
        'Gamification'
      )} - ${translate(language, 'Lucky Draw')}`}
    >
      {/* <SpecificProduct open={openSpecificProduct} setOpen={setOpenSpecificProduct} /> */}
      <Container>
        {/* --------------------------------Header Detail--------------------------- */}
        <Stack direction="row" justifyContent="space-between" mb={5}>
          <div style={{ display: 'flex', justifyContent: 'space-between', width: 'auto' }}>
            <Avatar
              sx={{ width: 56, height: 56 }}
              alt=" "
              src="/static/mock-images/avatars/referrals.png"
            />
            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '10px' }}>
              <Typography style={{ marginBottom: '-5px' }} variant="h4" gutterBottom>
                {translate(language, 'Intent Based Promotion')}
              </Typography>
              <Typography
                color={intent_based_promo ? '#00AB55' : 'error'}
                variant="button"
                gutterBottom
              >
                {intent_based_promo
                  ? translate(language, 'ACTIVE')
                  : translate(language, 'INACTIVE')}
              </Typography>
            </div>
          </div>
          <FormControlLabel
            control={<Switch onChange={handleActiveChange} />}
            label=""
            checked={Boolean(intent_based_promo)}
          />
        </Stack>
        {/* <Stack direction="row" alignItems="center">
          <Typography variant="h6" sx={{ paddingRight: '45px' }}>
            Promotion Type:
          </Typography>
          <RadioGroup
            onChange={(e) => setPromotionType(e.target.value)}
            row
            sx={{ marginLeft: '15px', flexGrow: 1, justifyContent: 'start' }}
          >
            <FormControlLabel
              value="discount"
              control={
                <Radio
                  checkedIcon={
                    <Icon
                      icon="fluent:checkbox-indeterminate-16-filled"
                      color="#00ab55"
                      width="20"
                    />
                  }
                  icon={<Icon icon="bx:checkbox" color="#00ab55" width="23" />}
                />
              }
              label="Discount"
            />
            <FormControlLabel
              value="freeshipping"
              control={
                <Radio
                  checkedIcon={
                    <Icon
                      icon="fluent:checkbox-indeterminate-16-filled"
                      color="#00ab55"
                      width="20"
                    />
                  }
                  icon={<Icon icon="bx:checkbox" color="#00ab55" width="23" />}
                />
              }
              label="Free shipping"
            />
            <FormControlLabel
              value="announcement"
              control={
                <Radio
                  checkedIcon={
                    <Icon
                      icon="fluent:checkbox-indeterminate-16-filled"
                      color="#00ab55"
                      width="20"
                    />
                  }
                  icon={<Icon icon="bx:checkbox" color="#00ab55" width="23" />}
                />
              }
              label="Announcement"
            />
          </RadioGroup>
        </Stack> */}
        <Grid container columns={12} sx={{ paddingTop: '25px' }}>
          <Grid item sm={9} xs={12}>
            <Tabs value={value} onChange={handleTabChange}>
              <Tab value={0} label={translate(language, 'Pop-Up')} key={0} />
              {/* <Tab value={0} label="All" key={0} /> */}
              {/* <Tab value={1} label="Banner" key={1} /> */}
              {/* <Tab value={2} label="Promotion Studio" key={2} /> */}
            </Tabs>
          </Grid>
        </Grid>
        <TabPanel index={0} value={value} key={0}>
          <PopUp language={language} promotionType={promotionType} />
        </TabPanel>
        {/* <TabPanel index={1} value={value} key={1}>
          <Grid container columns={12} spacing={2}>
            <Grid xs={12} sm={12} md={6} lg={5}>
              <Stack
                sx={{
                  border: '1px solid #D0D0D0',
                  padding: '20px',
                  borderRadius: '10px',
                  marginTop: '20px'
                }}
                direction="column"
              >
                <Stack sx={{ display: 'flex', justifyContent: 'space-between' }} direction="row">
                  <Typography>Campaign Schedule</Typography>
                  <Icon
                    onClick={handleClickCampaign}
                    icon="akar-icons:chevron-down"
                    style={{ cursor: 'pointer' }}
                  />
                </Stack>
                <Collapse in={openCampaign} timeout="auto" unmountOnExit>
                  <CampaignSchedule />
                </Collapse>
              </Stack>
              <Stack
                sx={{
                  border: '1px solid #D0D0D0',
                  padding: '20px',
                  borderRadius: '10px',
                  marginTop: '20px'
                }}
                direction="column"
              >
                <Stack sx={{ display: 'flex', justifyContent: 'space-between' }} direction="row">
                  <Typography>Discount Range and Goal</Typography>
                  <Icon
                    onClick={handleClickDiscountRange}
                    icon="akar-icons:chevron-down"
                    style={{ cursor: 'pointer' }}
                  />
                </Stack>
                <Collapse in={openDiscountRange} timeout="auto" unmountOnExit>
                  <DiscountRangeAndGoal />
                </Collapse>
              </Stack>
              <Stack
                sx={{
                  border: '1px solid #D0D0D0',
                  padding: '20px',
                  borderRadius: '10px',
                  marginTop: '20px'
                }}
                direction="column"
              >
                <Stack sx={{ display: 'flex', justifyContent: 'space-between' }} direction="row">
                  <Typography>Coupon Settings</Typography>
                  <Icon
                    onClick={handleClickCouponSettings}
                    icon="akar-icons:chevron-down"
                    style={{ cursor: 'pointer' }}
                  />
                </Stack>
                <Collapse in={openCouponSettings} timeout="auto" unmountOnExit>
                  <CouponSettings setOpen={setOpenSpecificProduct} />
                </Collapse>
              </Stack>
              <Stack
                sx={{
                  border: '1px solid #D0D0D0',
                  padding: '20px',
                  borderRadius: '10px',
                  marginTop: '20px'
                }}
                direction="column"
              >
                <Stack sx={{ display: 'flex', justifyContent: 'space-between' }} direction="row">
                  <Typography>Campaign Audience</Typography>
                  <Icon
                    onClick={handleClickCampaignAudience}
                    icon="akar-icons:chevron-down"
                    style={{ cursor: 'pointer' }}
                  />
                </Stack>
                <Collapse in={openCampaignAudience} timeout="auto" unmountOnExit>
                  <CampaignAudience />
                </Collapse>
              </Stack>
              <Stack
                sx={{
                  border: '1px solid #D0D0D0',
                  padding: '20px',
                  borderRadius: '10px',
                  marginTop: '20px'
                }}
                direction="column"
              >
                <Stack sx={{ display: 'flex', justifyContent: 'space-between' }} direction="row">
                  <Typography>Transferrable</Typography>
                  <Icon
                    onClick={handleClickTransferrable}
                    icon="akar-icons:chevron-down"
                    style={{ cursor: 'pointer' }}
                  />
                </Stack>
                <Collapse in={openTranferrable} timeout="auto" unmountOnExit>
                  <Transferrable />
                </Collapse>
              </Stack>
            </Grid>
            <Grid
              xs={12}
              sm={12}
              md={6}
              lg={7}
              sx={{
                justifyContent: 'center',
                marginTop: '20px'
              }}
            >
              <Stack
                direction="row"
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '80%',
                  marginX: 'auto'
                }}
              >
                <Stack direction="row">
                  <Icon icon="akar-icons:plus" width="22" />
                  <Icon icon="ant-design:minus-outlined" width="22" style={{ marginLeft: '5px' }} />
                </Stack>
                <Stack direction="row">
                  <Icon icon="icon-park-outline:preview-open" width="22" />
                  <Typography sx={{ marginLeft: '5px' }}>Preview</Typography>
                </Stack>
                <Stack direction="row">
                  <Icon icon="akar-icons:mobile-device" width="22" />
                  <Icon
                    icon="tabler:device-mobile-rotated"
                    width="22"
                    style={{ marginLeft: '5px' }}
                  />
                </Stack>
              </Stack>
              <img
                alt="no-apps"
                style={{ height: '300px', margin: 'auto', marginTop: '20px' }}
                src="/static/mock-images/intent_base_promotion.png"
              />
              <Typography sx={{ textAlign: 'center' }}>Golden Tickets</Typography>
            </Grid>
          </Grid>
        </TabPanel> */}
      </Container>
    </Page>
  );
}
