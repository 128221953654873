import React, { useEffect, useState } from 'react';
import {
  Container,
  Stack,
  Avatar,
  Typography,
  FormControlLabel,
  Switch,
  Grid
} from '@mui/material';
import coinsIcon from '@iconify/icons-fa-solid/coins';
import giftOutline from '@iconify/icons-eva/gift-outline';
import peopleGroup from '@iconify/icons-akar-icons/people-group';
import axios from 'axios';
import Page from '../Page';
import { RewardPoints } from './RewardPoints';
import { ReferralCards } from './ReferralCards';
import { StepperCard } from './StepperCard';
import PlayerReward from './PlayerReward';
import fetchReferralData from '../../utils/referralData';
import CenterSpinner from '../CenterSpinner';
import EditReferralRewardModal from './EditReferralRewardModal';
import fetchLevelsInfo from '../../utils/getOrgInfo';
import { translate } from '../_dashboard/app';

export default function Referral() {
  const [referral, setReferral] = useState({});
  const [referralInfo, setReferralInfo] = useState({});
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const language = localStorage.getItem('currentLanguage');

  const handleReferralActiveChange = async (event) => {
    setLoading(true);
    try {
      // eslint-disable-next-line
      const res = await axios.patch('org', { referral_active: event.target.checked });
      getData();
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const getData = async () => {
    setLoading(true);
    await fetchReferralData(setReferral);
    await fetchLevelsInfo(setReferralInfo);
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const {
    totalReferrals,
    totalRewardedVouchers,
    totalRewardedPoints,
    totalRewardedPointsInCash,
    currency,
    referralRules
  } = referral;

  const { referral_active: isActive } = referralInfo;

  if (loading) {
    return <CenterSpinner />;
  }

  const handleModalOpen = () => setOpen(true);

  return (
    <Page
      title={`${translate(language, 'Dashboard')} : ${translate(language, 'Program')} - ${translate(
        language,
        'Referrals'
      )}`}
    >
      <Container>
        {open && (
          <EditReferralRewardModal
            language={language}
            data={referralRules}
            getData={getData}
            open={open}
            setOpen={setOpen}
          />
        )}
        <Stack direction="row" justifyContent="space-between" mb={5}>
          <div style={{ display: 'flex', justifyContent: 'space-between', width: '16%' }}>
            <Avatar
              sx={{ width: 56, height: 56 }}
              alt=" "
              src="/static/mock-images/avatars/referrals.png"
            />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Typography style={{ marginBottom: '-5px' }} variant="h4" gutterBottom>
                {translate(language, 'Referrals')}
              </Typography>
              <Typography color={isActive ? '#00AB55' : 'error'} variant="button" gutterBottom>
                {isActive ? translate(language, 'ACTIVE') : translate(language, 'INACTIVE')}
              </Typography>
            </div>
          </div>
          <FormControlLabel
            control={<Switch onChange={handleReferralActiveChange} checked={Boolean(isActive)} />}
            label=""
          />
        </Stack>
        <ReferralCards
          CardTitle={translate(language, 'Total Referrals')}
          CardIcon={peopleGroup}
          CardValue={totalReferrals}
        />
        <Grid container spacing={3} marginY={3}>
          <Grid item xs={6}>
            <ReferralCards
              CardTitle={translate(language, 'Total Rewarded Vouchers')}
              CardIcon={giftOutline}
              CardValue={totalRewardedVouchers}
            />
          </Grid>
          <Grid item xs={6}>
            <RewardPoints
              CardTitle={translate(language, 'Total Rewarded Points')}
              CardIcon={coinsIcon}
              CardValue={totalRewardedPoints}
              CardCaption={totalRewardedPointsInCash}
            />
          </Grid>
          <Grid item xs={6}>
            <StepperCard language={language} />
          </Grid>
          <Grid item xs={6}>
            <PlayerReward
              language={language}
              CardTitle={`${referralRules?.points} ${
                referralRules?.points > referralRules?.mileStoneValue
                  ? translate(language, 'points for every')
                  : translate(language, 'point for every')
              } ${referralRules?.mileStoneValue} ${currency}`}
              CardIcon={coinsIcon}
              handleModalOpen={handleModalOpen}
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
