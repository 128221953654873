import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { translate } from '../../components/_dashboard/app';

export default function SelectSmall() {
  const [language, setLanguage] = React.useState(localStorage.getItem('currentLanguage') || 'en');

  const handleChange = (event) => {
    setLanguage(event.target.value);
    window.location.reload();
  };

  React.useEffect(() => {
    localStorage.setItem('currentLanguage', String(language));
    console.log('Lang changed...');
  }, [language]);

  return (
    <FormControl sx={{ minWidth: 140 }} size="small">
      <InputLabel id="demo-select-small">{translate(language, 'Language')}</InputLabel>
      <Select value={language} label={translate(language, 'Language')} onChange={handleChange}>
        <MenuItem value="en">
          <div style={{ display: 'flex' }}>
            <img src="/static/icons/ic_flag_en.svg" alt="English_Logo" />
            <div style={{ paddingLeft: '5px' }}>EN: ENGLISH</div>
          </div>
        </MenuItem>
        <MenuItem value="bg">
          <div style={{ display: 'flex' }}>
            <img src="/static/icons/ic_flag_bg.svg" alt="Bulgaria_Logo" />
            <div style={{ paddingLeft: '5px' }}>BG: BULGARIAN</div>
          </div>
        </MenuItem>
        <MenuItem value="hr">
          <div style={{ display: 'flex' }}>
            <img src="/static/icons/ic_flag_hr.svg" alt="Croatian_Logo" />
            <div style={{ paddingLeft: '5px' }}>HR: CROATIAN</div>
          </div>
        </MenuItem>
        <MenuItem value="cs">
          <div style={{ display: 'flex' }}>
            <img src="/static/icons/ic_flag_cs.svg" alt="Czech_Logo" />
            <div style={{ paddingLeft: '5px' }}>CS: CZECH</div>
          </div>
        </MenuItem>
        <MenuItem value="da">
          <div style={{ display: 'flex' }}>
            <img src="/static/icons/ic_flag_da.svg" alt="Danish_Logo" />
            <div style={{ paddingLeft: '5px' }}>DA: DANISH</div>
          </div>
        </MenuItem>
        <MenuItem value="nl">
          <div style={{ display: 'flex' }}>
            <img src="/static/icons/ic_flag_nl.svg" alt="Dutch_Logo" />
            <div style={{ paddingLeft: '5px' }}>NL: DUTCH</div>
          </div>
        </MenuItem>
        <MenuItem value="et">
          <div style={{ display: 'flex' }}>
            <img src="/static/icons/ic_flag_et.svg" alt="Estonian_Logo" />
            <div style={{ paddingLeft: '5px' }}>ET: ESTONIAN</div>
          </div>
        </MenuItem>
        <MenuItem value="fi">
          <div style={{ display: 'flex' }}>
            <img src="/static/icons/ic_flag_fi.svg" alt="Finnish_Logo" />
            <div style={{ paddingLeft: '5px' }}>FI: FINNISH</div>
          </div>
        </MenuItem>
        <MenuItem value="fr">
          <div style={{ display: 'flex' }}>
            <img src="/static/icons/ic_flag_fr.svg" alt="French_Logo" />
            <div style={{ paddingLeft: '5px' }}>FR: FRENCH</div>
          </div>
        </MenuItem>
        <MenuItem value="de">
          <div style={{ display: 'flex' }}>
            <img src="/static/icons/ic_flag_de.svg" alt="German_Logo" />
            <div style={{ paddingLeft: '5px' }}>DE: GERMAN</div>
          </div>
        </MenuItem>
        <MenuItem value="el">
          <div style={{ display: 'flex' }}>
            <img src="/static/icons/ic_flag_el.svg" alt="Greek_Logo" />
            <div style={{ paddingLeft: '5px' }}>EL: GREEK</div>
          </div>
        </MenuItem>
        <MenuItem value="gu">
          <div style={{ display: 'flex' }}>
            <img src="/static/icons/ic_flag_india.svg" alt="Gujarati_Logo" />
            <div style={{ paddingLeft: '5px' }}>GU: GUJARATI</div>
          </div>
        </MenuItem>
        <MenuItem value="hi">
          <div style={{ display: 'flex' }}>
            <img src="/static/icons/ic_flag_india.svg" alt="Hindi_Logo" />
            <div style={{ paddingLeft: '5px' }}>HI: HINDI</div>
          </div>
        </MenuItem>
        <MenuItem value="hu">
          <div style={{ display: 'flex' }}>
            <img src="/static/icons/ic_flag_hu.svg" alt="Hungarian_Logo" />
            <div style={{ paddingLeft: '5px' }}>HU: HUNGARIAN</div>
          </div>
        </MenuItem>
        <MenuItem value="ind">
          <div style={{ display: 'flex' }}>
            <img src="/static/icons/ic_flag_in.svg" alt="Indonesian_Logo" />
            <div style={{ paddingLeft: '5px' }}>IN: INDONESIAN</div>
          </div>
        </MenuItem>
        <MenuItem value="ga">
          <div style={{ display: 'flex' }}>
            <img src="/static/icons/ic_flag_ga.svg" alt="Irish_Logo" />
            <div style={{ paddingLeft: '5px' }}>GA: IRISH</div>
          </div>
        </MenuItem>
        <MenuItem value="it">
          <div style={{ display: 'flex' }}>
            <img src="/static/icons/ic_flag_it.svg" alt="Italian_Logo" />
            <div style={{ paddingLeft: '5px' }}>IT: ITALIAN</div>
          </div>
        </MenuItem>
        <MenuItem value="ka">
          <div style={{ display: 'flex' }}>
            <img src="/static/icons/ic_flag_india.svg" alt="Kannada_Logo" />
            <div style={{ paddingLeft: '5px' }}>KA: KANNADA</div>
          </div>
        </MenuItem>
        <MenuItem value="lv">
          <div style={{ display: 'flex' }}>
            <img src="/static/icons/ic_flag_lv.svg" alt="Latvian_Logo" />
            <div style={{ paddingLeft: '5px' }}>LV: LATVIAN</div>
          </div>
        </MenuItem>
        <MenuItem value="lt">
          <div style={{ display: 'flex' }}>
            <img src="/static/icons/ic_flag_lt.svg" alt="Lithuanian_Logo" />
            <div style={{ paddingLeft: '5px' }}>LT: LITHUANIAN</div>
          </div>
        </MenuItem>
        <MenuItem value="ms">
          <div style={{ display: 'flex' }}>
            <img src="/static/icons/ic_flag_ms.svg" alt="Malaysian_Logo" />
            <div style={{ paddingLeft: '5px' }}>MS: MALAYSIAN</div>
          </div>
        </MenuItem>
        <MenuItem value="mt">
          <div style={{ display: 'flex' }}>
            <img src="/static/icons/ic_flag_mt.svg" alt="Maltese_Logo" />
            <div style={{ paddingLeft: '5px' }}>MT: MALTESE</div>
          </div>
        </MenuItem>
        <MenuItem value="ml">
          <div style={{ display: 'flex' }}>
            <img src="/static/icons/ic_flag_india.svg" alt="Malayalam_Logo" />
            <div style={{ paddingLeft: '5px' }}>ML: MALAYALAM</div>
          </div>
        </MenuItem>
        <MenuItem value="ma">
          <div style={{ display: 'flex' }}>
            <img src="/static/icons/ic_flag_india.svg" alt="Marathi_Logo" />
            <div style={{ paddingLeft: '5px' }}>MA: MARATHI</div>
          </div>
        </MenuItem>
        <MenuItem value="pol">
          <div style={{ display: 'flex' }}>
            <img src="/static/icons/ic_flag_pol.svg" alt="Polish_Logo" />
            <div style={{ paddingLeft: '5px' }}>POL: POLISH</div>
          </div>
        </MenuItem>
        <MenuItem value="pt">
          <div style={{ display: 'flex' }}>
            <img src="/static/icons/ic_flag_pt.svg" alt="Portuguese_Logo" />
            <div style={{ paddingLeft: '5px' }}>PT: PORTUGUESE</div>
          </div>
        </MenuItem>
        <MenuItem value="ro">
          <div style={{ display: 'flex' }}>
            <img src="/static/icons/ic_flag_ro.svg" alt="Romanian_Logo" />
            <div style={{ paddingLeft: '5px' }}>RO: ROMANIAN</div>
          </div>
        </MenuItem>
        <MenuItem value="ru">
          <div style={{ display: 'flex' }}>
            <img src="/static/icons/ic_flag_ru.svg" alt="Russian_Logo" />
            <div style={{ paddingLeft: '5px' }}>RU: RUSSIAN</div>
          </div>
        </MenuItem>
        <MenuItem value="sk">
          <div style={{ display: 'flex' }}>
            <img src="/static/icons/ic_flag_sk.svg" alt="Slovak_Logo" />
            <div style={{ paddingLeft: '5px' }}>SK: SLOVAK</div>
          </div>
        </MenuItem>
        <MenuItem value="sl">
          <div style={{ display: 'flex' }}>
            <img src="/static/icons/ic_flag_sl.svg" alt="Slovenian_Logo" />
            <div style={{ paddingLeft: '5px' }}>SL: SLOVENIAN</div>
          </div>
        </MenuItem>
        <MenuItem value="es">
          <div style={{ display: 'flex' }}>
            <img src="/static/icons/ic_flag_es.svg" alt="Spanish_Logo" />
            <div style={{ paddingLeft: '5px' }}>ES: SPANISH</div>
          </div>
        </MenuItem>
        <MenuItem value="sv">
          <div style={{ display: 'flex' }}>
            <img src="/static/icons/ic_flag_sv.svg" alt="Swedish_Logo" />
            <div style={{ paddingLeft: '5px' }}>SV: SWEDISH</div>
          </div>
        </MenuItem>
        <MenuItem value="ta">
          <div style={{ display: 'flex' }}>
            <img src="/static/icons/ic_flag_india.svg" alt="Tamil_Logo" />
            <div style={{ paddingLeft: '5px' }}>TA: TAMIL</div>
          </div>
        </MenuItem>
      </Select>
    </FormControl>
  );
}
