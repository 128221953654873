import { useState } from 'react';
import { Container } from '@mui/material';
import Page from '../Page';
import EvolveLogo from './icons/EvolveLogo';
import QnA from './QnA/QnA';
import Home from './Home';
import WidgetSetUp from './WidgetSetUp';
import Video from './Video';
import SeeSetup from './SeeSetup';

const OnBoarding = () => {
  const [page, setPage] = useState('home');

  return (
    <Page title="OnBoarding">
      <Container>
        <div
          style={{
            margin: '15px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <EvolveLogo />
          {page === 'home' && <Home setPage={setPage} />}
          {page === 'qna' && <QnA setPage={setPage} />}
          {page === 'setup' && <WidgetSetUp setPage={setPage} />}
          {page === 'video' && <Video setPage={setPage} />}
          {page === 'see-setup' && <SeeSetup />}
        </div>
      </Container>
    </Page>
  );
};

export default OnBoarding;
