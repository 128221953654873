import { Card, CardContent, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import lightOrDark from '../../utils/lightOrDark';
import Arrow from './icons/Arrow';
import Coins from './icons/Coins';
import Dollar from './icons/Dollar';
import Redeem from './icons/Redeem';

const WidgetPreview = ({ color, widgetName, rewardsName }) => (
  <>
    <Card style={{ width: '300px', height: '500px' }}>
      <CardContent
        style={{
          height: '150px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: color,
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px'
        }}
      >
        <Typography color={`${lightOrDark(color) === 'light' ? '#000' : '#fff'}`}>
          Welcome to
        </Typography>
        <Typography color={`${lightOrDark(color) === 'light' ? '#000' : '#fff'}`}>
          {widgetName || 'Rewards'} Program
        </Typography>
      </CardContent>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          margin: '16px'
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <Coins />
          <Typography paddingLeft={1}>{rewardsName || 'Points'}</Typography>
        </div>
        <Typography>350</Typography>
      </div>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
          gap: '20px',
          marginTop: '20px'
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
            borderRadius: '10px',
            paddingY: '20px'
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <Dollar />
            <Typography paddingLeft={1}>Earn</Typography>
          </div>
          <Arrow />
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
            paddingY: '20px'
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <Redeem />
            <Typography paddingLeft={1}>Redeem</Typography>
          </div>
          <Arrow />
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-around',
          borderRadius: '10px',
          padding: '25px 10px',
          marginTop: '20px',
          alignItems: 'center'
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Typography style={{ fontSize: '18px', fontWeight: '400' }}>Levels</Typography>
          <Typography style={{ fontSize: '12px', paddingTop: '8px', width: '200px' }}>
            Earn {rewardsName || 'Points'} as we go a level up by winning different challenges!
          </Typography>
        </div>
        <Arrow />
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-around',
          borderRadius: '10px',
          paddingTop: '10px',
          alignItems: 'center'
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Typography style={{ fontSize: '18px', fontWeight: '400' }}>Refer and earn</Typography>
          <Typography style={{ fontSize: '12px', paddingTop: '8px' }}>
            Refer us to your friends and win <br />
            rewards!
          </Typography>
        </div>
        <Arrow />
      </div>
    </Card>
  </>
);

export default WidgetPreview;

WidgetPreview.propTypes = {
  color: PropTypes.string,
  widgetName: PropTypes.string,
  rewardsName: PropTypes.string
};
