import { DateRangePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Stack, TextField, Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { translate } from './_dashboard/app';

export default function DatePickerWithTitle({ title, language }) {
  const [value, setValue] = useState([null, null]);

  return (
    <Stack mb={5} mt={5} direction="row" alignItems="center" justifyContent="space-between">
      <Typography variant="h6">{title}</Typography>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DateRangePicker
          startText={translate(language, 'Start date')}
          endText={translate(language, 'End date')}
          value={value}
          onChange={(newValue) => {
            setValue(newValue);
          }}
          renderInput={(startProps, endProps) => (
            <>
              <TextField {...startProps} />
              <Box sx={{ mx: 2 }}> {translate(language, 'to')} </Box>
              <TextField {...endProps} />
            </>
          )}
        />
      </LocalizationProvider>
    </Stack>
  );
}

DatePickerWithTitle.propTypes = {
  title: PropTypes.string,
  language: PropTypes.element
};
