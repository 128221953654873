import { Container, Skeleton } from '@mui/material';

const skeletonStyle = {
  marginTop: '50px',
  marginBottom: '50px',
  borderRadius: '16px'
};

export default function ProgramSkeleton() {
  return (
    <>
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Skeleton variant="text" height={60} width={150} />
        <Skeleton variant="text" height={30} width={850} />
      </Container>
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <Skeleton variant="rectangular" height={280} width={480} style={skeletonStyle} />
        <Skeleton variant="rectangular" height={280} width={480} style={skeletonStyle} />
        <Skeleton variant="rectangular" height={280} width={480} style={skeletonStyle} />
        <Skeleton variant="rectangular" height={280} width={480} style={skeletonStyle} />
      </Container>
    </>
  );
}
