import React from 'react';
import { Select, MenuItem, Grid, CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import akarCoin from '@iconify/icons-akar-icons/coin';
import biCoin from '@iconify/icons-bi/coin';
import coinF from '@iconify/icons-jam/coin-f';
import coinIcon from '@iconify/icons-jam/coin';

export default function CoinIcon({ getFieldProps }) {
  const { value } = getFieldProps('coin_icon_name');

  if (!value) {
    return <CircularProgress />;
  }
  return (
    <Grid>
      <Select align="left" fullWidth {...getFieldProps('coin_icon_name')}>
        <MenuItem fullWidth required value="Icon 0">
          <Icon inline fontSize={20} icon={biCoin} />
          &nbsp;&nbsp;Coinbase Icon
        </MenuItem>
        <MenuItem fullWidth required value="Icon 1">
          <Icon inline fontSize={20} icon={akarCoin} /> &nbsp;&nbsp;Coinbase-one
        </MenuItem>
        <MenuItem fullWidth required value="Icon 2">
          <Icon inline fontSize={20} icon={coinF} /> &nbsp;&nbsp;Coinbase-two
        </MenuItem>
        <MenuItem fullWidth required value="Icon 3">
          <Icon inline fontSize={20} icon={coinIcon} /> &nbsp;&nbsp;Coinbase-three
        </MenuItem>
      </Select>
    </Grid>
  );
}

CoinIcon.propTypes = {
  getFieldProps: PropTypes.func
};
