import React from 'react';
import { Grid, Typography } from '@mui/material';
import { PropTypes } from 'prop-types';
import AccountIntegration from './AccountIntegration/AccountIntegration';
import MobileConfigurations from './MobileConfig/MobileConfigurations';
import Events from './Events/Events';
import { translate } from '../app';
import WordPressConfiguration from './WordPressConfig/WordPressConfiguration';

export default function IntegrationTab({ language }) {
  return (
    <div style={{ overflowX: 'hidden' }}>
      <Typography variant="h4" align="left" paddingTop={1}>
        {translate(language, 'Account Integration')}
      </Typography>
      <Grid paddingY={3} paddingX={2}>
        <AccountIntegration language={language} />
      </Grid>
      <Typography variant="h4" align="left" paddingTop={1}>
        {translate(language, 'Mobile Configurations')}
      </Typography>
      <Grid paddingY={3} paddingX={2}>
        <MobileConfigurations language={language} />
      </Grid>
      <Typography variant="h4" align="left" paddingTop={1}>
        {translate(language, 'SMS Configurations')}
      </Typography>
      <Grid paddingY={3} paddingX={2}>
        {translate(language, '---SMS Configurations Section---')}
      </Grid>
      <Typography variant="h4" align="left" paddingTop={1}>
        {translate(language, 'Events')}
      </Typography>
      <Grid paddingY={3} paddingX={2}>
        <Events />
      </Grid>
      <Typography variant="h4" align="left" paddingTop={1}>
        {translate(language, 'WordPress Configurations')}
      </Typography>
      <Grid paddingY={3} paddingX={2}>
        <WordPressConfiguration language={language} />
      </Grid>
    </div>
  );
}

IntegrationTab.propTypes = {
  language: PropTypes.element
};
