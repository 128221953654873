import React from 'react';
import { Container, Typography, styled, Stack, Paper } from '@mui/material';
import { useLocation } from 'react-router-dom';
import NotificationTabs from '../components/_dashboard/notifications/NotificationTabs';
import Page from '../components/Page';
import { translate } from '../components/_dashboard/app';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary
}));

export default function Notifications() {
  const language = localStorage.getItem('currentLanguage');
  const { state } = useLocation();
  return (
    <Page title={`${translate(language, 'Dashboard')}: ${translate(language, 'Notifications')}`}>
      <Container>
        <Stack direction="column" justifyContent="space-between" mb={4}>
          <Stack direction="col" alignItems="center">
            <Typography variant="h4" gutterBottom>
              {translate(language, 'Notifications')}
            </Typography>
          </Stack>
          <p>
            {translate(
              language,
              'Humans love appreciation, through notifications, you will make sure your players are recognized properly upon their achievements and get real time notifications!'
            )}
          </p>
        </Stack>
      </Container>
      <Container>
        <Item>
          <NotificationTabs style={{ postion: 'sticky' }} language={language} id={state} />
        </Item>
      </Container>
    </Page>
  );
}
