const Custom = () => (
  <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 12.8889H12.8889V0H0V12.8889ZM3.22222 3.22222H9.66667V9.66667H3.22222V3.22222ZM16.1111 0V12.8889H29V0H16.1111ZM25.7778 9.66667H19.3333V3.22222H25.7778V9.66667ZM0 29H12.8889V16.1111H0V29ZM3.22222 19.3333H9.66667V25.7778H3.22222V19.3333ZM24.1667 16.1111H20.9444V20.9444H16.1111V24.1667H20.9444V29H24.1667V24.1667H29V20.9444H24.1667V16.1111Z"
      fill="#428BC1"
    />
  </svg>
);

export default Custom;
