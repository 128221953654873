import React from 'react';
import { Stack, Grid, FormControl, OutlinedInput, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import CoinIcon from './CoinIcon';
import { translate } from '../app';

export default function General({ language, getFieldProps }) {
  return (
    <div>
      <Grid>
        <Stack spacing={-1.7} paddingTop={1} paddingBottom={2}>
          <Typography gutterBottom variant="h5" align="left">
            {translate(language, 'Program Name')}
          </Typography>
          <Typography gutterBottom variant="subtitle2" align="left" paddingTop={1}>
            {translate(language, 'Preferred name for Gamify Widget')}
          </Typography>
        </Stack>
        <FormControl variant="outlined" fullWidth>
          <OutlinedInput
            {...getFieldProps('widget_name')}
            placeholder={translate(language, 'Program Name')}
          />
        </FormControl>
      </Grid>
      <Grid>
        <Stack spacing={-1.7} paddingTop={2} paddingBottom={2}>
          <Typography gutterBottom variant="h5" align="left">
            {translate(language, 'Points Name')}
          </Typography>
          <Typography gutterBottom variant="subtitle2" align="left" paddingTop={1}>
            {translate(language, 'Enter a name for your player points')}
          </Typography>
        </Stack>
        <FormControl variant="outlined" fullWidth>
          <OutlinedInput
            {...getFieldProps('points_name')}
            placeholder={translate(language, 'Points')}
          />
        </FormControl>
      </Grid>
      <Grid>
        <Stack spacing={-1.7} paddingTop={2} paddingBottom={2}>
          <Typography gutterBottom variant="h5" align="left">
            {translate(language, 'Icon Name')}
          </Typography>
          <Typography gutterBottom variant="subtitle2" align="left" paddingTop={1}>
            {translate(language, 'Choose a suitable icon for your player points')}
          </Typography>
        </Stack>
      </Grid>
      <FormControl variant="outlined" fullWidth>
        <Grid>
          <CoinIcon getFieldProps={getFieldProps} />
        </Grid>
      </FormControl>
    </div>
  );
}
General.propTypes = {
  getFieldProps: PropTypes.func,
  setFieldValue: PropTypes.func,
  language: PropTypes.element
};
