import { Grid, Typography } from '@mui/material';
import { PropTypes } from 'prop-types';
import DatePickerWithTitle from '../DatePickerWithTitle';
import { AppWebsiteVisits, translate, AppWeeklySales } from '../_dashboard/app';

export default function Levels({ language }) {
  return (
    <>
      <DatePickerWithTitle language={language} title={translate(language, 'Levels')} />
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12}>
          <AppWeeklySales
            language={language}
            points="0"
            title={translate(language, 'Number of Levels')}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <AppWeeklySales
            language={language}
            points="0"
            title={translate(language, 'Total Accumulated Points')}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <AppWeeklySales
            language={language}
            points="0 INR"
            title={translate(language, 'Total Value of Accumulated Points')}
          />
        </Grid>
      </Grid>
      <Typography mt={5} mb={5} variant="h6">
        {translate(language, 'Detailed Analytics')}
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12}>
          <AppWebsiteVisits
            sub={translate(language, 'The current number of players per level')}
            title={translate(language, 'Players per level')}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <AppWebsiteVisits
            sub={translate(language, 'Number of challenges with status `Active` per level')}
            title={translate(language, 'Number of Active Challenges per Level')}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <AppWebsiteVisits
            sub={translate(
              language,
              'Points redeemed and rewarded per level within the selected period'
            )}
            title={translate(language, 'Performance per Level')}
          />
        </Grid>
      </Grid>
    </>
  );
}

Levels.propTypes = {
  language: PropTypes.element
};
