import React from 'react';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { translate } from '../app';

export default function GuestMessagePage({ language }) {
  return (
    <div>
      <Typography variant="h6" color="rgb(20,20,20)" align="left" paddingTop={1} paddingBottom={1}>
        {translate(language, 'This the default notification for Guest Users!')}
      </Typography>
    </div>
  );
}

GuestMessagePage.propTypes = {
  language: PropTypes.element
};
