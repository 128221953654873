/* eslint-disable */
import React, { useEffect } from 'react';
import { Typography } from '@mui/material';
import './ScratchCard.css';

function ScratchCard() {
  const scripts = () => {
    let bridge = document.getElementById('bridge'),
      bridgeCanvas = bridge.getContext('2d'),
      brushRadius = (bridge.width / 100) * 5,
      img = new Image();

    if (brushRadius < 50) {
      brushRadius = 20;
    }

    img.onload = function () {
      bridgeCanvas.drawImage(img, 0, 0, bridge.width, bridge.height);
    };
    img.loc = 'https://gsaas-getmok.s3.ap-south-1.amazonaws.com/default/';
    img.filename = 'file-1659946834998.png';
    if (window.devicePixelRatio >= 2) {
      let nameParts = img.filename.split('.');
      img.src = img.loc + nameParts[0] + '-2x' + '.' + nameParts[1];
    } else {
      img.src = img.loc + img.filename;
    }

    function detectLeftButton(event) {
      if ('buttons' in event) {
        return event.buttons === 1;
      } else if ('which' in event) {
        return event.which === 1;
      } else {
        return event.button === 1;
      }
    }

    function getBrushPos(xRef, yRef) {
      let bridgeRect = bridge.getBoundingClientRect();
      return {
        x: Math.floor(
          ((xRef - bridgeRect.left) / (bridgeRect.right - bridgeRect.left)) * bridge.width
        ),
        y: Math.floor(
          ((yRef - bridgeRect.top) / (bridgeRect.bottom - bridgeRect.top)) * bridge.height
        )
      };
    }

    function drawDot(mouseX, mouseY) {
      bridgeCanvas.beginPath();
      bridgeCanvas.arc(mouseX, mouseY, brushRadius, 0, 2 * Math.PI, true);
      bridgeCanvas.fillStyle = '#000';
      bridgeCanvas.globalCompositeOperation = 'destination-out';
      bridgeCanvas.fill();
    }

    bridge.addEventListener(
      'mousemove',
      function (e) {
        let brushPos = getBrushPos(e.clientX, e.clientY);
        let leftBut = detectLeftButton(e);
        if (leftBut == 1) {
          drawDot(brushPos.x, brushPos.y);
        }
      },
      false
    );

    bridge.addEventListener(
      'touchmove',
      function (e) {
        e.preventDefault();
        let touch = e.targetTouches[0];
        if (touch) {
          let brushPos = getBrushPos(touch.pageX, touch.pageY);
          drawDot(brushPos.x, brushPos.y);
        }
      },
      false
    );
  };

  useEffect(() => {
    scripts();
  }, []);

  return (
    <>
      <Typography
        style={{
          position: 'absolute',
          fontSize: '46px',
          fontWeight: '600',
          textAlign: 'center',
          lineHeight: '65px',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%,-50%)',
          width: '900px',
          marginTop: '40px',
          height: '150px'
        }}
      >
        CUSTOMIZING THE PERFECT <wbr /> SOLUTION FOR YOUR BRAND
      </Typography>
      <figure
        style={{ width: '900px',position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%,-50%)' }}
        id="bridgeContainer"
      >
        <canvas id="bridge" width="548" height="200" />
      </figure>
    </>
  );
}

export default ScratchCard;
