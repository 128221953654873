import * as React from 'react';
import { Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import CardModal from './CradModal';
import { translate } from '../app';

export default function BrowserSection({ language }) {
  return (
    <div>
      <Typography variant="h6" color="rgb(20,20,20)" align="left" paddingTop={1} paddingBottom={1}>
        {translate(
          language,
          'This the default notification that appears to your players upon receiving a cashback reward!'
        )}
      </Typography>
      <Typography variant="h4" align="left">
        {translate(language, 'Cashback')}
      </Typography>
      <Grid
        mb={0.5}
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'left',
          '& > :not(style)': {
            m: 3
          }
        }}
      >
        <Grid item xs={12} sm>
          <CardModal
            language={language}
            title={translate(language, 'Cashback Referral')}
            about={translate(language, 'All about cashback referral')}
            modalHeading={translate(language, 'Cashback Referral')}
            bodyText=""
            headingText=""
          />
        </Grid>
        <Grid item xs={12} sm>
          <CardModal
            language={language}
            title={translate(language, 'Cashback Notification')}
            about={translate(language, 'All about cashback notifications')}
            modalHeading={translate(language, 'Cashback Notifications')}
            bodyText=""
            headingText=""
          />
        </Grid>
      </Grid>
      <Typography variant="h4" align="left">
        {translate(language, 'Referrals')}
      </Typography>
      <Grid
        mb={0.5}
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'left',
          '& > :not(style)': {
            m: 3
          }
        }}
      >
        <Grid item xs={12} sm>
          <CardModal
            language={language}
            title={translate(language, 'Referrals')}
            about={translate(language, 'All about Referrals')}
            modalHeading={translate(language, 'Referrals')}
            bodyText=""
            HeadingText=""
          />
        </Grid>
      </Grid>
      <Typography variant="h4" align="left">
        {translate(language, 'Challenges')}
      </Typography>
      <Grid
        mb={0.5}
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'left',
          '& > :not(style)': {
            m: 3
          }
        }}
      >
        <Grid item xs={12} sm>
          <CardModal
            language={language}
            title={translate(language, 'Challenges')}
            about={translate(language, 'All about Challenges')}
            modalHeading={translate(language, 'Challenges')}
            bodyText=""
            headingText=""
          />
        </Grid>
      </Grid>
      <Typography variant="h4" align="left">
        {translate(language, 'Levels')}
      </Typography>
      <Grid
        mb={0.5}
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'left',
          '& > :not(style)': {
            m: 3
          }
        }}
      >
        <Grid item xs={12} sm>
          <CardModal
            language={language}
            title={translate(language, 'Levels')}
            about={translate(language, 'All about levels')}
            modalHeading={translate(language, 'Levels')}
            bodyText=""
            headingText=""
          />
        </Grid>
      </Grid>
    </div>
  );
}

BrowserSection.propTypes = {
  language: PropTypes.element
};
