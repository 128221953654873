import { Typography, Card, IconButton, CardContent } from '@mui/material';
import { Icon } from '@iconify/react';
import editFill from '@iconify/icons-eva/edit-fill';
import PropTypes from 'prop-types';
import { translate } from '../_dashboard/app';

export default function HeaderCard({ CardIcon, handleHeaderModalOpen, language }) {
  return (
    <div>
      <Card
        style={{
          height: '100px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <CardContent style={{ width: '100%' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div style={{ display: 'flex', justifyContent: 'stretch', alignItems: 'center' }}>
              <Icon color="#e1b836" icon={CardIcon} width={40} height={40} />
              <Typography variant="body" paddingLeft={2}>
                {translate(language, 'Header and Sub-Header')}
              </Typography>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-evenly',
                alignItems: 'center'
              }}
            >
              <div>
                <IconButton
                  edge="end"
                  style={{ marginRight: '10px' }}
                  onClick={handleHeaderModalOpen}
                >
                  <Icon fontSize={25} icon={editFill} />
                </IconButton>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}

HeaderCard.propTypes = {
  CardIcon: PropTypes.object.isRequired,
  handleHeaderModalOpen: PropTypes.func,
  language: PropTypes.element
};
