import React from 'react';
import { CircularProgress } from '@mui/material';

const spinnerCenter = {
  height: '100vh',
  // width: '100vw',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
};

export default function CenterSpinner() {
  return (
    <div style={spinnerCenter}>
      <h1>
        <CircularProgress />
      </h1>
    </div>
  );
}
