import axios from 'axios';

const fetchLevelsData = async (setLevelsDetails, setBenefitsDetails, setSumPoints) => {
  try {
    const res = await axios.get('levels');
    if (setLevelsDetails) setLevelsDetails(res.data.levelsData);
    if (setBenefitsDetails) setBenefitsDetails(res.data.benefitsData);
    if (setSumPoints) setSumPoints(res.data.totalPoints);
  } catch (error) {
    console.log(error);
  }
};

export default fetchLevelsData;
