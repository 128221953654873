import React from 'react';
import { Typography, Card, IconButton, CardContent } from '@mui/material';
import PropTypes from 'prop-types';
import editFill from '@iconify/icons-eva/edit-fill';
import { Icon } from '@iconify/react';
import { translate } from '../_dashboard/app';

export default function PlayerReward({ CardTitle, CardIcon, handleModalOpen, language }) {
  return (
    <div>
      <Typography variant="h6" marginY={2}>
        {translate(language, 'Referral Reward Rules')}
      </Typography>

      <Card
        style={{
          marginTop: '5px',
          height: '100px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <CardContent style={{ width: '100%' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div style={{ display: 'flex', justifyContent: 'stretch', alignItems: 'center' }}>
              <Icon color="#e1b836" icon={CardIcon} width={40} height={40} />
              <Typography variant="body" paddingLeft={2}>
                {CardTitle}
              </Typography>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-evenl',
                alignItems: 'center'
              }}
            >
              <div>
                <IconButton edge="end" style={{ marginRight: '10px' }} onClick={handleModalOpen}>
                  <Icon fontSize={25} icon={editFill} />
                </IconButton>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}

PlayerReward.propTypes = {
  CardTitle: PropTypes.string.isRequired,
  CardIcon: PropTypes.string.isRequired,
  handleModalOpen: PropTypes.func,
  language: PropTypes.element
};
