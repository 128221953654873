import { Grid, Typography } from '@mui/material';
import { PropTypes } from 'prop-types';
import DatePickerWithTitle from '../DatePickerWithTitle';
import { AppWebsiteVisits, AppWeeklySales, translate } from '../_dashboard/app';

export default function Redemption({ language }) {
  return (
    <>
      <DatePickerWithTitle language={language} title={translate(language, 'Redemption')} />
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4}>
          <AppWeeklySales
            language={language}
            points="0"
            title={translate(language, 'Current Outstanding Points')}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <AppWeeklySales
            language={language}
            points="0"
            title={translate(language, 'Current value of Outstanding Points')}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <AppWeeklySales
            language={language}
            points="0 %"
            title={translate(language, 'Redemption Rate')}
          />
        </Grid>
      </Grid>
      <Typography mt={5} mb={5} variant="h6">
        {translate(language, 'Detailed Analytics')}
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={6}>
          <AppWeeklySales
            language={language}
            points="0"
            title={translate(language, 'Redemption Points')}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <AppWeeklySales
            language={language}
            points="0 INR"
            title={translate(language, 'Value of Redemption Points')}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <AppWeeklySales
            language={language}
            points="0"
            title={translate(language, 'Expired Points')}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <AppWeeklySales
            language={language}
            points="0"
            title={translate(language, 'Unique Players Redeemed')}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <AppWebsiteVisits
            sub={translate(
              language,
              'A comparison between the type of redemption and total redeened points'
            )}
            title={translate(language, 'Type of Redemption Vs Points Redeemed')}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <AppWebsiteVisits
            sub={translate(
              language,
              'The trend of the redemption behavior by your players in points'
            )}
            title={translate(language, 'Redemption Behavior Trend')}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <AppWebsiteVisits
            sub={translate(language, 'The daily redemption trend in points')}
            title={translate(language, 'Redemption Daily Trend')}
          />
        </Grid>
      </Grid>
    </>
  );
}

Redemption.propTypes = {
  language: PropTypes.element
};
