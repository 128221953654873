import { Typography, Box } from '@mui/material';

export default function PlayerPage() {
  return (
    <Box
      mt={0.5}
      mb={0.5}
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        '& > :not(style)': {
          m: 6,
          width: 400
        }
      }}
    >
      <img alt="no-apps" src="/static/mock-images/no-apps.png" />
      <Typography align="center" variant="h3" style={{ marginTop: '-15px' }}>
        No Player Messages
      </Typography>
    </Box>
  );
}
