/* eslint-disable */
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import axios from 'axios';
import {
  Backdrop,
  Box,
  Fade,
  MenuItem,
  InputAdornment,
  Modal,
  Stack,
  TextField
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import PropTypes from 'prop-types';
import ProductsSelect from '../master-products/products-select';
import { translate } from '../_dashboard/app';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4
};
const language= localStorage.getItem('currentLanguage');
const rows = [
  {
    value: 'freeShipping',
    label: translate(language, 'Free Shipping')
  },
  {
    value: 'discount',
    label: translate(language,'Discount')
  },
  {
    value: 'fixedCost',
    label: translate(language,'Fixed Cost')
  },
  {
    value: 'product',
    label: translate(language,'Product Discount')
  }
];

export default function AddWheelTabOptions({ open, currency, setOpen, getData }) {
  const handleClose = () => setOpen(false);

  const WheelSchema = Yup.object().shape({
    name: Yup.string().required(translate(language,'Name is Required')),
    chance: Yup.number().required(translate(language,'Chance is Required')).positive().max(100).min(1),
    couponType: Yup.string().required(translate(language,'Coupon Type is Required')),
    discount: Yup.number()
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      chance: 0,
      couponType: '',
      discount: 0,
      productIds: [],
      productLabels: []
    },
    enableReinitialize: true,
    validationSchema: WheelSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        if (values.couponType === 'product' && !values.productIds.length) {
          toast.error('Products are required if type if product discount');
          return;
        }
        const res = await axios.post('spinwheel', values);
        resetForm();
        getData();
        setOpen(false);
      } catch (error) {
        console.log(error);
      }
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, values, setFieldValue } = formik;

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack spacing={3}>
                  <TextField
                    fullWidth
                    label={translate(language,"Name")}
                    {...getFieldProps('name')}
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                  />
                  <TextField
                    fullWidth
                    label={translate(language,"Chance")}
                    type="number"
                    {...getFieldProps('chance')}
                    error={Boolean(touched.chance && errors.chance)}
                    helperText={touched.chance && errors.chance}
                  />
                  <TextField
                    select
                    label={translate(language,"Coupon Type")}
                    {...getFieldProps('couponType')}
                    error={Boolean(touched.couponType && errors.couponType)}
                    helperText={touched.couponType && errors.couponType}
                  >
                    {rows.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  {['discount', 'fixedCost', 'product'].includes(values.couponType) && (
                    <TextField
                      fullWidth
                      label={translate(language,"Discount Amount")}
                      type="number"
                      {...getFieldProps('discount')}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {['discount', 'product'].includes(values.couponType) ? '%' : currency}
                          </InputAdornment>
                        )
                      }}
                      error={Boolean(touched.discount && errors.discount)}
                      helperText={touched.discount && errors.discount}
                    />
                  )}
                  {values.couponType === 'product' && (
                    <ProductsSelect
                     language = {language}
                      value={values.productLabels}
                      handleChange={(e) => {
                        const option = JSON.parse(e.target.value[e.target.value.length - 1]);
                        const { prId, title } = option;
                        if (values.productLabels.includes(title)) {
                          setFieldValue(
                            'productIds',
                            values.productIds.filter((ids) => ids !== prId)
                          );
                          setFieldValue(
                            'productLabels',
                            values.productLabels.filter((text) => text !== title)
                          );
                        } else {
                          setFieldValue('productIds', [...values.productIds, prId]);
                          setFieldValue('productLabels', [...values.productLabels, title]);
                        }
                      }}
                    />
                  )}
                  <LoadingButton
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                  >
                    {translate(language,'Add Option')}
                  </LoadingButton>
                </Stack>
              </Form>
            </FormikProvider>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

AddWheelTabOptions.propTypes = {
  open: PropTypes.bool,
  currency: PropTypes.string,
  setOpen: PropTypes.func,
  getData: PropTypes.func
};
