import { Stack, TextField, Box } from '@mui/material';
import { DateRangePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import PropTypes from 'prop-types';
import { translate } from '../../_dashboard/app';

export default function DatePickerWithTitle({
  dateRange,
  setDateRange,
  dateError,
  setDateError,
  language
}) {
  const handleDateInput = (value) => {
    if (!value[0] || !value[1]) {
      if (!value[0]) setDateError([true, false]);
      else if (!value[1]) setDateError([false, true]);

      setDateRange(value);
      return;
    }

    setDateRange(value);
    setDateError([false, false]);
  };
  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between">
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DateRangePicker
          startText={translate(language, 'Start date')}
          endText={translate(language, 'End date')}
          value={dateRange}
          onChange={(newValue) => handleDateInput(newValue)}
          renderInput={(startProps, endProps) => (
            <>
              <TextField
                {...startProps}
                sx={{ width: '30%' }}
                error={dateError[0]}
                helperText={dateError[0] ? translate(language, 'Start Date is required') : ''}
              />
              <Box sx={{ mx: 2 }}> {translate(language, 'to')} </Box>
              <TextField
                {...endProps}
                sx={{ width: '30%' }}
                error={dateError[1]}
                helperText={dateError[1] ? translate(language, 'End Date is required') : ''}
              />
            </>
          )}
        />
      </LocalizationProvider>
    </Stack>
  );
}

DatePickerWithTitle.propTypes = {
  dateRange: PropTypes.array,
  setDateRange: PropTypes.func,
  dateError: PropTypes.array,
  setDateError: PropTypes.func,
  language: PropTypes.element
};
