import {
  Container,
  Typography,
  Stack,
  Avatar,
  Grid,
  Card,
  CardContent,
  IconButton
} from '@mui/material';
import { Icon } from '@iconify/react';
import editFill from '@iconify/icons-eva/edit-fill';
import coinsIcon from '@iconify/icons-fa-solid/coins';
import { useState, useEffect } from 'react';
import Page from '../Page';
import CenterSpinner from '../CenterSpinner';
import fetchOrgInfo from '../../utils/getOrgInfo';
import fetchLevelsData from '../../utils/getLevelsData';
import EditRewardsModal from './EditRewardsModal';
import { translate } from '../_dashboard/app';

/* eslint-disable camelcase */
export default function CashBack() {
  const [loading, setLoading] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [levelsInfo, setLevelsInfo] = useState({});
  const [levelsDetails, setLevelsDetails] = useState([]);
  const language = localStorage.getItem('currentLanguage');

  const getLevelsData = async () => {
    setLoading(true);
    await fetchLevelsData(setLevelsDetails);
    setLoading(false);
  };

  useEffect(() => {
    fetchOrgInfo(setLevelsInfo);
    getLevelsData();
  }, []);

  const { currency } = levelsInfo;
  const firstLevel = levelsDetails.filter((item) => item.min_points === 0)[0];

  if (loading) {
    return <CenterSpinner />;
  }

  return (
    <Page
      title={`${translate(language, 'Dashboard')}: ${translate(language, 'Programs')} - ${translate(
        language,
        'Cashback'
      )}`}
    >
      <Container>
        {openEdit && (
          <EditRewardsModal
            language={language}
            open={openEdit}
            setOpen={setOpenEdit}
            firstLevel={firstLevel}
            getData={getLevelsData}
          />
        )}
        <Stack direction="row" justifyContent="space-between" mb={5}>
          <div style={{ display: 'flex', justifyContent: 'space-between', width: '16%' }}>
            <Avatar
              sx={{ width: 56, height: 56 }}
              alt=" "
              src="/static/mock-images/avatars/Cashback.png"
            />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Typography style={{ marginBottom: '-5px' }} variant="h4" gutterBottom>
                {translate(language, 'Cashback')}
              </Typography>
            </div>
          </div>
        </Stack>
        <Grid item lg={6}>
          <Typography variant="h6">{translate(language, 'Reward Rules')}</Typography>
          <Card style={{ marginTop: '25px' }}>
            <CardContent>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div
                  style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                >
                  <Icon color="#e1b836" icon={coinsIcon} width={40} height={40} />
                  <div style={{ marginLeft: '20px' }}>
                    <Typography>
                      <strong>
                        {`${firstLevel?.points ?? 0} ${translate(language, 'point for every')} ${
                          firstLevel?.milestone_amount ?? 0
                        } ${currency}`}{' '}
                        {translate(language, 'spent')}
                      </strong>
                    </Typography>
                    <Typography variant="caption">
                      {translate(language, 'Equals to %s% cashback', [
                        Math.floor((firstLevel?.points / firstLevel?.milestone_amount) * 100) || 0
                      ])}
                    </Typography>
                  </div>
                </div>
                <IconButton onClick={() => setOpenEdit(true)}>
                  <Icon fontSize={25} icon={editFill} />
                </IconButton>
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Container>
    </Page>
  );
}
