import React, { useEffect, useState } from 'react';
import { Typography, Container, Grid } from '@mui/material';
import { FormikProvider, useFormik, Form } from 'formik';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import WidgetSettingsTab from '../components/_dashboard/Widget-Settings/WidgetSettingsTab';
import PlayerWidgetLanguage from '../components/_dashboard/Widget-Settings/PlayerWidgetLanguage';
import General from '../components/_dashboard/Widget-Settings/General';
import WidgetStyle from '../components/_dashboard/Widget-Settings/WidgetStyle';
import ApplyChangesButton from '../components/_dashboard/Widget-Settings/ApplyChangesButton';
import WidgetSkeleton from '../components/WidgetSkeleton';
import Page from '../components/Page';
import { translate } from '../components/_dashboard/app';

export default function WidgetSettings() {
  const [myInitialValues, setInititalValues] = useState({});
  const [loading, setLoading] = useState(false);
  const language = localStorage.getItem('currentLanguage');

  const formik = useFormik({
    initialValues: myInitialValues,
    enableReinitialize: true,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        // eslint-disable-next-line
        const req = await axios.patch("widget/settings", values);
        toast.success(translate(language, 'Successfully updated'));
        getFormInitialvalues();
      } catch (error) {
        console.log(error);
        toast.error(translate(language, 'Something went wrong!'));
      }
      setLoading(false);
    }
  });

  const getFormInitialvalues = async () => {
    setLoading(true);
    try {
      const res = await axios.get('widget/settings');
      setInititalValues(res.data.data);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getFormInitialvalues();
  }, []);

  const { dirty, setFieldValue, handleSubmit, getFieldProps } = formik;

  if (loading) {
    return <WidgetSkeleton />;
  }

  return (
    <div style={{ overflowX: 'hidden' }}>
      <Page
        title={`${translate(language, 'Dashboard')}: ${translate(language, 'Widget Settings')}`}
      >
        <ToastContainer
          position="bottom-right"
          autoClose={2500}
          newestOnTop
          closeOnClick
          pauseOnFocusLoss
          pauseOnHover
          limit={2}
        />
        <Container>
          <Typography variant="h4" gutterBottom>
            {translate(language, 'Settings')}
          </Typography>
          <p>
            {translate(
              language,
              'Great Design starts with great branding, customize your Gamify Widget look and feel below. You can easily pick the colors, widget icon, and much more.'
            )}
          </p>
        </Container>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Container>
              <Grid>
                <Typography variant="h3" pt={2}>
                  {translate(language, 'Widget')}
                </Typography>
                <div>
                  <WidgetSettingsTab
                    language={language}
                    getFieldProps={getFieldProps}
                    setFieldValue={setFieldValue}
                  />
                </div>
                <Typography variant="h3" pt={5}>
                  {translate(language, 'Basic Configurations')}
                </Typography>
                <div>
                  <PlayerWidgetLanguage />
                  <General
                    language={language}
                    getFieldProps={getFieldProps}
                    setFieldValue={setFieldValue}
                  />
                  <WidgetStyle
                    language={language}
                    getFieldProps={getFieldProps}
                    setFieldValue={setFieldValue}
                  />
                </div>
              </Grid>
              <br />
              {dirty && <ApplyChangesButton language={language} />}
            </Container>
          </Form>
        </FormikProvider>
      </Page>
    </div>
  );
}
