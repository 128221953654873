import { Container, Typography, Stack, Tab, Tabs, Box } from '@mui/material';
import PropTypes from 'prop-types';

import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
// import AchievementListTable from '../components/_dashboard/players/AchievmentListTable';
import Page from '../components/Page';
import PlayersListTable from '../components/_dashboard/players/PlayersListTable';
import { translate } from '../components/_dashboard/app';
import PointsListTable from '../components/_dashboard/players/PointsListTable';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

export default function Players() {
  const [value, setValue] = useState(0);
  const { state } = useLocation();
  const language = localStorage.getItem('currentLanguage');

  useEffect(() => {
    if (state !== null) setValue(state);
  }, [state]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Page
      title={`${translate(language, 'Dashboard')}: ${translate(language, 'Players')} | ${translate(
        language,
        'Gamify'
      )}`}
    >
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <div>
            <Typography variant="h4" gutterBottom>
              {translate(language, 'Players')}
            </Typography>
            <p>
              {translate(
                language,
                "At Evolve we call our customers 'player'. Here you can track your players, and see how active and engaged they are. You can also view their daily activities, transaction history and achievements. Explore the impact of Gamify experience."
              )}
            </p>
          </div>
        </Stack>

        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            allowScrollButtonsMobile
            aria-label="scrollable force tabs example"
          >
            <Tab label={translate(language, 'Players')} {...a11yProps(0)} />
            <Tab label={translate(language, 'Points Transaction')} {...a11yProps(1)} />
            {/* <Tab label={translate(language,"Program Achievement")} {...a11yProps(2)} /> */}
          </Tabs>
        </Box>

        <TabPanel value={value} index={0}>
          <PlayersListTable language={language} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <PointsListTable language={language} />
        </TabPanel>
        {/* <TabPanel value={value} index={2}>
          <AchievementListTable language={language}/>
        </TabPanel> */}
      </Container>
    </Page>
  );
}
