import {
  en,
  hi,
  fr,
  ta,
  gu,
  ru,
  ma,
  bg,
  cs,
  da,
  de,
  el,
  es,
  et,
  fi,
  ga,
  hr,
  hu,
  ind,
  it,
  kn,
  lv,
  lt,
  ml,
  ms,
  mt,
  nl,
  pol,
  pt,
  ro,
  sk,
  sl,
  sv
} from '../../../languages';

const translation = {
  en,
  hi,
  fr,
  ta,
  gu,
  ml,
  ru,
  ma,
  bg,
  cs,
  da,
  de,
  el,
  es,
  et,
  fi,
  ga,
  hr,
  hu,
  ind,
  it,
  kn,
  lv,
  lt,
  ms,
  mt,
  nl,
  pol,
  pt,
  ro,
  sk,
  sl,
  sv
};

/**
 * @param {string} lang - language code
 * @param {string} key - message key
 * @param {string[] | number[]} vars - optional variables in sequence in an array
 */

export default (lang, key, vars = []) => {
  if (!translation[lang] || !translation[lang][key]) {
    console.log('No translation for', lang, key);
    return key;
  }
  return convertString(translation[lang][key], [...vars]);
};

function convertString(string, vars) {
  let out = string;
  vars.forEach((item) => (out = out.replace('%s', item)));
  return out;
}
