/* eslint-disable */
import { Button, Typography } from '@mui/material';
import { useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';

import Back from '../icons/Back';
import Fwd from '../icons/Fwd';

import QnA1 from './QnA1';
import QnA2 from './QnA2';
import QnA3 from './QnA3';

const QnA = ({ setPage }) => {
  const [questionNum, setQuestionNum] = useState(0);
  const [answers, setAnswers] = useState({});

  const handleSubmit = async () => {
    try {
      // eslint-disable-next-line
      const req = await axios.patch('org', {
        qa_monthly_active_users: answers[1],
        qa_product_category: answers[2],
        qa_business_goals: answers[3].join(',')
      });
      
      setPage('setup')
    } catch (error) {
      console.log(error);
    }
  }

  const questions = [
    { key: 0, jsx: <QnA1 answers={answers} setAnswers={setAnswers} setQuestionNum={setQuestionNum} /> },
    { key: 1, jsx: <QnA2 answers={answers} setAnswers={setAnswers} setQuestionNum={setQuestionNum} /> },
    { key: 2, jsx: <QnA3 answers={answers} setAnswers={setAnswers} /> }
  ];

  const handleClick = (flag) => {
    if (flag === 'back') setQuestionNum((prev) => prev - 1);
    if (flag === 'fwd') setQuestionNum((prev) => prev + 1);
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-between',
          height: '80vh',
          width: '900px'
        }}
      >
        <Typography style={{ fontSize: '44px', fontWeight: '600', textAlign: 'center' }}>
          Tell us about your e-commerce so we can <wbr />
          <span style={{ color: '#00AB55' }}>personalise</span> the experience for you!
        </Typography>
        <div
          style={{
            width: '60vw',
            display: 'flex',
            alignContent: 'center',
            justifyContent: 'space-around',
            margin: '30px 0px'
          }}
        >
          <Button disabled={questionNum === 0} onClick={() => handleClick('back')}>
            <Back color={questionNum === 0 ? '#808080' : '#000'} />
          </Button>
          {questions[questionNum].jsx}
          <Button disabled={questionNum === 2} onClick={() => handleClick('fwd')}>
            <Fwd color={questionNum === 2 ? '#808080' : '#000'} />
          </Button>
        </div>
        {questionNum === 2 ? (
          <Button
            onClick={handleSubmit}
            variant="contained"
            sx={{ paddingX: '55px', paddingY: '8px', fontSize: '18px' }}
          >
            Next Step
          </Button>
        ) : (
          <div style={{ height: '50px' }} />
        )}
      </div>
    </>
  );
};

export default QnA;

QnA.propTypes = {
  setPage: PropTypes.func
};
