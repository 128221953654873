import React from 'react';
import { Icon } from '@iconify/react';
import { Typography, Card, CardContent, Grid } from '@mui/material';
import PropTypes from 'prop-types';

export function RewardPoints({ CardTitle, CardIcon, CardValue, CardCaption }) {
  return (
    <div>
      <Card style={{ height: '100px' }}>
        <CardContent>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div style={{ display: 'flex', justifyContent: 'stretch', alignItems: 'center' }}>
              <Icon color="#e1b836" icon={CardIcon} width={40} height={40} />
              <Typography variant="body" paddingLeft={2}>
                {CardTitle}
              </Typography>
            </div>
            <Grid item xs={12} sm container>
              <Grid item xs container direction="column" spacing={2}>
                <Grid item xs>
                  <Typography align="right" variant="h4">
                    {CardValue}
                  </Typography>
                </Grid>
                <Typography align="right" variant="caption">
                  {CardCaption}
                </Typography>
              </Grid>
            </Grid>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}

RewardPoints.propTypes = {
  CardTitle: PropTypes.string.isRequired,
  CardIcon: PropTypes.string.isRequired,
  CardValue: PropTypes.number.isRequired,
  CardCaption: PropTypes.any
};
