import { useState, useEffect, useRef } from 'react';
import { Card, CardContent, Checkbox, Typography } from '@mui/material';
import PropTypes from 'prop-types';

export default function QnA3({ answers, setAnswers }) {
  const [selectedAnswer, setSelectedAnswer] = useState([]);

  const options = [
    {
      optionName: 'Establish a niche with the target market',
      optionVal: 'establish',
      isSelected: false
    },
    {
      optionName: 'Company growth (Employee strength)',
      optionVal: 'growth',
      isSelected: false
    },
    {
      optionName: 'Expand your product line',
      optionVal: 'expand',
      isSelected: false
    }
  ];

  const handleChange = (item, event) => {
    item.isSelected = event.target.checked;

    if (event.target.checked) setSelectedAnswer((prev) => (prev = [...prev, item.optionVal]));
    else setSelectedAnswer((prev) => (prev = prev.filter((elem) => elem !== item.optionVal)));
  };

  const firstUpdate = useRef(true);
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    setAnswers((prev) => (prev = { ...prev, 3: selectedAnswer }));
    // eslint-disable-next-line
  }, [selectedAnswer]);

  return (
    <Card>
      <CardContent style={{ margin: '15px', width: '40vw' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '25px' }}>
          <Typography>What is your business goals right now?</Typography>
          <Typography>(3/3)</Typography>
        </div>
        {options.map((item, index) => (
          <div key={index}>
            <Checkbox
              onChange={(event) => handleChange(item, event)}
              checked={answers[3]?.includes(item.optionVal) ?? false}
            />
            <Typography variant="p">{item.optionName}</Typography>
          </div>
        ))}
      </CardContent>
    </Card>
  );
}

QnA3.propTypes = {
  answers: PropTypes.object,
  setAnswers: PropTypes.func
};
