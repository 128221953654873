// material
import { styled } from '@mui/material/styles';
import { Card, Typography } from '@mui/material';
import CountUp from 'react-countup';
import translate from './translate';
// utils
// import { fShortenNumber } from '../../../utils/formatNumber';

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(5, 0),
  color: theme.palette.primary.darker,
  backgroundColor: theme.palette.primary.lighter
}));

// const IconWrapperStyle = styled('div')(({ theme }) => ({
//   margin: 'auto',
//   display: 'flex',
//   borderRadius: '50%',
//   alignItems: 'center',
//   width: theme.spacing(8),
//   height: theme.spacing(8),
//   justifyContent: 'center',
//   marginBottom: theme.spacing(3),
//   color: theme.palette.primary.dark,
//   backgroundImage: `linear-gradient(135deg, ${alpha(theme.palette.primary.dark, 0)} 0%, ${alpha(
//     theme.palette.primary.dark,
//     0.24
//   )} 100%)`
// }));

// ----------------------------------------------------------------------
/* eslint-disable react/prop-types */
export default function AppWeeklySales({ points, language, title, currency, duration }) {
  return (
    <RootStyle>
      <Typography variant="h6">{title}</Typography>
      <Typography variant="h3">
        {duration ? <CountUp end={points} /> : points} {currency}
      </Typography>
      <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
        {translate(language, 'Not impacted by time')}
      </Typography>
    </RootStyle>
  );
}
