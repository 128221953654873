import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import { Backdrop, Box, Fade, Modal, Stack, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { LoadingButton } from '@mui/lab';
import axios from 'axios';
import { translate } from '../_dashboard/app';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4
};

export default function EditReferralRewardModal({ open, language, setOpen, data, getData }) {
  const handleClose = () => setOpen(false);

  const RewardsSchema = Yup.object().shape({
    points: Yup.number().required(translate(language, 'Points is required')),
    mileStoneValue: Yup.number().required(translate(language, 'Milestone Value is required'))
  });

  const formik = useFormik({
    initialValues: {
      points: data.points,
      mileStoneValue: data.mileStoneValue
    },
    enableReinitialize: true,
    validationSchema: RewardsSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        // eslint-disable-next-line
        const res = await axios.patch('referral', {
          points: values.points,
          milestone_Value: values.mileStoneValue
        });
        resetForm();
        getData();
        setOpen(false);
      } catch (error) {
        console.log(error);
      }
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack spacing={3}>
                  <TextField
                    fullWidth
                    autoComplete="points"
                    type="number"
                    label={translate(language, 'Points Earn')}
                    {...getFieldProps('points')}
                    error={Boolean(touched.points && errors.points)}
                    helperText={touched.points && errors.points}
                  />
                  <TextField
                    fullWidth
                    autoComplete="mileStoneValue"
                    type="number"
                    label={translate(language, 'Milestone Value')}
                    {...getFieldProps('mileStoneValue')}
                    error={Boolean(touched.mileStoneValue && errors.mileStoneValue)}
                    helperText={touched.mileStoneValue && errors.mileStoneValue}
                  />

                  <LoadingButton
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                  >
                    {translate(language, 'Save')}
                  </LoadingButton>
                </Stack>
              </Form>
            </FormikProvider>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

EditReferralRewardModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  data: {
    points: PropTypes.string,
    mileStoneValue: PropTypes.string
  },
  getData: PropTypes.func,
  language: PropTypes.element
};
