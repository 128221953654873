import axios from 'axios';

const fetchReferralData = async (setReferral) => {
  try {
    const res = await axios.get('referral');
    setReferral(res.data.data);
  } catch (error) {
    console.log(error);
  }
};

export default fetchReferralData;
