import { Grid, Typography, Button } from '@mui/material';
import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import CenterSpinner from '../../CenterSpinner';
import CardModal from './CradModal';
import ProductModal from './ProductModal';
import { translate } from '../app';
/* eslint-disable camelcase */
export default function WidgetSection({ language }) {
  const [messageData, setMessageData] = useState({});
  const [loading, setLoading] = useState(true);
  const [productData, setProductData] = useState([]);
  const [orgData, setOrgData] = useState({});
  const [initialOpen, setInitialOpen] = useState(false);
  const bottomRef = useRef();

  const fetchOrgData = async () => {
    try {
      setLoading(true);
      const [org, referral] = await Promise.all([axios.get('org'), axios.get('referral')]);
      const {
        data: {
          data: { levels_active, cashback_active }
        }
      } = org;
      setOrgData({ levels_active, cashback_active, referral_active: referral.data.data.isActive });
    } catch (error) {
      console.log(error);
      toast.error(translate(language, 'Something went wrong!'));
    } finally {
      setLoading(false);
    }
  };

  const fetchMessageData = async () => {
    try {
      setLoading(true);
      const {
        data: { data }
      } = await axios.get('notification');

      setTimeout(() => setMessageData(data), 100);
    } catch (error) {
      console.error(error);
      toast.error(translate(language, 'Something went wrong!'));
    } finally {
      setLoading(false);
    }
  };

  const fetchProductData = async () => {
    try {
      setLoading(true);
      const {
        data: { data }
      } = await axios.get('notification/product');
      setProductData(data);
    } catch (error) {
      console.error(error);
      toast.error(translate(language, 'Something went wrong!'));
    } finally {
      setLoading(false);
    }
    if (initialOpen) bottomRef.current?.scrollIntoView();
  };

  const handleAddProduct = async () => {
    const productName = productData[productData.length - 1]?.id ?? 0;
    try {
      // eslint-disable-next-line
      const res = await axios.post('notification/activate', {
        template_name: `product-${productName + 1}`,
        program_name: 'product'
      });
      fetchProductData();
      setInitialOpen(true);
    } catch (error) {
      console.error(error);
      toast.error(translate(language, 'Something went wrong!'));
    }
  };

  useEffect(() => {
    fetchMessageData();
    fetchProductData();
    fetchOrgData();
    // eslint-disable-next-line
  }, []);

  if (loading) {
    return <CenterSpinner />;
  }

  return (
    <div>
      <Typography variant="h6" color="rgb(20,20,20)" align="left" paddingTop={1} paddingBottom={1}>
        {translate(
          language,
          'This the default notification that appears to your players upon receiving a cashback reward!'
        )}
      </Typography>
      <Typography variant="h4" align="left">
        {translate(language, 'Cashback')}
      </Typography>
      <Grid
        container
        justify="space-evenly"
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'left',
          '& > :not(style)': {
            m: 3
          }
        }}
      >
        <Grid item xs={12} sm>
          <CardModal
            language={language}
            data={messageData['cashback-notification']}
            programName="cashback"
            templateName="cashback-notification"
            title={translate(language, 'Cashback Notification')}
            about={translate(language, 'All about cashback notifications')}
            modalHeading={translate(language, 'Cashback Notification')}
            fetchMessageData={fetchMessageData}
            programActive={Boolean(orgData.cashback_active)}
          />
        </Grid>
      </Grid>
      <Typography variant="h4" align="left">
        {translate(language, 'Referrals')}
      </Typography>
      <Grid
        container
        justify="space-evenly"
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'left',
          '& > :not(style)': {
            m: 3
          }
        }}
      >
        <Grid item xs={12} sm>
          <CardModal
            language={language}
            data={messageData['successful-referral']}
            programName="referrals"
            templateName="successful-referral"
            title={translate(language, 'Successfully referred other player')}
            about={translate(language, 'This message is sent to player who referred others')}
            modalHeading={translate(language, 'Successfully referred other player')}
            fetchMessageData={fetchMessageData}
            programActive={Boolean(orgData.referral_active)}
          />
        </Grid>
        <Grid item xs={12} sm>
          <CardModal
            language={language}
            data={messageData['successful-got-referred']}
            programName="referrals"
            templateName="successful-got-referred"
            title={translate(language, 'Successfully got referred by other player')}
            about={translate(language, 'This message is sent to player who got referred by others')}
            modalHeading={translate(language, 'Successfully got referred by other player')}
            fetchMessageData={fetchMessageData}
            programActive={Boolean(orgData.referral_active)}
          />
        </Grid>
      </Grid>
      <Typography variant="h4" align="left">
        {translate(language, 'Challenges')}
      </Typography>
      <Grid
        container
        justify="space-evenly"
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'left',
          '& > :not(style)': {
            m: 3
          }
        }}
      >
        <Grid item xs={12} sm>
          <CardModal
            language={language}
            data={messageData.challenges}
            programName="challenges"
            templateName="challenges"
            title={translate(language, 'Challenges')}
            about={translate(language, 'All about Challenges')}
            modalHeading={translate(language, 'Unlocking a Challenge')}
            fetchMessageData={fetchMessageData}
          />
        </Grid>
        <Grid item xs={12} sm>
          <CardModal
            language={language}
            data={messageData['cheering-action']}
            programName="challenges"
            templateName="cheering-action"
            title={translate(language, 'Cheering for an Event or Action')}
            about={translate(language, 'All about cheering for an event or action')}
            modalHeading={translate(language, 'Cheering for an Event or Action')}
            fetchMessageData={fetchMessageData}
          />
        </Grid>
      </Grid>
      <Grid
        container
        justify="space-evenly"
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'left',
          '& > :not(style)': {
            m: 3
          }
        }}
      >
        <Grid item xs={12} sm>
          <CardModal
            language={language}
            data={messageData['new-challenge-achievement']}
            programName="challenges"
            templateName="new-challenge-achievement"
            title={translate(language, 'New Challenge Achievement')}
            about={translate(language, 'All about Challenge Achievements')}
            modalHeading={translate(language, 'New Challenge Achievement')}
            fetchMessageData={fetchMessageData}
          />
        </Grid>
        <Grid item xs={12} sm>
          <CardModal
            language={language}
            data={messageData['new-mission-achievment']}
            programName="challenges"
            templateName="new-mission-achievment"
            title={translate(language, 'New Mission Achievement')}
            about={translate(language, 'All about new mission achievements')}
            modalHeading={translate(language, 'New Mission Achievement')}
            fetchMessageData={fetchMessageData}
          />
        </Grid>
      </Grid>
      <Typography variant="h4" align="left">
        {translate(language, 'Levels')}
      </Typography>
      <Grid
        item
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'left',
          '& > :not(style)': {
            m: 3
          }
        }}
      >
        <Grid item xs={12} sm>
          <CardModal
            language={language}
            data={messageData['level-up']}
            programName="levels"
            templateName="level-up"
            title={translate(language, 'Level up')}
            about={translate(language, 'All about level up')}
            modalHeading={translate(language, 'Level Up')}
            fetchMessageData={fetchMessageData}
            programActive={Boolean(orgData.levels_active)}
          />
        </Grid>
        <Grid item xs={12} sm>
          <CardModal
            language={language}
            data={messageData['level-down']}
            programName="levels"
            templateName="level-down"
            title={translate(language, 'Level down')}
            about={translate(language, 'All about Level down')}
            modalHeading={translate(language, 'Level Down')}
            fetchMessageData={fetchMessageData}
            programActive={Boolean(orgData.levels_active)}
          />
        </Grid>
      </Grid>
      <Typography variant="h4" align="left">
        {translate(language, 'Redeem')}
      </Typography>
      <Grid
        item
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'left',
          '& > :not(style)': {
            m: 3
          }
        }}
      >
        <Grid item xs={12} sm>
          <CardModal
            language={language}
            data={messageData.redeem}
            programName="redeem"
            templateName="redeem"
            title={translate(language, 'Redeem')}
            about={translate(language, 'All about points redeem')}
            modalHeading={translate(language, 'Redeem')}
            fetchMessageData={fetchMessageData}
          />
        </Grid>
        <Grid item xs={12} sm>
          <CardModal
            language={language}
            data={messageData['spin-to-win']}
            programName="redeem"
            templateName="spin-to-win"
            title={translate(language, 'Spin to Win')}
            about={translate(language, 'All About Spin to win')}
            modalHeading={translate(language, 'Spin to Win')}
            fetchMessageData={fetchMessageData}
          />
        </Grid>
      </Grid>
      <Typography variant="h4" align="left">
        {translate(language, 'Sign-Up')}
      </Typography>
      <Grid
        item
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'left',
          '& > :not(style)': {
            m: 3
          }
        }}
      >
        <Grid item xs={12} sm>
          <CardModal
            language={language}
            data={messageData.signup}
            programName="signup"
            templateName="signup"
            title={translate(language, 'Sign-up')}
            about={translate(language, 'All about Sign-up Message')}
            modalHeading={translate(language, 'Sign-Up')}
            fetchMessageData={fetchMessageData}
          />
        </Grid>
      </Grid>
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
          marginBottom: '30px'
        }}
      >
        <Typography variant="h4" align="left">
          {translate(language, 'Product')}
        </Typography>
        <Button variant="outlined" onClick={handleAddProduct}>
          {translate(language, 'Add Product Message')}
        </Button>
      </div>
      <div>
        <Grid container>
          {productData?.map((item, index) => (
            <Grid key={index} item xs={12} md={6} ref={bottomRef}>
              <ProductModal
                language={language}
                initialOpen={index === productData.length - 1 ? initialOpen : false}
                setInitialOpen={setInitialOpen}
                data={item}
                fetchProductData={fetchProductData}
              />
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  );
}

WidgetSection.propTypes = {
  language: PropTypes.element
};
