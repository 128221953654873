import React, { useState } from 'react';
import {
  Grid,
  IconButton,
  Typography,
  Card,
  CardActionArea,
  CardContent,
  Switch,
  Stack,
  CardHeader,
  TextField,
  ToggleButtonGroup,
  ToggleButton,
  Avatar,
  Chip,
  Skeleton
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { string, func, object, element, bool } from 'prop-types';
import { Icon } from '@iconify/react';
import Modal from '@mui/material/Modal';
import { toast } from 'react-toastify';
import axios from 'axios';
import { FormikProvider, useFormik, Form, Field } from 'formik';
import * as Yup from 'yup';

import { translate } from '../app';
import Upload from '../../levels/icons/Upload';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 30,
  p: 4,
  borderRadius: 1
};

export default function CardModal({
  title,
  language,
  about,
  modalHeading,
  data,
  programName,
  templateName,
  fetchMessageData,
  programActive
}) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [preview, setPreview] = useState(false);

  const validationSchema = Yup.object().shape({
    title: Yup.string().required(),
    desc: Yup.string().required()
  });

  const handleOpen = () => {
    if (programActive === 0) {
      toast.info(
        translate(language, 'Activate the %s program first from program page', [programName])
      );
      return;
    }

    if (data && data.id) setOpen(true);
    else toast.info(translate(language, 'Activate the template first'));
  };
  const handleClose = () => setOpen(false);
  // const [checked, setChecked] = useState(data ? data.template_active : false);

  const handleActiveChange = async ({ target: { checked } }) => {
    try {
      if (programActive === 0 && checked === true) {
        toast.info(
          translate(language, 'Activate the %s program first from program page', [programName])
        );
        return;
      }
      setLoading(true);
      // eslint-disable-next-line
      const res = await axios.post('notification/activate', {
        active: checked,
        template_name: templateName,
        program_name: programName,
        ...data
      });
      fetchMessageData();
      // setChecked(checked);
    } catch (error) {
      console.error(error);
      toast.error(translate(language, 'Something went wrong!'));
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      title: data ? data.title : '',
      desc: data ? data.desc : '',
      img_url: data ? data.img_url : ''
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        const res = await axios.patch(`notification/${data.id}`, { ...values });
        toast.success(res.data.message);
        handleClose();
      } catch (error) {
        console.log(error.response.data.message);
        toast.error(translate(language, 'Something went wrong!'));
      }
    }
  });

  const {
    handleSubmit,
    isSubmitting,
    setFieldValue,
    handleChange,
    values,
    dirty,
    errors,
    touched
  } = formik;

  const handleImageChange = (event, newImage) => {
    setPreview(false);
    setFieldValue('img_url', newImage);
  };

  const handleCustomUpload = async (event) => {
    setPreview(true);
    const formData = new FormData();
    formData.append('file', event.target.files[0]);

    try {
      const res = await axios.post('upload', formData);
      setFieldValue('img_url', res.data.fileUrl);
    } catch (error) {
      console.log(error);
      toast.error(translate(language, 'Something went wrong!'));
    }
  };

  if (loading) {
    return <Skeleton sx={{ maxWidth: 500, height: 200, borderRadius: '15px' }} />;
  }

  return (
    <div>
      <Card align="left" variant="outlined" sx={{ maxWidth: 500 }}>
        <CardHeader
          title={title}
          action={<Switch onChange={handleActiveChange} checked={!!data?.template_active} />}
        />
        <CardActionArea>
          <CardContent onClick={handleOpen}>
            <Typography variant="body2" color="text.secondary" style={{ whiteSpace: 'pre-wrap' }}>
              <p>{about}</p>
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
      <Modal open={open} onClose={handleClose}>
        <Grid sx={style}>
          <Grid container pb={2}>
            <Grid item xs>
              <Typography variant="h3">{modalHeading}</Typography>
            </Grid>
            <Grid item>
              <Typography align="right">
                <IconButton width={22} height={22} onClick={() => setOpen(false)}>
                  <Icon icon="bi:x-lg" />
                </IconButton>
              </Typography>
            </Grid>
          </Grid>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={16}>
                  <Field
                    component={TextField}
                    name="title"
                    id="title"
                    rows={1}
                    placeholder={translate(language, 'Headings')}
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                    value={values.title}
                    error={Boolean(touched.title && errors.title)}
                    helperText={touched.title && errors.title}
                  />
                </Grid>
                <Grid item xs={16}>
                  <div style={{ display: 'flex', flexDirection: 'row', alignContent: 'center' }}>
                    {preview && (
                      <Avatar
                        style={{ marginTop: '15px', marginRight: '15px' }}
                        variant="square"
                        alt=""
                        src={values.img_url}
                      />
                    )}
                    <ToggleButton
                      style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                      component="label"
                      value="custom"
                    >
                      <Upload />
                      <input
                        type="file"
                        accept=".jpeg, .png"
                        hidden
                        style={{ margin: '10px', display: 'none' }}
                        onChange={handleCustomUpload}
                      />
                    </ToggleButton>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <ToggleButtonGroup
                        color="primary"
                        variant="contained"
                        value={values.img_url}
                        exclusive
                        onChange={handleImageChange}
                      >
                        <ToggleButton
                          style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                          component="label"
                          value="https://gsaas-getmok.s3.ap-south-1.amazonaws.com/file-1656067673143.png"
                        >
                          <Avatar
                            variant="square"
                            alt=""
                            src="https://gsaas-getmok.s3.ap-south-1.amazonaws.com/file-1656067673143.png"
                          />
                        </ToggleButton>
                        <ToggleButton
                          component="label"
                          value="https://gsaas-getmok.s3.ap-south-1.amazonaws.com/file-1656067351920.png"
                        >
                          <Avatar
                            variant="square"
                            alt=""
                            src="https://gsaas-getmok.s3.ap-south-1.amazonaws.com/file-1656067351920.png"
                          />
                        </ToggleButton>
                        <ToggleButton
                          component="label"
                          value="https://gsaas-getmok.s3.ap-south-1.amazonaws.com/file-1656067807016.png"
                        >
                          <Avatar
                            variant="square"
                            alt=""
                            src="https://gsaas-getmok.s3.ap-south-1.amazonaws.com/file-1656067807016.png"
                          />
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={16}>
                  <Field
                    component={TextField}
                    name="desc"
                    id="desc"
                    rows={10}
                    placeholder={translate(language, 'Headings')}
                    variant="outlined"
                    fullWidth
                    multiline
                    onChange={handleChange}
                    value={values.desc}
                    error={Boolean(touched.desc && errors.desc)}
                    helperText={touched.desc && errors.desc}
                  />
                </Grid>
              </Grid>
              <Stack spacing={1} direction="row-reverse" paddingTop={3}>
                <Chip
                  label="player_name"
                  color="secondary"
                  variant="outlined"
                  onClick={() => setFieldValue('desc', `${values.desc}{player_name}`)}
                />
                <Chip
                  label="player_points"
                  color="secondary"
                  variant="outlined"
                  onClick={() => setFieldValue('desc', `${values.desc}{player_points}`)}
                />
                <Chip
                  label="player_level"
                  color="secondary"
                  variant="outlined"
                  onClick={() => setFieldValue('desc', `${values.desc}{player_level}`)}
                />
                <Chip
                  label="points_earned"
                  color="secondary"
                  variant="outlined"
                  onClick={() => setFieldValue('desc', `${values.desc}{points_earned}`)}
                />
              </Stack>
              <Stack spacing={2} direction="row-reverse" paddingTop={3}>
                <LoadingButton
                  disabled={!dirty}
                  loading={isSubmitting}
                  variant="contained"
                  type="submit"
                >
                  {translate(language, 'Save')}
                </LoadingButton>
              </Stack>
            </Form>
          </FormikProvider>
        </Grid>
      </Modal>
    </div>
  );
}
CardModal.propTypes = {
  title: string,
  about: string,
  modalHeading: string,
  programName: string,
  templateName: string,
  fetchMessageData: func,
  data: object,
  language: element,
  programActive: bool
};
