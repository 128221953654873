import moment from 'moment';
import PropTypes from 'prop-types';
import { Avatar, Stack, Typography } from '@mui/material';
import { translate } from '../../_dashboard/app';

const Design2 = ({ discount, dateRange, language, image }) => {
  const startDate = moment(dateRange[0]);
  // const currDate = moment(new Date());
  const endDate = moment(dateRange[1]);
  const duration = moment.duration(endDate.diff(startDate));

  return (
    <>
      <Stack
        sx={{
          display: 'flex',
          position: 'relative',
          marginTop: '10px',
          color: '#c39878',
          fontSize: { xs: '10px', sm: '18px' }
        }}
      >
        <Avatar variant="square" alt=" " src={image} sx={{ width: '100%', height: '100%' }} />
        <Stack
          sx={{
            display: 'flex',
            flexDirection: 'column',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            justifyContent: 'space-between',
            padding: '10px'
          }}
        >
          <Stack direction="column">
            <Stack direction="row">
              <Typography sx={{ fontSize: { xs: '2rem', sm: '5rem' } }}>
                {discount || 0} %
              </Typography>
              <Typography
                sx={{
                  marginLeft: '1rem',
                  alignSelf: 'end',
                  marginBottom: { sm: '18px', xs: '2px' },
                  fontSize: { xs: '10px', sm: '18px' }
                }}
              >
                {translate(language, 'OFF')}
              </Typography>
            </Stack>
            {/* <Typography
              sx={{
                marginLeft: { sm: '12rem', xs: '7rem' },
                fontSize: { xs: '10px', sm: '18px' }
              }}
            >
              on All beaty products
            </Typography> */}
          </Stack>
          <Stack direction="column">
            <Typography
              sx={{
                marginLeft: 'auto',
                marginRight: 'auto',
                padding: { xs: '5px 8px', sm: '10px 15px' },
                background: '#c39878',
                color: 'white',
                width: 'max-content',
                fontSize: { xs: '10px', sm: '18px' }
              }}
            >
              {Math.ceil(duration?.asDays()) || 0} {translate(language, 'Days')}{' '}
              {duration?._data?.hours ?? 0} h {duration?._data?.minutes ?? 0} min
            </Typography>
            {endDate.format('MMM Do YYYY') !== translate(language, 'Invalid date') && (
              <Typography sx={{ textAlign: 'center', marginTop: '10px' }}>
                {translate(language, 'valid till')} {endDate.format('MMM Do YYYY')}
              </Typography>
            )}
          </Stack>
          {/* <Stack
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: { sm: 'row', xs: 'column' },
              marginBottom: '10px',
              justifyContent: 'center'
            }}
          >
            <Typography
              sx={{
                fontSize: { xs: '10px', sm: '18px' }
              }}
            >
              Use this Promo Code
            </Typography>
            <Stack
              sx={{
                display: 'flex',
                flexDirection: 'row',
                border: '2px solid #C39878',
                padding: '0px',
                margin: '-1px',
                height: 'fit-content',
                marginLeft: '10px'
              }}
            >
              <Typography
                sx={{
                  padding: { sm: '10px', xs: '5px' },
                  fontSize: { xs: '10px', sm: '18px' }
                }}
              >
                ABC12asdas3
              </Typography>

              <Typography
                sx={{
                  background: '#C39878',
                  padding: { sm: '10px', xs: '5px' },
                  color: 'white',
                  fontSize: { xs: '10px', sm: '18px' }
                }}
              >
                copy
              </Typography>
              <div
                style={{
                  display: 'flex',
                  position: 'relative',
                  marginTop: '10px',
                  color: '#c39878'
                }}
              >
                <Avatar
                  variant="square"
                  alt=" "
                  src="/static/pop-templates/template2.jpg"
                  sx={{ width: '100%', height: '100%' }}
                />
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    justifyContent: 'space-evenly'
                  }}
                >
                  <div style={{ marginLeft: '1rem', fontSize: '1.5rem' }}>
                    <span style={{ fontSize: '5rem' }}>25%</span> OFF
                  </div>

                  <div style={{ marginLeft: '12rem' }}>on All beaty products</div>

                  <div
                    style={{
                      marginLeft: 'auto',
                      marginRight: 'auto',
                      padding: '10px 15px',
                      marginTop: '8%',
                      background: '#c39878',
                      color: 'white',
                      width: 'max-content'
                    }}
                  >
                    12h : 23m : 34s
                  </div>

                  <div style={{ textAlign: 'center', marginTop: '10px' }}>
                    valid till 7 August,2022
                  </div>

                  <div
                    style={{
                      alignItems: 'center',
                      justifyContent: 'center',
                      display: 'flex',
                      flexDirection: 'row',
                      width: '100%',
                      height: '100%'
                    }}
                  >
                    <div>Use this Promo Code</div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        border: '2px solid #C39878',
                        padding: '0px',
                        margin: '-1px',
                        height: 'fit-content',
                        marginLeft: '10px'
                      }}
                    >
                      <div style={{ padding: '10px' }}>ABC123</div>
                      <div style={{ background: '#C39878', padding: '10px', color: 'white' }}>
                        copy
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Stack>
          </Stack> */}
        </Stack>
      </Stack>
    </>
  );
};

export default Design2;

Design2.propTypes = {
  discount: PropTypes.number,
  dateRange: PropTypes.array,
  image: PropTypes.string,
  language: PropTypes.element
};
