const Points = () => (
  <svg width="41" height="41" viewBox="0 0 41 41" fill="none">
    <path
      d="M40.0003 18.3683C40.0003 20.0567 35.897 21.4233 30.8337 21.4233C25.7703 21.4233 21.667 20.0567 21.667 18.3683V15.3133C21.667 17 25.7703 18.3683 30.8337 18.3683C35.897 18.3683 40.0003 17.0017 40.0003 15.3133"
      fill="#428BC1"
    />
    <path
      d="M30.8337 10.2283C25.7753 10.2283 21.667 8.85334 21.667 7.17334V13.24C21.667 14.9217 25.7753 16.2967 30.8337 16.2967C35.892 16.2967 40.0003 14.9217 40.0003 13.24V7.17334C40.0003 8.85334 35.892 10.2283 30.8337 10.2283Z"
      fill="#428BC1"
    />
    <path
      d="M40 3.055C40 1.36833 35.8967 0 30.8333 0C25.77 0 21.6667 1.36667 21.6667 3.055V5.09333C21.6667 6.78 25.77 8.14833 30.8333 8.14833C35.8967 8.14833 40 6.78167 40 5.09333M18.3333 36.7017C18.3333 38.39 14.23 39.7567 9.16667 39.7567C4.10333 39.7567 0 38.39 0 36.7017V33.6467C0 35.3333 4.10333 36.7 9.16667 36.7C14.23 36.7 18.3333 35.3333 18.3333 33.645V36.7017Z"
      fill="#428BC1"
    />
    <path
      d="M9.16667 28.5617C4.10833 28.5617 0 27.1867 0 25.5067V31.5733C0 33.255 4.10833 34.63 9.16667 34.63C14.225 34.63 18.3333 33.255 18.3333 31.5733V25.5067C18.3333 27.1867 14.225 28.5617 9.16667 28.5617Z"
      fill="#428BC1"
    />
    <path
      d="M18.3333 21.3883C18.3333 19.7017 14.23 18.3333 9.16667 18.3333C4.10333 18.3333 0 19.7 0 21.3883V23.4267C0 25.1133 4.10333 26.4817 9.16667 26.4817C14.23 26.4817 18.3333 25.115 18.3333 23.4267V21.3883ZM33.3333 35H30V30.6833L25.6833 35L23.3333 32.65L27.65 28.3333H23.3333V25H33.3333V35Z"
      fill="#428BC1"
    />
  </svg>
);

export default Points;
