import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { translate } from './_dashboard/app';

const EmptyPage = ({ name, language }) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      '& > :not(style)': {
        width: 300
      }
    }}
  >
    <img alt="no-apps" src="/static/mock-images/no-apps.png" />
    <Typography align="center" variant="h5">
      {translate(language, 'No %s Yet', [name])}
    </Typography>
  </Box>
);

export default EmptyPage;

EmptyPage.propTypes = {
  name: PropTypes.string,
  language: PropTypes.element
};
