import React from 'react';
import {
  Stack,
  Grid,
  FormControl,
  InputAdornment,
  OutlinedInput,
  Typography,
  IconButton,
  Button,
  Divider
} from '@mui/material';
import { Icon } from '@iconify/react';
import PropTypes from 'prop-types';
import editFill from '@iconify/icons-eva/edit-fill';
import { translate } from '../../app';

export default function mobileConfigurations({ language }) {
  return (
    <Grid>
      <Grid>
        <Stack spacing={-1.7}>
          <Typography gutterBottom variant="h5" align="left">
            {translate(language, 'Connection Information')}
          </Typography>
          <Typography gutterBottom variant="subtitle2" align="left" paddingTop={1}>
            {translate(language, 'Connection Information Description')}
          </Typography>
        </Stack>
        <FormControl fullWidth variant="outlined" margin="normal">
          <OutlinedInput
            value={translate(language, 'Server Key')}
            endAdornment={
              <InputAdornment position="end">
                <IconButton edge="end">
                  <Icon fontSize={25} icon={editFill} />
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        <FormControl fullWidth variant="outlined" margin="normal">
          <OutlinedInput
            value={translate(language, 'Sender ID')}
            endAdornment={
              <InputAdornment position="end">
                <IconButton edge="end">
                  <Icon fontSize={25} icon={editFill} />
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        <FormControl fullWidth margin="normal" variant="outlined">
          <OutlinedInput
            value={translate(language, 'Web API Key')}
            endAdornment={
              <InputAdornment position="end">
                <IconButton edge="end">
                  <Icon fontSize={25} icon={editFill} />
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        <Typography gutterBottom align="right" paddingBottom={5} paddingTop={5}>
          <Button variant="contained">
            <h3>{translate(language, 'Test Firebase Connection')}</h3>
          </Button>
        </Typography>
      </Grid>
      <Divider />
      <Grid>
        <Stack spacing={-1.7} paddingTop={2}>
          <Typography gutterBottom variant="h5" align="left">
            {translate(language, 'Referral Link')}
          </Typography>
          <Typography gutterBottom variant="subtitle2" align="left" paddingTop={1}>
            {translate(language, 'Referral Link Description')}
          </Typography>
        </Stack>
        <FormControl fullWidth variant="outlined" margin="normal">
          <OutlinedInput
            value={translate(language, 'Domain URL Prefix')}
            endAdornment={
              <InputAdornment position="end">
                <IconButton edge="end">
                  <Icon fontSize={25} icon={editFill} />
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
      </Grid>
      <Grid>
        <Stack spacing={-1.7} paddingTop={2}>
          <Typography gutterBottom variant="h5" align="left">
            {translate(language, 'IOS App Information')}
          </Typography>
          <Typography gutterBottom variant="subtitle2" align="left" paddingTop={1}>
            {translate(language, 'IOS App Information Description')}
          </Typography>
        </Stack>
        <FormControl fullWidth variant="outlined" margin="normal">
          <OutlinedInput
            value={translate(language, 'iOS App Store ID')}
            endAdornment={
              <InputAdornment position="end">
                <IconButton edge="end">
                  <Icon fontSize={25} icon={editFill} />
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        <FormControl fullWidth variant="outlined" margin="normal">
          <OutlinedInput
            value={translate(language, 'iOS Bundle ID')}
            endAdornment={
              <InputAdornment position="end">
                <IconButton edge="end">
                  <Icon fontSize={25} icon={editFill} />
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
      </Grid>
      <Grid>
        <Stack spacing={-1.7} paddingTop={2}>
          <Typography gutterBottom variant="h5" align="left">
            {translate(language, 'Android App Information')}
          </Typography>
          <Typography gutterBottom variant="subtitle2" align="left" paddingTop={1}>
            {translate(language, 'Android App Information Description')}
          </Typography>
        </Stack>
        <FormControl fullWidth variant="outlined" margin="normal">
          <OutlinedInput
            value={translate(language, 'Android Package Name')}
            endAdornment={
              <InputAdornment position="end">
                <IconButton edge="end">
                  <Icon fontSize={25} icon={editFill} />
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
      </Grid>
    </Grid>
  );
}

mobileConfigurations.propTypes = {
  language: PropTypes.element
};
