const Insta = () => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M28 0H8C3.58172 0 0 3.58172 0 8V28C0 32.4183 3.58172 36 8 36H28C32.4183 36 36 32.4183 36 28V8C36 3.58172 32.4183 0 28 0Z"
      fill="url(#paint0_linear_694_156)"
    />
    <path
      d="M25 4H10C6.68629 4 4 6.68629 4 10V25C4 28.3137 6.68629 31 10 31H25C28.3137 31 31 28.3137 31 25V10C31 6.68629 28.3137 4 25 4Z"
      stroke="white"
      strokeLinejoin="round"
    />
    <path
      d="M18 24C21.3137 24 24 21.3137 24 18C24 14.6863 21.3137 12 18 12C14.6863 12 12 14.6863 12 18C12 21.3137 14.6863 24 18 24Z"
      stroke="white"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M26 11C27.1046 11 28 10.1046 28 9C28 7.8954 27.1046 7 26 7C24.8954 7 24 7.8954 24 9C24 10.1046 24.8954 11 26 11Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_694_156"
        x1="7.5"
        y1="1.5"
        x2="26.5"
        y2="34"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.121499" stopColor="#C82BCB" />
        <stop offset="0.450642" stopColor="#FF5271" />
        <stop offset="0.978927" stopColor="#FFC727" />
      </linearGradient>
    </defs>
  </svg>
);

export default Insta;
