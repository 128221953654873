import { Button, Typography } from '@mui/material';
import PropTypes from 'prop-types';

const Video = ({ setPage }) => (
  <>
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: '70vh',
        width: '900px'
      }}
    >
      <Typography mt={1} mb={3} style={{ fontSize: '44px', fontWeight: '400' }}>
        A walk through before you Enter
      </Typography>
      <video width="500px" height="500px" controls src="/friday.mp4">
        <track default kind="captions" src="/media/examples/friday.vtt" />
        Sorry, your browser doesn't support embedded videos.
      </video>
      <Button
        onClick={() => setPage('see-setup')}
        variant="contained"
        sx={{ paddingX: '55px', paddingY: '8px', fontSize: '18px', marginTop: '75px' }}
      >
        Next
      </Button>
    </div>
  </>
);

export default Video;

Video.propTypes = {
  setPage: PropTypes.func
};
