import { Card, CardContent, Grid, Typography, MenuItem, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

import DatePickerWithTitle from '../DatePickerWithTitle';
import { AppWebsiteVisits, AppWeeklySales, translate } from '../_dashboard/app';

const StyledCardDiv = styled(`div`)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between'
});

export default function Programs({ language }) {
  return (
    <>
      <DatePickerWithTitle language={language} title={translate(language, 'Programs')} />
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4}>
          <AppWeeklySales
            language={language}
            points="0"
            title={translate(language, 'Total Accumulated Points')}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <AppWeeklySales
            language={language}
            points="0"
            title={translate(language, 'Total Manual Points')}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <AppWeeklySales
            language={language}
            points="0 INR"
            title={translate(language, 'Total Value of Redeemed Points')}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <AppWeeklySales
            language={language}
            points="0"
            title={translate(language, 'Accumulated Points')}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <AppWeeklySales
            language={language}
            points="0"
            title={translate(language, 'Manual Points')}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <AppWeeklySales
            language={language}
            points="0"
            title={translate(language, 'Total Redeemed Points')}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <AppWeeklySales
            language={language}
            points="0"
            title={translate(language, 'Number of Levels')}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <AppWeeklySales
            language={language}
            points="0"
            title={translate(language, 'Challenges')}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <AppWeeklySales
            language={language}
            points="0"
            title={translate(language, 'Value of Redeemed Points')}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <AppWeeklySales
            language={language}
            points="0"
            title={translate(language, 'Unique Players Widget Opens')}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <AppWeeklySales
            language={language}
            points="0"
            title={translate(language, 'Players Widget Opens')}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <AppWeeklySales
            language={language}
            points="0"
            title={translate(language, 'Guests Widget Opens')}
          />
        </Grid>
      </Grid>
      <Typography mt={5} mb={5} variant="h6">
        {translate(language, 'Players')}
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4}>
          <AppWeeklySales
            language={language}
            points="0"
            title={translate(language, 'Total Number of Players')}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <AppWeeklySales
            language={language}
            points="0"
            title={translate(language, 'Newly Enrolled Players')}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <AppWeeklySales
            language={language}
            points="0"
            title={translate(language, 'Number of Referred Friends')}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <AppWeeklySales
            language={language}
            points="0"
            title={translate(language, 'Unique Players Widget Opens')}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <AppWeeklySales
            language={language}
            points="0"
            title={translate(language, 'Number of Active Players')}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <AppWebsiteVisits
            title={translate(language, 'New vs Active Players')}
            sub={translate(language, 'Number of actions sent from new vs existing players')}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <AppWebsiteVisits
            title={translate(language, 'Achievements Types Performance')}
            sub={translate(language, 'Number of achievements vs achievement type')}
          />
        </Grid>
      </Grid>
      <Typography mt={5} mb={5} variant="h6">
        {translate(language, 'Leaderboard')}
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={6}>
          <Card variant="outlined">
            <CardContent style={{ borderBottom: '1px solid #D8E3E7' }}>
              <StyledCardDiv>
                <Typography variant="h6">{translate(language, 'Players Leaderboard')}</Typography>
                <Typography variant="h5">0</Typography>
              </StyledCardDiv>
              <StyledCardDiv>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                  {translate(language, 'Top players according to levelling-up method')}
                </Typography>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                  {translate(language, 'Players')}
                </Typography>
              </StyledCardDiv>
            </CardContent>
            <CardContent>
              <StyledCardDiv>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                  {translate(language, 'From 20 Jan 2022 to 26 Jan 2022')}
                </Typography>
                <TextField select size="small" value="Selected Range">
                  <MenuItem value="Selected Range">
                    {translate(language, 'Selected Range')}
                  </MenuItem>
                  <MenuItem value="All-Time">{translate(language, 'All-Time')}</MenuItem>
                </TextField>
              </StyledCardDiv>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

Programs.propTypes = {
  language: PropTypes.element
};
