import { Grid, Box, Container, Skeleton } from '@mui/material';

const skeletonStyle = {
  marginBottom: '30px',
  borderRadius: '8px/13.3px'
};

export default function WidgetSkeleton() {
  return (
    <>
      <Container>
        <Skeleton variant="text" height={60} width={120} />
        <Skeleton variant="text" height={25} width={800} />
        <Skeleton variant="text" height={25} width={300} />
      </Container>
      <Container>
        <Skeleton style={{ marginTop: '30px' }} height={120} width={200} />
        <Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Skeleton variant="rectangular" height={100} style={skeletonStyle} />
              <Skeleton variant="rectangular" height={100} style={skeletonStyle} />
              <Skeleton variant="rectangular" height={100} style={skeletonStyle} />
              <Skeleton variant="rectangular" height={150} style={skeletonStyle} />
            </Grid>
            <Grid item xs={6}>
              <Skeleton variant="rectangular" height={230} style={{ borderRadius: '8px/13.3px' }} />
            </Grid>
          </Grid>

          <Skeleton style={{ marginTop: '30px' }} height={120} width={350} />
          <Skeleton variant="rectangular" height={70} width={600} style={skeletonStyle} />
          <Skeleton variant="rectangular" height={70} width={300} style={skeletonStyle} />
          <Skeleton variant="rectangular" height={110} width={400} style={skeletonStyle} />
          <Skeleton variant="rectangular" height={70} width={350} style={skeletonStyle} />
          <Skeleton variant="rectangular" height={80} width={400} style={skeletonStyle} />
          <Skeleton variant="rectangular" height={75} width={300} style={skeletonStyle} />
          <Skeleton variant="rectangular" height={80} width={550} style={skeletonStyle} />
          <Skeleton variant="rectangular" height={80} width={600} style={skeletonStyle} />

          <Skeleton style={{ marginTop: '50px' }} height={80} width={350} />
          <Skeleton variant="rectangular" height={80} width={250} style={skeletonStyle} />
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={4} columns={16}>
              <Grid item xs={8}>
                <Grid>
                  <Skeleton variant="rectangular" height={70} style={skeletonStyle} />
                </Grid>
              </Grid>
              <Grid item xs={8}>
                <Grid>
                  <Skeleton variant="rectangular" height={70} style={skeletonStyle} />
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={4} columns={16}>
              <Grid item xs={8}>
                <Grid>
                  <Skeleton variant="rectangular" height={70} style={skeletonStyle} />
                </Grid>
              </Grid>
              <Grid item xs={8}>
                <Grid>
                  <Skeleton variant="rectangular" height={70} style={skeletonStyle} />
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={4} columns={16}>
              <Grid item xs={8}>
                <Grid>
                  <Skeleton variant="rectangular" height={70} width={350} style={skeletonStyle} />
                </Grid>
              </Grid>
              <Grid item xs={8}>
                <Grid>
                  <Skeleton variant="rectangular" height={70} width={350} style={skeletonStyle} />
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={4} columns={16}>
              <Grid item xs={8}>
                <Grid>
                  <Skeleton variant="rectangular" height={100} width={300} style={skeletonStyle} />
                </Grid>
              </Grid>
            </Grid>
          </Box>

          <Skeleton style={{ marginTop: '50px' }} height={80} width={350} />
          <Skeleton variant="rectangular" height={100} width={550} style={skeletonStyle} />
          <Skeleton variant="rectangular" height={100} width={550} style={skeletonStyle} />
          <Skeleton variant="rectangular" height={100} width={550} style={skeletonStyle} />

          <Skeleton style={{ marginTop: '30px' }} height={120} width={350} />

          <Skeleton variant="rectangular" height={50} width={150} style={skeletonStyle} />
          <Skeleton variant="rectangular" height={80} width={420} style={skeletonStyle} />

          <Skeleton variant="rectangular" height={50} width={150} style={skeletonStyle} />
          <Skeleton variant="rectangular" height={90} width={650} style={skeletonStyle} />
          <Skeleton variant="rectangular" height={90} width={650} style={skeletonStyle} />
          <Skeleton variant="rectangular" height={90} width={650} style={skeletonStyle} />
        </Grid>
      </Container>
    </>
  );
}
