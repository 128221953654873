import { Container, Box, Tabs, Tab, Typography, Stack } from '@mui/material';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { CurrentSub } from '../components/billing';
import Page from '../components/Page';
import {
  ChangePassword,
  HelpCenter,
  WriteYourReview
} from '../components/_dashboard/accountSetting';
import { translate } from '../components/_dashboard/app';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

export default function AccountSetting() {
  const [value, setValue] = useState(0);
  const language = localStorage.getItem('currentLanguage');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Page
      title={`${translate(language, 'Gamification')} | ${translate(language, 'Account Settings')}`}
    >
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {translate(language, 'Account Settings')}
          </Typography>
        </Stack>

        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab label={translate(language, 'Change password')} {...a11yProps(0)} />
            <Tab label={translate(language, 'Billing')} {...a11yProps(1)} />
            <Tab label={translate(language, 'Help center')} {...a11yProps(2)} />
            <Tab label={translate(language, 'Write a Review')} {...a11yProps(3)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <ChangePassword language={language} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <CurrentSub language={language} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <HelpCenter language={language} />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <WriteYourReview language={language} />
        </TabPanel>
      </Container>
    </Page>
  );
}
