import React from 'react';
import {
  FormControl,
  Radio,
  useMediaQuery,
  FormControlLabel,
  RadioGroup,
  Grid,
  Box,
  Stack,
  Typography
} from '@mui/material';
import PropTypes from 'prop-types';
import { translate } from '../app';

export default function WidgetStyle({ getFieldProps, language }) {
  const matchDownSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  return (
    <div>
      <Box sx={{ flexGrow: 1, pt: 2 }}>
        <Grid container spacing={matchDownSM ? '0' : '4'} columns={16}>
          {/* <Grid item xs={matchDownSM ? '16' : '8'}>
            <Stack spacing={-1.7} paddingTop={2} paddingBottom={2}>
              <Typography gutterBottom variant="h5" align="left">
                Widget Theme
              </Typography>
              <Typography
                gutterBottom
                variant="subtitle2"
                align={matchDownSM ? 'inherit' : 'left'}
                paddingTop={1}
              >
                Customize your widget theme
              </Typography>
            </Stack>
            <FormControl>
              <RadioGroup row name="position">
                <Typography marginRight={5}>
                  <FormControlLabel
                    {...getFieldProps('widget_theme')}
                    checked={getFieldProps('widget_theme').value === 'light'}
                    value="light"
                    control={<Radio />}
                    label="Light"
                  />
                </Typography>
                <Typography marginRight={5}>
                  <FormControlLabel
                    {...getFieldProps('widget_theme')}
                    checked={getFieldProps('widget_theme').value === 'dark'}
                    value="dark"
                    control={<Radio />}
                    label="Dark"
                  />
                </Typography>
              </RadioGroup>
            </FormControl>
          </Grid> */}
          <Grid item xs={matchDownSM ? '16' : '8'}>
            <Stack spacing={-1.7} paddingTop={2} paddingBottom={2}>
              <Typography gutterBottom variant="h5" align="left">
                {translate(language, 'Button Direction')}
              </Typography>
              <Typography
                gutterBottom
                variant="subtitle2"
                align={matchDownSM ? '' : 'left'}
                paddingTop={1}
              >
                {translate(language, 'Choose the location of Gamify widget button')}
              </Typography>
            </Stack>
            <FormControl>
              <RadioGroup row name="position">
                <Typography marginRight={5}>
                  <FormControlLabel
                    {...getFieldProps('widget_button_direction')}
                    checked={getFieldProps('widget_button_direction').value === 'left'}
                    value="left"
                    control={<Radio />}
                    label={translate(language, 'Left')}
                  />
                </Typography>
                <Typography marginRight={5}>
                  <FormControlLabel
                    {...getFieldProps('widget_button_direction')}
                    checked={getFieldProps('widget_button_direction').value === 'right'}
                    value="right"
                    control={<Radio />}
                    label={translate(language, 'Right')}
                  />
                </Typography>
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
WidgetStyle.propTypes = {
  getFieldProps: PropTypes.func,
  setFieldValue: PropTypes.func,
  language: PropTypes.element
};
