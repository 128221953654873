function Challenges() {
  return (
    <svg width="25" height="25" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.75 12.25H12.25V19.75H19.75V12.25ZM5.21875 1L9.4375 8.5H1L5.21875 1ZM16 8.5C16.9946 8.5 17.9484 8.10491 18.6516 7.40165C19.3549 6.69839 19.75 5.74456 19.75 4.75C19.75 3.75544 19.3549 2.80161 18.6516 2.09835C17.9484 1.39509 16.9946 1 16 1C15.0054 1 14.0516 1.39509 13.3483 2.09835C12.6451 2.80161 12.25 3.75544 12.25 4.75C12.25 5.74456 12.6451 6.69839 13.3483 7.40165C14.0516 8.10491 15.0054 8.5 16 8.5Z"
        stroke="#00AB55"
        strokeLinejoin="round"
      />
      <path
        d="M8.5 12.25L1 19.75M1 12.25L8.5 19.75L1 12.25Z"
        stroke="#00AB55"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Challenges;
