import React from 'react';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Toolbar, styled, Box, Button, Typography } from '@mui/material';
import { PropTypes } from 'prop-types';
import { translate } from '../../app';
import EmptyPage from '../../../EmptyPage';

const RootStyle = styled(Toolbar)(() => ({
  display: 'flex',
  justifyContent: 'space-between'
}));
export default function MerchantCreation({ language }) {
  return (
    <div>
      <RootStyle sx={{ flexGrow: 1 }}>
        <div style={{ display: 'flex' }} container justify="space-evenly">
          <Typography variant="h5">
            {translate(language, 'Merchants')}
            <Typography variant="subtitle2" paddingTop={1}>
              {translate(language, 'Numbers of Merchants (0)')}
            </Typography>
          </Typography>
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button variant="contained" startIcon={<Icon icon={plusFill} />}>
            {translate(language, 'Create New Merchants')}
          </Button>
        </div>
      </RootStyle>
      <Box
        mt={0.5}
        mb={0.5}
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          '& > :not(style)': {
            mt: 6,
            mb: 4,
            width: 400
          }
        }}
      >
        <EmptyPage language={language} name={translate(language, 'Merchants')} />
      </Box>
    </div>
  );
}

MerchantCreation.propTypes = {
  language: PropTypes.element
};
