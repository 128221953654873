function Dollar() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="#121212"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.9 10.5C12.3 10.35 11.7 10.05 11.25 9.6C10.8 9.45 10.65 9 10.65 8.7C10.65 8.4 10.8 7.95 11.1 7.8C11.55 7.5 12 7.2 12.45 7.35C13.35 7.35 14.1 7.8 14.55 8.4L15.9 6.6C15.45 6.15 15 5.85 14.55 5.55C14.1 5.25 13.5 5.1 12.9 5.1V3H11.1V5.1C10.35 5.25 9.6 5.7 9 6.3C8.4 7.05 7.95 7.95 8.1 8.85C8.1 9.75 8.4 10.65 9 11.25C9.75 12 10.8 12.45 11.7 12.9C12.15 13.05 12.75 13.35 13.2 13.65C13.5 13.95 13.65 14.4 13.65 14.85C13.65 15.3 13.5 15.75 13.2 16.2C12.75 16.65 12.15 16.8 11.7 16.8C11.1 16.8 10.35 16.65 9.9 16.2C9.45 15.9 9 15.45 8.7 15L7.2 16.65C7.65 17.25 8.1 17.7 8.7 18.15C9.45 18.6 10.35 19.05 11.25 19.05V21H12.9V18.75C13.8 18.6 14.55 18.15 15.15 17.55C15.9 16.8 16.35 15.6 16.35 14.55C16.35 13.65 16.05 12.6 15.3 12C14.55 11.25 13.8 10.8 12.9 10.5ZM12 0C5.4 0 0 5.4 0 12C0 18.6 5.4 24 12 24C18.6 24 24 18.6 24 12C24 5.4 18.6 0 12 0ZM12 22.35C6.3 22.35 1.65 17.7 1.65 12C1.65 6.3 6.3 1.65 12 1.65C17.7 1.65 22.35 6.3 22.35 12C22.35 17.7 17.7 22.35 12 22.35Z"
        fill="#121212"
      />
    </svg>
  );
}
export default Dollar;
