import { Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import DatePickerWithTitle from '../DatePickerWithTitle';
import { AppWebsiteVisits, AppWeeklySales, translate } from '../_dashboard/app';

export default function Referrals({ language }) {
  return (
    <>
      <DatePickerWithTitle language={language} title={translate(language, 'Referrals')} />
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4}>
          <AppWeeklySales
            language={language}
            points="0"
            title={translate(language, 'Total Accumulated Points')}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <AppWeeklySales
            language={language}
            points="0 INR"
            title={translate(language, 'Total Value of Accumulated Points')}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <AppWeeklySales
            language={language}
            points="0 INR"
            title={translate(language, 'Total Referral Sales')}
          />
        </Grid>
      </Grid>
      <Typography mt={5} mb={5} variant="h6">
        {translate(language, 'Detailed Analytics')}
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={6}>
          <AppWeeklySales
            language={language}
            points="0"
            title={translate(language, 'Number of Referral Reward Points')}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <AppWeeklySales
            language={language}
            points="0 INR"
            title={translate(language, 'Value of Referred Points')}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <AppWeeklySales
            language={language}
            points="0"
            title={translate(language, 'Number of Referred Friends')}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <AppWeeklySales
            language={language}
            points="0 INR"
            title={translate(language, 'Referral Sales')}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <AppWeeklySales
            language={language}
            points="0 %"
            title={translate(language, 'Conversion Rate')}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <AppWeeklySales
            language={language}
            points="0"
            title={translate(language, 'Referral Bonus Points')}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <AppWeeklySales
            language={language}
            points="0"
            title={translate(language, 'Referral Bonus Points')}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <AppWebsiteVisits sub="0" title={translate(language, 'Referral Links Clicks')} />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <AppWebsiteVisits sub="0" title={translate(language, 'Share Referral Insights')} />
        </Grid>
      </Grid>
    </>
  );
}

Referrals.propTypes = {
  language: PropTypes.element
};
