/* eslint-disable */
export default function minPointsRange(levelDetails) {
  const minVal = -1;
  const maxVal = Number.MAX_VALUE;

  const newArr = levelDetails.map((item) => ({
    ...item,
    minValOfMinPoints: minVal,
    maxValOfMinPoints: maxVal
  }));

  for (let i = 0; i < newArr.length; i++) {
    newArr[i].minValOfMinPoints = newArr[i - 1]?.min_points || minVal;
    newArr[i].maxValOfMinPoints = newArr[i + 1]?.min_points || maxVal;
  }
  
  return newArr;
}
