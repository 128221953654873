import { Typography, Box } from '@mui/material';
import PropTypes from 'prop-types';
// import { styled } from '@mui/material/styles';
import { translate } from '../_dashboard/app';

// const StyledHeaderCard = styled(CardHeader)({
//   borderBottom: '1px solid #D8E3E7'
// });

// const StyledCardDiv = styled(`div`)({
//   display: 'flex',
//   flexDirection: 'row',
//   justifyContent: 'space-between'
// });

// const StyledTotalCost = styled(CardContent)({
//   background: '#319044',
//   display: 'flex',
//   flexDirection: 'row',
//   justifyContent: 'space-between'
// });

// const StyledSquareBorderCard = styled(Card)({
//   borderRadius: 0
// });

export default function CurrentSub({ language }) {
  return (
    <Box
    //   style={{
    //     display: 'flex',
    //     flexDirection: 'column',
    //     justifyContent: 'center',
    //     alignItems: 'center'
    //   }}
    >
      <Typography variant="h4" gutterBottom>
        {translate(language, "You've activated your free plan until 31st December, 2022.")}
      </Typography>
      {/* <StyledSquareBorderCard variant="outlined">
        <StyledHeaderCard title="Current Subscription" />
        <CardContent style={{ borderBottom: '1px solid #D8E3E7' }}>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            Your account is billed monthly so your next payment is due on Dec 3, 2022.
          </Typography>
          <Typography variant="h6">Plan</Typography>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            Growth Plan
          </Typography>
        </CardContent>
        <CardContent>
          <StyledCardDiv>
            <Typography variant="h6">Subtotal</Typography>
            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
              Rs 500.00
            </Typography>
          </StyledCardDiv>
          <StyledCardDiv>
            <Typography variant="h6">Promo Code</Typography>
            <Typography variant="h6">
              <u>Add Promo Code</u>
            </Typography>
          </StyledCardDiv>
        </CardContent>
        <StyledTotalCost>
          <Typography color="white" variant="h6">
            Total Cost
          </Typography>
          <Typography color="white" variant="h6">
            Rs 500.00
          </Typography>
        </StyledTotalCost>
        <CardContent style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button variant="contained">Change Plan</Button>
        </CardContent>
      </StyledSquareBorderCard>
      <StyledSquareBorderCard style={{ marginTop: '10px' }} variant="outlined">
        <StyledHeaderCard title="Payment Method" />
        <CardContent>
          <Typography variant="h5" component="div">
            No Saved Cards
          </Typography>
        </CardContent>
      </StyledSquareBorderCard>
      <StyledSquareBorderCard variant="outlined" style={{ marginTop: '10px' }}>
        <StyledHeaderCard title="Invoice History" />
        <CardContent>
          <Typography variant="h5" component="div">
            No Invoices
          </Typography>
        </CardContent>
      </StyledSquareBorderCard>
      <StyledSquareBorderCard
        variant="outlined"
        style={{ marginTop: '10px', background: 'rgb(49,144,68,0.8)' }}
      >
        <Typography color="white">
          <CardHeader title="Cancel Subscription" color="white" />
          <CardContent>
            <Typography sx={{ fontSize: 14 }} gutterBottom>
              We will miss you. We try our best to meet our customer's expectations.
            </Typography>
          </CardContent>
        </Typography>
      </StyledSquareBorderCard> */}
    </Box>
  );
}

CurrentSub.propTypes = {
  language: PropTypes.element
};
