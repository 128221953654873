/* eslint-disable */
import { useState, useEffect } from 'react';
import {
  Container,
  Stack,
  Avatar,
  Grid,
  Typography,
  FormControlLabel,
  Switch
} from '@mui/material';

import axios from 'axios';
import coinsIcon from '@iconify/icons-fa-solid/coins';
import { ToastContainer } from 'react-toastify';

import HeaderCard from './HeaderCard';
import Page from '../Page';
import fetchSpinToWinData from '../../utils/spinToWinData';
import fetchOrgInfo from '../../utils/getOrgInfo';
import fetchWheelData from '../../utils/getWheelData';
import EditHeaders from './EditHeaders';
import WheelOptionsTable from './WheelOptionsTable';
import AddWheelTabOptions from './AddWheelTabOptions';
import CenterSpinner from '../CenterSpinner';
import { translate } from '../_dashboard/app';

export default function SpinToWin() {
  const [spinToWinData, setSpinToWinData] = useState({});
  const [wheelData, setWheelData] = useState([]);
  const [openHeader, setOpenHeader] = useState(false);
  const [spinToWinInfo, setSpinToWinInfo] = useState({});
  const [openWheelTabOptions, setopenWheelTabOptions] = useState(false);
  const [loading, setLoading] = useState(false);
  const language = localStorage.getItem('currentLanguage');

  const handleHeaderModalOpen = () => setOpenHeader(true);

  const handleOptionModalOpen = () => setopenWheelTabOptions(true);

  const handleActiveChange = async (value) => {
    setLoading(true);
    try {
      const res = await axios.patch('org', {
        spin_win_active: value
      });
      getData();
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const getData = async () => {
    setLoading(true);
    await fetchSpinToWinData(setSpinToWinData);
    await fetchOrgInfo(setSpinToWinInfo);
    setLoading(false);
  };

  const getWheelData = async () => {
    setLoading(true);
    await fetchWheelData(setWheelData);
    setLoading(false);
  };

  useEffect(() => {
    getData();
    getWheelData();
    if (wheelData.length < 4) {
      handleActiveChange(0);
    }
  }, []);

  const { spin_win_active, currency } = spinToWinInfo;

  if (loading) {
    return <CenterSpinner />;
  }

  return (
    <Page title={`${translate(language, 'Dashboard')} : ${translate(language, 'Program')} - ${translate(
      language,
      'Spin to Win'
    )}`}>
      <ToastContainer position="bottom-right" autoClose={1500} newestOnTop closeOnClick limit={2} />
      <Container>
        {openHeader && (
          <EditHeaders
            language={language}
            data={spinToWinData}
            getData={getData}
            open={openHeader}
            setOpen={setOpenHeader}
          />
        )}
        {openWheelTabOptions && (
          <AddWheelTabOptions
            currency={currency}
            getData={getWheelData}
            open={openWheelTabOptions}
            setOpen={setopenWheelTabOptions}
          />
        )}
        <Stack direction="row" justifyContent="space-between" mb={5}>
          <div style={{ display: 'flex', justifyContent: 'space-between', width: 'auto' }}>
            <Avatar
              sx={{ width: 56, height: 56 }}
              alt=" "
              src="/static/mock-images/avatars/referrals.png"
            />
            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '10px' }}>
              <Typography style={{ marginBottom: '-5px' }} variant="h4" gutterBottom>
              {translate(language,'Spin to Win')}
              </Typography>
              <Typography
                color={Boolean(spin_win_active) && wheelData.length > 3 ? '#00AB55' : 'error'}
                variant="button"
                gutterBottom
              >
                {spin_win_active && wheelData.length > 3 ? translate(language,'ACTIVE') : translate(language,'INACTIVE')}
              </Typography>
            </div>
          </div>
          <FormControlLabel
            control={
              <Switch
                onChange={(e) => handleActiveChange(e.target.checked)}
                checked={Boolean(spin_win_active) && wheelData.length > 3}
                disabled={wheelData.length < 4}
              />
            }
            label=""
          />
        </Stack>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <HeaderCard language={language} CardIcon={coinsIcon} handleHeaderModalOpen={handleHeaderModalOpen} />
          </Grid>
          <Grid item xs={12}>
            <WheelOptionsTable
              language={language}
              wheelData={wheelData}
              getWheelData={getWheelData}
              handleOptionModalOpen={handleOptionModalOpen}
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
