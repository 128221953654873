import {
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography
} from '@mui/material';
import PropTypes from 'prop-types';

export default function QnA1({ setQuestionNum, answers, setAnswers }) {
  return (
    <Card>
      <CardContent style={{ margin: '15px' }}>
        <FormControl style={{ width: '40vw' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '25px' }}>
            <Typography>What are your monthly active users?</Typography>
            <Typography>(1/3)</Typography>
          </div>
          <RadioGroup
            defaultValue={answers[1]}
            onChange={(event) => {
              setAnswers((prev) => (prev = { ...prev, 1: event.target.value }));
              setQuestionNum((prev) => prev + 1);
            }}
          >
            <FormControlLabel value="0-10" control={<Radio />} label="0-10k" />
            <FormControlLabel value="20-40" control={<Radio />} label="20k-40k" />
            <FormControlLabel value="above-40" control={<Radio />} label="More than 40k" />
          </RadioGroup>
        </FormControl>
      </CardContent>
    </Card>
  );
}

QnA1.propTypes = {
  answers: PropTypes.object,
  setAnswers: PropTypes.func,
  setQuestionNum: PropTypes.func
};
