import { useState } from 'react';
import axios from 'axios';
import {
  IconButton,
  Card,
  CardContent,
  Typography,
  Avatar,
  Dialog,
  DialogActions,
  DialogContent,
  Button
} from '@mui/material';
import { toast, ToastContainer } from 'react-toastify';
import { Icon } from '@iconify/react';
import trashFill from '@iconify/icons-eva/trash-2-fill';
import editFill from '@iconify/icons-eva/edit-fill';
import PropTypes from 'prop-types';
import BenefitsCard from './BenefitsCard';
import Coins from './icons/Coins';
import Points from './icons/Points';
import EditLevelsForm from './EditLevelsForm';
import { translate } from '../_dashboard/app';

const LevelDetailsCard = ({
  id,
  name,
  language,
  imageUrl,
  points,
  minPoints,
  bonusPoints,
  milestoneAmount,
  benefitsTable,
  getLevelsData,
  levelsTable,
  index,
  totalPlayer
}) => {
  const [openDeleteProp, setOpenDeleteProp] = useState(false);
  const [openEditLevelsForm, setOpenEditLevelsForm] = useState(false);

  const benefits = benefitsTable.filter((item) => item.level_id === id);
  const levels = levelsTable.filter((item) => item.id === id);

  const handleDeleteLevel = async () => {
    try {
      // eslint-disable-next-line
      const res = await axios.delete(`levels/${levels[0].id}`);

      await getLevelsData();
      toast.success(translate(language, 'Level Deleted Successfully!'));
    } catch (error) {
      console.log(error);
      toast.error(translate(language, 'Something went wrong!'));
    }
    setOpenDeleteProp(false);
  };

  if (index === 0) return '';

  return (
    <div
      style={{
        marginBottom: '55px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
      }}
    >
      {openEditLevelsForm && (
        <EditLevelsForm
          language={language}
          open={openEditLevelsForm}
          setOpen={setOpenEditLevelsForm}
          getData={getLevelsData}
          levelsData={levels[0]}
          benefitsData={benefits}
        />
      )}
      <Dialog open={openDeleteProp} onClose={() => setOpenDeleteProp(false)}>
        <DialogContent>
          {translate(language, 'Are you sure you want to delete this Level ?')}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteProp(false)}>{translate(language, 'No')}</Button>
          <Button onClick={handleDeleteLevel}>{translate(language, 'Yes')}</Button>
        </DialogActions>
      </Dialog>
      <ToastContainer position="bottom-right" autoClose={1500} newestOnTop closeOnClick limit={2} />
      <div>
        <Card style={{ minHeight: '100px', maxWidth: '350px' }}>
          <CardContent
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-around',
              alignItems: 'center'
            }}
          >
            <Typography variant="h5" mr={2} p={1}>
              #{index}
            </Typography>
            <Avatar alt="" variant="square" src={imageUrl} />
            <Typography component="div" ml={3}>
              <Typography variant="p">
                {name} ({totalPlayer} {translate(language, 'Players')})
              </Typography>
              <Typography variant="h6" sx={{ maxWidth: '170px' }}>
                {translate(language, 'Starts at %s Points', [minPoints])}
              </Typography>
            </Typography>
          </CardContent>
        </Card>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <Card style={{ minHeight: '100px', minWidth: '400px' }}>
          <CardContent
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start'
            }}
          >
            <div
              style={{
                marginBottom: '25px',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <Coins />
              <div style={{ marginLeft: '40px' }}>
                <Typography variant="p">{translate(language, 'Bonus Points')}</Typography>
                <Typography variant="h6">
                  {bonusPoints} {translate(language, 'Points')}
                </Typography>
              </div>
            </div>
            <div
              style={{
                marginBottom: '25px',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <Points />
              <div style={{ marginLeft: '40px' }}>
                <Typography variant="p">{translate(language, 'Points Ratio')}</Typography>
                <Typography variant="h6" sx={{ maxWidth: '250px' }}>
                  {points} {translate(language, 'point for every')} {milestoneAmount} INR{' '}
                  {translate(language, 'spent')}
                </Typography>
              </div>
            </div>
            {benefits.map((item, index) => (
              <BenefitsCard
                language={language}
                key={index}
                type={item.type}
                customBenefits={item.desc}
                discountPercentage={item.discount_percentage}
              />
            ))}
          </CardContent>
        </Card>
        <div style={{ marginLeft: '5px' }}>
          <IconButton onClick={() => setOpenEditLevelsForm(true)}>
            <Icon icon={editFill} />
          </IconButton>
          <IconButton onClick={() => setOpenDeleteProp(true)} edge="end">
            <Icon icon={trashFill} />
          </IconButton>
        </div>
      </div>
    </div>
  );
};

export default LevelDetailsCard;

LevelDetailsCard.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  imageUrl: PropTypes.string,
  points: PropTypes.number,
  minPoints: PropTypes.number,
  bonusPoints: PropTypes.number,
  milestoneAmount: PropTypes.number,
  benefitsTable: PropTypes.array,
  getLevelsData: PropTypes.func,
  levelsTable: PropTypes.array,
  index: PropTypes.number,
  totalPlayer: PropTypes.number,
  language: PropTypes.element
};
