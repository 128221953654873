import axios from 'axios';

const fetchEventsData = async (setEventsData) => {
  try {
    const res = await axios.get('events');
    setEventsData(res.data.data);
  } catch (error) {
    console.log(error);
  }
};

export default fetchEventsData;
