import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import Custom from './icons/Custom';
import Discount from './icons/Discount';
import { translate } from '../_dashboard/app';

const BenefitsCard = ({ type, language, customBenefits, discountPercentage }) => (
  <div
    style={{
      marginBottom: '25px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center'
    }}
  >
    {type === 'coupon' ? <Discount /> : <Custom />}
    <div style={{ marginLeft: '50px' }}>
      <Typography variant="p">
        {type === 'coupon'
          ? translate(language, 'Discount Coupon')
          : translate(language, 'Custom Benefit')}
      </Typography>
      <Typography variant="h6" sx={{ maxWidth: '250px' }}>
        {type === 'coupon'
          ? `${discountPercentage}% ${translate(language, 'Discount')}`
          : customBenefits}
      </Typography>
    </div>
  </div>
);

export default BenefitsCard;

BenefitsCard.propTypes = {
  type: PropTypes.string,
  customBenefits: PropTypes.string,
  discountPercentage: PropTypes.number,
  language: PropTypes.element
};
