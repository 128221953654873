import React, { useEffect, useState } from 'react';
import {
  Stack,
  Grid,
  TextField,
  Typography,
  FormControl,
  InputAdornment,
  OutlinedInput,
  IconButton
} from '@mui/material';
import { PropTypes } from 'prop-types';
import { Icon } from '@iconify/react';
import axios from 'axios';
import editFill from '@iconify/icons-eva/edit-fill';
import ShopDetails from './ShopDetails';
import { translate } from '../../app';
import CenterSpinner from '../../../CenterSpinner';

export default function AccountIntegration({ language }) {
  const user = JSON.parse(localStorage.getItem('loginUser'));
  const [orgData, setOrgData] = useState({
    shopDomain: '',
    shopType: 'other',
    shopKey: '',
    shopSecret: '',
    shopify_access_token: false
  });
  const [loading, setloading] = useState(false);
  useEffect(() => {
    fetchOrgData();
  }, []);

  const fetchOrgData = async () => {
    setloading(true);
    try {
      const res = await axios.get('org');
      setOrgData({
        shopDomain: res.data.data.shopDomain,
        shopType: res.data.data.shopType,
        shopKey: res.data.data.shopKey,
        shopSecret: res.data.data.shopSecret,
        shopify_access_token: res.data.data.shopify_access_token
      });
    } catch (error) {
      console.log(error);
    }
    setloading(false);
  };

  if (loading) {
    return <CenterSpinner />;
  }

  return (
    <div>
      <Grid>
        <Stack spacing={-1.7} paddingBottom={2}>
          <Typography gutterBottom variant="h5" align="left">
            {translate(language, 'Live API Key')}
          </Typography>
          <Typography variant="subtitle2" align="left" paddingTop={1}>
            {translate(
              language,
              'Use this key on your live production environment of your platform'
            )}
          </Typography>
        </Stack>
        <TextField
          value={user.user.orgs[0].apiKey}
          placeholder="Header"
          variant="outlined"
          fullWidth
        />
      </Grid>
      <Grid>
        <Stack spacing={-1.7} paddingTop={2} paddingBottom={2}>
          <Typography gutterBottom variant="h5" align="left">
            {translate(language, 'Test API Key')}
          </Typography>
          <Typography gutterBottom variant="subtitle2" align="left" paddingTop={1}>
            {translate(
              language,
              'Use this key to test the integration on your test (UAT or Stagging) staging environment of your platform'
            )}
          </Typography>
        </Stack>
        <TextField
          value={user.user.orgs[0].apiKey}
          placeholder="Header"
          variant="outlined"
          fullWidth
        />
      </Grid>
      <Grid>
        <Stack spacing={-1.7} paddingTop={2} paddingBottom={2}>
          <Typography gutterBottom variant="h5" align="left">
            {translate(language, 'Transaction key')}
          </Typography>
          <Typography gutterBottom variant="subtitle2" align="left" paddingTop={1}>
            {translate(
              language,
              'This is the transaction key for securing points transit for work on both test and live environment'
            )}
          </Typography>
        </Stack>
        <TextField
          value="hi8u9dhf56gfgh56h7556urdr6g8tq"
          placeholder="Header"
          variant="outlined"
          fullWidth
        />
      </Grid>
      <Grid>
        <Stack spacing={-1.7} paddingTop={2} paddingBottom={2}>
          <Typography gutterBottom variant="h5" align="left">
            {translate(language, 'Live Domain')}
          </Typography>
          <Typography gutterBottom variant="subtitle2" align="left" paddingTop={1}>
            {translate(language, 'Live Domain Description')}
          </Typography>
        </Stack>
        <FormControl fullWidth variant="outlined">
          <OutlinedInput
            value={translate(language, 'Live Domain')}
            endAdornment={
              <InputAdornment position="end">
                <IconButton edge="end">
                  <Icon fontSize={25} icon={editFill} />
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
      </Grid>
      <ShopDetails language={language} data={orgData} />
      <Grid>
        <Stack spacing={-1.7} paddingTop={2} paddingBottom={2}>
          <Typography gutterBottom variant="h5" align="left">
            {translate(language, 'Webhooks')}
          </Typography>
          <Typography gutterBottom variant="subtitle2" align="left" paddingTop={1}>
            {translate(language, 'Webhooks Description')}
          </Typography>
        </Stack>
        <FormControl fullWidth variant="outlined">
          <OutlinedInput
            value={translate(language, 'Notification Webhooks')}
            endAdornment={
              <InputAdornment position="end">
                <IconButton edge="end">
                  <Icon fontSize={25} icon={editFill} />
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
      </Grid>
    </div>
  );
}

AccountIntegration.propTypes = {
  language: PropTypes.element
};
