import { Container, Typography, Stack, Tab, Tabs, Box, IconButton } from '@mui/material';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import circleFilled from '@iconify/icons-ant-design/plus-circle-filled';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import GuestTable from '../components/_dashboard/messages/GuestTable';
import PlayerPage from '../components/_dashboard/messages/PlayerPage';
import Page from '../components/Page';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

export default function Messages() {
  const [value, setValue] = useState(0);

  const { state } = useLocation();
  useEffect(() => {
    if (state !== null) setValue(state);
  }, [state]);

  const handleChange = (event, newValue) => {
    console.log(newValue);
    setValue(newValue);
  };

  return (
    <Page title="Dashboard: Messages | Gamify">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <div>
            <Stack direction="col" alignItems="center">
              <Typography variant="h4" gutterBottom>
                Messsages
              </Typography>
              <p
                style={{
                  backgroundColor: '#A7F3D0',
                  color: '#059669',
                  marginLeft: 10,
                  borderRadius: 5,
                  padding: 3,
                  fontFamily: 'Public Sans',
                  fontSize: '12px'
                }}
              >
                Coming Soon !
              </p>
            </Stack>
            <p>
              Gamify enables you to send interactive messages based on events, smart triggers and
              behavior cohorts. Messages can be across multiple channels: push-notifications, in-app
              popups, and we in-app notifications.
            </p>
          </div>
        </Stack>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            allowScrollButtonsMobile
            aria-label="scrollable force tabs example"
          >
            <Tab label="Guest Messages" {...a11yProps(0)} />
            <Tab label="Player Messages" {...a11yProps(1)} />
          </Tabs>
        </Box>

        <TabPanel value={value} index={0}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              marginBottom: '5px'
            }}
          >
            <Typography variant="h6" style={{ color: 'green' }}>
              Add new guest message
            </Typography>
            <IconButton>
              <Icon fontSize={25} icon={circleFilled} color="green" />
            </IconButton>
          </div>
          <GuestTable />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <PlayerPage />
        </TabPanel>
      </Container>
    </Page>
  );
}
