import { useState, useEffect } from 'react';
import {
  Container,
  Stack,
  Avatar,
  Grid,
  Card,
  Select,
  Button,
  MenuItem,
  CardContent,
  Typography,
  FormControlLabel,
  Switch
} from '@mui/material';

import axios from 'axios';
import Page from '../Page';
import fetchOrgInfo from '../../utils/getOrgInfo';
import fetchLevelsData from '../../utils/getLevelsData';
import LevelDetailsCard from './LevelDetailsCard';
import PiggyBank from './icons/PiggyBank';
import LevelsForm from './LevelsForm';
import minPointsRange from '../../utils/minimumPointsRange';
import CenterSpinner from '../CenterSpinner';
import EmptyPage from '../EmptyPage';
import { translate } from '../_dashboard/app';

/* eslint-disable camelcase */
export default function Levels() {
  const [loading, setLoading] = useState(false);
  const [levelsInfo, setLevelsInfo] = useState({});
  const [levelsDetails, setLevelsDetails] = useState([]);
  const [benefitsDetails, setBenefitsDetails] = useState([]);
  const [openLevelsForm, setOpenLevelsForm] = useState(false);
  const [sumPoints, setSumPoints] = useState(0);
  const language = localStorage.getItem('currentLanguage');

  const handleActiveChange = async (event) => {
    try {
      // eslint-disable-next-line
      const req = await axios.patch('org', {
        levels_active: event.target.checked
      });
      getLevelsInfo();
    } catch (error) {
      console.log(error);
    }
  };

  const handleLevelsWindowChange = async (event) => {
    try {
      // eslint-disable-next-line
      const req = await axios.patch('org', {
        levels_window: event.target.value
      });
      getLevelsInfo();
    } catch (error) {
      console.log(error);
    }
  };

  const getLevelsInfo = async () => {
    setLoading(true);
    await fetchOrgInfo(setLevelsInfo);
    setLoading(false);
  };

  const getLevelsData = async () => {
    setLoading(true);
    await fetchLevelsData(setLevelsDetails, setBenefitsDetails, setSumPoints);
    setLoading(false);
  };

  useEffect(() => {
    getLevelsInfo();
    getLevelsData();
  }, []);

  const { levels_active, levels_window } = levelsInfo;

  const newlevelsDetails = minPointsRange(levelsDetails);

  const minPointsTillNow = levelsDetails.reduce(
    (acc, curr) => (acc < curr.min_points ? (acc = curr.min_points) : 0),
    -1
  );

  if (loading) return <CenterSpinner />;

  return (
    <Page
      title={`${translate(language, 'Dashboard')} : ${translate(language, 'Program')} - ${translate(
        language,
        'Levels'
      )}`}
    >
      <Container>
        {openLevelsForm && (
          <LevelsForm
            language={language}
            open={openLevelsForm}
            setOpen={setOpenLevelsForm}
            minPointsTillNow={minPointsTillNow}
            getData={getLevelsData}
          />
        )}
        <Stack direction="row" justifyContent="space-between" mb={5}>
          <div style={{ display: 'flex', justifyContent: 'space-between', width: '16%' }}>
            <Avatar
              sx={{ width: 56, height: 56 }}
              alt=" "
              src="/static/mock-images/avatars/Levels.png"
            />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Typography style={{ marginBottom: '-5px' }} variant="h4" gutterBottom>
                {translate(language, 'Levels')}
              </Typography>
              <Typography color={levels_active ? '#00AB55' : 'error'} variant="button" gutterBottom>
                {levels_active ? translate(language, 'ACTIVE') : translate(language, 'INACTIVE')}
              </Typography>
            </div>
          </div>
          <FormControlLabel
            control={<Switch onChange={handleActiveChange} checked={Boolean(levels_active)} />}
            label=""
          />
        </Stack>
        <Grid style={{ justifyContent: 'space-between' }} container>
          <Grid item>
            <Card style={{ minHeight: '100px' }}>
              <CardContent>
                <div
                  style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
                >
                  <PiggyBank />
                  <div style={{ marginLeft: '15px' }}>
                    <Typography variant="p">{translate(language, 'Amount Spent')}</Typography>
                    <Typography variant="h6">
                      {sumPoints} {translate(language, 'Points')}
                    </Typography>
                  </div>
                </div>
              </CardContent>
            </Card>
          </Grid>
          <Grid item>
            <Card style={{ minHeight: '100px', minWidth: '250px' }}>
              <CardContent>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-around',
                    alignItems: 'center'
                  }}
                >
                  <Typography variant="h5" mr={2}>
                    {translate(language, 'Level Expiry')}
                  </Typography>
                  <Select value={levels_window || ''} onChange={handleLevelsWindowChange}>
                    {/* <MenuItem value={30}>30 {translate(language,'Days')}</MenuItem>
                    <MenuItem value={90}>90 {translate(language,'Days')}</MenuItem>
                    <MenuItem value={180}>180 {translate(language,'Days')}</MenuItem>
                    <MenuItem value={360}>360 {translate(language,'Days')}</MenuItem> */}
                    <MenuItem value={-1}>{translate(language, 'Forever')}</MenuItem>
                  </Select>
                </div>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Grid marginY={8}>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <Typography variant="h5">
              {translate(language, 'Player Levels')}({Math.max(levelsDetails.length - 1, 0)})
            </Typography>
            <Button variant="contained" onClick={() => setOpenLevelsForm(true)}>
              {translate(language, 'Create New Level')}
            </Button>
          </div>
        </Grid>
        {levelsDetails.length === 1 && (
          <EmptyPage language={language} name={translate(language, 'Levels')} />
        )}
        {levelsDetails.map((item, index) => (
          <LevelDetailsCard
            language={language}
            index={index}
            key={index}
            id={item.id}
            name={item.name}
            imageUrl={item.image_url}
            points={item.points}
            minPoints={item.min_points}
            bonusPoints={item.bonus_points}
            milestoneAmount={item.milestone_amount}
            benefitsTable={benefitsDetails}
            levelsTable={newlevelsDetails}
            getLevelsData={getLevelsData}
            totalPlayer={item.playerCount}
          />
        ))}
      </Container>
    </Page>
  );
}
