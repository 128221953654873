import { Divider, Stack, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import PropTypes from 'prop-types';
import Eye from './icons/Eye';
import WidgetPreview from './WidgetPreview';

const WidgetSetUp = ({ setPage }) => {
  const SetupSchema = Yup.object().shape({
    title: Yup.string().required('Title is Required'),
    color: Yup.string().required('Color is Required'),
    points: Yup.string().required('Points is Required')
  });

  const formik = useFormik({
    initialValues: {
      title: '',
      color: '#1F6547',
      points: ''
    },
    validationSchema: SetupSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        // eslint-disable-next-line
        const req = await axios.patch('widget/settings', {
          color: values.color,
          widget_name: values.title,
          points_name: values.points
        });

        resetForm();
        setPage('video');
      } catch (error) {
        console.log(error);
        toast.error('Something went wrong');
      }
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, setFieldValue, values } =
    formik;

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <ToastContainer
              position="bottom-right"
              autoClose={1500}
              newestOnTop
              closeOnClick
              pauseOnFocusLoss={false}
              pauseOnHover={false}
              limit={2}
            />
            <Stack style={{ width: '80vw' }} direction="row" justifyContent="space-around">
              <Stack direction="column" justifyContent="space-between" alignItems="start">
                <Typography style={{ fontSize: '44px', fontWeight: '600', marginBottom: '25px' }}>
                  <span style={{ color: '#00AB55' }}>Set it up</span> your way
                </Typography>

                <Typography style={{ fontSize: '18px', fontWeight: '400' }}>Widget Name</Typography>
                <TextField
                  type="text"
                  fullWidth
                  placeholder="Rewards"
                  {...getFieldProps('title')}
                  error={Boolean(touched.title && errors.title)}
                  helperText={touched.title && errors.title}
                />

                <Typography style={{ fontSize: '18px', fontWeight: '400' }}>
                  Brand Colour
                </Typography>
                <TextField
                  onBlur={(event) => setFieldValue('color', event.target.value)}
                  defaultValue="#1F6547"
                  type="color"
                  fullWidth
                />
                <Typography style={{ fontSize: '18px', fontWeight: '400', paddingTop: '25px' }}>
                  Refer Loyalty points as
                </Typography>
                <TextField
                  type="text"
                  fullWidth
                  placeholder="Points"
                  {...getFieldProps('points')}
                  error={Boolean(touched.points && errors.points)}
                  helperText={
                    (touched.points && errors.points) || 'Example: Munchies, Coins, Tokens'
                  }
                />
                <LoadingButton
                  variant="contained"
                  type="submit"
                  size="large"
                  loading={isSubmitting}
                  sx={{ margin: '28px 0', paddingX: '55px', paddingY: '8px', fontSize: '18px' }}
                >
                  Next Step
                </LoadingButton>
              </Stack>
              <Divider orientation="vertical" style={{ height: '600px', color: '#000' }} />
              <Stack direction="column" justifyContent="start" alignContent="center">
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '15px 0'
                  }}
                >
                  <Eye />
                  <Typography paddingLeft={2}>Preview</Typography>
                </div>
                <WidgetPreview
                  color={values.color}
                  widgetName={values.title}
                  rewardsName={values.points}
                />
              </Stack>
            </Stack>
          </Form>
        </FormikProvider>
      </div>
    </>
  );
};

export default WidgetSetUp;

WidgetSetUp.propTypes = {
  setPage: PropTypes.func
};
