const Arrow = () => (
  <svg width="14" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="13" height="13" />
    <path
      d="M4.7998 1.6001L11.2964 8.12365L4.7998 14.4001"
      stroke="#121212"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Arrow;
