import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import axios from 'axios';
import PropTypes from 'prop-types';
import { Backdrop, Box, Fade, Modal, Stack, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { translate } from '../_dashboard/app';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4
};

export default function EventModal({ open, language, setOpen, type, data, getData }) {
  const handleClose = () => setOpen(false);

  const EventSchema = Yup.object().shape({
    name: Yup.string()
      .required('Name is Required')
      .matches('^[a-zA-Z0-9_]*$', 'Only Alphabets and Numbers allowed'),
    points: Yup.number()
      .required('Points is a required field')
      .positive('Must be a Positive Number')
  });

  const formik = useFormik({
    initialValues: {
      name: type === 'edit' ? data?.event_name : '',
      points: type === 'edit' ? data?.points : ''
    },
    validationSchema: EventSchema,
    enableReinitialize: true,
    onSubmit: async (values, { resetForm }) => {
      try {
        // eslint-disable-next-line
        const res =
          type === 'edit'
            ? await axios.patch(`events/${data.event_id}`, values)
            : await axios.post('events', values);

        resetForm();
        await getData();
        setOpen(false);
      } catch (error) {
        console.log(error);
      }
    }
  });

  const { dirty, errors, touched, handleSubmit, isSubmitting, getFieldProps, values } = formik;

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack spacing={3}>
                  <TextField
                    fullWidth
                    label={translate(language, 'Name')}
                    onInput={(e) => (e.target.value = e.target.value.replace(/ /g, '_'))}
                    value={values.name}
                    {...getFieldProps('name')}
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                  />
                  <TextField
                    fullWidth
                    label={translate(language, 'Points')}
                    type="number"
                    value={values.points}
                    {...getFieldProps('points')}
                    error={Boolean(touched.points && errors.points)}
                    helperText={touched.points && errors.points}
                  />
                  <LoadingButton
                    fullWidth
                    size="large"
                    type="submit"
                    disabled={!dirty}
                    variant="contained"
                    loading={isSubmitting}
                  >
                    {type === 'edit'
                      ? translate(language, 'Modify Event')
                      : translate(language, 'Add Event')}
                  </LoadingButton>
                </Stack>
              </Form>
            </FormikProvider>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

EventModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  type: PropTypes.string,
  data: PropTypes.object,
  getData: PropTypes.func,
  language: PropTypes.element
};
