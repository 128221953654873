import { styled } from '@mui/material/styles';
import { TextField, MenuItem, Toolbar } from '@mui/material';
import PropTypes from 'prop-types';
import { translate } from '../app';

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1, 0, 3)
}));

export default function PlayerListToolbar({ language, sortType, setSortType }) {
  return (
    <RootStyle>
      <TextField
        select
        size="medium"
        value={sortType}
        onChange={(event) => setSortType(event.target.value)}
      >
        <MenuItem value="Creation date by newest first">
          {translate(language, 'Creation date by newest first')}
        </MenuItem>
        <MenuItem value="Creation date by oldest first">
          {translate(language, 'Creation date by oldest first')}
        </MenuItem>
        <MenuItem value="Total Amount spent by highest first">
          {translate(language, 'Total amount spent by highest first')}
        </MenuItem>
        <MenuItem value="Total Amount spent by lowest first">
          {translate(language, 'Total amount spent by lowest first')}
        </MenuItem>
      </TextField>
    </RootStyle>
  );
}

PlayerListToolbar.propTypes = {
  sortType: PropTypes.string,
  setSortType: PropTypes.func,
  language: PropTypes.element
};
