import React from 'react';
import { Grid, Typography } from '@mui/material';
import { PropTypes } from 'prop-types';
import MerchantCreation from './MerchantCreation/MarchantCreation';
import { translate } from '../app';

export default function MerchantsTab({ language }) {
  return (
    <div style={{ overflowX: 'hidden' }}>
      <Typography variant="h4" align="left" paddingTop={1}>
        {translate(language, 'Merchants Creations')}
      </Typography>
      <Grid paddingY={3} paddingX={2}>
        <MerchantCreation language={language} />
      </Grid>
    </div>
  );
}
MerchantsTab.propTypes = {
  language: PropTypes.element
};
