import React from 'react';
import { Icon } from '@iconify/react';
import editFill from '@iconify/icons-eva/edit-fill';
import plusFill from '@iconify/icons-eva/plus-fill';
import { PropTypes } from 'prop-types';
import deleteFill from '@iconify/icons-ant-design/delete-filled';
import {
  Table,
  TableCell,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  Paper,
  Toolbar,
  Button,
  IconButton
} from '@mui/material';
import { translate } from '../../app';

function createData(name, status, userRole, creationDate, lastLogin) {
  return { name, status, userRole, creationDate, lastLogin };
}

const rows = [
  createData('Frozen yoghurt', 'Active', 'Admin', '19/NOV/2021 AT 07:17', '27/NOV/2021 AT 07:17'),
  createData('Chilled Milk', 'Active', 'Viewer', '25/MAR/2021 AT 15:03', '27/NOV/2021 AT 07:17'),
  createData('Fry Potato', 'Active', 'Sales', '07/FEB/2021 AT 11:45', '27/NOV/2021 AT 07:17')
];

const RootStyle = styled(Toolbar)(() => ({
  display: 'flex',
  justifyContent: 'space-between'
}));

export default function Accounts({ language }) {
  const handleEdit = (values) => {
    console.log(' Working ', values);
  };
  const handleDelete = (values) => {
    console.log(' Working ', values);
  };
  return (
    <div style={{ overflowX: 'hidden' }}>
      <RootStyle sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button variant="contained" startIcon={<Icon icon={plusFill} />}>
          {translate(language, 'Create New User')}
        </Button>
      </RootStyle>

      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>{translate(language, 'Username')}</TableCell>
              <TableCell align="right">{translate(language, 'Status')}</TableCell>
              <TableCell align="right">{translate(language, 'User Role')}</TableCell>
              <TableCell align="right">{translate(language, 'Creation Date')}</TableCell>
              <TableCell align="right">{translate(language, 'Last Login')}</TableCell>
              <TableCell align="right">{translate(language, 'Actions')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.name}>
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell align="right">{row.status}</TableCell>
                <TableCell align="right">{row.userRole}</TableCell>
                <TableCell align="right">{row.creationDate}</TableCell>
                <TableCell align="right">{row.lastLogin}</TableCell>
                <TableCell align="right">
                  <IconButton aria-label="expand row" size="small" onClick={() => handleEdit(row)}>
                    <Icon icon={editFill} />
                  </IconButton>
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => handleDelete(row)}
                  >
                    <Icon icon={deleteFill} />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

Accounts.propTypes = {
  language: PropTypes.element
};
