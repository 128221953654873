import { useState } from 'react';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import {
  Backdrop,
  Box,
  Fade,
  Modal,
  Container,
  Grid,
  Button,
  Card,
  CardContent,
  Typography,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  InputAdornment,
  Avatar
} from '@mui/material';
import PropTypes from 'prop-types';
import { LoadingButton } from '@mui/lab';
import Upload from './icons/Upload';
import Coins from './icons/Coins';
import { translate } from '../_dashboard/app';
import Discount from './icons/Discount';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '70%',
  borderRadius: '15px',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4
};

export default function LevelsForm({ open, setOpen, language, minPointsTillNow, getData }) {
  const [image, setImage] = useState('');
  const [preview, setPreview] = useState(false);

  const handleClose = () => setOpen(false);

  const handleImageChange = (event, newImage) => {
    setPreview(false);
    setImage(newImage);
  };

  const [benefits, setBenefits] = useState([]);

  const addTextField = () => setBenefits((prev) => [...prev, { type: '', value: '' }]);

  const handleBenefitChange = (event) => {
    setBenefits((prev) => {
      const newInput = prev.slice();
      newInput[newInput.length - 1] = { type: 'text', desc: event.target.value };

      return newInput;
    });
  };

  const handleCustomUpload = async (event) => {
    setPreview(true);
    const formData = new FormData();
    formData.append('file', event.target.files[0]);

    try {
      const res = await axios.post('upload', formData);
      setImage(res.data.fileUrl);
    } catch (error) {
      console.log(error);
    }
  };

  const LevelsFormSchema = Yup.object().shape({
    name: Yup.string().required(translate(language, 'Name is Required')),
    minPoints: Yup.number()
      .required(translate(language, 'Minimum Points is Required'))
      .positive()
      .min(
        minPointsTillNow + 1,
        translate(language, 'Minimum Points should be greater than %s', [minPointsTillNow])
      ),
    bonusPoints: Yup.number().required(translate(language, 'Bonus Points is Required')).positive(),
    discountPercentage: Yup.number()
      .positive(translate(language, 'Discount Percentage should be greater than 0'))
      .max(100, translate(language, 'Discount Percentage should be less than 100')),
    points: Yup.number()
      .required(translate(language, 'Required'))
      .positive(translate(language, 'Must be a Positive Number')),
    milestoneAmount: Yup.number()
      .required(translate(language, 'Required'))
      .positive(translate(language, 'Must be a Positive Number'))
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      minPoints: '',
      bonusPoints: '',
      discountPercentage: '',
      points: '',
      milestoneAmount: ''
    },
    validationSchema: LevelsFormSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        if (values.discountPercentage !== '')
          benefits.push({ type: 'coupon', discountPercentage: values.discountPercentage });

        values = { ...values, benefits };

        // eslint-disable-next-line
        const res = await axios.post('levels', {
          name: values.name,
          imageUrl: image,
          minPoints: values.minPoints,
          milestoneAmount: values.milestoneAmount,
          points: values.points,
          bonusPoints: values.bonusPoints,
          benefits: values.benefits
        });

        resetForm();
        getData();
        setOpen(false);
      } catch (error) {
        const newBenefits = benefits.filter((item) => item.type === 'text');
        setBenefits(newBenefits);
        if (error.response.data.message) toast.error(error.response.data.message);
        if (error.response.data.errors) toast.error(error.response.data.errors[0].msg);
      }
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <div>
      <ToastContainer
        position="bottom-right"
        autoClose={2500}
        newestOnTop
        closeOnClick
        pauseOnFocusLoss
        pauseOnHover
        limit={2}
      />
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={open}>
          <Box sx={style} style={{ height: '85%', overflowY: 'scroll' }}>
            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Container>
                  <Grid container spacing={5} mb={3}>
                    <Grid item md={6} lg={6}>
                      <Typography variant="div">
                        <Typography variant="h6">{translate(language, 'Level Name')}</Typography>
                        <Typography variant="p">
                          {translate(
                            language,
                            'Choose a suitable level name and image to appear to your players.'
                          )}
                        </Typography>
                      </Typography>
                      <Typography variant="subtitle2" marginY={3}>
                        {translate(language, 'Language: English (Default)')}
                      </Typography>
                      <TextField
                        fullWidth
                        label={translate(language, 'Name')}
                        type="text"
                        {...getFieldProps('name')}
                        error={Boolean(touched.name && errors.name)}
                        helperText={touched.name && errors.name}
                      />
                      <Typography variant="h6" marginTop={3} mb={1}>
                        {translate(language, 'Level Icon')}
                      </Typography>
                      <div
                        style={{ display: 'flex', flexDirection: 'row', alignContent: 'center' }}
                      >
                        {preview && (
                          <Avatar
                            style={{ marginTop: '15px', marginRight: '15px' }}
                            variant="square"
                            alt=""
                            src={image}
                          />
                        )}
                        <ToggleButton
                          style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                          component="label"
                          value="custom"
                        >
                          <Upload />
                          <input
                            type="file"
                            accept=".jpeg, .png"
                            hidden
                            style={{ margin: '10px', display: 'none' }}
                            onChange={handleCustomUpload}
                          />
                        </ToggleButton>
                        <Typography component="span" sx={{ display: 'flex', flexDirection: 'row' }}>
                          <ToggleButtonGroup
                            color="primary"
                            variant="contained"
                            value={image}
                            exclusive
                            onChange={handleImageChange}
                          >
                            <ToggleButton
                              style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                              component="label"
                              value="https://gsaas-getmok.s3.ap-south-1.amazonaws.com/file-1656067673143.png"
                            >
                              <Avatar
                                variant="square"
                                alt=""
                                src="https://gsaas-getmok.s3.ap-south-1.amazonaws.com/file-1656067673143.png"
                              />
                            </ToggleButton>
                            <ToggleButton
                              component="label"
                              value="https://gsaas-getmok.s3.ap-south-1.amazonaws.com/file-1656067351920.png"
                            >
                              <Avatar
                                variant="square"
                                alt=""
                                src="https://gsaas-getmok.s3.ap-south-1.amazonaws.com/file-1656067351920.png"
                              />
                            </ToggleButton>
                            <ToggleButton
                              component="label"
                              value="https://gsaas-getmok.s3.ap-south-1.amazonaws.com/file-1656067807016.png"
                            >
                              <Avatar
                                variant="square"
                                alt=""
                                src="https://gsaas-getmok.s3.ap-south-1.amazonaws.com/file-1656067807016.png"
                              />
                            </ToggleButton>
                          </ToggleButtonGroup>
                        </Typography>
                      </div>
                    </Grid>
                    <Grid item md={6} lg={6}>
                      <Typography component="div" marginBottom={3}>
                        <Typography variant="h6">
                          {translate(language, 'Minimum Points')}
                        </Typography>
                        <Typography component="p" marginBottom={3}>
                          {translate(
                            language,
                            'Enter the minimum value to be achieved by your player to reach this level.'
                          )}
                        </Typography>
                        <TextField
                          fullWidth
                          placeholder={translate(language, 'Minimum Points')}
                          type="number"
                          {...getFieldProps('minPoints')}
                          error={Boolean(touched.minPoints && errors.minPoints)}
                          helperText={touched.minPoints && errors.minPoints}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Coins width="30" height="30" />
                              </InputAdornment>
                            )
                          }}
                        />
                      </Typography>
                      <Typography component="div" marginY={3}>
                        <Typography variant="h6">
                          {translate(language, 'Loyal Welcome Reward')}
                        </Typography>
                        <Typography component="p" marginBottom={3}>
                          {translate(language, 'Define a welcome reward upon reaching this level.')}
                        </Typography>
                        <TextField
                          fullWidth
                          placeholder={translate(language, 'Bonus Points')}
                          type="number"
                          {...getFieldProps('bonusPoints')}
                          error={Boolean(touched.bonusPoints && errors.bonusPoints)}
                          helperText={touched.bonusPoints && errors.bonusPoints}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Coins width="30" height="30" />
                              </InputAdornment>
                            )
                          }}
                        />
                      </Typography>
                      <Typography component="div" marginY={3}>
                        <Typography variant="h6" component="h6" marginBottom={3}>
                          {translate(language, 'Discount Coupon Reward')}
                        </Typography>
                        <TextField
                          fullWidth
                          placeholder={translate(language, 'Discount %')}
                          type="number"
                          {...getFieldProps('discountPercentage')}
                          error={Boolean(touched.discountPercentage && errors.discountPercentage)}
                          helperText={touched.discountPercentage && errors.discountPercentage}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Discount width="30" height="30" />
                              </InputAdornment>
                            )
                          }}
                        />
                      </Typography>
                      <Typography variant="h6" mb={3}>
                        {translate(language, 'Points Ratio')}
                      </Typography>
                      <Typography component="div">
                        {translate(language, 'Player will get')}{' '}
                        <TextField
                          type="number"
                          size="small"
                          sx={{ width: '80px', bottom: '7px' }}
                          {...getFieldProps('points')}
                          error={Boolean(touched.points && errors.points)}
                          helperText={touched.points && errors.points}
                        />{' '}
                        {translate(language, 'points for every')}{' '}
                        <TextField
                          type="number"
                          size="small"
                          sx={{ width: '80px', bottom: '7px' }}
                          {...getFieldProps('milestoneAmount')}
                          error={Boolean(touched.milestoneAmount && errors.milestoneAmount)}
                          helperText={touched.milestoneAmount && errors.milestoneAmount}
                        />{' '}
                        INR {translate(language, 'spent on the store.')}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography component="div" marginY={10}>
                    <Card>
                      <CardContent>
                        <Typography variant="h6">
                          {translate(language, 'Level Custom Benefits')}
                        </Typography>
                        <Typography component="p" marginY={3}>
                          {translate(
                            language,
                            'You can add custom benefits per level which will be shown to your players on Evolve widget.'
                          )}
                        </Typography>
                        {benefits.map((item, index) => (
                          <TextField
                            key={index}
                            onBlur={handleBenefitChange}
                            sx={{ marginY: '35px' }}
                            fullWidth
                            placeholder={translate(language, 'Benefit details')}
                            variant="standard"
                          />
                        ))}
                        <Button onClick={addTextField}>
                          + {translate(language, 'Add benefit')}
                        </Button>
                      </CardContent>
                    </Card>
                  </Typography>
                  <Typography
                    component="div"
                    style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}
                  >
                    <LoadingButton
                      size="large"
                      type="submit"
                      variant="contained"
                      loading={isSubmitting}
                    >
                      {translate(language, 'Save')}
                    </LoadingButton>
                  </Typography>
                </Container>
              </Form>
            </FormikProvider>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

LevelsForm.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  minPointsTillNow: PropTypes.number,
  getData: PropTypes.func,
  language: PropTypes.element
};
