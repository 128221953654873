/* eslint-disable */
import React, { useState, useEffect } from 'react';
import {
  Avatar,
  Container,
  CardHeader,
  Card,
  Box,
  Button,
  CardActions,
  CardActionArea,
  CardContent,
  Typography
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

import Page from '../components/Page';
import { translate } from '../components/_dashboard/app';
import fetchWheelData from '../utils/getWheelData';
import fetchReferralData from '../utils/referralData';
import fetchOrgInfo from '../utils/getOrgInfo';
import ProgramSkeleton from '../components/ProgramSkeleton';
import fetchCouponsTableData from '../utils/getCouponsTableData';
import fetchLevelsData from '../utils/getLevelsData';
import fetchEventsData from '../utils/getEventsData';

export default function Programs() {
  const [loading, setLoading] = useState(false);
  const language = localStorage.getItem('currentLanguage');

  const navigate = useNavigate();

  const [referral, setReferral] = useState({});
  const [orgInfo, setOrgInfo] = useState({})
  const [redeemData, setredeemData] = useState({});
  const [levelsData, setLevelsData] = useState([]);
  const [eventsData, setEventsData] = useState([]);
  const [wheelData, setWheelData] = useState([]);

  useEffect(() => {
    setLoading(true);
    fetchReferralData(setReferral);
    fetchOrgInfo(setOrgInfo);
    fetchCouponsTableData(setredeemData);
    fetchEventsData(setEventsData);
    fetchLevelsData(setLevelsData);
    fetchWheelData(setWheelData);
    setLoading(false);
  }, []);

  const { currency, referralRules } = referral;
  const { levels_active, referral_active: isActive, spin_win_active, intent_based_promo } = orgInfo;

  const firstLevel = levelsData.filter((item) => item.min_points === 0)[0];

  if (loading) {
    return <ProgramSkeleton />;
  }

  return (
    // ----------------------------------------------Program Header ------------------------------------------------------
    <Page title={`${translate(language, 'Dashboard')}: ${translate(language, 'Programs')}`}>
      <Container>
        <Typography variant="h4" gutterBottom>
        {translate(language, 'Programs')}
        </Typography>
        <p>
        {translate(language, "Gamify your player's experience through different programs Start now and set up programs to keep them engaged!")}
        </p>
      </Container>
      {/* ---------------------------------------------------------------------------------------------------------------------------------  */}
      <Container>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-evenly',
            '& > :not(style)': {
              m: 1.7,
              mt: 5,
              width: 400
            }
          }}
        >
          {/* ----------------------------------------------------Card CashBack---------------------------------------------------------------- */}
          <Card varient="outlined" style={{ width: '26rem', height: '' }}>
            <CardHeader
              avatar={<Avatar alt=" " src="/static/mock-images/avatars/Cashback.png" />}
              title={translate(language, "Cashback")}
            />
            <CardActionArea>
              <CardContent>
                <Typography variant="body2" color="text.secondary" style={{ whiteSpace: 'pre-wrap' }}>
                  <p>{translate(language, 'Reward your players some amazing discount for their every purchase on your platform.')}</p>
                  <p> </p>
                  <Button size="small">{translate(language, 'Learn More')}</Button>
                  <h4>
                    {firstLevel?.points ?? 0} {translate(language, 'point for every')}{' '}
                    {`${firstLevel?.milestone_amount ?? 0} ${currency}`} {translate(language, 'spent')}
                  </h4>
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions component="div">
              <Button
                onClick={() => navigate('/dashboard/programs/cashback')}
                variant="contained"
                size="medium"
              >
                {translate(language, 'Configure')}
              </Button>
            </CardActions>
          </Card>
          {/* ------------------------------Redeem------------------------------------------- */}
          <Card varient="outlined" style={{ width: '26rem', height: '' }}>
            <CardHeader
              avatar={<Avatar alt=" " src="/static/mock-images/avatars/Levels.png" />}
              title={translate(language, "Redeem")}
              action={
                <Button color={redeemData.length !== 0 ? 'success' : 'error'}>
                  {redeemData.length !== 0 ? translate(language, 'Active') : translate(language, 'Needs Configuration')}
                </Button>
              }
            />
            <CardActionArea>
              <CardContent>
                <Typography variant="body2" color="text.secondary" style={{ whiteSpace: 'pre-wrap' }}>
                  <p>{translate(language, 'Players redeem their coupons to win exciting offers and great deals offered by you.')}</p>
                  <p> </p>
                  <Button size="small">{translate(language, 'Learn More')}</Button>
                  <h4 style={{ visibility: 'hidden' }}>{translate(language, '5 challenges and 0 missions activated.')}</h4>
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions component="div">
              <Button
                onClick={() => navigate('/dashboard/programs/redeem')}
                variant="contained"
                size="medium"
              >
                {translate(language, 'Configure')}
              </Button>
            </CardActions>
          </Card>
          {/* ----------------------------------------------Card Referrals-------------------------------------------------------------------- */}
          <Card varient="outlined" style={{ width: '26rem', height: '' }}>
            <CardHeader
              avatar={<Avatar alt=" " src="/static/mock-images/avatars/referrals.png" />}
              title={translate(language, "Referrals")}
              action={
                <Button color={isActive ? 'success' : 'error'}>
                  {isActive ? translate(language, 'Active') : translate(language, 'Inactive')}
                </Button>
              }
            />
            <CardActionArea>
              <CardContent>
                <Typography variant="body2" color="text.secondary" style={{ whiteSpace: 'pre-wrap' }}>
                  <p>
                  {translate(language, "Gift your player and their friends with rewards for every successful referral cycle")}
                  </p>
                  <p> </p>
                  <Button size="small">{translate(language, 'Learn More')}</Button>
                  <h4>
                    {referralRules?.points} {translate(language, 'point for every')} {referralRules?.mileStoneValue} {currency}
                  </h4>
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions component="div">
              <Button
                onClick={() => navigate('/dashboard/programs/referrals')}
                variant="contained"
                size="medium"
              >
                {translate(language, 'Configure')}
              </Button>
            </CardActions>
          </Card>
          {/* --------------------------------------------------Card Challenges and Missions--------------------------------------------------- */}
          <Card varient="outlined" style={{ width: '26rem', height: '' }}>
            <CardHeader
              avatar={<Avatar alt=" " src="/static/mock-images/avatars/Challange-Mission.png" />}
              title={translate(language, "Challenges")}
            />
            <CardActionArea>
              <CardContent>
                <Typography variant="body2" color="text.secondary" style={{ whiteSpace: 'pre-wrap' }}>
                  <p>
                  {translate(language, "Players complete challenges and collect rewards through designed activities on your platform.")}
                  </p>
                  <p> </p>
                  <Button size="small">{translate(language, 'Learn More')}</Button>
                  <h4 style={{ visibility: 'hidden' }}>{translate(language, "5 challenges and 0 missions activated.")}</h4>
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions component="div">
              <Button
                onClick={() => navigate("/dashboard/programs/challenges")}
                variant="contained"
                size="medium"
              >
                {translate(language, 'Configure')}
              </Button>
            </CardActions>
          </Card>
          {/* ---------------------------------------------------Card Levels----------------------------------------------------------------- */}
          <Card varient="outlined" style={{ width: '26rem', height: '' }}>
            <CardHeader
              avatar={<Avatar alt=" " src="/static/mock-images/avatars/Levels.png" />}
              title={translate(language, "Levels")}
              action={
                <Button color={levels_active ? 'success' : 'error'}>
                  {levels_active ? translate(language, 'Active') : translate(language, 'Inactive')}
                </Button>
              }
            />
            <CardActionArea>
              <CardContent>
                <Typography variant="body2" color="text.secondary" style={{ whiteSpace: 'pre-wrap' }}>
                  <p>{translate(language, "Players are tiered under different levels based on their amount spent.")}</p>
                  <p> </p>
                  <Button size="small">{translate(language, 'Learn More')}</Button>
                  <h4>
                    {levelsData?.length <= 1 ? 'No' : levelsData?.length - 1} {translate(language, "Level")}
                    {levelsData?.length > 2 ? 's' : ''} {translate(language, "Activated")}
                  </h4>
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions component="div">
              <Button
                onClick={() => navigate('/dashboard/programs/levels')}
                variant="contained"
                size="medium"
              >
                {translate(language, 'Configure')}
              </Button>
            </CardActions>
          </Card>
          {/* ---------------------------------------------------Spin Wheel----------------------------------------------------------------- */}
          <Card varient="outlined" style={{ width: '26rem', height: '' }}>
            <CardHeader
              avatar={<Avatar alt=" " src="/static/mock-images/avatars/referrals.png" />}
              title={translate(language, "Spin to Win")}
              action={
                <Button color={Boolean(spin_win_active) && (wheelData.length > 3) ? 'success' : 'error'}>
                  {Boolean(spin_win_active) && (wheelData.length > 3) ? translate(language, "Active") : ((wheelData.length > 3) ? translate(language, "Inactive") : translate(language, "Needs Configuration"))}
                </Button>
              }
            />
            <CardActionArea>
              <CardContent>
                <Typography variant="body2" color="text.secondary" style={{ whiteSpace: 'pre-wrap' }}>
                  <p>{translate(language, "Engage more with your players by giving them a chance to win exciting prizes through spin to win wheel")}</p>
                  <p> </p>
                  <Button size="small">{translate(language, 'Learn More')}</Button>
                  <h4 style={{ visibility: 'hidden' }}>{translate(language, "5 challenges and 0 missions activated.")}</h4>
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions component="div">
              <Button
                onClick={() => navigate('/dashboard/programs/spinToWin')}
                variant="contained"
                size="medium"
              >
                {translate(language, 'Configure')}
              </Button>
            </CardActions>
          </Card>
          {/* ---------------------------------------------------Events----------------------------------------------------------------- */}
          <Card varient="outlined" style={{ width: '26rem', height: '' }}>
            <CardHeader
              avatar={<Avatar alt=" " src="/static/mock-images/avatars/referrals.png" />}
              title={translate(language, "Events")}
              action={
                <Button color={eventsData.length > 0 ? "success" : "error"}>
                  {eventsData.length > 0 ? translate(language, "Active") : translate(language, "Needs Configuration")}
                </Button>
              }
            />
            <CardActionArea>
              <CardContent>
                <Typography variant="body2" color="text.secondary" style={{ whiteSpace: 'pre-wrap' }}>
                  <p>{translate(language, "Setup custom events like Order Created, Page Viewed, Review Given and give points based on these events.")}</p>
                  <p> </p>
                  <Button size="small">{translate(language, 'Learn More')}</Button>
                  <h4 style={{ visibility: 'hidden' }}>{translate(language, '5 challenges and 0 missions activated.')}</h4>
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions component="div">
              <Button
                onClick={() => navigate('/dashboard/programs/events')}
                variant="contained"
                size="medium"
              >
                {translate(language, 'Configure')}
              </Button>
            </CardActions>
          </Card>
          {/* -------------------------------------------Intent Based Promotion----------------------------------------------------------------- */}
          <Card varient="outlined" style={{ width: '26rem', height: '' }}>
            <CardHeader
              avatar={<Avatar alt=" " src="/static/mock-images/avatars/referrals.png" />}
              title={translate(language, "Intent Based Promotion")}
              action={
                <Button color={intent_based_promo ? "success" : "error"}>
                  {intent_based_promo ? translate(language, "Active") : translate(language, "Inactive")}
                </Button>
              }
            />
            <CardActionArea>
              <CardContent>
                <Typography variant="body2" color="text.secondary" style={{ whiteSpace: 'pre-wrap' }}>
                  <p>{translate(language, 'Let our Prediction engine create promotions based on each visitor’s intent and any optional guidelines you set.')}</p>
                  <p> </p>
                  <Button size="small">{translate(language, 'Learn More')}</Button>
                  <h4 style={{ visibility: 'hidden' }}>{translate(language, '5 challenges and 0 missions activated.')}</h4>
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions component="div">
              <Button
                onClick={() => navigate('/dashboard/programs/intentbasepromotion')}
                variant="contained"
                size="medium"
              >
                {translate(language, 'Configure')}
              </Button>
            </CardActions>
          </Card>
        </Box>
      </Container>
    </Page>
  );
}