import React from 'react';
import { Button, Typography, TextField, MenuItem } from '@mui/material';

export default function ScheduleMessage() {
  return (
    <div style={{ display: 'flex', justifyContent: 'flex-left' }}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Typography
          variant="h5"
          gutterBottom
          style={{
            width: '450px'
          }}
        >
          Message Time
        </Typography>
        <Typography align="left">
          Choose if the message should appear immediately after the trigger or set a delay.
        </Typography>
        <TextField select size="medium" value="Language 0" align="left" fullWidth>
          <MenuItem fullWidth required value="Language 0">
            Immediately
          </MenuItem>
        </TextField>
        <Typography style={{ height: '22px' }} align="center">
          {}
        </Typography>
        <Typography
          variant="h5"
          gutterBottom
          style={{
            width: '450px'
          }}
        >
          Frequency Settings
        </Typography>
        <Typography align="left">
          Select the frequency of the appearance of this message.
        </Typography>
        <TextField select size="medium" value="Language 0" align="left" fullWidth>
          <MenuItem fullWidth required value="Language 0">
            One-time
          </MenuItem>
        </TextField>
        <Typography style={{ height: '22px' }} align="center">
          {}
        </Typography>
        <Typography
          variant="h5"
          gutterBottom
          style={{
            width: '450px'
          }}
        >
          Message Status
        </Typography>
        <Typography align="left">
          Choose "Active" to publish the message on your platform.
        </Typography>
        <TextField select size="medium" value="Language 0" align="left" fullWidth>
          <MenuItem fullWidth required value="Language 0">
            Active
          </MenuItem>
        </TextField>
        <Typography align="right" pt={5}>
          <Button style={{ height: '50px', width: '100px' }} variant="contained">
            Next
          </Button>
        </Typography>
      </div>
    </div>
  );
}
