import { Container, Box, Tabs, Tab, Typography, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Page from '../components/Page';
import BasicTab from '../components/_dashboard/admin/BasicTab';
import PlayersTab from '../components/_dashboard/admin/PlayersTab';
import IntegrationTab from '../components/_dashboard/admin/IntegrationTab';
import MerchantsTab from '../components/_dashboard/admin/MerchantsTab';
import AccountsTab from '../components/_dashboard/admin/AccountsTab';
import { translate } from '../components/_dashboard/app';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

export default function Analytics() {
  const [value, setValue] = useState(0);
  const { state } = useLocation();
  useEffect(() => {
    if (state !== null) setValue(state);
  }, [state]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const language = localStorage.getItem('currentLanguage');
  return (
    <Page
      title={`${translate(language, 'Dashboard')}: ${translate(language, 'Organization Settings')}`}
    >
      <Container>
        <Stack direction="column" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {translate(language, 'Organization Settings')}
          </Typography>
          <p>
            {translate(
              language,
              'Set up your Evolve account setting, players data and integrations'
            )}
            .
          </p>
        </Stack>

        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            allowScrollButtonsMobile
            aria-label="scrollable force tabs example"
          >
            <Tab label={translate(language, 'Basic')} {...a11yProps(0)} />
            <Tab label={translate(language, 'Players')} {...a11yProps(1)} />
            <Tab label={translate(language, 'Integrations')} {...a11yProps(2)} />
            <Tab label={translate(language, 'Merchants')} {...a11yProps(3)} />
            <Tab label={translate(language, 'Team')} {...a11yProps(4)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <BasicTab language={language} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <PlayersTab language={language} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <IntegrationTab language={language} />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <MerchantsTab language={language} />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <AccountsTab language={language} />
        </TabPanel>
      </Container>
    </Page>
  );
}
