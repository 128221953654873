import {
  Card,
  Table,
  TableHead,
  TableContainer,
  TableCell,
  TableRow,
  IconButton,
  TableBody
} from '@mui/material';
import { Icon } from '@iconify/react';
import baseLineDelete from '@iconify/icons-ic/baseline-delete';
import editFill from '@iconify/icons-eva/edit-fill';
import { Link as RouterLink } from 'react-router-dom';

export default function GuestTable() {
  return (
    <Card>
      <TableContainer style={{ marginTop: '20px', display: 'flex' }} sx={{ minWidth: 800 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="left">Discover our reward experience</TableCell>
              <TableCell align="left">Channel</TableCell>
              <TableCell align="left">Trigger</TableCell>
              <TableCell align="left">Sent</TableCell>
              <TableCell align="left">Clicked</TableCell>
              <TableCell align="left">Conversion</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align="left">
                <RouterLink
                  to="/dashboard/messages/InAppNotification"
                  style={{
                    color: 'black',
                    textDecoration: 'none'
                  }}
                >
                  {'\u2B24'} Collect Badges...
                </RouterLink>
              </TableCell>
              <TableCell align="left">
                <RouterLink
                  to="/dashboard/messages/InAppNotification"
                  style={{
                    color: 'black',
                    textDecoration: 'none'
                  }}
                >
                  In-app, Web
                </RouterLink>
              </TableCell>
              <TableCell align="left">
                <RouterLink
                  to="/dashboard/messages/InAppNotification"
                  style={{
                    color: 'black',
                    textDecoration: 'none'
                  }}
                >
                  Default
                </RouterLink>
              </TableCell>
              <TableCell align="left">
                <RouterLink
                  to="/dashboard/messages/InAppNotification"
                  style={{
                    color: 'black',
                    textDecoration: 'none'
                  }}
                >
                  0
                </RouterLink>
              </TableCell>
              <TableCell align="left">
                <RouterLink
                  to="/dashboard/messages/InAppNotification"
                  style={{
                    color: 'black',
                    textDecoration: 'none'
                  }}
                >
                  0
                </RouterLink>
              </TableCell>
              <TableCell align="left">
                <RouterLink
                  to="/dashboard/messages/InAppNotification"
                  style={{
                    color: 'black',
                    textDecoration: 'none'
                  }}
                >
                  0
                </RouterLink>
              </TableCell>
              <TableCell align="left" style={{ color: 'green' }}>
                {'\u2B24'} Active
              </TableCell>
              <TableCell>
                <IconButton>
                  <Icon icon={editFill} fontSize={25} />
                </IconButton>
                <IconButton>
                  <Icon icon={baseLineDelete} fontSize={25} align="right" />
                </IconButton>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
}
