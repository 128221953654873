export default function FirstOrder() {
  return (
    <svg width="66" height="57" viewBox="0 0 66 57" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M41.37 16.18C42.02 16.15 42.57 15.9 43.02 15.43C43.47 14.96 43.7 14.4 43.7 13.75C43.7 14.4 43.92 14.96 44.37 15.43C44.82 15.9 45.37 16.15 46.02 16.18C45.37 16.21 44.82 16.46 44.37 16.93C44.1524 17.1515 43.9811 17.4142 43.8661 17.7026C43.7511 17.9911 43.6946 18.2995 43.7 18.61C43.7 17.96 43.48 17.4 43.02 16.93C42.57 16.46 42.02 16.21 41.37 16.18ZM43.7 8.97998C44.96 8.91998 46.03 8.42998 46.91 7.50998C47.79 6.58998 48.23 5.49998 48.23 4.22998C48.23 5.49998 48.67 6.58998 49.55 7.50998C50.43 8.42998 51.5 8.90998 52.77 8.97998C51.94 9.01998 51.18 9.24998 50.48 9.68998C49.79 10.12 49.24 10.7 48.83 11.42C48.43 12.14 48.23 12.91 48.23 13.75C48.23 12.48 47.79 11.38 46.91 10.46C46.03 9.52998 44.96 9.03998 43.7 8.97998ZM47.02 19.75C47.97 19.71 48.78 19.34 49.44 18.65C50.1 17.96 50.43 17.14 50.43 16.18C50.43 17.14 50.76 17.96 51.42 18.65C52.08 19.34 52.88 19.71 53.83 19.75C52.88 19.79 52.08 20.16 51.42 20.85C50.76 21.54 50.43 22.36 50.43 23.32C50.43 22.36 50.1 21.54 49.44 20.85C48.8092 20.1796 47.9399 19.7844 47.02 19.75ZM53.83 15.01C54.78 14.97 55.58 14.6 56.24 13.91C56.9 13.22 57.22 12.4 57.22 11.43C57.22 12.39 57.55 13.21 58.21 13.9C58.87 14.59 59.68 14.96 60.63 15C59.68 15.04 58.87 15.41 58.21 16.1C57.55 16.79 57.22 17.61 57.22 18.57C57.22 17.61 56.89 16.79 56.24 16.1C55.58 15.42 54.78 15.05 53.83 15.01Z"
        fill="#F9C73A"
      />
      <circle cx="10.6273" cy="17.4908" r="1.5528" fill="#F9C73A" />
      <circle cx="23.0497" cy="17.4908" r="1.5528" fill="#F9C73A" />
      <path
        d="M10.9379 15.6274C10.9379 14.5922 10.9379 13.4535 10.9379 12.2113C10.9379 11.9007 11.5246 6.00011 17.1491 6.00011C21.8074 6.00011 23.1532 9.93386 23.3602 12.2113V15.6274"
        stroke="black"
      />
      <path
        d="M31.4348 14.0747L32.677 24.9443L34.5 51.3418H14.6646H0.999985L4.41613 14.0747H31.4348Z"
        fill="#FF725E"
        stroke="black"
      />
      <path
        d="M47.2733 56.0002L45.4099 26.1865H17.1491L14.6646 55.6896L47.2733 56.0002Z"
        fill="#428BC1"
      />
      <path
        d="M46.3416 26.1865L51.3106 52.2735L48.205 55.6896M45.4099 26.1865L47.2733 56.0002L14.6646 55.6896L17.1491 26.1865H45.4099Z"
        stroke="black"
      />
      <circle cx="24.2919" cy="29.2921" r="1.5528" fill="#F9C73A" />
      <circle cx="36.7143" cy="29.2921" r="1.5528" fill="#F9C73A" />
      <path
        d="M24.6025 31.1553C24.6025 32.1905 24.6025 33.3292 24.6025 34.5714C24.6025 34.882 25.1892 40.7826 30.8137 40.7826C35.472 40.7826 36.8178 36.8489 37.0248 34.5714V31.1553"
        stroke="black"
      />
      <path
        d="M33.9387 46.515V48H28.2087V46.515H30.3387V41.28C30.2487 41.4 30.1087 41.525 29.9187 41.655C29.7287 41.785 29.5137 41.91 29.2737 42.03C29.0437 42.14 28.8087 42.23 28.5687 42.3C28.3287 42.37 28.1087 42.405 27.9087 42.405V40.86C28.1087 40.86 28.3387 40.8 28.5987 40.68C28.8587 40.56 29.1137 40.42 29.3637 40.26C29.6237 40.09 29.8437 39.93 30.0237 39.78C30.2037 39.62 30.3087 39.505 30.3387 39.435H32.0337V46.515H33.9387Z"
        fill="#F9C73A"
      />
    </svg>
  );
}
