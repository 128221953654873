export default function FifthOrder() {
  return (
    <svg width="51" height="51" viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.8777 34.9821V42.9908V34.9821ZM5.00436 42.9908V46.9952H19.8777V42.9908H5.00436ZM5.00436 42.9908H1V34.9821C1 33.2659 2.1441 31.2637 3.86026 29.8336C5.57642 28.4035 9.00873 27.5454 9.00873 27.5454H15.8733C15.8733 27.5454 19.3057 28.4035 21.0218 29.8336C22.738 31.2637 23.8821 33.2659 23.8821 34.9821V42.9908H19.8777H5.00436ZM5.00436 42.9908V34.9821V42.9908Z"
        fill="#F9C83A"
      />
      <path
        d="M5.00436 42.9908V34.9821M19.8777 34.9821V42.9908V34.9821ZM5.00436 42.9908V46.9952H19.8777V42.9908H5.00436ZM5.00436 42.9908H1V34.9821C1 33.2659 2.1441 31.2637 3.86026 29.8336C5.57642 28.4035 9.00873 27.5454 9.00873 27.5454H15.8733C15.8733 27.5454 19.3057 28.4035 21.0218 29.8336C22.738 31.2637 23.8821 33.2659 23.8821 34.9821V42.9908H19.8777H5.00436Z"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.8733 27.5456H9.0087V24.1133H15.8733V27.5456Z"
        fill="#F9C83A"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M42.6477 3.51927L39.8332 1.71979C39.3023 1.38041 39.0372 1.2109 38.7668 1.27038C38.4964 1.32987 38.3269 1.595 37.9875 2.12583L32.2292 11.1325C31.8898 11.6633 31.7203 11.9284 31.7798 12.1988C31.8393 12.4692 32.1044 12.6387 32.6352 12.9781L33.7611 13.6979L35.6067 13.2918L36.0127 15.1375L41.6419 18.7364C42.1727 19.0758 42.4378 19.2453 42.7082 19.1858C42.9786 19.1264 43.1481 18.8612 43.4875 18.3304L44.8416 16.2124C45.7399 14.8074 46.1891 14.1049 46.0775 13.3615C45.9661 12.6178 45.3307 12.0779 44.0599 10.9982L43.741 10.7269C42.4702 9.64715 41.8345 9.1071 41.7231 8.36343C41.6119 7.62021 42.0608 6.91798 42.9593 5.51266L43.0538 5.3649C43.3932 4.83407 43.5627 4.56893 43.5032 4.29855C43.4437 4.02817 43.1786 3.85866 42.6477 3.51927ZM44.5672 16.6416L33.3089 9.44372L44.5672 16.6416ZM37.3577 3.11093L42.424 6.35L37.3577 3.11093Z"
        fill="#FFC727"
      />
      <path
        d="M37.3577 3.11093L42.424 6.35M42.6477 3.51927L39.8332 1.71979C39.3023 1.38041 39.0372 1.2109 38.7668 1.27038C38.4964 1.32987 38.3269 1.595 37.9875 2.12583L32.2292 11.1325C31.8898 11.6633 31.7203 11.9284 31.7798 12.1988C31.8393 12.4692 32.1044 12.6387 32.6352 12.9781L33.7611 13.6979L35.6067 13.2918L36.0127 15.1375L41.6419 18.7364C42.1727 19.0758 42.4378 19.2453 42.7082 19.1858C42.9786 19.1264 43.1481 18.8612 43.4875 18.3304L44.8416 16.2124C45.7399 14.8074 46.1891 14.1049 46.0775 13.3615C45.9661 12.6178 45.3307 12.0779 44.0599 10.9982L43.741 10.7269C42.4702 9.64715 41.8345 9.1071 41.7231 8.36343C41.6119 7.62021 42.0608 6.91798 42.9593 5.51266L43.0538 5.3649C43.3932 4.83407 43.5627 4.56893 43.5032 4.29855C43.4437 4.02817 43.1786 3.85866 42.6477 3.51927ZM44.5672 16.6416L33.3089 9.44372L44.5672 16.6416Z"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37.8445 9.17146C38.3108 9.46961 38.9305 9.33327 39.2287 8.86693C39.5268 8.4006 39.3905 7.78086 38.9241 7.48272C38.4578 7.18457 37.8381 7.32091 37.5399 7.78724C37.2418 8.25358 37.3781 8.87331 37.8445 9.17146Z"
        fill="#407BFF"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.7924 18.5661L16.5629 9.3208H28.8899L29.6603 18.5661H23.8212L22.7264 14.3287L21.6316 18.5661H15.7924ZM22.7264 9.3208L23.882 12.0173L22.7264 9.3208ZM22.7264 9.3208L21.1855 12.21L22.7264 9.3208ZM20.4151 9.3208H25.0377H20.4151Z"
        fill="#FF725E"
      />
      <path
        d="M20.4151 9.3208H25.0377M15.7924 18.5661L16.5629 9.3208H28.8899L29.6603 18.5661H23.8212L22.7264 14.3287L21.6316 18.5661H15.7924ZM22.7264 9.3208L23.882 12.0173L22.7264 9.3208ZM22.7264 9.3208L21.1855 12.21L22.7264 9.3208Z"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M18.566 50.0002V21.4238H46.0707V50.0002H18.566Z" fill="#428BC1" />
      <path
        d="M23.2096 25.7103C25.9482 30.1158 33.3542 36.2835 41.0698 25.7103M18.566 21.4238V50.0002H46.0707V21.4238H18.566Z"
        stroke="black"
      />
      <path d="M47.1423 21.0663L50 18.5659V44.999L47.1423 49.2855" stroke="black" />
    </svg>
  );
}
