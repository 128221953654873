import * as React from 'react';
import { Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import CardModal from './CradModal';
import { translate } from '../app';

export default function EmailsSection({ language }) {
  return (
    <div>
      <Typography variant="h6" color="rgb(20,20,20)" align="left" paddingTop={1} paddingBottom={1}>
        {translate(
          language,
          'This the default emails that appears to your players upon receiving a reward!'
        )}
      </Typography>
      <Typography variant="h4" align="left">
        {translate(language, 'Cashback')}
      </Typography>
      <Grid
        mb={0.5}
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'left',
          '& > :not(style)': {
            m: 3
          }
        }}
      >
        <Grid item xs={12} sm>
          <CardModal
            language={language}
            title={translate(language, 'Cashback Email')}
            about={translate(language, 'All about cashback email')}
            modalHeading={translate(language, 'Cashback Email')}
            headingText=""
            bodyText={translate(language, 'Email Subject: New Purchase, New Reward!')}
          />
        </Grid>
      </Grid>
      <Typography variant="h4" align="left">
        {translate(language, 'Referrals')}
      </Typography>
      <Grid
        mb={0.5}
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'left',
          '& > :not(style)': {
            m: 3
          }
        }}
      >
        <Grid item xs={12} sm>
          <CardModal
            language={language}
            title={translate(language, 'Referral Email')}
            about={translate(language, 'All about referral email')}
            modalHeading={translate(language, 'Referral Email')}
            headingText=""
            bodyText={translate(language, 'Email Subject: New Purchase, New Reward!')}
          />
        </Grid>
      </Grid>
      <Typography variant="h4" align="left">
        {translate(language, 'Challenges')}
      </Typography>
      <Grid
        mb={0.5}
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'left',
          '& > :not(style)': {
            m: 3
          }
        }}
      >
        <Grid item xs={12} sm>
          <CardModal
            language={language}
            title={translate(language, 'Challenges')}
            about={translate(language, 'All about Challenges')}
            modalHeading={translate(language, 'Challenges')}
            bodyText=""
            headingText=""
          />
        </Grid>
      </Grid>
      <Typography variant="h4" align="left">
        {translate(language, 'Levels')}
      </Typography>
      <Grid
        mb={0.5}
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'left',
          '& > :not(style)': {
            m: 3
          }
        }}
      >
        <Grid item xs={12} sm>
          <CardModal
            language={language}
            title={translate(language, 'Levels')}
            about={translate(language, 'All about levels')}
            modalHeading={translate(language, 'Levels')}
            headingText=""
            bodyText={translate(language, 'Email Subject: Congrats on your new level.')}
          />
        </Grid>
      </Grid>
      <Typography variant="h4" align="left">
        {translate(language, 'Points Redemption')}
      </Typography>
      <Grid
        mb={0.5}
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'left',
          '& > :not(style)': {
            m: 3
          }
        }}
      >
        <Grid item xs={12} sm>
          <CardModal
            language={language}
            title={translate(language, 'Points Expiry Reminder (15 days)')}
            about={translate(language, 'All about points expiry reminder')}
            modalHeading={translate(language, 'Points Expiry Reminder')}
            headingText=""
            bodyText={translate(language, 'Email Subject: Points expiring soon!!')}
          />
        </Grid>
      </Grid>
    </div>
  );
}

EmailsSection.propTypes = {
  language: PropTypes.element
};
