const PiggyBank = () => (
  <svg width="47" height="40" viewBox="0 0 47 40" fill="none">
    <path
      d="M35.4961 6.79386C35.4961 6.79386 39.9125 4.30328 40.5559 4.54401C41.1994 4.78473 39.9927 9.68409 39.8322 10.0051C39.6717 10.326 35.4961 6.79386 35.4961 6.79386Z"
      fill="#FFC727"
      stroke="#263238"
    />
    <path
      d="M40.3135 5.18748C40.3135 5.18748 40.1221 5.4853 39.8783 5.91429C39.4524 6.66423 38.8691 7.81539 38.8691 8.48047C38.8691 9.52361 39.5111 9.44337 39.6716 8.63478L40.2332 6.30469"
      stroke="#263238"
    />
    <path
      d="M3.19275 19.8609C4.40378 19.8609 5.38551 18.5828 5.38551 17.0061C5.38551 15.4295 4.40378 14.1514 3.19275 14.1514C1.98173 14.1514 1 15.4295 1 17.0061C1 18.5828 1.98173 19.8609 3.19275 19.8609Z"
      fill="#FFC727"
      stroke="#263238"
    />
    <path
      d="M23.0675 37.4908C34.6067 37.4908 43.9611 29.5714 43.9611 19.8022C43.9611 10.0331 34.6067 2.11362 23.0675 2.11362C11.5282 2.11362 2.17383 10.0331 2.17383 19.8022C2.17383 29.5714 11.5282 37.4908 23.0675 37.4908Z"
      fill="#FFC727"
      stroke="#263238"
    />
    <path
      d="M36.6382 31.9958C33.0891 34.8228 28.3209 36.5557 23.0681 36.5557C13.6938 36.5557 5.84092 31.036 3.79785 23.626"
      fill="#FFC727"
    />
    <path
      d="M36.6382 31.9958C33.0891 34.8228 28.3209 36.5557 23.0681 36.5557C13.6938 36.5557 5.84092 31.036 3.79785 23.626"
      stroke="#263238"
    />
    <path
      d="M3.29205 20.3577C3.28434 20.1741 3.28125 19.9889 3.28125 19.8022C3.28176 17.7822 3.70544 15.7847 4.52499 13.9384"
      fill="#FFC727"
    />
    <path
      d="M3.29205 20.3577C3.28434 20.1741 3.28125 19.9889 3.28125 19.8022C3.28176 17.7822 3.70544 15.7847 4.52499 13.9384"
      stroke="#263238"
    />
    <path d="M16.793 4.06102C20.9045 2.89857 25.2578 2.89857 29.3693 4.06102" stroke="#263238" />
    <path
      d="M42.8039 14.3427C42.8039 14.3427 45.455 13.4585 45.8562 13.9415C46.2574 14.4245 46.2574 20.0445 45.9364 21.0892C45.6155 22.1339 43.4459 21.8916 41.9244 20.7682C41.4882 20.4619 41.1351 20.0519 40.897 19.575C40.6588 19.0982 40.5431 18.5696 40.5603 18.0369"
      fill="#FFC727"
    />
    <path
      d="M42.8039 14.3427C42.8039 14.3427 45.455 13.4585 45.8562 13.9415C46.2574 14.4245 46.2574 20.0445 45.9364 21.0892C45.6155 22.1339 43.4459 21.8916 41.9244 20.7682C41.4882 20.4619 41.1351 20.0519 40.897 19.575C40.6588 19.0982 40.5431 18.5696 40.5603 18.0369"
      stroke="#263238"
    />
    <path d="M41.1982 17.7962L39.5918 18.0369" stroke="#263238" />
    <path
      d="M40.7964 19.563C40.7964 19.563 40.3952 22.3746 40.8766 22.4548C41.3581 22.5351 42.162 21.0892 42.162 21.0892C42.162 21.0892 41.0371 19.8038 40.7964 19.563Z"
      fill="#263238"
      stroke="#263238"
    />
    <path
      d="M39.3353 12.9786C39.4896 13.5573 39.3955 14.0819 39.1378 14.1483C38.8801 14.2146 38.5514 13.798 38.4017 13.2224C38.252 12.6469 38.3415 12.1191 38.5992 12.0528C38.8569 11.9864 39.1871 12.3984 39.3353 12.9786Z"
      fill="#263238"
    />
    <path
      d="M30.8955 5.1952C30.8955 5.1952 35.9492 0.141529 36.9352 1.12757C37.9213 2.11362 37.5525 6.42814 37.5525 7.04538V8.77057"
      fill="#FFC727"
    />
    <path
      d="M30.8955 5.1952C30.8955 5.1952 35.9492 0.141529 36.9352 1.12757C37.9213 2.11362 37.5525 6.42814 37.5525 7.04538V8.77057"
      stroke="#263238"
    />
    <path
      d="M37.1022 2.21546C37.1022 2.21546 37.1825 0.930058 36.6193 1.73402C36.056 2.53797 34.4512 8.07772 34.9326 8.72119C35.4141 9.36467 36.7844 9.04216 37.1022 7.837C37.4201 6.63183 37.1825 3.17836 37.1825 3.17836"
      stroke="#263238"
    />
    <path d="M9.43945 31.5267L12.2047 39H15.257L16.6211 35.1438" fill="#FFC727" />
    <path d="M9.43945 31.5267L12.2047 39H15.257L16.6211 35.1438" stroke="#263238" />
    <path d="M34.9327 32.4943L32.2831 39H29.2309L27.8652 35.1438" fill="#FFC727" />
    <path d="M34.9327 32.4943L32.2831 39H29.2309L27.8652 35.1438" stroke="#263238" />
    <path
      d="M22.0482 28.564V27.0039C20.897 26.9586 20.0365 26.5898 19.4666 25.8975C18.8966 25.2052 18.588 24.1713 18.5407 22.7959L20.9063 22.3931C20.9164 22.8645 20.9754 23.3335 21.0822 23.7927C21.1445 24.1062 21.2924 24.3962 21.5096 24.6306C21.6077 24.7239 21.7235 24.7966 21.8502 24.8443C21.977 24.8921 22.112 24.9138 22.2472 24.9084C22.5929 24.9084 22.8305 24.7973 22.9602 24.572C23.0919 24.3365 23.1585 24.0702 23.153 23.8004C23.1705 23.2935 23.0327 22.7933 22.758 22.3669C22.4508 21.9326 22.0876 21.5409 21.6778 21.2018L20.252 19.9766C19.9409 19.6879 19.6497 19.3786 19.3802 19.0507C19.1157 18.7276 18.9071 18.3625 18.7629 17.9706C18.6026 17.5127 18.5258 17.0298 18.5361 16.5447C18.5361 15.4954 18.8354 14.6771 19.4342 14.0897C20.0329 13.5022 20.8348 13.1695 21.8399 13.0913V11.6145H22.6577V13.1067C23.1786 13.1172 23.6885 13.2583 24.1406 13.5172C24.5071 13.7385 24.8106 14.0505 25.0217 14.423C25.2259 14.7959 25.3726 15.1975 25.4569 15.6142C25.5421 16.0252 25.5968 16.442 25.6205 16.8611L23.2225 17.2129C23.2156 16.8447 23.1821 16.4775 23.1222 16.1142C23.0842 15.8436 22.978 15.5871 22.8136 15.3689C22.7305 15.276 22.6262 15.2046 22.5096 15.1608C22.393 15.117 22.2675 15.1021 22.1439 15.1174C22.0038 15.1154 21.8655 15.148 21.7411 15.2123C21.6168 15.2767 21.5102 15.3707 21.4309 15.4862C21.2696 15.7173 21.1844 15.9929 21.1871 16.2747C21.1669 16.7163 21.2975 17.1516 21.5575 17.5092C21.8126 17.8326 22.0928 18.1354 22.3954 18.415L23.7842 19.6217C24.1578 19.959 24.5035 20.3259 24.8181 20.7188C25.1325 21.1128 25.3867 21.5512 25.5726 22.0197C25.7704 22.5402 25.8663 23.094 25.855 23.6507C25.8623 24.2106 25.7387 24.7644 25.4939 25.2679C25.2601 25.7484 24.9014 26.1571 24.4554 26.4515C23.9751 26.762 23.4242 26.9462 22.8537 26.9869V28.564H22.0482Z"
      fill="#263238"
    />
    <path
      opacity="0.35"
      d="M27.9794 19.6001C30.7543 19.6001 33.0038 17.4218 33.0038 14.7347C33.0038 12.0476 30.7543 9.86926 27.9794 9.86926C25.2046 9.86926 22.9551 12.0476 22.9551 14.7347C22.9551 17.4218 25.2046 19.6001 27.9794 19.6001Z"
      fill="white"
    />
  </svg>
);

export default PiggyBank;
