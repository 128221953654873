import axios from 'axios';

const fetchSpinToWinData = async (setSpinToWinData) => {
  try {
    const res = await axios.get('widget/settings');
    setSpinToWinData(res.data.data);
  } catch (error) {
    console.log(error);
  }
};

export default fetchSpinToWinData;
